"use client"

import { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"

import { Box } from "@bullseye/components"

export const TopNavbarContainer = ({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <Box
      className={twMerge(
        "top-0 z-50 flex h-14 w-full flex-row items-center justify-between gap-2 bg-white px-4 md:shadow-sm",
        className,
      )}
    >
      {children}
    </Box>
  )
}
