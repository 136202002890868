import { get } from "lodash"
import resolveConfig from "tailwindcss/resolveConfig"

import tailwindConfig from "@bullseye/tailwind-config/index"

export const getTheme = () => resolveConfig(tailwindConfig)

export const getColor = (name: string) => {
  const { theme } = getTheme()
  const { colors } = theme as unknown as { colors: Record<string, string> }
  return get(
    colors,
    name?.replace(/^(bg-|text-|border-)/g, "").replace("-", "."),
  )
}
