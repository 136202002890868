"use client"

import { ReactNode } from "react"
import * as Sentry from "@sentry/nextjs"

import { LoggingContextProvider } from "./LoggingContextProvider"

const LoggingProvider = ({ children }: { children: ReactNode }) => {
  return (
    <LoggingContextProvider
      captureMessage={Sentry.captureMessage}
      captureException={(error: Error) => Sentry.captureException(error)}
    >
      {children}
    </LoggingContextProvider>
  )
}

export default LoggingProvider
