"use client"

import { FormattedMessage } from "react-intl"

import {
  useMessageChannelByStreamId,
  useUserMessageChannel,
} from "@bullseye/components"

import { AcceptMessagePrompt } from "./AcceptMessagePrompt"
import { NoReplyMessage } from "./NoReplyMessage"

type Props = {
  channelId?: string
  InputComponent: React.ComponentType<{ grow?: boolean }>
}

export const MessengerInput = ({ channelId, InputComponent }: Props) => {
  const { messageChannel } = useMessageChannelByStreamId(channelId)
  const { userMessageChannel } = useUserMessageChannel(messageChannel?.id)

  const isHankAdminUser =
    messageChannel?.creator_id === "a17d77d8-aa6a-4559-9a3a-d19bf872f185" ||
    messageChannel?.creator?.stream_id === "hello-gethank-com"

  const userIsSuspended = messageChannel?.dm_user?.is_suspended || false

  if (isHankAdminUser) {
    return (
      <NoReplyMessage
        message={<FormattedMessage id="messenger.hankUser.noReply" />}
      />
    )
  }

  if (userIsSuspended) {
    return (
      <NoReplyMessage
        message={<FormattedMessage id="messenger.suspendedUser.noReply" />}
      />
    )
  }

  if (!messageChannel || !userMessageChannel) return null

  if (!messageChannel.is_group_message && !userMessageChannel.is_accepted) {
    return <AcceptMessagePrompt messageChannel={messageChannel} />
  }

  return <InputComponent grow />
}
