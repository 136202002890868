"use client"

import React from "react"

import { Box, Button, ListDivider, LOGOUT_URI } from "@bullseye/components"

import { TopNavBarMenu } from "../components/TopNavBarMenu"
import { useAuthedItems } from "./useAuthedItems"

export const AuthedMenu = () => {
  const items = useAuthedItems()

  return (
    <TopNavBarMenu items={items}>
      <ListDivider />
      <Box className="px-0.5 pt-2 sm:px-3">
        <Button as="a" variant="tertiary" href={LOGOUT_URI}>
          Log out
        </Button>
      </Box>
    </TopNavBarMenu>
  )
}
