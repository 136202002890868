"use client"

import { Box, IconButton, P, TextButton } from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { PageHeader } from "../PageHeader"

type Props = {
  children: React.ReactNode
}

export const OnboardingLayout = ({ children }: Props) => {
  const router = useRouter()
  return (
    <Box className="h-screen">
      <PageHeader>
        <IconButton
          iconType="arrow-left"
          variant="filled"
          size="lg"
          handlePress={() => router.back()}
        />
      </PageHeader>
      {children}
      <Box className="flex h-12 w-full items-center justify-center border-t border-solid border-gray-100">
        <TextButton
          startIconType="mail"
          as="a"
          href="mailto:support@gethank.com"
          height="lg"
        >
          <P>support@gethank.com</P>
        </TextButton>
      </Box>
    </Box>
  )
}
