"use client"

import { ReactNode } from "react"

import {
  ACTIVITIES_URI,
  ATTENDING_URI,
  Box,
  ME_URI,
  MESSENGER_URI,
  MobileTabs,
  useDeviceContext,
  USER_HOME_URI,
  useUnreadCount,
  useUserContext,
} from "@bullseye/components"

import { AuthedNavbar } from "../../components/Navbar"

type Props = {
  children: ReactNode
  ExtraActions?: ReactNode
  PageTitle?: ReactNode
}

export const AuthedStandardLayout = ({
  PageTitle,
  ExtraActions = <></>,
  children,
}: Props) => {
  const { user } = useUserContext()
  const unreadCount = useUnreadCount()
  const { platform } = useDeviceContext()
  const isWeb = platform === "web"
  return (
    <>
      <AuthedNavbar
        PageTitle={PageTitle}
        ExtraActions={ExtraActions}
        user={user}
      />
      <Box className="grow">{children}</Box>
      {isWeb && (
        <MobileTabs
          items={[
            {
              icon: "home",
              titleKey: "navigation.home",
              title: "Home",
              uri: USER_HOME_URI,
            },
            {
              icon: "explore",
              titleKey: "navigation.explore",
              title: "Explore",
              uri: ACTIVITIES_URI,
            },
            {
              icon: "check-circle",
              titleKey: "navigation.attending",
              title: "Attending",
              uri: ATTENDING_URI,
            },
            {
              icon: "chat",
              titleKey: "navigation.messages",
              title: "Messages",
              uri: MESSENGER_URI,
              badge: unreadCount,
            },
            {
              titleKey: "navigation.profile",
              title: "Profile",
              avatar: {
                imageUrl: user.avatar?.url,
                name: user.formatted_name,
              },
              uri: ME_URI,
            },
          ]}
        />
      )}
    </>
  )
}
