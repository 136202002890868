"use client"

import { createContext, useContext } from "react"
import { StreamChat } from "stream-chat"

import { LoadingFullScreen } from "../../components/LoadingFullScreen"
import { useClient } from "./hooks/useClient"
import { useUnreadCount } from "./hooks/useUnreadCount"

type MessagingContextType = {
  client: StreamChat<any>
  totalUnreadCount: number
  isConnected: boolean
  isError: boolean
}

type MessagingProviderProps = {
  children: React.ReactNode
  config: {
    apiKey: string
  }
}

export const MessagingContext = createContext<MessagingContextType | null>(null)

export const useMessagingContext = () => {
  const ctx = useContext(MessagingContext)
  if (!ctx) {
    throw new Error(
      "useMessagingContext must be used within a MessagingProvider",
    )
  }
  return ctx
}

export const MessagingProvider = ({
  children,
  config: { apiKey },
}: MessagingProviderProps) => {
  const { client, error } = useClient(apiKey)
  const { unreadCount = 0 } = useUnreadCount(client)

  if (!client) return <LoadingFullScreen />

  return (
    <MessagingContext.Provider
      value={{
        client,
        totalUnreadCount: unreadCount,
        isConnected: !client,
        isError: !!error,
      }}
    >
      {children}
    </MessagingContext.Provider>
  )
}
