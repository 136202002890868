"use client"

import clsx from "clsx"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  Box,
  Button,
  Experience,
  H4,
  Icon,
  isWeb,
  makeMessengerUri,
  P,
  useExperienceMessageChannel,
  useExperienceUrl,
  User,
} from "@bullseye/components"

import { ActivityRemainingSpots, ShareButton } from "../../../../components"
import { AttendButton } from "./AttendButton"
import { ManageActivityButton } from "./ManageActivityButton"
import { WaitlistCurrentCount, WaitlistInfo } from "./WaitlistInfo"

type Props = {
  experience: Experience
  className?: string
  user?: User
}

const AttendingBanner = ({ experience, user }: Props) => {
  const isOrganizer = experience.organizer_id === user?.id
  const isAttending = experience.requester?.is_attending

  if (!isOrganizer && !isAttending) return null

  return (
    <Box className="flex-row items-center rounded-lg bg-green-50 p-4 g-2">
      <Box className="rounded-full bg-green-600 p-1">
        <Icon color="text-white" type="check" />
      </Box>
      <P>
        <FormattedMessage
          id={
            isOrganizer ? "activity.cta.isOrganizer" : "activity.cta.attending"
          }
        />
      </P>
    </Box>
  )
}

export const ActivityCTA = ({ experience, className, user }: Props) => {
  const experienceUrl = useExperienceUrl(experience.slug)
  const { messageChannel } = useExperienceMessageChannel(experience.id)
  const intl = useIntl()

  const isOrganizer = experience.organizer_id === user?.id

  const remainingSpots =
    experience.max_attendees > 0
      ? experience.max_attendees - experience.attendees_count
      : null

  return (
    <Box
      className={twMerge(
        "flex flex-col rounded-none bg-white p-6 g-4 md:rounded-lg",
        className,
      )}
    >
      <AttendingBanner experience={experience} user={user} />
      <Box className="flex flex-col g-1">
        {!!experience.ticket_price && (
          <H4>
            <FormattedNumber
              style="currency"
              currency="USD"
              value={experience.ticket_price ?? 0}
            />
          </H4>
        )}
        {remainingSpots !== null && (
          <ActivityRemainingSpots
            as={experience.ticket_price ? "p" : "h4"}
            spots={remainingSpots}
          />
        )}
        <WaitlistCurrentCount />
        <WaitlistInfo />
      </Box>
      <Box className="flex flex-col g-4">
        <Box className="g-2">
          <AttendButton />
          {(isOrganizer || user?.admin) && (
            <ManageActivityButton
              href={experienceUrl}
              experience={experience}
            />
          )}
          <Box
            className={clsx({
              hidden: !experience.requester?.is_attending && !isOrganizer,
            })}
          >
            <Button
              as="a"
              variant="secondary"
              startIconType="chat"
              href={makeMessengerUri(messageChannel?.stream_channel_id)}
            >
              <FormattedMessage id="general.ctas.openChat" />
            </Button>
          </Box>
        </Box>
        {isWeb() && (
          <Box className="-z-10">
            <ShareButton
              emailSubject={intl.formatMessage(
                {
                  id: "activity.share.subject",
                },
                { title: experience.title },
              )}
              message={intl.formatMessage(
                {
                  id: "activity.share.message",
                },
                { title: experience.title },
              )}
              url={experienceUrl}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
