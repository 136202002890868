import React from "react"
import Svg, { Path, Rect } from "react-native-svg"

export const Mastercard = () => (
  <Svg viewBox="0 0 64 40" fill="none">
    <Rect width="64" height="40" rx="4" fill="#0A3573" />
    <Path d="M38.231 10.464H25.569V31.31H38.23V10.464z" fill="#FF5F00" />
    <Path
      d="M26.873 20.889a13.321 13.321 0 011.32-5.794 13.216 13.216 0 013.703-4.627 13.06 13.06 0 00-13.875-1.5 13.189 13.189 0 00-5.4 4.89 13.334 13.334 0 00-2.003 7.033c0 2.487.694 4.924 2.004 7.033a13.188 13.188 0 005.4 4.89 13.06 13.06 0 0013.874-1.5 13.215 13.215 0 01-3.704-4.63 13.32 13.32 0 01-1.319-5.795z"
      fill="#EB001B"
    />
    <Path
      d="M52.022 28.215v-.427h.182V27.7h-.434v.088h.171v.427h.08zm.843 0V27.7h-.131l-.153.368-.154-.368h-.131v.515h.095v-.39l.142.335h.099l.142-.335v.39h.091zM53.177 20.889c0 2.487-.695 4.925-2.004 7.033a13.19 13.19 0 01-5.4 4.89 13.061 13.061 0 01-13.877-1.502 13.238 13.238 0 003.703-4.629 13.344 13.344 0 000-11.588 13.238 13.238 0 00-3.702-4.629 13.061 13.061 0 0113.876-1.502 13.188 13.188 0 015.4 4.89 13.334 13.334 0 012.004 7.033v.004z"
      fill="#F79E1B"
    />
  </Svg>
)
