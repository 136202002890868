"use client"

import React from "react"
import { Pressable } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { getColor } from "../../utils"
import { Box, BoxProps } from "../Layout"

const Container = styled(Pressable)

type Props = React.ComponentProps<typeof Container>

export const ListItem = ({ className, ...props }: Props) => {
  return (
    <Container
      {...props}
      className={twMerge(
        clsx(className, "w-full px-4 py-2 hover:bg-primary-50"),
      )}
      style={({ pressed }) => ({
        backgroundColor: pressed ? getColor("bg-primary-50") : undefined,
      })}
    />
  )
}

export const ListDivider = ({ className, ...props }: BoxProps) => (
  <Box
    {...props}
    className={twMerge(clsx("w-full border-b border-gray-100", className))}
  />
)
