"use client"

import { useRef, useState } from "react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Box,
  FormattedDateFilter,
  FormattedDistanceFilter,
  H2,
  HankLogo,
  IconButton,
  isWeb,
  ScrollBox,
} from "@bullseye/components"
import { ACTIVITIES_URI, POST_ACTIVITY_URI } from "@bullseye/routes"
import { User } from "@bullseye/types"

import { ActivitySearch, PostActivityButton } from "../../components"
import { PageHeader } from "../../layouts"
import { LocationFilter } from "./components"
import { ActivitiesList } from "./components/ActivitiesList"

type Props = {
  user?: User
}

export const ActivitiesListPage = ({ user }: Props) => {
  const [dateFilter, setDateFilter] = useState<{
    [k: string]: string | undefined
  }>()
  const dateFilterRef = useRef<{ reset(): void }>()
  const [distanceFilter, setDistanceFilter] = useState<Record<string, string>>()
  const distanceFilterRef = useRef<{ reset(): void }>()
  const [locationFilter, setLocationFilter] = useState<Record<string, string>>()

  const resetFilters = () => {
    dateFilterRef?.current?.reset()
    distanceFilterRef?.current?.reset()
  }

  return (
    <>
      <PageHeader
        className={clsx("bg-white py-2 md:hidden", {
          hidden: !user,
        })}
      >
        <HankLogo href={ACTIVITIES_URI} />
        <Box className="flex-row g-2">
          <ActivitySearch />
          <IconButton
            iconType="plus"
            size="lg"
            as="a"
            href={POST_ACTIVITY_URI}
          />
        </Box>
      </PageHeader>
      <Box className="z-[1000] flex w-full items-center bg-white px-4 py-4 md:py-6 md:g-6">
        <Box className="hidden w-full max-w-[1200px] flex-row items-center justify-between bg-white md:flex">
          <H2>
            <FormattedMessage id="navigation.explore" />
          </H2>
          {user && <PostActivityButton />}
        </Box>
        <ScrollBox
          className="no-scrollbar w-full max-w-[1200px] !overflow-x-scroll md:!overflow-visible"
          contentContainerStyle={{
            gap: 8,
          }}
          showsHorizontalScrollIndicator={false}
          horizontal
        >
          <LocationFilter
            user={user}
            onChange={(val) => {
              setLocationFilter(val)
            }}
          />
          <FormattedDateFilter
            onChange={(_, val) => {
              setDateFilter(val)
            }}
            storageKey="activities-date-filter"
            ref={dateFilterRef}
          />
          <FormattedDistanceFilter
            onChange={(_, val) => {
              setDistanceFilter(val)
            }}
            storageKey="activities-distance-filter"
            ref={distanceFilterRef}
          />
        </ScrollBox>
      </Box>
      <ActivitiesList
        resetFilters={resetFilters}
        filters={{
          limit: isWeb() ? "25" : "10",
          order_by: user ? "start_date" : "distance",
          canceled_at: "null",
          ...dateFilter,
          ...distanceFilter,
          ...locationFilter,
        }}
      />
    </>
  )
}
