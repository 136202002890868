"use client"

import { FormattedMessage } from "react-intl"

import {
  isWeb,
  ListItem,
  P,
  useHTTPContext,
  useUserContext,
} from "@bullseye/components"
import { Experience } from "@bullseye/types"

async function downloadAttendeesCSV(
  experience: Experience,
  apiBase: string,
  accessToken: string,
) {
  const res = await fetch(
    `${apiBase}/v1/experiences/${experience.id}/attendees/csv`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )

  const url = window.URL.createObjectURL(new Blob([await res.blob()]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${experience.title} attendees.csv`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const DownloadCSV = ({ experience }: { experience: Experience }) => {
  const { accessToken } = useUserContext()
  const { apiBase } = useHTTPContext()
  if (!isWeb()) return null
  return (
    <ListItem
      onPress={async () => {
        await downloadAttendeesCSV(experience, apiBase, accessToken)
      }}
    >
      <P>
        <FormattedMessage id="activity.manageActivity.downloadCsv" />
      </P>
    </ListItem>
  )
}
