import { useQueryClient } from "@tanstack/react-query"

import { PublicUser, UserRelationV1 } from "@bullseye/types"

import { useUserContext } from "../providers/UserAuthProvider/UserProvider"
import { useGetRequest } from "./useRequest"

type UserWithRelation = PublicUser & {
  relation: UserRelationV1
}

type UserData = {
  data: UserWithRelation[]
}

export const useChatUserSearch = (name: string | undefined | null) => {
  const { accessToken } = useUserContext()
  const client = useQueryClient()
  const { data, isLoading, error, refetch, key } = useGetRequest<UserData>(
    {
      method: "GET",
      path: "v1/chat/users",
      params: {
        name,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!accessToken && !!name,
    },
  )

  return {
    users: data?.data,
    isLoading,
    error,
    refetch,
    cancel: () => client.cancelQueries({ queryKey: key }),
  }
}
