"use client"

import React from "react"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  ACTIVITIES_URI,
  ATTENDING_URI,
  Badge,
  Box,
  HankLogo,
  isWeb,
  Link,
  ME_URI,
  MESSENGER_URI,
  P,
  User,
  USER_HOME_URI,
  UserAvatar,
  useUnreadCount,
} from "@bullseye/components"

import { TopNavbarContainer } from "../components/TopNavbarContainer"
import { AuthedMenu } from "./AuthedMenu"

type Props = {
  PageTitle?: React.ReactNode
  ExtraActions?: React.ReactNode
  user: User
}

const DefaultPageTitle = <HankLogo href={isWeb() ? USER_HOME_URI : undefined} />

export const AuthedNavbar = ({
  PageTitle = DefaultPageTitle,
  ExtraActions = <></>,
  user,
}: Props) => {
  const unreadMessages = useUnreadCount()

  const menuItems = [
    {
      title: "Activities",
      titleKey: "navigation.explore",
      uri: ACTIVITIES_URI,
      extraClassName: "md:block hidden",
    },
    {
      title: "Attending",
      titleKey: "navigation.attending",
      uri: ATTENDING_URI,
      extraClassName: "md:block hidden",
    },
    {
      title: "Messages",
      titleKey: "navigation.messages",
      uri: MESSENGER_URI,
      badgeCount: unreadMessages,
      extraClassName: "md:block hidden",
    },
  ]

  return (
    <TopNavbarContainer>
      <Box role="menu" className="flex flex-row items-center g-6">
        <Box role="menuitem" className="list-none">
          {PageTitle}
        </Box>
        {menuItems.map((item, idx) => (
          <Box
            role="menuitem"
            key={idx}
            className={twMerge("list-none", item.extraClassName)}
          >
            <Link href={item.uri}>
              <Badge
                badgeContent={item.badgeCount}
                position="right-middle"
                max={9}
              >
                <P className="font-semibold text-gray-950 no-underline">
                  <FormattedMessage
                    id={item.titleKey}
                    defaultMessage={item.title}
                    description="Navigation link"
                  />
                </P>
              </Badge>
            </Link>
          </Box>
        ))}
      </Box>
      <Box className="flex flex-row flex-nowrap items-center g-2">
        {ExtraActions}
        <Box className="hidden flex-row flex-nowrap items-center g-2 md:flex">
          <Link href={ME_URI}>
            <UserAvatar user={user} size="lg" />
          </Link>
          <AuthedMenu />
        </Box>
      </Box>
    </TopNavbarContainer>
  )
}
