"use client"

import { useEffect } from "react"

import { usePathname } from "@bullseye/navigation"

import { usePageview } from "../../hooks/usePageview"

export const WebTracking = () => {
  const path = usePathname()
  const pageView = usePageview()

  useEffect(() => {
    pageView(path, {
      path,
    })
  }, [path])

  return <></>
}
