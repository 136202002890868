"use client"

import { useCookies } from "react-cookie"

import { isWeb } from "../utils"

export const useImpersonationCookie = () => {
  if (!isWeb()) {
    throw new Error("hook called from unsupported platform.")
  }
  const [cookies] = useCookies(["impersonate_id"])

  return cookies
}
