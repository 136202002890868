import { useState } from "react"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  Sheet,
  TextInput,
  useLoggingContext,
  useUpdateUser,
  useUserContext,
} from "@bullseye/components"
import { Modal } from "@bullseye/types"

import { useToastContext } from "../../../providers"

export const EditBioModal = ({ isOpen, dismiss }: Modal) => {
  const { user, refetch } = useUserContext()
  const { update } = useUpdateUser()
  const [bio, setBio] = useState(user.bio)
  const [isLoading, setIsLoading] = useState(false)
  const { openErrorToast } = useToastContext()
  const { captureException } = useLoggingContext()

  async function handleSubmit() {
    try {
      setIsLoading(true)
      await update({ bio })
      await refetch()
      dismiss()
    } catch (error) {
      captureException(new Error(`Failed to update bio: ${error}`))
      openErrorToast({
        delayMs: 3000,
        label: <FormattedMessage id="errors.unknown" />,
      })
    }
    setIsLoading(false)
  }

  return (
    <Sheet
      title={<FormattedMessage id="profile.bio" />}
      show={isOpen}
      size="lg"
      dismiss={dismiss}
      icon="x"
      renderContent={() => {
        return (
          <Box className="flex g-4">
            <TextInput
              multiline
              label={<FormattedMessage id="profile.bio" />}
              value={bio}
              onChangeText={(text) => {
                setBio(text)
              }}
            />
            <Box className="flex flex-row g-2">
              <Button
                as="button"
                handlePress={() => setBio("")}
                variant="tertiary"
                className="flex-1"
                disabled={!bio || isLoading}
              >
                <FormattedMessage id="general.clear" />
              </Button>
              <Button
                as="button"
                handlePress={handleSubmit}
                loading={isLoading}
                className="flex-1"
              >
                <FormattedMessage id="general.submit" />
              </Button>
            </Box>
          </Box>
        )
      }}
    />
  )
}
