import { useMemo } from "react"

import { useAppConfig } from "../providers"
import { isWeb } from "../utils"

export const useExperienceUrl = (slug: string) => {
  const { hankAppUrl } = useAppConfig()
  return useMemo(() => {
    if (isWeb()) {
      return `${window.location.origin}/activities/${slug}`
    }
    return `${hankAppUrl}/activities/${slug}`
  }, [])
}
