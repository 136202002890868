import { useMemo } from "react"

import { useUserContext } from "../providers"
import { Experience, WaitlistElement } from "../types"
import { useDeleteRequest, useGetRequest, usePostRequest } from "./useRequest"

export const useWaitlist = (experience?: Experience) => {
  const { user, accessToken } = useUserContext()

  const enabled = !!accessToken && !!experience && experience?.max_attendees > 0
  const { data, isLoading, error, refetch, isRefetching } = useGetRequest<{
    data: WaitlistElement[]
  }>(
    {
      method: "GET",
      path: `v1/experiences/${experience?.id}/waitlist`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: enabled,
    },
  )

  const waitlist = data?.data

  const waitlistIndex = useMemo(() => {
    if (!waitlist || !user) return null

    const idx = waitlist.findIndex(
      (waitlistElement) => waitlistElement.user_id === user.id,
    )
    if (idx === -1) return null
    return idx
  }, [waitlist?.length])

  return {
    waitlist: data?.data,
    waitlistIndex,
    isLoading,
    error,
    refetch,
    isRefetching,
    enabled,
  }
}

export const useAddToWaitlist = (experience: Experience) => {
  const { accessToken } = useUserContext()

  const { postRequest, postRequestAsync, ...rest } = usePostRequest({
    path: `v1/experiences/${experience.id}/waitlist`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    addToWaitlist: () => postRequest({}),
    addToWaitlistAsync: () => postRequestAsync({}),
    ...rest,
  }
}

export const useRemoveFromWaitlist = (experience: Experience) => {
  const { accessToken } = useUserContext()

  const { deleteRequest, deleteRequestAsync, ...rest } = useDeleteRequest({
    path: `v1/experiences/${experience.id}/waitlist`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    removeFromWaitlist: () => deleteRequest({}),
    removeFromWaitlistAsync: () => deleteRequestAsync({}),
    ...rest,
  }
}
