"use client"

import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

import {
  Box,
  COMMUNITY_GUIDELINES_URL,
  H3,
  Icon,
  P,
  TextLink,
} from "@bullseye/components"

export const MessengerUserSuspended = ({ hidden }: { hidden: boolean }) => {
  if (hidden) return null
  return (
    <Box className={"flex-1 items-center justify-center p-10 g-4"}>
      <Icon type="user-outline" size="xxl" />
      <H3 className="text-center">
        <FormattedMessage id="messenger.suspendedUser.title" />
      </H3>
      <P className="text-center">
        <FormattedMessage
          id="messenger.suspendedUser.description"
          values={{
            a: (t: ReactNode[]) => (
              <TextLink
                variant="primary"
                label={t}
                href={COMMUNITY_GUIDELINES_URL}
              />
            ),
          }}
        />
      </P>
    </Box>
  )
}
