"use client"

import * as React from "react"
import { useEffect, useRef, useState } from "react"
import {
  Animated,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
} from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { Box } from "../Layout"
import { P } from "../Typography"

const TextInputStyled = styled(RNTextInput, "")
const AnimatedStyledView = styled(Animated.View)

type Sizes = "sm" | "md" | "lg"
type LabelPosition = "top" | "floating"

export type TextInputProps = Omit<RNTextInputProps, "multiline"> & {
  className?: string
  label?: React.ReactNode
  error?: boolean
  errorMessage?: React.ReactNode
  helperText?: React.ReactNode
  labelPosition?: LabelPosition
  size?: Sizes
  grow?: boolean
  maxHeight?: number
  disableFocusOutline?: boolean
  borderless?: boolean
  enabled?: boolean
} & (
    | {
        defaultHeight?: number
        multiline: true
      }
    | { multiline?: false; defaultHeight?: never }
  )

const sizesClassNameMap: { [k in Sizes]: string } = {
  sm: "min-h-10 h-10 max-h-10",
  md: "min-h-12 h-12 max-h-12",
  lg: "min-h-14 h-14 max-h-14",
}
const sizesToValue: { [k in Sizes]: number } = {
  sm: 40,
  md: 48,
  lg: 56,
}

export const TextInput = React.forwardRef(
  (
    {
      className,
      label,
      error,
      errorMessage,
      helperText,
      onFocus,
      onBlur,
      placeholder,
      labelPosition = "floating",
      size = "lg",
      editable = true,
      defaultHeight = 150,
      maxHeight = 150,
      grow = false,
      disableFocusOutline = false,
      borderless,
      ...props
    }: TextInputProps,
    ref,
  ) => {
    const [height, setHeight] = useState(defaultHeight)
    const [focused, setFocused] = useState(false)
    const isError = error || !!errorMessage
    const isLabelShifted =
      labelPosition === "floating" &&
      (Boolean(props.value) ||
        focused ||
        (Boolean(placeholder) && Boolean(editable)))
    const labelTranslateY = useRef(
      new Animated.Value(
        labelPosition !== "floating" ? 0 : sizesToValue[size] / 2 - 10,
      ),
    ).current

    useEffect(() => {
      let toValue = sizesToValue[size] / 2 - 10
      if (labelPosition === "top") {
        toValue = 0
      } else if (isLabelShifted) {
        toValue = size == "sm" ? 4 : size === "md" ? 6 : 10
      }

      Animated.timing(labelTranslateY, {
        toValue: toValue,
        duration: 150,
        useNativeDriver: true,
      }).start()
    }, [labelPosition, isLabelShifted, size])

    return (
      <Box
        className={twMerge(
          clsx(
            "relative inline-flex flex-col rounded-lg bg-primary-100 g-2",
            {
              "bg-white": labelPosition === "floating",
              "bg-transparent": !editable,
            },
            className,
          ),
        )}
        style={props.style}
      >
        {label && (
          <AnimatedStyledView
            className={clsx({
              absolute: labelPosition === "floating",
              "": labelPosition === "top",
            })}
            style={{
              transform: [
                {
                  translateY: labelTranslateY,
                },
                { translateX: labelPosition === "floating" ? 10 : 0 },
              ],
            }}
          >
            <P
              className={twMerge(
                clsx(
                  "text-gray-600",
                  { "text-red-600": isError },
                  labelPosition === "top" &&
                    "text-base font-semibold text-gray-950",
                  labelPosition === "floating" && {
                    "text-gray-300": !editable,
                    "text-xs": isLabelShifted,
                  },
                ),
              )}
            >
              {label}
            </P>
          </AnimatedStyledView>
        )}
        <Box
          className={twMerge(
            clsx(
              "z-10 rounded-lg border border-gray-100",
              { "border-none": borderless, "bg-gray-10": borderless },
              !props.multiline && sizesClassNameMap[size],
              {
                "bg-white": labelPosition === "top",
                "border-primary-500": !disableFocusOutline && focused,
                "bg-gray-10 text-gray-300": !editable,
                "bg-transparent": !editable,
                "border-red-600": isError,
              },
              props.multiline && {
                "px-2.5": true,
                "pt-1": !label,
                "pb-5": label,
                "py-2.5": labelPosition === "top",
                "pt-6": isLabelShifted && label,
                "pt-2": isLabelShifted && label && size === "sm",
              },
            ),
          )}
          style={{
            height: props.multiline ? height : sizesToValue[size],
            maxHeight: props.multiline ? maxHeight : sizesToValue[size],
          }}
        >
          {/* @ts-ignore */}
          <TextInputStyled
            ref={ref}
            aria-label={label}
            onFocus={(e) => {
              if (!Boolean(editable)) {
                return
              }
              setFocused(true)
              onFocus?.(e)
            }}
            onBlur={(e) => {
              setFocused(false)
              onBlur?.(e)
            }}
            onContentSizeChange={(e) => {
              if (!props.multiline && !grow) return

              const { height: newHeight } = e.nativeEvent.contentSize
              if (newHeight > maxHeight) return
              if (newHeight < defaultHeight) {
                setHeight(defaultHeight)
                return
              }

              setHeight(newHeight)
            }}
            className={twMerge(
              clsx(
                "h-full font-sans text-base text-black outline-none",
                { "text-gray-300": !editable || !props.value },

                !props.multiline && {
                  "px-2.5": true,
                  "pt-5": isLabelShifted && label,
                  "pt-3": isLabelShifted && label && size === "sm",
                },
              ),
            )}
            placeholder={editable ? placeholder : undefined}
            editable={editable}
            {...props}
          />
        </Box>
        {(errorMessage || helperText) && (
          <P
            className={clsx("text-sm text-gray-600", {
              "text-red-600": errorMessage,
            })}
          >
            {errorMessage || helperText}
          </P>
        )}
      </Box>
    )
  },
)

TextInput.displayName = "TextInput"
