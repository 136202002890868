import { PaymentMethod } from "@bullseye/types"

export const useCreditCardInfo = (paymentMethod?: PaymentMethod) => {
  const ccLabel = [
    paymentMethod?.card?.brand?.toUpperCase(),
    "••••",
    paymentMethod?.card?.last4,
  ].join(" ")

  const year = paymentMethod?.card?.exp_year?.toString().slice(-2)
  const ccDate = [paymentMethod?.card?.exp_month, year].join("/")

  return {
    number: ccLabel,
    date: ccDate,
  }
}
