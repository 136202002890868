"use client"

import { FormattedDate, FormattedTime } from "react-intl"

import { Box, Experience, Icon, P } from "@bullseye/components"

type Props = {
  experience: Experience
}

export const ActivityDate = ({
  experience: { start_date, timezone },
}: Props) => {
  if (!start_date) {
    return <P>Coming soon</P>
  }

  const startDate = new Date(start_date)

  return (
    <Box className="flex flex-row g-3">
      <Box>
        <Box className="overflow-hidden rounded-lg bg-gray-10 p-1.5">
          <Icon type="calendar" size="md" />
        </Box>
      </Box>
      <Box className="flex flex-col items-start g-1">
        <Box>
          <P bold>
            <FormattedDate
              value={startDate}
              timeZone={timezone}
              weekday="long"
              month="long"
              year="numeric"
              day="numeric"
            />
          </P>
        </Box>
        <Box>
          <P>
            <FormattedTime
              value={startDate}
              timeZone={timezone}
              hour="numeric"
              minute="numeric"
              timeZoneName="short"
            />
          </P>
        </Box>
      </Box>
    </Box>
  )
}
