"use client"

import { useState } from "react"
import { Image } from "react-native"
import { FormattedMessage } from "react-intl"

import { Box, Button, Loading, useUserContext } from "@bullseye/components"

import { OnboardingStep } from "../../OnboardingStep"
import { useProfilePhotoCtx } from "../providers/ProfilePhotoContext"

type Props = {
  goBack: () => void
}

export const PreviewPhoto = ({ goBack }: Props) => {
  const { capturedPhoto, setAvatar } = useProfilePhotoCtx()
  const { user } = useUserContext()
  const [isLoading, setIsLoading] = useState(false)

  async function handleUpload() {
    setIsLoading(true)
    await setAvatar()
    setIsLoading(false)
  }

  return (
    <OnboardingStep
      title={
        <FormattedMessage
          id="photoUpload.upload.title"
          values={{ name: user.first_name }}
        />
      }
      description={<FormattedMessage id="photoUpload.upload.description" />}
      imageComponent={
        <Box className="flex h-[250px] w-[250px] items-center justify-center overflow-hidden rounded-full bg-gray-100">
          {isLoading ? (
            <Loading />
          ) : (
            <Image
              source={{ uri: capturedPhoto?.image?.url }}
              style={{ width: 250, height: 250 }}
            />
          )}
        </Box>
      }
      secondaryAction={
        <Button
          as="button"
          height="lg"
          handlePress={goBack}
          variant="secondary"
          disabled={isLoading}
        >
          <FormattedMessage id="photoUpload.upload.cancel" />
        </Button>
      }
      primaryAction={
        <Button
          as="button"
          height="lg"
          handlePress={handleUpload}
          disabled={isLoading}
        >
          <FormattedMessage id="photoUpload.upload.continue" />
        </Button>
      }
    />
  )
}
