"use client"

import { useEffect } from "react"
import { useWindowDimensions, ViewProps } from "react-native"
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated"
import { SafeAreaView } from "react-native-safe-area-context"
import clsx from "clsx"
import { styled } from "nativewind"

import { isWeb } from "../../utils"
import { IconProps } from "../Icon"
import { IconButton } from "../IconButton"
import { Box } from "../Layout"
import { TextButton } from "../TextButton"
import { P } from "../Typography"

export type ToastProps = {
  id?: string
  label: React.ReactNode
  show?: boolean
  delayMs?: number
  preventDismiss?: boolean
  onPressDismiss?: () => void
  onDismiss?: VoidFunction
  position?:
    | "top-left"
    | "top-center"
    | "top-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right"
  primaryAction?: React.ReactNode
  secondaryAction?: React.ReactNode
  iconType?: IconProps["type"]
  iconColor?: IconProps["color"]
  iconBgColor?: IconProps["color"]
  toastContainerStyle?: ViewProps["style"]
  onLayout?: ViewProps["onLayout"]
}

const StyledView = styled(Animated.View)
const StyledSafeArea = styled(SafeAreaView)

export const ToastButton = TextButton

const DEFAULT_DISMISS_DELAY = 10000

export const Toast = ({
  position = "bottom-right",
  label,
  show = false,
  preventDismiss = false,
  delayMs = DEFAULT_DISMISS_DELAY,
  onPressDismiss,
  onDismiss,
  primaryAction,
  secondaryAction,
  iconType,
  iconBgColor = "bg-green-500",
  iconColor = "text-white",
  toastContainerStyle,
  onLayout,
}: ToastProps) => {
  const { height: windowHeight } = useWindowDimensions()

  const [positionY, positionX] = position.split("-")

  const startPositionY = positionY === "top" ? -windowHeight : windowHeight

  const translateY = useSharedValue(startPositionY)

  const modalBodyStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: translateY.value,
        },
      ],
    }
  }, [translateY])

  const dismissWithAnimation = () => {
    translateY.value = withTiming(startPositionY, { duration: 300 })
    onDismiss()
  }

  useEffect(() => {
    if (show) {
      translateY.value = withTiming(0, { duration: 300 })
    } else {
      translateY.value = withTiming(startPositionY, { duration: 300 })
    }
  }, [show])

  useEffect(() => {
    if (!onDismiss || preventDismiss) return
    const timeout = setTimeout(() => {
      dismissWithAnimation()
    }, delayMs)
    return () => clearTimeout(timeout)
  }, [show])

  return (
    <StyledSafeArea
      className={clsx("bottom-0 left-0 right-0 top-0 z-[999] h-full w-full", {
        absolute: !isWeb(),
        fixed: isWeb(),
        hidden: !show,
      })}
      pointerEvents="box-none"
    >
      <StyledView
        className={clsx("flex h-full px-4", {
          "justify-start": positionY === "top",
          "justify-end": positionY === "bottom",
          "items-center": positionX === "center",
          "items-start": positionX === "left",
          "items-end": positionX === "right",
        })}
        style={[modalBodyStyles]}
        pointerEvents="box-none"
      >
        <Box
          className="inline-flex flex-row items-center overflow-hidden rounded-md bg-gray-900 p-4 drop-shadow g-2"
          style={[toastContainerStyle]}
          onLayout={onLayout}
        >
          {iconType && (
            <IconButton
              iconType={iconType}
              iconColor={iconColor}
              color={iconBgColor}
              size="sm"
            />
          )}
          <P size="sm" className="max-w-[400px] text-white">
            {label}
          </P>
          {secondaryAction}
          {primaryAction}
          {onPressDismiss && (
            <IconButton
              iconType="x"
              color="bg-gray-900"
              iconColor="text-white"
              size="sm"
              handlePress={onPressDismiss}
            />
          )}
        </Box>
      </StyledView>
    </StyledSafeArea>
  )
}
