"use client"

import { Share } from "react-native"
import { useIntl } from "react-intl"

import { isWeb, useLoggingContext } from "@bullseye/components"

import { UnauthedAppModal, useUnauthedAppModalContext } from "../../components"
import { useToastContext } from "../../providers"

type ShareArgs = {
  emailSubject: string
  message: string
  url: string
}

export const useHandleShare = () => {
  const intl = useIntl()
  const { openModal } = useUnauthedAppModalContext()
  const { openSuccessToast, openErrorToast } = useToastContext()
  const { captureException } = useLoggingContext()

  async function handleShare({ emailSubject, message, url }: ShareArgs) {
    try {
      if (isWeb()) {
        // use navigator.share on mobile web
        if (navigator.share) {
          await navigator.share({
            title: emailSubject,
            text: message,
          })
          return
        }

        // otherwise, display the share sheet
        openModal(UnauthedAppModal.Share, {
          url,
          message,
          emailSubject,
          onCopy: (success: boolean) => {
            if (success) {
              openSuccessToast({
                label: intl.formatMessage({ id: "general.copied.success" }),
              })
              return
            }

            openErrorToast({
              label: intl.formatMessage({ id: "general.copied.error" }),
            })
          },
        })
        return
      }

      await Share.share(
        {
          message,
          url,
        },
        {
          subject: emailSubject,
        },
      )
    } catch (error) {
      captureException(new Error(`Error sharing experience: ${error}`))
    }
  }

  return handleShare
}
