"use client"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Chip,
  H4,
  Loading,
  P,
  PublicUser,
  useUserInterests,
  useUserPrompts,
} from "@bullseye/components"

export function UserAbout({ user }: { user: PublicUser }) {
  const { data: interests, isLoading: loadingInterests } = useUserInterests(
    user.id,
  )
  const { data: prompts, isLoading: loadingPrompts } = useUserPrompts(user.id)

  const requiredProps = [
    user.bio,
    user.reasons_for_joining,
    user.family,
  ].filter(Boolean)

  if (loadingInterests || loadingPrompts) {
    return (
      <Box className="flex w-full justify-center">
        <Loading />
      </Box>
    )
  }

  const profileEmpty =
    !requiredProps.length && !interests?.length && !prompts?.length
  if (profileEmpty) {
    return (
      <Box className="w-full bg-white p-4">
        <Box className="flex w-full items-center justify-center overflow-hidden rounded-lg bg-gray-10 p-4 text-center">
          <P bold>
            <FormattedMessage id="profile.incomplete" />
          </P>
        </Box>
      </Box>
    )
  }

  return (
    <Box className="flex bg-white p-4 g-8">
      <Box
        className={clsx("flex items-start g-2", {
          hidden: !user.bio,
        })}
      >
        <H4 className="text-center text-gray-600">
          <FormattedMessage id="profile.bio" />
        </H4>
        <P>{user.bio}</P>
      </Box>
      <Box
        className={clsx("flex items-start g-2", {
          hidden: !user.reasons_for_joining?.length,
        })}
      >
        <H4 className="text-center text-gray-600">
          <FormattedMessage id="profile.reasonForJoining" />
        </H4>
        <Box className="flex-row flex-wrap g-2">
          {user.reasons_for_joining?.map((reason) => (
            <Chip
              size="md"
              color="purple"
              key={reason}
              label={<FormattedMessage id={`profile.reasons.${reason}`} />}
            />
          ))}
        </Box>
      </Box>
      <Box
        className={clsx("flex items-start g-2", {
          hidden: !user.family?.length,
        })}
      >
        <H4 className="text-center text-gray-600">
          <FormattedMessage id="profile.family" />
        </H4>
        <Box className="flex-row flex-wrap g-2">
          {user.family?.map((family) => (
            <Chip
              size="md"
              color="purple"
              key={family}
              label={<FormattedMessage id={`profile.family.${family}`} />}
            />
          ))}
        </Box>
      </Box>
      <Box
        className={clsx("flex items-start g-2", {
          hidden: !interests?.length,
        })}
      >
        <H4 className="text-center text-gray-600">
          <FormattedMessage id="profile.interests" />
        </H4>
        <Box className="flex-row flex-wrap g-2">
          {interests?.map((interest) => (
            <Chip
              size="md"
              color="purple"
              key={interest.id}
              label={interest.title}
            />
          ))}
        </Box>
      </Box>
      <Box
        className={clsx("flex items-start g-2", {
          hidden: !prompts?.length,
        })}
      >
        <H4 className="text-center text-gray-600">
          <FormattedMessage id="profile.prompts" />
        </H4>
        <Box className="flex-row flex-wrap g-2">
          {prompts?.map((prompt) => (
            <Box
              key={prompt.id}
              className="flex w-full overflow-hidden rounded-lg bg-primary-50 p-4 g-2"
            >
              <P className="text-gray-400">
                <FormattedMessage
                  id={`profile.prompts.${prompt.profile_prompt_id}`}
                />
              </P>
              <P>{prompt.response}</P>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
