import { EventProperties } from "@segment/analytics-next"

import { useAnalyticsContext } from "../hooks"

export const usePageview = () => {
  const analytics = useAnalyticsContext()

  return (page: string, properties: EventProperties) => {
    void analytics.client.page(page, properties)
  }
}

type ScreenProperties = {
  screen?: string
  path?: string
  initial?: string
  params?: string
  state?: string
}

export const useScreenview = () => {
  const analytics = useAnalyticsContext()

  return (screen: string, properties?: ScreenProperties) => {
    void analytics.client.screen(screen, properties)
  }
}
