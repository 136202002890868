"use client"

import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Box,
  Button,
  H3,
  P,
  Sheet,
  useCancelExperience,
  useExperienceContext,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

import { useToastContext } from "../../../providers"

export const CancelActivity = ({ isOpen, dismiss }: Modal) => {
  const { experience, refetch } = useExperienceContext()
  const { cancelExperience } = useCancelExperience(experience.id)
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const { openErrorToast } = useToastContext()

  return (
    <Sheet
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="flex pb-10 g-4">
            <H3>
              <FormattedMessage id="activity.cancel.title" />
            </H3>
            <P>
              <FormattedMessage id="activity.cancel.description" />
            </P>
          </Box>
        )
      }}
      renderFooter={() => {
        return (
          <Box className="flex-row items-center justify-around g-2">
            <Box className="w-6/12">
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => dismiss()}
                disabled={isLoading}
              >
                <FormattedMessage id="general.dismiss" />
              </Button>
            </Box>
            <Box className="w-6/12">
              <Button
                as="button"
                variant="danger"
                loading={isLoading}
                handlePress={async () => {
                  setIsLoading(true)
                  try {
                    await cancelExperience()
                    await refetch()
                  } catch (error) {
                    openErrorToast({
                      label: intl.formatMessage({
                        id: "errors.unknown",
                      }),
                    })
                  }
                  setIsLoading(false)
                  dismiss()
                }}
              >
                <FormattedMessage id="general.confirm" />
              </Button>
            </Box>
          </Box>
        )
      }}
    />
  )
}
