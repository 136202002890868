"use client"

import { FormattedMessage } from "react-intl"

import { Button, makeMessengerUri } from "@bullseye/components"

export const OpenActivityChatButton = ({
  isEnabled,
  channelId,
}: {
  isEnabled: boolean
  channelId?: string
}) => {
  return (
    <Button
      disabled={!isEnabled}
      startIconType={isEnabled ? "chat" : "lock"}
      as="a"
      href={makeMessengerUri(channelId)}
      variant="secondary"
    >
      {isEnabled ? (
        <FormattedMessage id="general.ctas.openChat" />
      ) : (
        <FormattedMessage id="activity.chat.register" />
      )}
    </Button>
  )
}
