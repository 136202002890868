"use client"

import { format, subHours } from "date-fns"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  Experience,
  H3,
  Loading,
  P,
  Sheet,
  useCancelAttendance,
  useExperienceContext,
  useInvalidateExperienceAttendees,
  useUserContext,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export type CancelAttendanceModalProps = {
  refetch: () => Promise<any>
  userId: string
}

function formatCancelBy(startDate: string) {
  const adjustedDate = subHours(new Date(startDate), 12)

  return format(adjustedDate, "EEE, MMM. dd 'at' h:mm a")
}

const CancelOtherRSVPContent = () => (
  <>
    <H3>
      <FormattedMessage id="activity.attend.cancelModal.other.title" />
    </H3>
    <P>
      <FormattedMessage id="activity.attend.cancelModal.other.description" />
    </P>
  </>
)

const CancelOwnRSVPContent = ({ experience }: { experience: Experience }) => (
  <>
    <H3>
      {experience.no_show_fee ? (
        <FormattedMessage id="activity.attend.cancelModal.description" />
      ) : (
        <FormattedMessage id="activity.attend.cancelModal.title" />
      )}
    </H3>
    <P>
      {experience.no_show_fee ? (
        <FormattedMessage
          id="activity.attend.cancelModal.description.withFee"
          values={{
            price: experience.no_show_fee,
            cancelBy: formatCancelBy(experience.start_date),
          }}
        />
      ) : (
        <FormattedMessage id="activity.attend.cancelModal.description" />
      )}
    </P>
  </>
)

export const CancelAttendance = ({
  isOpen,
  dismiss,
  refetch,
  userId,
}: Modal<CancelAttendanceModalProps>) => {
  const { experience } = useExperienceContext()
  const invalidate = useInvalidateExperienceAttendees(experience.id)
  const { user } = useUserContext()

  const { cancelAttendanceAsync, isLoading } = useCancelAttendance(
    experience.id,
    userId,
  )

  return (
    <Sheet
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        const isAttending = experience.requester?.is_attending
        if (isAttending) {
          return (
            <Box className="flex pb-10 g-4">
              <CancelOwnRSVPContent experience={experience} />
            </Box>
          )
        }

        const isOrganizer = userId === experience.organizer_id
        if (user.admin || isOrganizer) {
          return (
            <Box className="flex pb-10 g-4">
              <CancelOtherRSVPContent />
            </Box>
          )
        }

        return (
          <Box className="flex items-center justify-center pb-10">
            <Loading />
          </Box>
        )
      }}
      renderFooter={() => {
        return (
          <Box className="flex-row items-center justify-around g-2">
            <Box className="w-6/12">
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => dismiss()}
                disabled={isLoading}
              >
                <FormattedMessage id="general.dismiss" />
              </Button>
            </Box>
            <Box className="w-6/12">
              <Button
                as="button"
                variant="danger"
                loading={isLoading}
                handlePress={async () => {
                  await cancelAttendanceAsync({})
                  dismiss()
                  return Promise.all([
                    refetch().catch(() => undefined),
                    invalidate(),
                  ])
                }}
              >
                <FormattedMessage id="general.confirm" />
              </Button>
            </Box>
          </Box>
        )
      }}
    />
  )
}
