"use client"

import { Pressable } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { type IconProps } from "../Icon"
import { IconButton, type IconButtonProps } from "../IconButton"
import { P } from "../Typography"

const Container = styled(
  Pressable,
  twMerge(
    "flex flex-row justify-center items-center",
    "border border-solid",
    "h-fit inline-flex rounded-full px-2 py-1",
    "gap-2",
  ),
)

type Props = {
  label: React.ReactNode
  handlePress?: () => void
  iconType?: IconProps["type"]
  size?: "sm" | "md"
  variant?: "outlined" | "filled"
  color: "purple" | "gray" | "green" | "orange" | "red" | "blue"
  iconColor?: IconButtonProps["iconColor"]
}

const colors = {
  purple: "bg-purple-50 border-purple-50",
  gray: "bg-gray-50 border-gray-50",
  green: "bg-green-50 border-green-50",
  orange: "bg-orange-50 border-orange-50",
  red: "bg-red-50 border-red-50",
  blue: "bg-blue-50 border-blue-50",
}

export const Chip = ({
  label,
  handlePress,
  iconType,
  size = "sm",
  color = "gray",
  variant = "filled",
  iconColor,
}: Props) => {
  return (
    <Container
      role={handlePress ? "button" : "none"}
      className={clsx(
        {
          "border-gray-100": color === "gray" && variant === "outlined",
          "bg-white": variant === "outlined",
          "h-9": size === "md",
          "p-1.5": size === "md",
          "justify-between": iconType,
        },
        colors[color],
      )}
      onPress={handlePress}
      disabled={!handlePress}
    >
      <P size={size === "sm" ? "sm" : "md"}>{label}</P>
      {iconType && (
        <IconButton
          className="items-center justify-center"
          size={size}
          iconType={iconType}
          handlePress={handlePress}
          color="bg-transparent"
          iconColor={iconColor}
        />
      )}
    </Container>
  )
}
