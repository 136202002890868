"use client"

import { useState } from "react"
import { FormattedMessage } from "react-intl"

import {
  Box,
  EmptyState,
  Experience,
  H4,
  isWeb,
  Loading,
  useUserContext,
  useUserExperiences,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import { ACTIVITIES_URI } from "@bullseye/routes"
import { PublicUser } from "@bullseye/types"

import { ActivityCard } from "../../../components"

const ActivitiesList = ({
  experiences,
  past,
  isLoading,
}: {
  past?: boolean
  experiences?: Experience[]
  isLoading: boolean
}) => {
  const { user: currentUser } = useUserContext()
  const router = useRouter()

  if (isLoading) {
    return (
      <Box>
        <H4>
          <FormattedMessage id={past ? "general.past" : "general.upcoming"} />
        </H4>
        <Box className="flex h-[200px] items-center justify-center">
          <Loading />
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <H4>
        <FormattedMessage id={past ? "general.past" : "general.upcoming"} />
      </H4>
      {experiences.map((exp) => {
        return (
          <ActivityCard
            key={exp.id}
            data={exp}
            currentUserId={currentUser.id}
          />
        )
      })}
      {!experiences?.length && (
        <EmptyState
          icon="calendar"
          buttonProps={{
            handlePress: () => {
              void router.push(ACTIVITIES_URI)
            },
            children: "Explore activities",
          }}
          description={
            <FormattedMessage
              id={past ? "general.noPast" : "general.noUpcoming"}
            />
          }
        />
      )}
    </Box>
  )
}

type Props = {
  user: PublicUser
}

export function UserActivities({ user }: Props) {
  const [now] = useState(new Date())
  const future = useUserExperiences(user, {
    "start_date[gte]": now.toISOString(),
    canceled_at: "null",
    limit: isWeb() ? "25" : "5",
    order_by: "start_date",
    direction: "asc",
  })
  const past = useUserExperiences(user, {
    "start_date[lte]": now.toISOString(),
    "start_date[not]": "null",
    order_by: "start_date",
    direction: "desc",
    limit: isWeb() ? "25" : "5",
  })

  return (
    <Box className="w-full p-4 g-4">
      <ActivitiesList
        experiences={future.data?.pages?.flatMap((page) => page.results)}
        isLoading={future.isLoading}
      />
      <ActivitiesList
        past
        experiences={past.data?.pages?.flatMap((page) => page.results)}
        isLoading={past.isLoading}
      />
    </Box>
  )
}
