"use client"

import { FormattedMessage } from "react-intl"

import { Box, Icon, IconButton, P } from "@bullseye/components"

type Props = {
  noShowFee: string
}

export const ActivityNoShowPolicy = ({ noShowFee }: Props) => {
  return (
    <Box className="flex flex-row items-start g-3">
      <Box className="overflow-hidden rounded-lg bg-gray-10 p-1.5">
        <Icon type="info-circle" size="md" />
      </Box>
      <Box className="flex-shrink flex-col items-start g-1">
        <Box className="flex flex-row items-center g-1">
          <P bold>
            <FormattedMessage id="activity.noShowPolicy.title" />
          </P>
          <IconButton
            as="a"
            size="sm"
            color="bg-white"
            iconColor="text-gray-600"
            href="https://help.gethank.com/en/articles/8927787-no-shows-and-cancellations"
            iconType="question-circle"
          />
        </Box>
        <P>
          <FormattedMessage
            id="activity.noShowPolicy.description"
            values={{ price: noShowFee }}
          />
        </P>
      </Box>
    </Box>
  )
}
