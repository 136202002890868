"use client"

import { FormattedMessage } from "react-intl"

import { Button, POST_ACTIVITY_URI } from "@bullseye/components"

export const PostActivityButton = () => {
  return (
    <Button
      as="a"
      href={POST_ACTIVITY_URI}
      variant="highlight"
      startIconType="plus"
      rounded
      compact
    >
      <FormattedMessage id="general.postActivity" />
    </Button>
  )
}
