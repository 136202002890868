"use client"

import { twMerge } from "tailwind-merge"

import {
  Avatar,
  Box,
  Experience,
  H3,
  P,
  SIGN_UP_URI,
  TextButton,
} from "@bullseye/components"

import { ActivityDate, ActivityNoShowPolicy } from "../../../../components"
import { UnauthedActivityLocation } from "../UnauthedActivityLocation"

export function UnauthedActivityInfo({
  experience,
}: {
  experience: Experience
}) {
  return (
    <Box
      className={twMerge(
        "flex flex-col rounded-none bg-white p-6 g-6 md:rounded-lg",
      )}
    >
      <Box className="flex flex-col g-6">
        <H3>{experience.title}</H3>
        {experience.organizer && (
          <Box className="flex flex-row items-center g-3">
            <Avatar
              imageUrl={experience.organizer.avatar?.url}
              size="md"
              name={experience.organizer.formatted_name ?? ""}
            />
            <Box className="flex-row">
              <P className="flex flex-row items-center justify-center">
                Hosted by{" "}
              </P>
              <TextButton as="a" href={SIGN_UP_URI}>
                {experience.organizer.formatted_name}
              </TextButton>
            </Box>
          </Box>
        )}
        <ActivityDate experience={experience} />
        <UnauthedActivityLocation experience={experience} />
        {experience.no_show_fee && (
          <ActivityNoShowPolicy noShowFee={experience.no_show_fee} />
        )}
      </Box>
    </Box>
  )
}
