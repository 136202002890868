import { isWeb } from "./device"
import { MESSENGER_URI } from "./routes"

export const makeMessengerUri = (channelId?: string) => {
  const uri = MESSENGER_URI

  if (!channelId) return uri

  if (isWeb()) {
    return `${uri}?channel=${channelId}`
  }

  return `${uri}/${channelId}`
}
