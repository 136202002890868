"use client"

import clsx from "clsx"
import { twMerge } from "tailwind-merge"

import { Box, P } from "@bullseye/components"

export type SingleSetting = {
  label: JSX.Element | string
  description?: JSX.Element | string
  enabled?: boolean
}

export const SettingElement = ({
  settingLabel,
  children: children,
  disabled,
  className,
}: {
  settingLabel: SingleSetting
  children: React.ReactElement
  disabled?: boolean
  className?: string
}) => (
  <Box className={twMerge("w-full py-4", className)}>
    <Box
      className={clsx(
        "flex flex-row flex-nowrap items-center justify-between g-10",
        { "opacity-25": disabled },
      )}
    >
      <Box className="max-w-[255px] shrink g-1 md:max-w-full">
        <P className="text-base font-normal text-zinc-800">
          {settingLabel.label}
        </P>
        {settingLabel.description && (
          <P className="text-sm text-zinc-600">{settingLabel.description}</P>
        )}
      </Box>
      <Box className="flex-row items-center g-3">{children}</Box>
    </Box>
  </Box>
)
