"use client"

import { createContext, useContext, useState } from "react"

import { dismissKeyboardAsync } from "@bullseye/components"

import { ChannelOptions, ChannelOptionsModalProps } from "./ChannelOptions"
import { NewMessage } from "./NewMessage"

export enum MessengerModal {
  NewMessage = "NewMessage",
  ChannelOptions = "ChannelOptions",
}

type Props = {
  children: React.ReactNode
}

type OpenModalMethods =
  // NewMessage
  ((id: MessengerModal.NewMessage) => void) &
    // ChannelOptions
    ((
      id: MessengerModal.ChannelOptions,
      props: ChannelOptionsModalProps,
    ) => void)

type ModalProps = ChannelOptionsModalProps | null

const MessengerModalContext = createContext<{
  openModal: OpenModalMethods
  closeModal: (modal: MessengerModal) => void
} | null>(null)

export const useMessengerModalContext = () => {
  const ctx = useContext(MessengerModalContext)
  if (ctx === null) {
    throw new Error(
      "useMessengerModalContext must be used within a MessengerModalProvider",
    )
  }
  return ctx
}

export const MessengerModalProvider = ({ children }: Props) => {
  const [openModals, setOpenModals] = useState<Record<MessengerModal, boolean>>(
    {
      NewMessage: false,
      ChannelOptions: false,
    },
  )

  const [extraData, setExtraData] = useState<ModalProps | null>(null)

  function closeModal(id: string) {
    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: false }
    })
  }

  async function openModal(id: string, props?: unknown) {
    await dismissKeyboardAsync()

    if (props) {
      setExtraData(props as ModalProps)
    }
    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: true }
    })
  }

  return (
    <MessengerModalContext.Provider
      value={{
        closeModal,
        openModal,
      }}
    >
      {children}
      <ChannelOptions
        isOpen={openModals.ChannelOptions}
        dismiss={() => closeModal(MessengerModal.ChannelOptions)}
        {...extraData}
      />
      <NewMessage
        isOpen={openModals.NewMessage}
        dismiss={() => closeModal(MessengerModal.NewMessage)}
      />
    </MessengerModalContext.Provider>
  )
}
