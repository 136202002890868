"use client"

import clsx from "clsx"

import {
  Autocomplete,
  AutocompleteItem,
  Box,
  P,
  TextInputProps,
  usePlacesTextSearch,
} from "@bullseye/components"
import { PlaceResult } from "@bullseye/types"

type FormattedPlace = PlaceResult & {
  formattedPlaceName?: string
}

type Props = TextInputProps & {
  onDismiss?: () => void
  onSelect: (place: FormattedPlace) => void
  disabled?: boolean
}

export const SearchPlaces = ({
  onSelect,
  value,
  onChangeText,
  onDismiss,
  disabled,
  ...inputProps
}: Props) => {
  const { data } = usePlacesTextSearch(value)

  return (
    <Autocomplete<FormattedPlace>
      value={value}
      onChangeText={(text) => {
        onChangeText?.(text)
      }}
      onDismiss={onDismiss}
      disabled={disabled}
      inputMode="text"
      returnKeyType="done"
      data={data}
      dropdownClassname="w-full mt-4 cursor-pointer py-0"
      renderItem={({ item, index, dismiss }) => {
        if (!data) return null

        const isFirst = index === 0
        const isLast = index === data.length - 1
        return (
          <Box
            className={clsx("border-b border-solid border-gray-100", {
              "border-t-0 md:border-t-0": isFirst,
              "md:border-b-0": isLast,
            })}
            key={item.id}
          >
            <AutocompleteItem
              item={item}
              onPress={(place) => {
                const placeName = [
                  place.displayName.text,
                  place.formattedAddress,
                ]

                if (placeName[1]?.startsWith(placeName[0])) {
                  placeName.splice(0, 1)
                }

                place.formattedPlaceName = placeName.join(", ")
                onSelect(place)
                dismiss()
              }}
              className={clsx({
                "md:rounded-t-lg": isFirst,
                "md:rounded-b-lg": isLast,
              })}
            >
              <P>{item.displayName.text}</P>
              <P size="sm" className="text-gray-600">
                {item.formattedAddress}
              </P>
            </AutocompleteItem>
          </Box>
        )
      }}
      {...inputProps}
    />
  )
}
