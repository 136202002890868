"use client"

import { useEffect, useState } from "react"
import { Pressable } from "react-native"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Avatar,
  Box,
  Loading,
  makeMessengerUri,
  P,
  ScrollBox,
  Sheet,
  TextInput,
  useChatUserSearch,
  useCreateMessageChannel,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import type { Modal } from "@bullseye/types"

export const NewMessage = ({ isOpen, dismiss }: Modal) => {
  const { createMessageChannel, data, status } = useCreateMessageChannel()
  const [query, setQuery] = useState<string>("")
  const { users = [] } = useChatUserSearch(query)
  const router = useRouter()

  useEffect(() => {
    if (!data || status !== "success") return

    router.push(makeMessengerUri(data.stream_channel_id))
    dismiss()
  }, [status, data])

  return (
    <Sheet
      title={<FormattedMessage id="messenger.modal.newMessage.title" />}
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      contentContainerStyle="overflow-hidden"
      size="lg"
      renderContent={() => {
        return (
          <Box className="h-full w-full flex-col items-start g-6">
            <TextInput
              editable
              className="w-full"
              placeholder="Start typing for suggestions..."
              onChangeText={(text) => setQuery(text)}
              value={query}
            />
            <ScrollBox
              className={clsx("max-h-full w-full md:max-h-[80%]", {
                hidden: status === "loading",
              })}
              contentContainerStyle={{
                gap: 8,
              }}
            >
              {users.map((user) => {
                const [commonExp] = user.relation.experiences || []

                return (
                  <Pressable
                    key={user.id}
                    onPress={() => {
                      createMessageChannel({
                        body: {
                          is_group_message: false,
                          users: [{ user_id: user.id }],
                        },
                      })
                    }}
                  >
                    <Box className="flex-row items-center g-3">
                      <Avatar
                        imageUrl={user.avatar?.url}
                        name={user.formatted_name}
                        size="xl"
                      />
                      <Box className="flex g-1">
                        <P>{user.formatted_name}</P>
                        <P className="text-gray-600" size="sm">
                          {commonExp?.title}
                        </P>
                      </Box>
                    </Box>
                  </Pressable>
                )
              })}
            </ScrollBox>
            <Box
              className={clsx("flex h-full w-full items-center", {
                hidden: status !== "loading",
              })}
            >
              <Loading />
            </Box>
          </Box>
        )
      }}
    />
  )
}
