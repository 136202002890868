"use client"

import { useEffect } from "react"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useMutation, useQuery } from "@tanstack/react-query"

import { useLoggingContext } from "./useLoggingContext"

function parseAsString(value: string | object) {
  try {
    return JSON.parse(value as string) as object
  } catch (e) {
    return value as string
  }
}

function useGetStorageValue(key?: string) {
  const { captureException } = useLoggingContext()
  return useQuery(
    ["getStoredValue", key],
    async () => {
      return AsyncStorage.getItem(key).then(async (value) => {
        if (value) {
          try {
            const storedData = JSON.parse(value) as StorageData
            if (!storedData.metadata?.expiresAtTimestamp) {
              return parseAsString(storedData.value)
            }

            if (storedData.metadata?.expiresAtTimestamp < Date.now()) {
              await AsyncStorage.removeItem(key).catch((err) => {
                captureException(
                  new Error(`Error removing expired key ${key} ${err}`),
                )
              })
              return null
            }

            return parseAsString(storedData.value)
          } catch (e) {
            captureException(
              new Error(`Error parsing stored value for key ${key}`),
            )
            return null
          }
        }
        return null
      })
    },
    {
      enabled: !!key,
    },
  )
}

type StorageData = {
  value: string | object
  metadata: {
    expiresAtTimestamp?: number
  }
}
type StorageArgs = StorageData & {
  key: string
}

function useSetStorageValue() {
  const res = useMutation({
    mutationFn: ({ key, value, metadata }: StorageArgs) => {
      let val = value as string
      if (typeof value === "object") {
        val = JSON.stringify(value)
      }
      const storedValue = JSON.stringify({
        value: val,
        metadata,
      })
      return AsyncStorage.setItem(key, storedValue)
    },
  })
  return {
    ...res,
    setValue: res.mutate,
    setValueAsync: res.mutateAsync,
  }
}

function useRemoveStorageValue() {
  const res = useMutation({
    mutationFn: ({ key }: { key: string }) => {
      return AsyncStorage.removeItem(key)
    },
  })
  return {
    ...res,
    removeValue: res.mutateAsync,
  }
}

type AsyncStorageOptions = {
  expiresInMs?: number
}

export function useAsyncStorage(key: string, defaultValue?: string | object) {
  const { data, isLoading, error, refetch, isRefetching } =
    useGetStorageValue(key)
  const { mutateAsync, status } = useSetStorageValue()
  const { removeValue: clearKey } = useRemoveStorageValue()
  const { captureException } = useLoggingContext()

  async function setValue(
    value: string | object,
    options?: AsyncStorageOptions,
  ) {
    try {
      const metadata = {
        expiresAtTimestamp: options?.expiresInMs
          ? Date.now() + options.expiresInMs
          : undefined,
      }
      await mutateAsync({ key, value, metadata })
      await refetch()
    } catch (error) {
      captureException(new Error(`Error setting ${key} in AsyncStorage`))
    }
  }

  async function removeValue() {
    try {
      await clearKey({ key })
      await refetch()
    } catch (error) {
      captureException(new Error(`Error removing ${key} in AsyncStorage`))
    }
  }

  useEffect(() => {
    if (data || isLoading || error || status !== "idle") return
    if (defaultValue) {
      void setValue(defaultValue)
    }
  }, [data, isLoading, error, defaultValue])

  return {
    data,
    isLoading,
    isRefetching,
    error,
    setValue,
    removeValue,
  }
}
