"use client"

import { Box, Divider, useExperienceAttendees } from "@bullseye/components"

import { ActivityAttendeeCount } from "../../../../components"

export function UnauthedAttendeeCount({
  experienceId,
  organizerId,
}: {
  experienceId: string
  organizerId: string
}) {
  const { isLoading, data } = useExperienceAttendees(experienceId, {
    "user_id[not]": organizerId ?? "null",
  })

  if (isLoading) return null
  const [firstPage] = data?.pages || []

  return (
    <>
      <Divider />
      <Box className="flex p-6">
        <ActivityAttendeeCount count={firstPage?.metadata?.total_count} />
      </Box>
    </>
  )
}
