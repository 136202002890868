"use client"

import { ReactNode } from "react"

import {
  Box,
  Button,
  ButtonProps,
  makeMessengerUri,
  PublicUser,
  useCreateMessageChannel,
  useLoggingContext,
  useMutualChannel,
  useUserContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

type Props = {
  user: PublicUser
  label: ReactNode
  variant?: ButtonProps["variant"]
  disabled?: boolean
}

export function MessageUserButton({
  user,
  label,
  variant = "primary",
  disabled = false,
}: Props) {
  const router = useRouter()
  const { user: self } = useUserContext()
  const {
    channel,
    isLoading: loadingChannel,
    error,
  } = useMutualChannel([self.stream_id, user.stream_id])
  const { captureException } = useLoggingContext()

  const isViewingSelf = user.id === self.id
  const { createMessageChannelAsync, status } = useCreateMessageChannel()

  return (
    <Box className="w-full min-w-[160px] flex-row justify-center bg-white">
      <Box className="w-full">
        <Button
          as="button"
          variant={variant}
          disabled={
            status === "loading" ||
            isViewingSelf ||
            loadingChannel ||
            !!error ||
            disabled
          }
          loading={loadingChannel || status === "loading"}
          handlePress={async () => {
            if (channel) {
              router.push(makeMessengerUri(channel.id))
              return
            }
            try {
              const { data: mc } = await createMessageChannelAsync({
                body: {
                  is_group_message: false,
                  users: [{ user_id: user.id }],
                },
              })

              router.push(makeMessengerUri(mc?.data.stream_channel_id))
            } catch (error) {
              captureException(
                new Error(`Failed to create message channel: ${error}`),
              )
            }
          }}
        >
          {label}
        </Button>
      </Box>
    </Box>
  )
}
