import { useState } from "react"
import { Platform } from "react-native"
import Bowser from "bowser"

export const useBrowser = () => {
  const [browser] = useState(() =>
    Platform.OS === "web" ? Bowser.getParser(window.navigator.userAgent) : null,
  )

  let os = browser?.getOSName(true) || ""
  if (os === "macos" && window.navigator.maxTouchPoints > 1) {
    // iPadOS reports as macOS
    os = "ios"
  }
  return {
    os: os,
    name: browser?.getBrowserName(true),
  }
}
