import { useState } from "react"
import { differenceInYears, startOfDay } from "date-fns"
import parsePhoneNumber from "libphonenumber-js"

import { User } from "@bullseye/types"

import { useGetRequest } from "../../../hooks/useRequest"

export const useMe = (accessToken?: string) => {
  const [today] = useState(startOfDay(new Date()))

  const { data, ...rest } = useGetRequest<{ data: User[] }>(
    {
      method: "GET",
      path: "v1/users/me",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!accessToken,
    },
  )

  const user = data?.data?.[0]
  if (user?.phone) {
    try {
      user.formatted_phone = parsePhoneNumber(
        user.phone,
        "US",
      )?.formatNational()
    } catch {}
  }

  if (user) {
    user.age = differenceInYears(today, startOfDay(new Date(user?.dob)))
  }

  return {
    user,
    ...rest,
  }
}
