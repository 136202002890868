"use client"

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { FormattedMessage } from "react-intl"

import { useAsyncStorage } from "../../hooks/useAsyncStorage"
import { Select, SelectProps } from "../Select"

const distanceOptions = [
  {
    label: <FormattedMessage id="filters.distance.options.any" />,
    value: 100,
  },
  {
    label: <FormattedMessage id="filters.distance.options.2-miles" />,
    value: 2,
  },
  {
    label: <FormattedMessage id="filters.distance.options.5-miles" />,
    value: 5,
  },
  {
    label: <FormattedMessage id="filters.distance.options.10-miles" />,
    value: 10,
  },
  {
    label: <FormattedMessage id="filters.distance.options.25-miles" />,
    value: 25,
  },
  {
    label: <FormattedMessage id="filters.distance.options.50-miles" />,
    value: 50,
  },
]

type Props = Omit<
  SelectProps,
  "onChange" | "options" | "defaultValue" | "defaultLabel"
> & {
  onChange(val: number): void
}

export const DistanceFilter = (props: Props) => {
  return (
    <Select
      name={<FormattedMessage id="filters.distance.header" />}
      defaultLabel={distanceOptions[0]!.label}
      defaultValue={distanceOptions[0]!.value}
      options={distanceOptions}
      {...props}
    />
  )
}

type PersistentProps = Props & {
  storageKey: string
}

export const PersistentDistanceFilter = forwardRef(
  (
    {
      onChange,
      storageKey = "distance-filter",
      value,
      ...props
    }: PersistentProps,
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      reset() {
        handleChange(100)
      },
    }))

    const {
      data: storedValue,
      isLoading,
      setValue,
    } = useAsyncStorage(storageKey, `${distanceOptions[0]!.value}`)

    const handleChange = (val: number) => {
      void setValue(`${val}`)
    }

    useEffect(() => {
      if (isLoading || !storedValue) return
      onChange(+storedValue)
    }, [storedValue, isLoading])

    return <DistanceFilter value={value} onChange={handleChange} {...props} />
  },
)

PersistentDistanceFilter.displayName = "PersistentDistanceFilter"

type FormattedProps = Omit<PersistentProps, "onChange" | "id"> & {
  onChange(val: number, formattedVal?: Record<string, string>): void
}

export const FormattedDistanceFilter = forwardRef(
  ({ onChange, ...props }: FormattedProps, ref) => {
    const [value, setValue] = useState<number>(100)

    const handleChange = (val: number) => {
      onChange(val, { ["distance[lte]"]: `${val}` })
      setValue(val)
    }

    return (
      <PersistentDistanceFilter
        id={props.storageKey}
        onChange={handleChange}
        value={value}
        ref={ref}
        {...props}
      />
    )
  },
)

FormattedDistanceFilter.displayName = "FormattedDistanceFilter"
