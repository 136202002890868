"use client"

import { Pressable } from "react-native"
import { styled } from "nativewind"
import { FormattedMessage } from "react-intl"

import { Box, P, PublicUser, Sheet, useUserBlock } from "@bullseye/components"

import {
  ApplicationModal,
  useApplicationModalContext,
} from "../ApplicationModals"

type Props = {
  isOpen: boolean
  dismiss: VoidFunction
  user: PublicUser
}

const PressableRow = styled(Pressable, "w-full flex-row")

export const MoreSheet = ({ dismiss, user }: Omit<Props, "isOpen">) => {
  const { openModal } = useApplicationModalContext()
  const { data } = useUserBlock(user.id)
  return (
    <Box className="flex justify-center py-3 g-4 md:px-4">
      <PressableRow
        onPress={() => {
          dismiss()
          openModal(ApplicationModal.Report, {
            contentType: "user",
            contentId: user.id,
          })
        }}
      >
        <P bold className="text-red-600">
          <FormattedMessage id="messenger.modal.channelOptions.reportUser" />
        </P>
      </PressableRow>
      {data && (
        <PressableRow
          onPress={() => {
            dismiss()
            openModal(ApplicationModal.BlockUser, {
              userId: user.id,
            })
          }}
        >
          <P bold className="text-red-600">
            {!data?.is_blocked ? (
              <FormattedMessage id="applicationModals.blockUser.block.cta" />
            ) : (
              <FormattedMessage id="applicationModals.blockUser.unblock.cta" />
            )}
          </P>
        </PressableRow>
      )}
    </Box>
  )
}

export const UserSettingsModal = ({ isOpen, dismiss, user }: Props) => {
  return (
    <Sheet
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return <MoreSheet dismiss={dismiss} user={user} />
      }}
    />
  )
}
