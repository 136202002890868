import { useCallback } from "react"
import { GestureResponderEvent } from "react-native"
import * as Linking from "expo-linking"
import * as WebBrowser from "expo-web-browser"

import { useRouter } from "@bullseye/navigation"

import { isWeb } from "../utils"

type Args = {
  as: "button" | "a"
  onPress?: (event: GestureResponderEvent) => void
  href?: string
}

export const useLinkOrButtonPress = (args: Args) => {
  const router = useRouter()
  return useCallback(
    (e: GestureResponderEvent) => {
      switch (args.as) {
        case "button":
          args.onPress?.(e)
          return
        case "a":
          const isAbsUrl = args.href!.startsWith("http")
          const isMailToOrTel =
            args.href!.startsWith("mailto:") || args.href!.startsWith("tel:")
          if (isAbsUrl || isMailToOrTel) {
            e.preventDefault()
            if (isAbsUrl) {
              isWeb()
                ? window.open(args.href, "_blank")
                : void WebBrowser.openBrowserAsync(args.href!).catch(
                    () => undefined,
                  )
              return
            }
            if (isMailToOrTel) {
              void Linking.openURL(args.href!).catch(() => undefined)
            }
          }
          // If on the web, and we don't need to open in a new tab,
          // we will let the default implementation of the anchor element
          // handle routing
          if (isWeb()) return

          router.push(args.href!)
          break
      }
    },
    [args],
  )
}
