"use client"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  ACTIVITIES_URI,
  Box,
  MESSENGER_URI,
  PROFILE_URI,
  PublicUser,
  Sheet,
  TextButton,
  useDeleteMessageChannel,
  useLoggingContext,
  useMessageChannelByStreamId,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import type { Modal } from "@bullseye/types"

import {
  ApplicationModal,
  useApplicationModalContext,
} from "../../../../components/"

export type ChannelOptionsModalProps = {
  user: PublicUser | null | undefined
  streamChannelId: string | null | undefined
  messageChannelId: string | null | undefined
}

export const ChannelOptions = ({
  isOpen,
  dismiss,
  user,
  streamChannelId,
  messageChannelId,
}: Modal<ChannelOptionsModalProps>) => {
  const { captureException } = useLoggingContext()
  const { messageChannel } = useMessageChannelByStreamId(streamChannelId)
  const router = useRouter()

  const { openModal } = useApplicationModalContext()
  const { deleteMessageChannelAsync } =
    useDeleteMessageChannel(messageChannelId)

  return (
    <Sheet
      title={<FormattedMessage id="messenger.modal.channelOptions.title" />}
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      contentContainerStyle="overflow-hidden"
      renderContent={() => {
        return (
          <Box className="flex-col items-start pt-4 g-6">
            <TextButton
              as="a"
              href={
                messageChannel?.experience
                  ? `${ACTIVITIES_URI}/${messageChannel.experience.slug}`
                  : `${PROFILE_URI}/${user?.slug}`
              }
            >
              {messageChannel?.experience ? (
                <FormattedMessage id="messenger.modal.channelOptions.viewExp" />
              ) : (
                <FormattedMessage id="messenger.modal.channelOptions.viewProfile" />
              )}
            </TextButton>
            <TextButton
              as="button"
              className={clsx({ hidden: messageChannel?.experience })}
              handlePress={async () => {
                try {
                  await deleteMessageChannelAsync({})
                } catch (error) {
                  captureException(
                    new Error(`Failed to delete channel ${error}`),
                  )
                } finally {
                  router.push(MESSENGER_URI)
                  dismiss()
                }
              }}
            >
              <FormattedMessage id="messenger.modal.channelOptions.delete" />
            </TextButton>
            <TextButton
              as="button"
              handlePress={() => {
                dismiss()
                openModal(ApplicationModal.Report, {
                  contentType: "message_channel",
                  contentId: messageChannelId,
                })
              }}
            >
              {user ? (
                <FormattedMessage id="messenger.modal.channelOptions.reportUser" />
              ) : (
                <FormattedMessage id="messenger.modal.channelOptions.reportExp" />
              )}
            </TextButton>
            {!messageChannel?.experience && (
              <TextButton
                as="button"
                handlePress={() => {
                  dismiss()
                  openModal(ApplicationModal.BlockUser, {
                    userId: user?.id,
                    onSuccess: () => {
                      router.push(MESSENGER_URI)
                    },
                  })
                }}
              >
                <FormattedMessage id="applicationModals.blockUser.block.cta" />
              </TextButton>
            )}
          </Box>
        )
      }}
    />
  )
}
