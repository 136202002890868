import { useUserContext } from "../providers"
import { usePostRequest } from "./useRequest"

type AddAttendesRequest = {
  emails: string[]
}

type AddAttendeesResponse = {
  data: { created: number; invalid: string[] }[]
}

export const useAddAttendeesByExperience = (experienceId: string) => {
  const { accessToken } = useUserContext()
  const { postRequestAsync, ...rest } = usePostRequest<
    AddAttendeesResponse,
    AddAttendesRequest
  >({
    path: `v1/experiences/${experienceId}/attendees/create-by-emails`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    addByEmails: (emails: string[]) =>
      postRequestAsync({ body: { emails } }).then((res) => {
        return res.data.data[0]
      }),
    ...rest,
  }
}
