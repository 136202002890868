import { useEffect } from "react"
import { useCalendars } from "expo-localization"
import { FormattedTimeParts, useIntl } from "react-intl"

import { Box, P, TextButton, useTimezone } from "@bullseye/components"
import { Coordinates } from "@bullseye/types"

import { useToastContext } from "../../../providers"

type Props = {
  date: Date
  coordinates?: Coordinates
  value?: string
  onChange: (timeZone: string) => void
  disabled?: boolean
}

export const TimeZone = ({
  date,
  coordinates,
  onChange,
  value,
  disabled,
}: Props) => {
  const [userCal] = useCalendars()
  const intl = useIntl()
  const { openToast } = useToastContext()

  const { data: timeZone } = useTimezone({
    latitude: coordinates?.latitude,
    longitude: coordinates?.longitude,
    timestamp: date.getTime() / 1000,
  })

  useEffect(() => {
    const timeZoneId = timeZone?.timeZoneId || userCal.timeZone
    onChange(timeZoneId)
  }, [userCal, timeZone])

  return (
    <Box className={"flex items-start"}>
      <TextButton
        as="button"
        disabled={disabled}
        handlePress={() => {
          openToast({
            label: intl.formatMessage({
              id: "activityForm.changeTimeZone",
            }),
          })
        }}
        startIconType="globe"
      >
        <FormattedTimeParts timeStyle="full" value={date} timeZone={value}>
          {(parts) => {
            const firstTimeZonePart = parts.findIndex(
              (part) => part.type === "timeZoneName",
            )

            if (firstTimeZonePart === -1) {
              return null
            }

            const timezone = parts
              .slice(firstTimeZonePart)
              .map((p) => p.value)
              .join("")
            return <P>{timezone}</P>
          }}
        </FormattedTimeParts>
      </TextButton>
    </Box>
  )
}
