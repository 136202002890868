"use client"

import { useEffect } from "react"
import { FormattedMessage } from "react-intl"

import {
  Button,
  LoadingFullScreen,
  useAvailablePartnerships,
  useInitiateSubscription,
  useUserContext,
} from "@bullseye/components"
import { ErrConflict } from "@bullseye/components/src/hooks/useRequest"

import { OnboardingStep } from "../../components/OnboardingStep"
import { OnboardingJourney } from "../../layouts"
import { Present } from "./assets/Present"

type Props = {
  previousPage?: string
  advanceToNextStep: () => Promise<void>
}

export const OnboardingSubscription = ({
  previousPage,
  advanceToNextStep,
}: Props) => {
  const { user, refetch, isRefetching } = useUserContext()
  const { data: partnership, isLoading: loadingPartnerships } =
    useAvailablePartnerships()
  const { initiateSubscriptionAsync, status } = useInitiateSubscription()

  useEffect(() => {
    if (loadingPartnerships) return
    if (!partnership) {
      void advanceToNextStep()
    }
  }, [partnership])

  if (loadingPartnerships) {
    return <LoadingFullScreen />
  }

  const handleCreateSubscription = async () => {
    await initiateSubscriptionAsync(partnership?.id).catch((err) => {
      switch (err) {
        case ErrConflict:
          return
        default:
          throw err
      }
    })
    await refetch()
    return advanceToNextStep()
  }

  return (
    <OnboardingJourney previousPage={previousPage} isPremium>
      <OnboardingStep
        imageSource={{ uri: Present }}
        imageAlt="Notifications"
        title={
          <FormattedMessage
            id="onboarding.subscription.partnership.title"
            values={{
              city: user.city,
            }}
          />
        }
        description={
          <FormattedMessage
            id="onboarding.subscription.partnership.description"
            values={{ partnerName: partnership?.partner_name }}
          />
        }
        primaryAction={
          <Button
            as="button"
            height="lg"
            handlePress={handleCreateSubscription}
            disabled={status === "loading" || isRefetching}
          >
            <FormattedMessage id="onboarding.subscription.primaryCta" />
          </Button>
        }
      />
    </OnboardingJourney>
  )
}
