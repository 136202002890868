export enum NotificationTrigger {
  Sample = "sample",
  MessageNew = "message.new",
  WaitlistSelected = "waitlist.selected",
}

export interface Notification {
  trigger: NotificationTrigger
}

export interface MessageNotification extends Notification {
  trigger: NotificationTrigger.MessageNew
  originator: {
    formattedName: string
  }
  message_channel: {
    id: string
    title: string
    is_group_message: boolean
    experience_id: string
  }
}

export interface WaitlistNotification extends Notification {
  trigger: NotificationTrigger.MessageNew
  experience: {
    id: string
    slug: string
    title: string
  }
}
