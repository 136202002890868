"use client"

import React, { useRef, useState } from "react"
import { Camera, CameraType } from "expo-camera"
import { FormattedMessage } from "react-intl"

import { Box, Button, Loading } from "@bullseye/components"

import { OnboardingStep } from "../../OnboardingStep"
import { useProfilePhotoCtx } from "../providers/ProfilePhotoContext"

export const CapturePhoto = () => {
  const cameraRef = useRef<Camera | null>(null)
  const [isCapturing, setIsCapturing] = useState(false)
  const { takePhoto, setShowCamera } = useProfilePhotoCtx()

  async function takePicture() {
    if (!cameraRef.current) return

    setIsCapturing(true)
    await takePhoto(cameraRef.current)
    setIsCapturing(false)
  }

  return (
    <OnboardingStep
      title={<FormattedMessage id="photoUpload.capturePhoto.title" />}
      description={
        <FormattedMessage id="photoUpload.capturePhoto.description" />
      }
      imageComponent={
        <Box className="flex h-[250px] w-[250px] items-center justify-center overflow-hidden rounded-full bg-gray-100">
          {isCapturing ? (
            <Loading />
          ) : (
            <Camera
              ref={cameraRef}
              type={CameraType.front}
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                transform: [{ scaleX: -1 }],
              }}
            />
          )}
        </Box>
      }
      secondaryAction={
        <Button
          as="button"
          height="lg"
          handlePress={takePicture}
          disabled={isCapturing}
        >
          <FormattedMessage id="photoUpload.capturePhoto.cta" />
        </Button>
      }
      primaryAction={
        <Button
          height="lg"
          as="button"
          handlePress={() => setShowCamera(false)}
          variant="secondary"
          disabled={isCapturing}
        >
          <FormattedMessage id="photoUpload.capturePhoto.cancel" />
        </Button>
      }
    />
  )
}
