import { useQueryClient } from "@tanstack/react-query"

import { useUserContext } from "../providers/UserAuthProvider/UserProvider"
import { useLoggingContext } from "./useLoggingContext"
import { useDeleteRequest, useGetRequest, usePutRequest } from "./useRequest"

type UserBlockResponse = {
  data: Array<{
    is_blocked: boolean
  }>
}

type UserBlockQueryCache = {
  data: UserBlockResponse | null
}

export const useUserBlockedMe = (userId?: string) => {
  const { accessToken } = useUserContext()
  const { data, ...rest } = useGetRequest<UserBlockResponse>(
    {
      path: `v1/block/${userId}/me`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!userId,
    },
  )

  return {
    ...rest,
    data: data?.data?.[0]?.is_blocked ?? false,
  }
}

export const useUserBlock = (userId?: string) => {
  const queryClient = useQueryClient()
  const { accessToken } = useUserContext()
  const { captureException } = useLoggingContext()
  const { data, error, isLoading, key, refetch } =
    useGetRequest<UserBlockResponse>(
      {
        path: `v1/block/${userId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      {
        enabled: !!userId,
      },
    )

  const { putRequestAsync: createUserBlock } = usePutRequest<
    UserBlockResponse,
    { is_blocked: boolean }
  >({
    path: `v1/block/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries({ queryKey: key })
      const { data } = queryClient.getQueryData<UserBlockQueryCache>(key)!
      if (!data?.data?.[0]) return
      data.data[0].is_blocked = Boolean(newData?.body?.is_blocked)
      queryClient.setQueryData<UserBlockQueryCache>(key, {
        data,
      })
    },
  })

  const createBlock = async () => {
    await createUserBlock(
      { body: { is_blocked: true } },
      {
        onSuccess: (data) => {
          queryClient.setQueryData<UserBlockQueryCache>(key, data)
        },
        onError: async (e) => {
          captureException(new Error(`Failed to update user block: ${e}`))
          await refetch()
        },
      },
    )
  }

  const { deleteRequestAsync: deleteUserBlock } =
    useDeleteRequest<UserBlockResponse>({
      path: `v1/block/${userId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

  const deleteBlock = async () => {
    await deleteUserBlock(
      { body: { is_blocked: false } },
      {
        onSuccess: (data) => {
          queryClient.setQueryData<UserBlockQueryCache>(key, data)
        },
        onError: async (e) => {
          captureException(new Error(`Failed to update user block: ${e}`))
          await refetch()
        },
      },
    )
  }

  return {
    data: data?.data[0] || null,
    isLoading,
    createBlock,
    deleteBlock,
    error,
  }
}
