import { ImagePickerAsset } from "expo-image-picker"
import { useMutation } from "@tanstack/react-query"

import { MediaUpload, V1APIResponse } from "@bullseye/types"

import { Options } from "."
import { useUserContext } from "../../providers"
import {
  ErrMediaInappropriate,
  ErrMediaInvalidFormat,
  ErrMediaMultipleFaces,
  ErrMediaNoFaces,
  ErrMediaTooLarge,
} from "./errors"

export type MediaMutationOptions = Options & {
  apiBase: string
  accessToken: string
  impersonateId?: string
}

type Transforms = Array<
  (form: FormData, file: ImagePickerAsset, blob: Blob) => void
>

export const useMediaMutation = (
  options: MediaMutationOptions,
  ...transformFns: Transforms
) => {
  const { user } = useUserContext()
  const { mutateAsync, status, isLoading } = useMutation(
    async (file: ImagePickerAsset) => {
      const formData = new FormData()
      formData.append("target", options.mediaPurpose)

      const blob = await fetch(file.uri).then((r) => r.blob())
      for (const transform of transformFns) {
        transform(formData, file, blob)
      }

      const headers: Record<string, string> = {
        Authorization: `Bearer ${options.accessToken}`,
      }

      if (user.admin && options.impersonateId) {
        headers["X-Impersonate-Id"] = options.impersonateId
      }

      const res = await fetch(`${options.apiBase}/v1/medias`, {
        method: "POST",
        headers,
        body: formData,
      })

      if (!res.ok) {
        switch (res.status) {
          case 413:
            throw ErrMediaTooLarge
          case 415:
            throw ErrMediaInvalidFormat
          case 422:
            const json = (await res.json()) as { message: string }
            if (json.message) {
              if (json.message.includes("no face")) {
                throw ErrMediaNoFaces
              }
              if (json.message.includes("face(s)")) {
                throw ErrMediaMultipleFaces
              }
              if (json.message.includes("content")) {
                throw ErrMediaInappropriate
              }
            }
          default:
            throw new Error(`Unhandled media upload error: ${res.status}`)
        }
      }

      const body = (await res.json()) as V1APIResponse<MediaUpload>
      return body.data[0]
    },
  )
  return {
    status,
    isLoading,
    uploadMediaAsync: (file: ImagePickerAsset) => {
      return mutateAsync(file)
    },
  }
}
