"use client"

import { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import {
  BirthdatePicker,
  Box,
  Button,
  getAutocompleteSearchResults,
  GooglePlacePrediction,
  isWeb,
  P,
  TextInput,
  useAppConfig,
  useLoggingContext,
  useSupportedLanguage,
  useTracking,
  useUpdateUser,
  useUserContext,
} from "@bullseye/components"

import { CityZipSearch } from "../../components/CityZipSearch"
import { OnboardingStep } from "../../components/OnboardingStep"
import { OnboardingJourney } from "../../layouts"
import { useToastContext } from "../../providers"

type BasicInfoData = {
  first_name?: string
  last_name?: string
  dob?: Date
  zipcode?: string
}

function useGetPlaceFromZipcode() {
  const language = useSupportedLanguage()
  const { mapsBaseUrl, googlePlacesApiKey } = useAppConfig()
  return async (
    zipcode: string,
  ): Promise<GooglePlacePrediction | undefined> => {
    const places = await getAutocompleteSearchResults({
      query: zipcode,
      language,
      mapsBaseUrl,
      googlePlacesApiKey,
      options: {
        types: "postal_code",
      },
    })

    if (!places?.predictions?.length) {
      return undefined
    }

    const [firstPrediction] = places.predictions
    if (zipcode !== firstPrediction?.structured_formatting.main_text) {
      return undefined
    }

    return firstPrediction
  }
}

type Props = {
  advanceToNextStep: () => void
}

export const BasicInfo = ({ advanceToNextStep }: Props) => {
  const { user, refetch } = useUserContext()
  const { update } = useUpdateUser()
  const [submitting, setSubmitting] = useState(false)
  const [selectedPlace, setSelectedPlace] =
    useState<GooglePlacePrediction | null>()

  const { openErrorToast } = useToastContext()
  const intl = useIntl()
  const { captureException } = useLoggingContext()
  const getPlaceFromZipcode = useGetPlaceFromZipcode()
  const track = useTracking()

  async function updateUser() {
    try {
      setSubmitting(true)
      const data = form.getValues()
      if (!data.zipcode) {
        openErrorToast({
          label: intl.formatMessage({ id: "errors.unknown_place" }),
        })
        return
      }
      let place = selectedPlace
      if (!place?.place_id) {
        place = await getPlaceFromZipcode(data.zipcode)
        if (!place) {
          openErrorToast({
            label: intl.formatMessage({ id: "errors.unknown_place" }),
          })
          return
        }
      }

      await update({
        dob: data.dob?.toISOString(),
        first_name: data.first_name,
        last_name: data.last_name,
        google_place_id: place.place_id,
      })

      const user = await refetch()

      await track("user_activated", user)

      return advanceToNextStep()
    } catch (error) {
      captureException(new Error(`Error updating user: ${error}`))
      openErrorToast({
        label: intl.formatMessage({ id: "errors.unknown" }),
      })
    } finally {
      setSubmitting(false)
    }
  }

  const form = useForm<BasicInfoData>({
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      zipcode: user?.zipcode ?? undefined,
      dob: user?.dob ? new Date(user.dob) : undefined,
    },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        first_name: yup
          .string()
          .required(intl.formatMessage({ id: "validation.required" })),
        last_name: yup
          .string()
          .required(intl.formatMessage({ id: "validation.required" })),
        zipcode: yup
          .string()
          .required(intl.formatMessage({ id: "validation.required" })),
        dob: yup
          .date()
          .required(intl.formatMessage({ id: "validation.required" })),
      }),
    ),
  })

  return (
    <FormProvider {...form}>
      <OnboardingJourney>
        <OnboardingStep
          title={<FormattedMessage id="onboarding.basicInfo.title" />}
          description={<FormattedMessage id="onboarding.basicInfo.subtitle" />}
          primaryAction={
            <Box className="z-[-1]">
              <Button
                as="button"
                handlePress={form.handleSubmit(updateUser, () => {
                  openErrorToast({
                    label: intl.formatMessage({ id: "validation.general" }),
                    delayMs: 3000,
                  })
                })}
                loading={submitting}
                height="lg"
              >
                <FormattedMessage id="general.continue" />
              </Button>
            </Box>
          }
        >
          <Box className="z-10 w-full g-4">
            <Box className="flex w-full items-center g-4">
              <Controller
                name="first_name"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextInput
                      label={intl.formatMessage({
                        id: "onboarding.basicInfo.firstName",
                      })}
                      value={value}
                      onChangeText={onChange}
                      className="w-full"
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
              <Controller
                name="last_name"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextInput
                      label={intl.formatMessage({
                        id: "onboarding.basicInfo.lastName",
                      })}
                      value={value}
                      onChangeText={onChange}
                      className="w-full"
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </Box>
            <Box className="flex w-full g-2">
              <Controller
                name="zipcode"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <CityZipSearch
                      types="postal_code"
                      label={intl.formatMessage({
                        id: "onboarding.basicInfo.zipcode",
                      })}
                      value={value}
                      onSelect={(place) => {
                        setSelectedPlace(place)
                        onChange(place.structured_formatting.main_text)
                      }}
                      onChangeText={(text) => {
                        onChange(text)
                      }}
                      className="flex-grow"
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
              <P size="sm" className="text-gray-600">
                <FormattedMessage id="onboarding.basicInfo.zipcode.explanation" />
              </P>
            </Box>
            <Box className="z-[-1] flex g-2">
              <P className={clsx({ hidden: !isWeb() })}>
                <FormattedMessage id="onboarding.basicInfo.dateOfBirth" />
              </P>
              <Controller
                name="dob"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <BirthdatePicker
                      selectedValue={value}
                      onChange={onChange}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </Box>
          </Box>
        </OnboardingStep>
      </OnboardingJourney>
    </FormProvider>
  )
}
