"use client"

import { twMerge } from "tailwind-merge"

import { Box, Divider, Experience } from "@bullseye/components"

import { ActivityHost } from "./ActivityHost"
import { AttendeesList } from "./AttendeesList"

type Props = {
  experience: Experience
  className?: string
}
export const ActivityAttendees = ({ className, experience }: Props) => {
  return (
    <Box className={twMerge("rounded-none bg-white md:rounded-lg", className)}>
      <ActivityHost experience={experience} />
      <Divider />
      <AttendeesList experience={experience} />
    </Box>
  )
}
