"use client"

import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H4,
  isWeb,
  P,
  ReportedContent,
  Sheet,
  TextInput,
  useCreateReportedContent,
  useLoggingContext,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export type ReportModalProps = {
  contentType: ReportedContent["content_type"]
  contentId: string
}

enum ReportReason {
  FakeOrScam = "fake_or_scam",
  Inapporpriate = "inappropriate",
  Harmful = "harmful",
  Spam = "spam",
  InPerson = "inPerson",
  Other = "other",
}

export const ReportModal = ({
  isOpen,
  dismiss,
  contentType,
  contentId,
}: Modal<ReportModalProps>) => {
  const { captureException } = useLoggingContext()

  const [reason, setReason] = useState<ReportReason | null>(null)
  const [report, setReport] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)

  const { createReportedContentAsync } = useCreateReportedContent()
  useEffect(() => {
    if (!isOpen) {
      setReason(null)
      setReport("")
      setShowSuccess(false)
    }
  }, [isOpen, reason, setReason, report, setReport])

  const submit = async (reason: ReportReason, extraInfo = "") => {
    try {
      await createReportedContentAsync({
        content_type: contentType,
        content_id: contentId,
        reason,
        additional_information: extraInfo,
      })
      setReason(null)
      setShowSuccess(true)
    } catch (error) {
      captureException(new Error(`Failed to report content ${error}`))
      dismiss()
    }
  }

  return (
    <Sheet
      icon={reason ? "arrow-left" : "x"}
      show={isOpen}
      size={isWeb() ? "md" : "lg"}
      onIconPress={reason ? () => setReason(null) : dismiss}
      dismiss={dismiss}
      contentContainerStyle="overflow-hidden"
      renderContent={() => {
        if (showSuccess) {
          return (
            <Box className="flex flex-grow justify-between g-4">
              <Box className="g-4">
                <H4>
                  <FormattedMessage id="app.modal.report.success" />
                </H4>
                <P>
                  <FormattedMessage id="app.modal.report.success.description" />
                </P>
              </Box>
              <Button as="button" handlePress={() => dismiss()}>
                <FormattedMessage id="general.close" />
              </Button>
            </Box>
          )
        }
        if (reason) {
          return (
            <Box className="g-4">
              <TextInput
                editable
                multiline
                label={<FormattedMessage id="app.modal.report.details.label" />}
                onChangeText={setReport}
                value={report}
                autoFocus
              />
              <Box className="flex-row justify-between g-2">
                <Box className="flex-1">
                  <Button as="button" variant="tertiary" handlePress={dismiss}>
                    <FormattedMessage id="app.modal.report.cancel" />
                  </Button>
                </Box>
                <Box className="flex-1">
                  <Button
                    as="button"
                    handlePress={() => submit(reason, report)}
                    disabled={report.length < 5}
                  >
                    <FormattedMessage id="app.modal.report.submit" />
                  </Button>
                </Box>
              </Box>
            </Box>
          )
        }
        return (
          <Box className="flex-col g-4">
            <Box className="flex-col g-2">
              <H4>
                <FormattedMessage id="app.modal.report.subtitle" />
              </H4>
              <P size="sm">
                <FormattedMessage id="app.modal.report.description" />
              </P>
            </Box>
            <Box className="flex-col items-start g-2">
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => submit(ReportReason.FakeOrScam)}
              >
                <FormattedMessage id="app.modal.report.reason.fakeOrScam" />
              </Button>
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => submit(ReportReason.Inapporpriate)}
              >
                <FormattedMessage id="app.modal.report.reason.inappropriate" />
              </Button>
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => submit(ReportReason.Harmful)}
              >
                <FormattedMessage id="app.modal.report.reason.harmful" />
              </Button>
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => submit(ReportReason.Spam)}
              >
                <FormattedMessage id="app.modal.report.reason.spam" />
              </Button>
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => setReason(ReportReason.InPerson)}
              >
                <FormattedMessage id="app.modal.report.reason.inPerson" />
              </Button>
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => setReason(ReportReason.Other)}
              >
                <FormattedMessage id="app.modal.report.reason.other" />
              </Button>
            </Box>
          </Box>
        )
      }}
    />
  )
}
