import { useQueryClient } from "@tanstack/react-query"

import { ExperienceAttendee, PaginatedResponse } from "@bullseye/types"

import { usePaginatedRequest } from "./useRequest"

type Options = {
  limit?: string
  page?: string
  "user_id[not]"?: string
  enabled?: boolean
}

export const useExperienceAttendees = (
  id: string | undefined | null,
  options: Options = {},
) => {
  const { enabled = true, ...params } = options
  return usePaginatedRequest<PaginatedResponse<ExperienceAttendee>>(
    {
      path: `experiences/${id}/attendees`,
      params,
    },
    {
      enabled: !!id && enabled,
    },
  )
}

export const useInvalidateExperienceAttendees = (experienceId: string) => {
  const { key } = useExperienceAttendees(experienceId, { enabled: false })
  const queryClient = useQueryClient()
  return () => {
    return queryClient.invalidateQueries(key[0])
  }
}
