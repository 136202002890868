"use client"

import { Camera, PermissionResponse } from "expo-camera"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { useUserContext } from "../providers"

export const useCheckCameraPermissions = () => {
  const { user } = useUserContext()
  const queryKey = ["check-camera-permissions", user.id]
  const query = useQuery(queryKey, () => Camera.getCameraPermissionsAsync())

  return {
    ...query,
    queryKey,
  }
}

export const useRequestCameraPermissions = () => {
  const { queryKey } = useCheckCameraPermissions()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    async () => {
      return await Camera.requestCameraPermissionsAsync()
    },
    {
      onSuccess: async (permissions: PermissionResponse) => {
        await queryClient.cancelQueries({ queryKey })
        queryClient.setQueryData(queryKey, permissions)
      },
    },
  )

  return {
    ...mutation,
    requestCameraPermissionsAsync: mutation.mutateAsync,
  }
}
