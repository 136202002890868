"use client"

import React from "react"

import {
  Badge,
  IconButton,
  NOTIFICATIONS_URI,
  useUnseenNotificationCount,
} from "@bullseye/components"

export const NotificationsButton = () => {
  const { count } = useUnseenNotificationCount()

  return (
    <Badge
      badgeContent={count}
      max={20}
      position="right-top"
      className="-right-1 -top-1 translate-x-0 translate-y-0"
    >
      <IconButton as="a" href={NOTIFICATIONS_URI} iconType="bell" size="lg" />
    </Badge>
  )
}
