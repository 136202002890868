"use client"

import { useMemo } from "react"
import { FormattedMessage } from "react-intl"

import {
  areTriggersEnabled,
  Box,
  triggerToTemplateIdMap,
  useGlobalSettings,
  UserNotificationSettings,
  useUpdateUser,
  useUserContext,
  useWorkflowSettings,
  WorkflowSetting,
} from "@bullseye/components"

import { ChannelSettings } from "../ChannelSettings"
import { SingleSetting } from "../SettingElement"

function emailSettingElements(
  notificationElements: EmailNotificationElement[],
  user: UserNotificationSettings,
  workflowData: WorkflowSetting[],
) {
  const templateIdMap = triggerToTemplateIdMap(workflowData)

  return notificationElements.map((element) => {
    let enabled = false
    for (const prop of element.userProps) {
      enabled ||= !!user[prop]
    }

    let templateIds: string[] = []
    if (element.triggers) {
      const te = areTriggersEnabled(element.triggers, "email", templateIdMap)
      enabled ||= te.enabled
      templateIds = te.templateIds
    }

    return {
      ...element,
      enabled,
      templateIds,
    }
  })
}

export interface EmailNotificationElement extends SingleSetting {
  userProps: Array<keyof UserNotificationSettings>
  triggers?: string[]
}

type EmailSettingsProps = {
  elements: EmailNotificationElement[]
}

export const EmailSettings = ({
  elements: emailElements,
}: EmailSettingsProps) => {
  const { user, accessToken } = useUserContext()
  const { update } = useUpdateUser()

  const {
    isLoading: isGlobalLoading,
    data: allData,
    updateChannel,
  } = useGlobalSettings(accessToken)

  const {
    data: workflowData,
    isLoading: elementsLoading,
    isUpdateLoading: elementsUpdating,
    updateTemplates,
  } = useWorkflowSettings(accessToken)

  const allOn =
    user.receive_roundup_notifications ||
    user.receive_message_notifications ||
    user.receive_marketing_emails ||
    user.receive_new_post_notifications ||
    user.receive_new_comment_notifications ||
    user.receive_new_event_notifications ||
    allData?.channels.email ||
    false

  const elements = useMemo(() => {
    if (elementsLoading || !workflowData) {
      return null
    }

    return emailSettingElements(emailElements, user, workflowData)
  }, [user, elementsLoading, elementsUpdating])

  return (
    <Box className="w-full">
      <ChannelSettings
        title={<FormattedMessage id="settings.notifications.email.title" />}
        all={{
          label: <FormattedMessage id="settings.notifications.email.label" />,
        }}
        allOn={allOn}
        setAllOn={async (t) => {
          await update({
            receive_roundup_notifications: t,
            receive_message_notifications: t,
            receive_marketing_emails: t,
            receive_new_post_notifications: t,
            receive_new_comment_notifications: t,
            receive_new_event_notifications: t,
          })
          await updateChannel("email", t)
        }}
        isAllLoading={isGlobalLoading}
        toggleElement={async (id, v) => {
          if (elements === null) {
            return
          }
          const fields = elements[id]!.userProps
          if (fields.length === 0) {
            return
          }
          const req = fields.reduce((acc, field) => {
            acc[field] = v
            return acc
          }, {} as Record<string, boolean>)
          await update(req)

          const tIds = elements[id]?.templateIds
          if (!tIds || tIds.length === 0) return
          await updateTemplates(tIds, v, "email")
        }}
        elementsLoading={elementsLoading || elements === null}
        elements={elements ?? emailElements}
      />
    </Box>
  )
}
