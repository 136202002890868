"use client"

import React from "react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import { usePathname } from "@bullseye/navigation"

import type * as icons from "../../icons"
import { Avatar } from "../Avatar"
import { Badge } from "../Badge"
import { Icon } from "../Icon"
import { Box } from "../Layout"
import Link from "../Link"
import { Span } from "../Typography"

type Props = {
  items: {
    icon?: (typeof icons.iconTypes)[number]
    avatar?: {
      imageUrl?: string
      name?: string
    }
    titleKey: string
    title: string
    uri: string
    badge?: number
  }[]
}

export const MobileTabs = ({ items }: Props) => {
  const pathname = usePathname()
  return (
    <Box className="p fixed bottom-0 z-40 block w-full border-t border-solid border-gray-100 bg-white px-2 pt-2 pb-safe md:hidden">
      <Box className="flex flex-row justify-around pb-2">
        {items.map((i, idx) => {
          const isActive = pathname === i.uri
          return (
            <Link href={i.uri} key={idx}>
              <Box className="flex flex-col items-center gap-1 px-1 no-underline">
                <Badge
                  badgeContent={i.badge}
                  max={9}
                  position="right-top"
                  className="-top-1 left-4 right-auto translate-x-0 translate-y-0"
                >
                  {i.icon && (
                    <Icon
                      type={i.icon}
                      size="lg"
                      color={isActive ? "text-primary-500" : "text-gray-600"}
                    />
                  )}
                  {i.avatar && (
                    <Avatar
                      imageUrl={i.avatar.imageUrl}
                      name={i.avatar.name ?? ""}
                      size="sm"
                      borderless
                    />
                  )}
                </Badge>
                <Span
                  className={twMerge(
                    clsx("text-sm text-gray-600", {
                      "text-primary-500": isActive,
                    }),
                  )}
                >
                  <FormattedMessage
                    id={i.titleKey}
                    defaultMessage={i.title}
                    description="Navigation link"
                  />
                </Span>
              </Box>
            </Link>
          )
        })}
      </Box>
    </Box>
  )
}
