"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H3,
  P,
  Sheet,
  useExperienceContext,
  useRemoveFromWaitlist,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export type CancelWaitlistModalProps = {
  refetch?: () => Promise<any>
}

export const CancelWaitlist = ({
  isOpen,
  dismiss,
  refetch,
}: Modal<CancelWaitlistModalProps>) => {
  const { experience } = useExperienceContext()

  const { removeFromWaitlistAsync } = useRemoveFromWaitlist(experience)

  return (
    <Sheet
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="flex pb-10 g-4">
            <H3>
              <FormattedMessage id="activity.waitlist.modal.cancel.title" />
            </H3>
            <P>
              <FormattedMessage id="activity.waitlist.modal.cancel.description" />
            </P>
          </Box>
        )
      }}
      renderFooter={() => {
        return (
          <Box className="flex-row items-center justify-around g-2">
            <Box className="w-6/12">
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => dismiss()}
              >
                <FormattedMessage id="general.dismiss" />
              </Button>
            </Box>
            <Box className="w-6/12">
              <Button
                as="button"
                variant="danger"
                handlePress={async () => {
                  await removeFromWaitlistAsync()
                  await refetch?.()
                  dismiss()
                }}
              >
                <FormattedMessage id="general.confirm" />
              </Button>
            </Box>
          </Box>
        )
      }}
    />
  )
}
