export {
  APP_URL,
  MARKETING_URL,
  ACTIVITIES_URI,
  POST_ACTIVITY_URI,
  ATTENDING_URI,
  MEMBERS_URI,
  MESSENGER_URI,
  USER_HOME_URI,
  PROFILE_URI,
  HOME_URI,
  PRICING_URI,
  LOGIN_URI,
  SIGN_UP_URI,
  LOGOUT_URI,
  ME_EDIT_URI,
  ME_URI,
  SETTINGS_URI,
  SUBSCRIPTION_URI,
  ACCOUNT_REFERRALS_URI,
  NOTIFICATIONS_URI,
  WELCOME_BACK_URI,
  LOGIN_AUTH0_URI,
  LOGOUT_AUTH0_URI,
  SIGN_UP_AUTH0_URI,
  NOTIFICATION_SETTINGS_URI,
  BLOG_URL,
  ABOUT_US_URL,
  COMMUNITY_GUIDELINES_URL,
  HELP_CENTER_URL,
  HANK_ATTENDANCE_POLICY_URL,
  HANK_BADGE_INFO_URL,
  HANK_BADGE_URL,
  IP_GEOLOCATION_V2,
  HANK_HOST_PROGRAM_URL,
  PRIVACY_POLICY_URI,
  TERMS_OF_SERVICE_URI,
  NATIVE_LOCATION_PERMISSIONS,
  NATIVE_NOTIFICATION_PERMISSIONS,
  ONBOARDING_SUCCESS,
} from "@bullseye/routes"
