import React, { PropsWithChildren } from "react"
import clsx from "clsx"
import { twMerge } from "tailwind-merge"

import { Box } from "../Layout"
import { Span } from "../Typography"

type Horizontal = "left" | "right" | "center"
type Vertical = "top" | "middle" | "bottom"
type Position = `${Horizontal}-${Vertical}`

type BadgeProps = PropsWithChildren<{
  badgeContent?: React.ReactNode
  className?: string
  containerClassName?: string
  max?: number
  showZero?: boolean
  position?: Position
}>

type ClassesMapType = { [k in Horizontal]: { [y in Vertical]?: string } }

const containerClasses: ClassesMapType = {
  left: {
    middle: "flex-row-reverse gap-1 items-center",
  },
  center: {
    top: "flex-col-reverse gap-1 items-center",
    bottom: "flex-col gap-1 items-center",
  },
  right: {
    middle: "flex-row gap-1 items-center",
  },
}

const badgeClasses: ClassesMapType = {
  left: {
    top: "absolute left-0 -translate-x-1/2 top-0 -translate-y-1/2",
    bottom: "absolute left-0 -translate-x-1/2 bottom-0 translate-y-1/2",
  },
  center: {
    middle: "absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2",
  },
  right: {
    top: "absolute right-0 translate-x-1/2 top-0 -translate-y-1/2",
    bottom: "absolute right-0 translate-x-1/2 bottom-0 translate-y-1/2",
  },
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  badgeContent,
  max = 0,
  showZero = false,
  className,
  containerClassName,
  position = "right-top",
}) => {
  if (typeof badgeContent === "undefined") {
    return <>{children}</>
  }

  const isNumber = typeof badgeContent === "number"
  const isZero = isNumber && badgeContent === 0
  let content = badgeContent
  if (isNumber && max && max < badgeContent) {
    content = `${max}+`
  }
  const [horizontal, vertical] = position.split("-") as [Horizontal, Vertical]

  return (
    <Box
      className={twMerge(
        clsx("relative inline-flex", containerClasses[horizontal][vertical]),
        containerClassName,
      )}
    >
      {children}
      {(!isZero || showZero) && (
        <Box
          className={twMerge(
            clsx(
              "min-w-[16px] rounded-full bg-red-400 px-[3px]",
              badgeClasses[horizontal][vertical],
            ),
            className,
          )}
        >
          <Span className="text-center text-sm font-medium text-white">
            {content}
          </Span>
        </Box>
      )}
    </Box>
  )
}
