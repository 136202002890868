"use client"

import { ReactNode, useState } from "react"
import { Linking, Pressable } from "react-native"
import * as WebBrowser from "expo-web-browser"
import { styled } from "nativewind"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  COMMUNITY_GUIDELINES_URL,
  HELP_CENTER_URL,
  Icon,
  isWeb,
  LOGOUT_URI,
  NOTIFICATION_SETTINGS_URI,
  P,
  PRIVACY_POLICY_URI,
  SETTINGS_URI,
  TERMS_OF_SERVICE_URI,
  useAppConfig,
  User,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { DeleteProfileModal } from "./DeleteProfileModal"

const rowStyles = "flex w-full cursor-pointer flex-row justify-between py-4"

type LinkRowProps = {
  presentation: "sheet" | "external" | "internal"
  href: string
  label: ReactNode
}

function LinkRow({ label, href, presentation }: LinkRowProps) {
  const router = useRouter()

  const onPress = () => {
    if (presentation === "internal") {
      router.push(href)
      return
    }

    if (presentation === "sheet") {
      return WebBrowser.openBrowserAsync(href)
    }

    return Linking.openURL(href)
  }

  const props = isWeb()
    ? {
        href,
      }
    : {
        onPress,
      }

  return (
    <Row className={rowStyles} role={isWeb() ? "link" : "button"} {...props}>
      <P>{label}</P>
      <Icon type="chevron-right" />
    </Row>
  )
}

const Row = styled(Pressable)

const Br = () => (
  <Box className="align-self-center w-full border-b border-solid border-gray-100" />
)

export const UserSettingsLinks = ({ user }: { user: User }) => {
  const [showDeletionModal, setShowDeletionModal] = useState(false)
  const { hankAppUrl } = useAppConfig()
  return (
    <Box className="flex w-full flex-1 bg-gray-10 g-2 md:bg-white md:px-4 md:g-6">
      <Box className="flex w-full overflow-hidden bg-white px-4 md:rounded-lg md:border md:border-solid md:border-gray-100 md:px-6 md:py-4">
        <LinkRow
          href={NOTIFICATION_SETTINGS_URI}
          label={
            <FormattedMessage id="navigation.account.notification-settings" />
          }
          presentation="internal"
        />
        <Br />
        <LinkRow
          href={SETTINGS_URI}
          label={<FormattedMessage id="navigation.paymentMethods" />}
          presentation="internal"
        />
      </Box>
      <Box className="flex w-full overflow-hidden bg-white px-4 md:rounded-lg md:border md:border-solid md:border-gray-100 md:px-6 md:py-4">
        <LinkRow
          href={HELP_CENTER_URL}
          label={<FormattedMessage id="navigation.help-center" />}
          presentation="sheet"
        />
        <Br />
        <LinkRow
          href={
            isWeb() ? PRIVACY_POLICY_URI : `${hankAppUrl}${PRIVACY_POLICY_URI}`
          }
          label={<FormattedMessage id="navigation.privacyPolicy" />}
          presentation="sheet"
        />
        <Br />
        <LinkRow
          href={COMMUNITY_GUIDELINES_URL}
          label={<FormattedMessage id="navigation.guidelines" />}
          presentation="sheet"
        />
        <Br />
        <LinkRow
          href={
            isWeb()
              ? TERMS_OF_SERVICE_URI
              : `${hankAppUrl}${TERMS_OF_SERVICE_URI}`
          }
          label={<FormattedMessage id="navigation.terms" />}
          presentation="sheet"
        />
        <Br />
        <Row
          className={rowStyles}
          onPress={() => {
            setShowDeletionModal(true)
          }}
        >
          <P className="text-red-600">
            <FormattedMessage id="profile.settings.deleteAccount" />
          </P>
        </Row>
        <Br />
        <Box className={rowStyles}>
          <Button as="a" href={LOGOUT_URI} variant="tertiary">
            <FormattedMessage id="general.logOut" />
          </Button>
        </Box>
      </Box>
      <DeleteProfileModal
        isOpen={showDeletionModal}
        dismiss={() => setShowDeletionModal(false)}
        userId={user.id}
      />
    </Box>
  )
}
