import { ReportedContent } from "@bullseye/types"

import { useUserContext } from "../providers"
import { usePostRequest } from "./useRequest"

type CreateReportedContentRequest = Omit<
  ReportedContent,
  "reporter_id" | "id" | "created_at" | "updated_at"
>

export const useCreateReportedContent = () => {
  const { accessToken } = useUserContext()
  const { postRequest, postRequestAsync, data, ...rest } = usePostRequest<
    ReportedContent,
    CreateReportedContentRequest
  >({
    path: `reported-contents`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    createReportedContent: (data: CreateReportedContentRequest) =>
      postRequest({ body: data }),
    createReportedContentAsync: (data: CreateReportedContentRequest) => {
      return postRequestAsync({ body: data })
    },
    data,
    ...rest,
  }
}
