import { useEffect, useState } from "react"

import { isWeb } from "../utils/device"

export const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  callback: VoidFunction,
) => {
  const [firstMount, setFirstMount] = useState(true)

  const handleClick = (e: globalThis.MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
      callback()
    }
  }

  useEffect(() => {
    if (!isWeb()) return
    if (!firstMount) {
      setFirstMount(true)
      return
    }

    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  })
}
