"use client"

import { usePathname, useRouter } from "next/navigation"

import { useAvailablePartnerships, useUserContext } from "@bullseye/components"
import {
  HOME_URI,
  ONBOARDING_PROFILE_PHOTO,
  ONBOARDING_REGISTRATION,
  ONBOARDING_SUBSCRIPTION,
  ONBOARDING_SUCCESS,
  ONBOARDING_URI,
  ONBOARDING_VERIFICATION,
} from "@bullseye/routes"
import { Partnership, User } from "@bullseye/types"

const orderedPages = (user: User, partnership?: Partnership) => {
  const steps = []

  if (partnership && user.tier === "free") {
    steps.push(ONBOARDING_SUBSCRIPTION)
  }

  if (!user.avatar?.url) {
    steps.push(ONBOARDING_PROFILE_PHOTO)
  }

  if ((user.age ?? 0) >= 64) {
    steps.push(ONBOARDING_REGISTRATION)
  }

  if (!user.is_verified && user.tier === "premium") {
    steps.push(ONBOARDING_VERIFICATION)
  }

  if (steps.length) {
    steps.push(ONBOARDING_SUCCESS)
  }

  return steps
}

const useOnboardingFlow = () => {
  const { refetch: refetchUser } = useUserContext()
  const { isLoading, refetch: refetchPartnerships } = useAvailablePartnerships()
  const pathname = usePathname()
  const router = useRouter()

  function refetch() {
    return Promise.all([refetchUser(), refetchPartnerships()])
  }

  async function advanceToNextStep() {
    const [user, partnership] = await refetch()
    const pages = orderedPages(user, partnership)

    if (pathname === ONBOARDING_URI) {
      router.push(pages[0] || HOME_URI)
      return
    }

    const currentPagePosition = pages.findIndex((page) => page === pathname)
    const nextStep = pages[currentPagePosition + 1]

    router.push(nextStep || ONBOARDING_SUCCESS)
  }

  return {
    isLoading: isLoading,
    advanceToNextStep,
    refetch,
  }
}

export default useOnboardingFlow
