import { useUserContext } from "../providers"
import { usePutRequest } from "./useRequest"

type UploaderRequest = {
  file_name: string
}

type UploaderResponse = {}

export const useSetAvatar = () => {
  const { accessToken } = useUserContext()
  const { putRequestAsync, ...rest } = usePutRequest<
    UploaderResponse,
    UploaderRequest
  >({
    path: "v1/users/me/avatar",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    setAvatarAsync: (media: string) =>
      putRequestAsync({ body: { file_name: media } }),
    ...rest,
  }
}
