"use client"

import React, { ReactNode } from "react"
import { useWindowDimensions } from "react-native"
import clsx from "clsx"

import {
  Box,
  HankLogo,
  IconButton,
  isWeb,
  JourneyLine,
  ScrollBox,
  useUserContext,
} from "@bullseye/components"

type Props = {
  children: ReactNode
  previousPage?: string
  isPremium?: boolean
}

export const OnboardingJourney = ({
  children,
  previousPage,
  isPremium = false,
}: Props) => {
  const { width } = useWindowDimensions()
  const { user } = useUserContext()

  const Container = !isWeb() ? ScrollBox : React.Fragment

  return (
    <Box className="relative flex h-full w-full flex-1 items-center justify-between bg-white p-4">
      <Box className="absolute left-[-12px] top-[-12px] pt-4">
        <JourneyLine className="aspect-[4/1]" style={{ width }} />
      </Box>
      <Box className="flex w-full flex-row">
        <Box
          className={clsx("flex-1", {
            hidden: !previousPage,
          })}
        >
          <IconButton iconType="arrow-left" as="a" href={previousPage} />
        </Box>
        <Box className="flex-1 flex-row items-center justify-center">
          <HankLogo
            size="lg"
            isPremium={isPremium || user.tier === "premium"}
          />
        </Box>
        <Box
          className={clsx("flex-1", {
            hidden: !previousPage,
          })}
        />
      </Box>
      <Box className="flex h-full w-full max-w-[400px] flex-1 items-center justify-center g-10">
        <Container
          className="h-full w-full"
          contentContainerStyle={{
            flexGrow: 1,
            maxWidth: 400,
            width: "100%",
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  )
}
