import { isPast } from "date-fns"
import { FormattedMessage } from "react-intl"

import {
  Box,
  P,
  TextButton,
  useExperienceContext,
  useUserContext,
} from "@bullseye/components"

import { ActivityModal, useActivityModalContext } from "../../../../components"

export const WaitlistCurrentCount = ({}) => {
  const { user } = useUserContext()
  const { experience, waitlist, isFull } = useExperienceContext()
  const { openModal } = useActivityModalContext()

  if (!isFull) return null
  if (!(user?.admin || experience.organizer_id === user?.id)) return null

  if (isPast(new Date(experience.start_date))) {
    return null
  }
  const count = waitlist ? waitlist.length : 0

  if (count === 0)
    return (
      <P bold>
        <FormattedMessage id="activity.waitlist.current.label" />:{" "}
        <FormattedMessage id="general.people" values={{ count }} />
      </P>
    )

  return (
    <TextButton
      as="button"
      variant="primary"
      className="justify-start"
      handlePress={() => openModal(ActivityModal.NotifyList)}
    >
      <FormattedMessage id="activity.waitlist.current.label" />:{" "}
      <FormattedMessage id="general.people" values={{ count }} />
    </TextButton>
  )
}

export const WaitlistInfo = ({}) => {
  const { openModal } = useActivityModalContext()
  const { experience, isFull, waitlistIndex: index } = useExperienceContext()

  if (!isFull) return null
  if (experience.requester.is_attending) return null
  if (isPast(new Date(experience.start_date))) {
    return null
  }

  if (!index && index !== 0) {
    return (
      <P>
        <FormattedMessage id="activity.waitlist.join.info" />
      </P>
    )
  }

  return (
    <Box>
      <P>
        <FormattedMessage id="activity.waitlist.queued.info" />
      </P>
      <TextButton
        as="button"
        variant="primary"
        className="justify-start"
        handlePress={() => openModal(ActivityModal.Waitlist)}
      >
        <FormattedMessage id="general.learnMore" />
      </TextButton>
    </Box>
  )
}
