"use client"

import { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { AsYouType } from "libphonenumber-js"
import { styled } from "nativewind"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import {
  Avatar,
  BirthdatePicker,
  Box,
  Button,
  H2,
  H4,
  IconButton,
  InputSheet,
  P,
  Picker,
  PressableBox,
  ScrollBox,
  TextButton,
  ToggleWithLabel,
  useLoggingContext,
  User,
  useUpdateUser,
  useUserContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { ApplicationModal, useApplicationModalContext } from "../../components"
import { useToastContext } from "../../providers"
import { EditBioModal } from "./components/EditBioModal"

import "yup-phone-lite"

const Section = styled(
  Box,
  "bg-white g-2 py-6 px-4 md:px-0 w-full max-w-[880px]",
)

export const EditProfilePage = () => {
  const { user, refetch } = useUserContext()
  const userName = `${user.first_name} ${user.last_name}`
  const { openModal } = useApplicationModalContext()
  const [showEditBio, setShowEditBio] = useState(false)
  const { update } = useUpdateUser()
  const { openErrorToast } = useToastContext()
  const { captureException } = useLoggingContext()
  const router = useRouter()

  const intl = useIntl()
  const required = intl.formatMessage({ id: "validation.required" })
  const schema = yup.object().shape({
    first_name: yup.string().required(required),
    last_name: yup.string().required(required),
    email: yup.string().email().required(required),
    phone: yup
      .string()
      .phone("US", intl.formatMessage({ id: "validation.phone" }))
      .nullable(),
    dob: yup.date().required(required),
    gender: yup.string().nullable(),
    show_gender: yup.boolean().optional(),
    show_age: yup.boolean().optional(),
  })

  const form = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.formatted_phone,
      dob: new Date(user.dob),
      gender: user.gender,
      show_gender: user.show_gender,
      show_age: user.show_age,
    },
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  async function handleUserUpdate(data: Partial<User>) {
    try {
      await update(data)
      await refetch()
    } catch (error) {
      captureException(new Error(`Failed to update user: ${error}`))
      openErrorToast({
        delayMs: 3000,
        label: intl.formatMessage({ id: "errors.unknown" }),
      })
    }
  }
  return (
    <>
      <Box className="flex w-full items-center bg-white px-4 pb-6 md:py-6">
        <Box className="w-full max-w-[880px]">
          <Box className="flex h-14 justify-center md:hidden">
            <IconButton
              iconType="arrow-left"
              as="button"
              size="lg"
              handlePress={() => {
                router.back()
              }}
            />
          </Box>
          <Box className="flex h-14 justify-center">
            <H2>
              <FormattedMessage id="profile.edit" />
            </H2>
          </Box>
        </Box>
      </Box>
      <ScrollBox
        contentContainerStyle={{
          width: "100%",
        }}
      >
        <FormProvider {...form}>
          <Box className="flex w-full max-w-[880px] items-center self-center md:pb-10">
            <Box className="w-full flex-col bg-gray-10 g-2 md:flex-row md:bg-white">
              <Box className="flex w-full items-start md:h-full md:w-[33%]">
                <Section className="flex w-full items-center md:items-start md:py-0">
                  <Box className="flex items-center g-2">
                    <PressableBox
                      className="h-32 w-32 rounded-full md:h-[200px] md:w-[200px]"
                      onPress={() =>
                        openModal(ApplicationModal.ProfilePhoto, {
                          avatarUrl: user.avatar?.url,
                        })
                      }
                    >
                      <Avatar
                        name={userName}
                        className="h-32 w-32 md:h-[200px] md:w-[200px]"
                        imageUrl={user.avatar?.url}
                      />
                    </PressableBox>
                    <Button
                      as="button"
                      variant="secondary"
                      startIconType="camera"
                      handlePress={() => {
                        openModal(ApplicationModal.ProfilePhoto, {
                          avatarUrl: user.avatar?.url,
                        })
                      }}
                      compact
                    >
                      {user.avatar?.url ? (
                        <FormattedMessage id="photoUpload.changePhoto" />
                      ) : (
                        <FormattedMessage id="photoUpload.addPhoto" />
                      )}
                    </Button>
                  </Box>
                </Section>
              </Box>
              <Box className="w-full flex-col items-center bg-gray-10 g-2 md:w-[66%] md:g-px">
                <Section>
                  <Box className="flex-row items-center justify-between">
                    <H4>
                      <FormattedMessage id="profile.bio" />
                    </H4>
                    <TextButton
                      as="button"
                      underlined
                      handlePress={() => {
                        setShowEditBio(true)
                      }}
                    >
                      <FormattedMessage id="general.edit" />
                    </TextButton>
                  </Box>
                  {user.bio ? (
                    <Box>
                      <P>{user.bio}</P>
                    </Box>
                  ) : (
                    <PressableBox
                      onPress={() => setShowEditBio(true)}
                      className="flex items-center justify-center rounded-lg bg-gray-10 p-4"
                    >
                      <P>
                        <FormattedMessage id="profile.addBio" />
                      </P>
                    </PressableBox>
                  )}
                </Section>
                <Section>
                  <H4>
                    <FormattedMessage id="profile.basics" />
                  </H4>
                  <Controller
                    name="first_name"
                    rules={{
                      required: intl.formatMessage({
                        id: "validation.required",
                      }),
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputSheet
                          iconType="user-outline"
                          value={value}
                          onChangeText={(text) => {
                            onChange(text)
                          }}
                          onSubmit={(firstName) => {
                            return handleUserUpdate({ first_name: firstName })
                          }}
                          onDismiss={() => {
                            onChange(user.first_name)
                          }}
                          label={
                            <FormattedMessage id="onboarding.basicInfo.firstName" />
                          }
                          errorMessage={error?.message}
                          secondaryAction={(dismiss) => {
                            onChange(user.first_name)
                            dismiss()
                          }}
                          clearText={intl.formatMessage({
                            id: "general.dismiss",
                          })}
                          disabledOnError
                        />
                      )
                    }}
                  />
                  <Controller
                    name="last_name"
                    rules={{
                      required: intl.formatMessage({
                        id: "validation.required",
                      }),
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputSheet
                          iconType="user-outline"
                          value={value}
                          onChangeText={(text) => {
                            onChange(text)
                          }}
                          onSubmit={(lastName) => {
                            return handleUserUpdate({ last_name: lastName })
                          }}
                          onDismiss={() => {
                            onChange(user.last_name)
                          }}
                          secondaryAction={(dismiss) => {
                            onChange(user.first_name)
                            dismiss()
                          }}
                          clearText={intl.formatMessage({
                            id: "general.dismiss",
                          })}
                          label={
                            <FormattedMessage id="onboarding.basicInfo.lastName" />
                          }
                          errorMessage={error?.message}
                          disabledOnError
                        />
                      )
                    }}
                  />
                  <Controller
                    name="phone"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputSheet
                          iconType="phone"
                          value={value}
                          onChangeText={(phone) => {
                            const priorValue = form.getValues("phone")
                            const next =
                              priorValue?.length > phone?.length
                                ? phone
                                : new AsYouType("US").input(phone)
                            onChange(next)
                          }}
                          onSubmit={async (phone) => {
                            return handleUserUpdate({
                              phone,
                            })
                          }}
                          onDismiss={() => {
                            onChange(user.formatted_phone)
                          }}
                          label={<FormattedMessage id="profile.phone" />}
                          secondaryAction={(dismiss) => {
                            onChange(user.first_name)
                            dismiss()
                          }}
                          clearText={intl.formatMessage({
                            id: "general.dismiss",
                          })}
                          errorMessage={error?.message}
                          disabledOnError
                        />
                      )
                    }}
                  />
                  <Controller
                    name="email"
                    render={({ field: { value } }) => {
                      return (
                        <InputSheet
                          iconType="mail"
                          value={value}
                          onSubmit={() => null}
                          enabled={false}
                          label={<FormattedMessage id="profile.email" />}
                          helperText={
                            <FormattedMessage id="profile.email.notEditable" />
                          }
                        />
                      )
                    }}
                  />
                </Section>
                <Section>
                  <H4>
                    <FormattedMessage id="profile.gender" />
                  </H4>
                  <Controller
                    name="gender"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Picker
                          onChange={async (gender) => {
                            onChange(gender)
                            return handleUserUpdate({ gender })
                          }}
                          selectedValue={value}
                          placeholder={intl.formatMessage({
                            id: "profile.gender",
                          })}
                          items={[
                            {
                              label: "Man",
                              value: "male",
                            },
                            {
                              label: "Woman",
                              value: "female",
                            },
                            {
                              label: "Non-binary",
                              value: "other",
                            },
                          ]}
                          selectionMode="radio"
                        />
                      )
                    }}
                  />
                  <Controller
                    name="show_gender"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ToggleWithLabel
                          label={<FormattedMessage id="profile.showGender" />}
                          value={value}
                          onChange={(value) => {
                            onChange(value)
                            return handleUserUpdate({ show_gender: value })
                          }}
                        />
                      )
                    }}
                  />
                </Section>
                <Section>
                  <H4>
                    <FormattedMessage id="onboarding.basicInfo.dateOfBirth" />
                  </H4>
                  <Controller
                    name="dob"
                    rules={{ required: true }}
                    render={({ field: { onBlur, onChange, value } }) => {
                      return (
                        <BirthdatePicker
                          selectedValue={value}
                          onChange={(dob: string) => {
                            onChange(dob)
                            onBlur()
                            return handleUserUpdate({ dob })
                          }}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="show_age"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ToggleWithLabel
                          label={<FormattedMessage id="profile.showAge" />}
                          value={value}
                          onChange={(value) => {
                            onChange(value)
                            return handleUserUpdate({ show_age: value })
                          }}
                        />
                      )
                    }}
                  />
                </Section>
              </Box>
            </Box>
          </Box>
          <EditBioModal
            isOpen={showEditBio}
            dismiss={() => setShowEditBio(false)}
          />
        </FormProvider>
      </ScrollBox>
    </>
  )
}
