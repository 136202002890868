import { useQuery } from "@tanstack/react-query"

import { useMessagingContext } from "../providers/MessagingProvider"

export const useChannel = (channelId?: string) => {
  const { client } = useMessagingContext()
  const { data, ...rest } = useQuery(
    ["channel", channelId],
    () => {
      return client.channel("messaging", channelId)
    },
    {
      enabled: !!channelId,
    },
  )

  return {
    ...rest,
    channel: data,
  }
}
