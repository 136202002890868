import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  useMutation as useRQMutation,
} from "@tanstack/react-query"

import { PaginatedResponse } from "@bullseye/types"

import { useHTTPContext } from "../providers/HTTPProvider"

export const ErrUnauthorized = new Error("Unauthorized")
export const ErrConflict = new Error("Conflict")
export const ErrParse = new Error("Parse Error")
export const ErrBadGateway = new Error("Bad Gateway")

const defaultOptions = {
  enabled: true,
}

type MutationProperties<Body, Params> =
  | {
      body?: Body
      params?: Params
    }
  | null
  | undefined

type RequestArgs<P = Record<string, any>> = {
  path: string
  method?: string
  params?: P
  headers?: Record<string, string>
}

type UpdateRequestArgs<B, P> = RequestArgs<P> & {
  body?: B
  onMutate?: (variables: MutationProperties<B, P>) => Promise<unknown> | unknown
  signal?: AbortSignal
}

type QueryArgs = {
  enabled: boolean
}

function pathWithParams(path: string, params?: Record<string, any>) {
  if (!params) return path

  path = path.includes("?") ? `&${path}` : `${path}?`
  const searchParams = new URLSearchParams(params)

  Object.keys(params).forEach((key) => {
    if (params[key] && Array.isArray(params[key])) {
      searchParams.delete(key)
      params[key].forEach((value: string) => {
        searchParams.append(key, value)
      })
    }
  })

  return `${path}${searchParams.toString()}`
}

const makeRequest = async <R, B, P = Record<string, string>>({
  path,
  method,
  params,
  body,
  headers = {},
  signal,
}: UpdateRequestArgs<B, P>) => {
  const res = await fetch(params ? pathWithParams(path, params) : path, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers,
    signal,
  })

  if (res.ok) {
    if (res.status === 204) {
      return { data: null, status: 204 }
    }

    const response = await res.json().catch(() => {
      throw ErrParse
    })

    return { data: response as R, status: res.status }
  }

  switch (res.status) {
    case 401:
      throw ErrUnauthorized
    case 409:
      throw ErrConflict
    case 502:
      throw ErrBadGateway
    default:
      throw new Error(`Request failed with status ${res.status}`)
  }
}

export type PaginatedRequest<R, P> = RequestArgs<P> & {
  queryKey?: QueryKey
  select?: (data: InfiniteData<R>) => InfiniteData<R>
}

type InfiniteResult<R> = UseInfiniteQueryResult<R, unknown> & {
  key?: QueryKey
}

export const usePaginatedRequest = <R, P = Record<string, any>>(
  { path, params, headers = {}, queryKey, select }: PaginatedRequest<R, P>,
  options: QueryArgs = defaultOptions,
): InfiniteResult<R> => {
  const { apiBase } = useHTTPContext()

  const key = queryKey || [path, params]

  const query = useInfiniteQuery<R>({
    queryKey: key,
    select,
    queryFn: async ({ pageParam: page = 1 }) => {
      const { data } = await makeRequest<R, undefined, P>({
        path: `${apiBase}/${path}`,
        method: "GET",
        headers,
        params: {
          ...params,
          page: page.toString(),
        },
      })

      return data
    },
    getNextPageParam: (lastPage: any) => {
      const page = lastPage as Pick<PaginatedResponse<any>, "metadata">
      if (!page?.metadata?.page) return undefined
      const nextPage = page.metadata.page + 1
      if (nextPage > page.metadata.total_pages) return undefined
      return nextPage
    },
    enabled: options.enabled,
  })

  return {
    key,
    ...query,
  }
}

export const useGetRequest = <R extends {}>(
  { path, headers = {}, params, method = "GET" }: RequestArgs,
  { enabled = true }: QueryArgs = defaultOptions,
) => {
  const { apiBase } = useHTTPContext()
  const p = params ? pathWithParams(path, params) : path
  const url = `${apiBase}/${p}`
  const key: QueryKey = [url]
  const { data, status, error, isLoading, refetch, isRefetching } = useQuery(
    key,
    async ({ signal }) =>
      makeRequest<R, null, any>({ path: url, method, headers, signal }),
    {
      enabled,
    },
  )

  return {
    error,
    data: data?.data,
    status,
    isLoading,
    refetch,
    isRefetching,
    key,
  }
}

const useMutation = <R, Body, Params>({
  path,
  method,
  params,
  body,
  headers = {},
  onMutate,
}: UpdateRequestArgs<Body, Params>) => {
  const { apiBase } = useHTTPContext()
  const url = `${apiBase}/${path}`
  const { mutate, mutateAsync, data, status, error, ...rest } = useRQMutation({
    onMutate,
    mutationFn: async (mutation?: MutationProperties<Body, Params>) => {
      const { data, status } = await makeRequest<R, Body, Params>({
        path: url,
        method,
        params: mutation?.params || params,
        body: mutation?.body || body,
        headers,
      })
      return { data, status }
    },
    cacheTime: 0,
  })

  return { mutate, mutateAsync, error, data: data?.data, status, ...rest }
}

export const usePostRequest = <R, B, P = Record<string, string>>({
  path,
  params,
  body,
  headers = {},
  onMutate,
}: UpdateRequestArgs<B, P>) => {
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json"
  }
  const {
    mutate: postRequest,
    mutateAsync: postRequestAsync,
    ...rest
  } = useMutation<R, B, P>({
    path,
    params,
    body,
    method: "POST",
    headers,
    onMutate,
  })
  return { postRequest, postRequestAsync, ...rest }
}

export const usePutRequest = <R, B = unknown, P = unknown>({
  path,
  params,
  body,
  headers = {},
  onMutate,
}: UpdateRequestArgs<B, P>) => {
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json"
  }
  const {
    mutate: putRequest,
    mutateAsync: putRequestAsync,
    ...rest
  } = useMutation<R, B, P>({
    path,
    params,
    body,
    method: "PUT",
    headers,
    onMutate,
  })
  return { putRequest, putRequestAsync, ...rest }
}

export const useDeleteRequest = <R, P = Record<string, string>>({
  path,
  params,
  headers = {},
}: RequestArgs<P>) => {
  const {
    mutate: deleteRequest,
    mutateAsync: deleteRequestAsync,
    ...rest
  } = useMutation<R, {}, P>({
    path,
    params,
    method: "DELETE",
    headers,
  })
  return { deleteRequest, deleteRequestAsync, ...rest }
}
