"use client"

import { StyleProp, ViewStyle } from "react-native"
import { twMerge } from "tailwind-merge"

import { Box } from "@bullseye/components"

type Props = {
  children: React.ReactNode
  className?: string
  style?: StyleProp<ViewStyle>
}
export const PageHeader = ({ style, children, className }: Props) => {
  return (
    <Box
      className={twMerge(
        "h-14 w-full flex-row items-center justify-between bg-white px-4",
        className,
      )}
      style={style}
    >
      {children}
    </Box>
  )
}
