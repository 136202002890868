import React from "react"
import Svg, { Path, Rect } from "react-native-svg"

export const Discover = () => (
  <Svg viewBox="0 0 64 40" fill="none">
    <Rect
      x=".5"
      y=".5"
      width="63"
      height="39"
      rx="2.5"
      fill="#fff"
      stroke="#DBDBDB"
    />
    <Path
      d="M33.5 15c2.485 0 4.5 2.012 4.5 4.497v.003a4.5 4.5 0 01-9 0v-.003A4.498 4.498 0 0133.5 15z"
      fill="#F47216"
    />
    <Path
      d="M26.257 15c.74 0 1.36.154 2.117.527v1.968c-.716-.68-1.337-.965-2.158-.965-1.614 0-2.883 1.299-2.883 2.946 0 1.736 1.23 2.957 2.963 2.957.78 0 1.39-.27 2.078-.939v1.97c-.783.358-1.417.5-2.158.5-2.62 0-4.656-1.956-4.656-4.476 0-2.494 2.09-4.488 4.697-4.488zm-8.135.054c.967 0 1.852.322 2.592.951l-.9 1.146c-.448-.488-.872-.694-1.388-.694-.741 0-1.281.41-1.281.95 0 .463.303.709 1.335 1.08 1.957.696 2.537 1.313 2.537 2.675 0 1.66-1.254 2.816-3.042 2.816-1.31 0-2.26-.501-3.054-1.632l1.112-1.041c.396.745 1.057 1.144 1.878 1.144.767 0 1.336-.515 1.336-1.21 0-.36-.173-.669-.516-.887-.173-.104-.516-.258-1.19-.489-1.616-.565-2.17-1.17-2.17-2.351 0-1.404 1.19-2.458 2.751-2.458zm19.661.15h1.88l2.352 5.76 2.383-5.76h1.865L42.453 24h-.927l-3.743-8.796zm-25.23.013h1.72v8.576h-1.72v-8.576zm34.488 0h4.88v1.453h-3.16v1.903h3.043v1.453H48.76v2.315h3.16v1.452h-4.88v-8.576zm9.682 4.963c1.296-.256 2.01-1.118 2.01-2.431 0-1.606-1.138-2.532-3.122-2.532H53.06v8.576h1.718v-3.445h.225l2.381 3.445H59.5l-2.777-3.613zm-1.442-1.015h-.503v-2.597h.53c1.072 0 1.654.436 1.654 1.271 0 .862-.582 1.326-1.681 1.326zM7.025 15.217H4.5v8.576h2.512c1.336 0 2.3-.307 3.147-.99 1.007-.81 1.602-2.03 1.602-3.292 0-2.531-1.945-4.294-4.736-4.294zm2.01 6.442c-.54.475-1.242.682-2.354.682H6.22V16.67h.462c1.112 0 1.786.194 2.354.694.595.515.953 1.313.953 2.135 0 .823-.358 1.645-.953 2.16z"
      fill="#000"
    />
  </Svg>
)
