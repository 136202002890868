"use client"

import React, { useRef, useState } from "react"
import { FlatList, FlatListProps, RefreshControl } from "react-native"
import { formatRelative } from "date-fns"
import { useIntl } from "react-intl"

import {
  Box,
  getColor,
  isWeb,
  Loading,
  useExperiences,
  useLocale,
} from "@bullseye/components"
import { Experience, User } from "@bullseye/types"

import { ActivityCardGroup } from "./ActivityCardGroup"
import { ActivityEmptyState } from "./ActivityEmptyState"

type Props = {
  filters: Record<string, any>
  resetFilters: VoidFunction
  user?: User
  onScroll?: FlatListProps<Experience>["onScroll"]
}

export const ActivitiesList = ({
  filters,
  resetFilters,
  user,
  onScroll,
}: Props) => {
  const displayedDates = useRef({})
  const locale = useLocale()
  const intl = useIntl()
  const extendedLocale = {
    ...locale,
    formatRelative: (token: string) => {
      // we ignore nextWeek because date-fns considers something to be
      // next week if it's between 2 and 7 days in the future.
      // https://github.com/date-fns/date-fns/blob/main/src/formatRelative/index.ts#L89
      if (token === "nextWeek") return "EEEE, MMMM d"
      if (token === "other") return "EEEE, MMMM d"

      const dateString = `dates.${token}`
      const relativeDate = intl.formatMessage({
        id: dateString,
        defaultMessage: "EEEE, MMMM d",
      })
      return `'${relativeDate}'`
    },
  }
  const {
    data,
    isLoading,
    hasNextPage,
    refetch,
    isFetchingNextPage,
    fetchNextPage,
  } = useExperiences(filters, {
    enabled: true,
    select: (data) => {
      const experiences = data?.pages.flatMap((page) => page.results)

      const dates: Record<string, number> = {}
      for (let i = 0; i < experiences.length; i++) {
        const experience = experiences[i]
        const date = formatRelative(
          new Date(experience.start_date),
          new Date(),
          {
            locale: extendedLocale,
            weekStartsOn: 0,
          },
        )
        if (dates[date] === undefined) {
          dates[date] = i
        }
        experience.formattedDate = date
      }

      displayedDates.current = dates
      return data
    },
  })
  const [isRefreshing, setIsRefreshing] = useState(false)

  async function refresh() {
    try {
      setIsRefreshing(true)
      await refetch()
    } catch {}
    setIsRefreshing(false)
  }

  if (isLoading) {
    return (
      <Box className="flex h-full w-full items-center justify-center">
        <Loading />
      </Box>
    )
  }

  if (!data?.pages?.[0].results?.length) {
    return (
      <Box className="flex h-full w-full items-center justify-center">
        <ActivityEmptyState handlePress={resetFilters} />
      </Box>
    )
  }

  const experiences = data.pages.flatMap((page) => page.results)

  return (
    <FlatList<Experience>
      data={experiences}
      renderItem={({ item: exp, index }) => (
        <ActivityCardGroup
          experience={exp}
          index={index}
          displayedDates={displayedDates.current}
          user={user}
          isLastElement={index === experiences.length - 1}
        />
      )}
      onEndReached={() => {
        if (hasNextPage && !isFetchingNextPage) {
          return fetchNextPage()
        }
      }}
      onScroll={onScroll}
      refreshing={isRefreshing}
      refreshControl={
        <RefreshControl
          refreshing={isRefreshing}
          onRefresh={refresh}
          tintColor={getColor("bg-primary-500")}
        />
      }
      ListFooterComponent={() => {
        if (hasNextPage && isFetchingNextPage) {
          return (
            <Box className="flex h-[100px] w-full items-center justify-center">
              <Loading />
            </Box>
          )
        }

        if (isWeb()) {
          return <Box className="hidden h-10 bg-gray-50 md:flex" />
        }

        return null
      }}
    />
  )
}
