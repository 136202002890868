import { useAnalyticsContext } from "../hooks"
import { useUserContext } from "../providers"

type TrackingObject = {
  event: string
  properties?: any
}

export const useTracking = () => {
  const { user } = useUserContext()
  const analytics = useAnalyticsContext()

  return (event: string | TrackingObject, properties?: any) => {
    if (typeof event === "object") {
      console.warn("useTracking: passing an object is deprecated")
      properties = event.properties
      event = event.event
    }

    const defaultProps = user
      ? {
          userId: user.id,
          email: user.email,
          slug: user.slug,
          phone: user.phone,
        }
      : {}

    return analytics.client.track(event, {
      ...defaultProps,
      ...properties,
    })
  }
}
