"use client"

import { FormattedMessage } from "react-intl"

import { ListItem, P, useExperienceContext } from "@bullseye/components"

import { ActivityModal, useActivityModalContext } from "../../../../components"

export function NotifyList({ dismiss }: { dismiss: () => void }) {
  const { openModal } = useActivityModalContext()
  const { experience, waitlist } = useExperienceContext()

  if (!experience.max_attendees) return null
  if (!waitlist?.length) return null

  return (
    <ListItem
      onPress={() => {
        dismiss()
        openModal(ActivityModal.NotifyList)
      }}
    >
      <P>
        <FormattedMessage id="activity.manageActivity.viewNotifyList" />
      </P>
    </ListItem>
  )
}
