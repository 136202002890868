import { useQueryClient } from "@tanstack/react-query"

import {
  MessageChannel,
  PaginatedResponse,
  UserMessageChannel,
} from "@bullseye/types"

import { useUserContext } from "../providers"
import { useGetRequest, usePostRequest } from "./useRequest"

type Data = UserMessageChannel & {
  message_channel: MessageChannel
  is_verified: boolean
  slug: string
}

type UserMessageChannelResponse = PaginatedResponse<Data>

export const useUserMessageChannel = (id: string | undefined | null) => {
  const { user, accessToken } = useUserContext()
  const { data, ...rest } = useGetRequest<UserMessageChannelResponse>(
    {
      method: "GET",
      path: `message-channels/${id}/users`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        user_id: user.id,
      },
    },
    {
      enabled: !!id && !!accessToken,
    },
  )

  return {
    userMessageChannel: data?.results?.[0],
    ...rest,
  }
}

export const useAcceptChat = (messageChannel: MessageChannel) => {
  const queryClient = useQueryClient()
  const { userMessageChannel, refetch, key } = useUserMessageChannel(
    messageChannel.id,
  )
  const { accessToken } = useUserContext()

  async function handleMutate() {
    await queryClient.cancelQueries(key)

    const results = [
      {
        ...userMessageChannel,
        is_accepted: true,
      },
    ]

    queryClient.setQueryData(key, { data: { results } })
  }

  const { postRequestAsync, isLoading, error } = usePostRequest<null, null>({
    path: `v1/chat/message-channels/${messageChannel.id}/accept`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onMutate: async () => handleMutate(),
  })

  const accept = async () => {
    await postRequestAsync(null, {
      onSuccess: () => {
        return handleMutate()
      },
      onError: async (err) => {
        await refetch()
        throw err
      },
    })
  }

  return {
    accept,
    isLoading,
    error,
  }
}
