import React from "react"
import clsx from "clsx"

import { Box } from "../Layout"

type Props = React.ComponentProps<typeof Box>

export const HR = ({ className, ...props }: Props) => {
  return (
    <Box
      className={clsx(
        "m-0 border-0 border-t border-solid border-gray-100",
        className,
      )}
      {...props}
    ></Box>
  )
}
