"use client"

import { useMemo } from "react"

import {
  COMMUNITY_GUIDELINES_URL,
  HELP_CENTER_URL,
  ME_EDIT_URI,
  NOTIFICATION_SETTINGS_URI,
  SETTINGS_URI,
  useUnreadCount,
} from "@bullseye/components"

export function useAuthedItems() {
  const unreadMessages = useUnreadCount()
  return useMemo(() => {
    const items = [
      [
        {
          href: ME_EDIT_URI,
          title: "Edit Profile",
          titleKey: "navigation.profile.edit",
        },
        {
          href: SETTINGS_URI,
          title: "Payment methods",
          titleKey: "navigation.paymentMethods",
        },
        {
          href: NOTIFICATION_SETTINGS_URI,
          title: "Notification settings",
          titleKey: "navigation.account.notification-settings",
        },
      ],
      [
        {
          href: COMMUNITY_GUIDELINES_URL,
          title: "Community Guidelines",
          titleKey: "navigation.guidelines",
        },
        {
          href: HELP_CENTER_URL,
          title: "Help center",
          titleKey: "navigation.help-center",
        },
      ],
    ]

    return items
  }, [unreadMessages])
}
