"use client"

import { ScrollBox, Sheet, usePaymentMethods } from "@bullseye/components"
import type { Modal } from "@bullseye/types"

import PaymentMethodForm from "../PaymentMethodForm"

export type AddPaymentMethodModalProps = {
  onSelect: () => void
}

export const AddPaymentMethod = ({
  isOpen,
  dismiss,
  onSelect,
}: Modal<AddPaymentMethodModalProps>) => {
  const { refetch } = usePaymentMethods({
    enabled: isOpen,
  })
  return (
    <Sheet
      icon="x"
      show={isOpen}
      size="lg"
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        if (!isOpen) return null
        return (
          <ScrollBox
            className="h-full md:pb-4"
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <PaymentMethodForm
              onSuccess={async () => {
                await refetch()
                dismiss()
                onSelect()
              }}
            />
          </ScrollBox>
        )
      }}
    />
  )
}
