"use client"

import { type User } from "@bullseye/types"

import { Avatar, type AvatarProps } from "./Avatar"

export type UserAvatarProps = {
  user: Pick<User, "first_name" | "formatted_name" | "avatar">
} & Omit<AvatarProps, "name">

export const UserAvatar = ({ user, ...props }: UserAvatarProps) => {
  const src = user.avatar?.url
  return (
    <Avatar
      name={user.formatted_name || user.first_name}
      imageUrl={src}
      {...props}
    />
  )
}
