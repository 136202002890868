"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  Experience,
  H4,
  Link,
  P,
  TextButton,
} from "@bullseye/components"

import {
  ActivityModal,
  MessageUserButton,
  useActivityModalContext,
  UserLockup,
} from "../../../../components"

export const ActivityHost = ({ experience }: { experience: Experience }) => {
  const { openModal } = useActivityModalContext()
  if (!experience.organizer) {
    let mailToURL = "mailto:hello@gethank.com"
    mailToURL += `?subject=${encodeURIComponent(
      `Question about ${experience.title}`,
    )}`
    return (
      <Box className="flex flex-col p-6 g-4">
        <H4>
          <FormattedMessage id="activity.host" />
        </H4>
        <Box className="flex-row items-center overflow-hidden rounded-lg bg-gray-10 p-4">
          <Box className="flex flex-1 items-start g-2">
            <P>
              <FormattedMessage id="activity.hostless" />
            </P>
            <TextButton
              as="button"
              handlePress={() => {
                openModal(ActivityModal.Hostless)
              }}
              underlined
            >
              <FormattedMessage id="general.learnMore" />
            </TextButton>
          </Box>
        </Box>
        <Button as="a" href={mailToURL.toString()} variant="secondary">
          <FormattedMessage id="activity.contactHank" />
        </Button>
      </Box>
    )
  }

  return (
    <Box className="flex flex-col p-6 g-4">
      <H4>
        <FormattedMessage id="activity.host" />
      </H4>
      <Box className="flex flex-row items-center">
        <Link href={`/p/${experience.organizer.slug}`}>
          <Box className="g-2">
            <UserLockup user={experience.organizer} />
          </Box>
        </Link>
      </Box>
      <MessageUserButton
        user={experience.organizer}
        label={<FormattedMessage id="activity.contactHost" />}
        variant="secondary"
      />
    </Box>
  )
}
