"use client"

import { PropsWithChildren, ReactNode } from "react"
import { ImageSourcePropType } from "react-native"
import clsx from "clsx"

import { Box, H3, Image, P } from "@bullseye/components"

type Props = PropsWithChildren<{
  title: ReactNode
  description?: ReactNode
  primaryAction?: ReactNode
  secondaryAction?: ReactNode
  imageComponent?: ReactNode
  imageSource?: ImageSourcePropType
  imageAlt?: string
}>

export const OnboardingStep = ({
  title,
  description,
  imageSource,
  imageAlt,
  imageComponent,
  primaryAction,
  secondaryAction,
  children,
}: Props) => {
  return (
    <>
      <Box
        className={clsx("flex w-full flex-1 items-center pt-10 g-10", {
          "justify-center pt-0": !children,
        })}
      >
        {imageSource && (
          <Image
            source={imageSource}
            className="h-[200px] w-[200px] rounded-full"
            alt={imageAlt}
          />
        )}
        {imageComponent && <>{imageComponent}</>}
        <Box className="flex items-center g-2">
          <H3 className="text-center">{title}</H3>
          {description && <P className="text-center">{description}</P>}
        </Box>
        {children}
        <Box className="hidden w-full items-center g-2 md:flex">
          <Box className="md:w-[360px]">{secondaryAction}</Box>
          <Box className="md:w-[360px]">{primaryAction}</Box>
        </Box>
      </Box>
      <Box className="flex-0 flex w-full justify-end pt-4 g-2 md:hidden">
        {secondaryAction}
        {primaryAction}
      </Box>
    </>
  )
}
