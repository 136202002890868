import React from "react"
import Svg, { Path, Rect } from "react-native-svg"

export const Visa = () => (
  <Svg viewBox="0 0 64 40" fill="none">
    <Rect width="64" height="40" rx="3" fill="#0E4595" />
    <Path
      d="M23.64 27.898l2.67-15.66h4.268l-2.67 15.66H23.64zm19.69-15.323c-.846-.317-2.172-.657-3.826-.657-4.218 0-7.19 2.123-7.215 5.168-.024 2.25 2.121 3.506 3.74 4.255 1.662.767 2.22 1.257 2.213 1.942-.01 1.05-1.327 1.53-2.554 1.53-1.709 0-2.616-.238-4.018-.822l-.55-.25-.6 3.506c.997.438 2.841.816 4.756.836 4.487 0 7.4-2.1 7.433-5.35.016-1.782-1.122-3.138-3.584-4.256-1.492-.724-2.406-1.208-2.396-1.941 0-.651.773-1.347 2.444-1.347a7.878 7.878 0 013.195.6l.383.18.578-3.394zm10.984-.338h-3.298c-1.022 0-1.787.28-2.236 1.3l-6.34 14.351h4.483s.733-1.93.899-2.353l5.467.006c.127.549.519 2.347.519 2.347h3.961l-3.455-15.651zM49.081 22.35c.353-.902 1.7-4.378 1.7-4.378-.025.042.35-.906.566-1.494l.289 1.35.988 4.522h-3.543zM20.017 12.237l-4.18 10.68-.445-2.17c-.778-2.502-3.202-5.212-5.912-6.57l3.822 13.697 4.516-.005 6.72-15.632h-4.521"
      fill="#fff"
    />
    <Path
      d="M11.938 12.237H5.054L5 12.562c5.355 1.297 8.898 4.43 10.37 8.193l-1.497-7.196c-.259-.992-1.008-1.288-1.935-1.323"
      fill="#F2AE14"
    />
  </Svg>
)
