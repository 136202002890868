import * as Device from "expo-device"
import { useQuery } from "@tanstack/react-query"

export const useDeviceType = () => {
  const res = useQuery(["deviceType"], () => Device.getDeviceTypeAsync(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  return {
    ...res,
    deviceType: res.data,
  }
}
