"use client"

import { FormattedMessage } from "react-intl"

import { Box, P, Sheet, TextButton } from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export const Hostless = ({ isOpen, dismiss }: Modal) => {
  return (
    <Sheet
      title={<FormattedMessage id="activity.hostless" />}
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="flex-col items-start g-4">
            <P>
              <FormattedMessage id="activity.hostless.modal.body.line1" />
            </P>
            <P>
              <FormattedMessage id="activity.hostless.modal.body.line2" />
            </P>
            <TextButton
              as="a"
              href="https://help.gethank.com/en/articles/8053635-how-to-find-other-hank-members-at-activities"
              underlined
            >
              <FormattedMessage id="activity.hostless.modal.body.instructions" />
            </TextButton>
          </Box>
        )
      }}
    />
  )
}
