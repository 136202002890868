"use client"

import { Box, HankLogo, HOME_URI } from "@bullseye/components"

type Props = {
  children: React.ReactNode
}

export const SecurityLayout = ({ children }: Props) => {
  return (
    <Box className="h-full w-full">
      <Box className="flex h-12 w-full items-center justify-center border-b border-solid border-gray-100">
        <HankLogo href={HOME_URI} />
      </Box>
      {children}
    </Box>
  )
}
