"use client"

import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { Box, Button, PublicUser } from "@bullseye/components"

import { UserAbout } from "./UserAbout"
import { UserActivities } from "./UserActivities"

export function UserDetails({ user }: { user: PublicUser }) {
  const [section, setSection] = useState<"about" | "activities">("about")
  return (
    <Box className="flex w-full items-center bg-gray-50 md:z-[-1]">
      <Box className="my-2 flex w-full flex-row items-center justify-center bg-white py-4 g-2 md:my-px md:justify-start md:px-4">
        <Button
          active={section === "about"}
          as="button"
          compact
          outlined
          rounded
          variant={"secondary"}
          handlePress={() => setSection("about")}
        >
          <FormattedMessage id="profile.about" />
        </Button>
        <Button
          active={section === "activities"}
          as="button"
          compact
          outlined
          rounded
          variant={"secondary"}
          handlePress={() => setSection("activities")}
        >
          <FormattedMessage id="profile.activities" />
        </Button>
      </Box>
      <Box className="flex w-full items-start bg-white">
        {section === "about" ? (
          <UserAbout user={user} />
        ) : (
          <UserActivities user={user} />
        )}
      </Box>
    </Box>
  )
}
