import { useUserContext } from "../providers"
import { usePostRequest } from "./useRequest"

export type VerificationResponse = {
  url: string
  client_secret: string
  ephemeral_key_secret: string
  session_id: string
}

export const useInitiateVerification = () => {
  const { accessToken } = useUserContext()
  const { data, isLoading, error, postRequestAsync, ...rest } = usePostRequest<
    {
      data: VerificationResponse[]
    },
    null
  >({
    method: "POST",
    path: "v1/verification/initiate",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    verification: data?.data?.[0],
    isLoading,
    error,
    requestVerificationAsync: () => postRequestAsync(null),
    ...rest,
  }
}
