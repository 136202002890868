import { Box } from "../Layout"
import Loading from "../Loading"

export function LoadingFullScreen() {
  return (
    <Box className="flex h-screen w-screen items-center justify-center">
      <Loading className="h-9 w-9" />
    </Box>
  )
}
