"use client"

import { FlatList, Pressable, useWindowDimensions } from "react-native"
import { useQueryClient } from "@tanstack/react-query"
import { styled } from "nativewind"
import { FormattedMessage } from "react-intl"

import {
  Box,
  ExperienceAttendee,
  IconButton,
  Link,
  Loading,
  makeMessengerUri,
  Sheet,
  useCreateMessageChannel,
  useExperienceAttendees,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import type { Modal } from "@bullseye/types"

import { UserLockup } from "../../UserLockup"
import { ActivityModal, useActivityModalContext } from "./index"

export type AttendeesModalProps = {
  experienceId: string
  organizerId: string
  queryKey: string
}

const StyledPressable = styled(Pressable, "cursor-default")

export const Attendees = ({
  isOpen,
  dismiss,
  experienceId,
  organizerId,
  queryKey,
}: Modal<AttendeesModalProps>) => {
  const { height } = useWindowDimensions()
  const { createMessageChannelAsync, status } = useCreateMessageChannel()
  const router = useRouter()
  const { openModal } = useActivityModalContext()
  const queryClient = useQueryClient()

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useExperienceAttendees(experienceId, {
      limit: "10",
      "user_id[not]": organizerId ?? "null",
    })

  return (
    <Sheet
      title={<FormattedMessage id="activity.attendees.modal.title" />}
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="overflow-auto" style={{ maxHeight: height * 0.8 }}>
            <FlatList<ExperienceAttendee>
              data={data?.pages?.flatMap((page) => page.results)}
              onEndReached={() => {
                if (hasNextPage && !isFetchingNextPage) {
                  return fetchNextPage()
                }
              }}
              renderItem={({ item: attendee }) => (
                <StyledPressable className="w-full flex-row justify-between py-2">
                  <Link href={`/p/${attendee.user.slug}`}>
                    <UserLockup key={attendee.user.id} user={attendee.user} />
                  </Link>
                  <Box className="flex flex-row g-2">
                    <IconButton
                      disabled={status === "loading"}
                      iconType="chat"
                      size="md"
                      handlePress={async () => {
                        const { data: mc } = await createMessageChannelAsync({
                          body: {
                            is_group_message: false,
                            users: [{ user_id: attendee.user.id }],
                          },
                        })
                        dismiss()
                        router.push(
                          makeMessengerUri(mc?.data.stream_channel_id),
                        )
                      }}
                    />
                    <IconButton
                      disabled={status === "loading"}
                      iconType="x"
                      size="md"
                      handlePress={() => {
                        dismiss()
                        openModal(ActivityModal.CancelAttendance, {
                          refetch: async () => {
                            await queryClient.invalidateQueries({
                              queryKey: [queryKey],
                            })
                          },
                          userId: attendee.user.id,
                        })
                      }}
                    />
                  </Box>
                </StyledPressable>
              )}
              ListFooterComponent={() => {
                if (hasNextPage && isFetchingNextPage) {
                  return (
                    <Box className="flex h-[100px] w-full items-center justify-center">
                      <Loading />
                    </Box>
                  )
                }
                return null
              }}
            />
          </Box>
        )
      }}
    />
  )
}
