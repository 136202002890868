"use client"

import { PropsWithChildren } from "react"

import Link, { LinkProps } from "../Link"

type OptionalLinkProps = PropsWithChildren<Partial<LinkProps>>

export const OptionalLink = ({
  href,
  children,
  ...rest
}: OptionalLinkProps) => {
  if (href) {
    return (
      <Link href={href} {...rest}>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}
