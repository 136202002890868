"use client"

import { useMemo } from "react"
import { AnalyticsBrowser } from "@segment/analytics-next"

import { LoadingFullScreen } from "../../components"
import { AnalyticsContext } from "./AnalyticsContextProvider"
import { Props } from "./index.d"

const AnalyticsProvider = ({ children, apiKey }: Props) => {
  const client = useMemo(() => {
    return AnalyticsBrowser.load({ writeKey: apiKey })
  }, [apiKey])

  async function identify(userId: string, traits?: any) {
    await client.identify(userId, traits)
    return
  }

  async function track(event: string, properties?: any) {
    await client.track(event, properties)
    return
  }

  async function page(name: string, properties?: any) {
    await client.page(name, properties)
    return
  }

  if (!client) {
    return <LoadingFullScreen />
  }

  return (
    <AnalyticsContext.Provider
      value={{ client: { track, identify, screen: page, page } }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
