"use client"

import { FormattedMessage } from "react-intl"

import {
  Experience,
  ListItem,
  makeMessengerUri,
  P,
  useJoinExperienceChat,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { useToastContext } from "../../../../providers"

export const JoinActivityChat = ({
  experience,
  dismiss,
}: {
  experience: Experience
  dismiss: () => void
}) => {
  const { joinChatAsync } = useJoinExperienceChat(experience.id)
  const router = useRouter()
  const { openErrorToast } = useToastContext()
  return (
    <ListItem
      onPress={async () => {
        try {
          const { data } = await joinChatAsync()
          if (data) {
            dismiss()
            router.push(makeMessengerUri(data.stream_channel_id))
          }
        } catch (error) {
          dismiss()
          openErrorToast({
            label: <FormattedMessage id="errors.unknown" />,
          })
        }
      }}
    >
      <P>
        <FormattedMessage id="activity.manageActivity.joinChat" />
      </P>
    </ListItem>
  )
}
