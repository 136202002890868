import {
  Experience,
  PaginatedResponse,
  PublicUser,
  User,
} from "@bullseye/types"

import { useUserContext } from "../providers"
import { usePaginatedRequest } from "./useRequest"

type AnyUser = PublicUser | User

type Params = Record<string, string>
export const useUserExperiences = (user: AnyUser, params?: Params) => {
  const { accessToken } = useUserContext()
  return usePaginatedRequest<PaginatedResponse<Experience>>({
    path: `users/${user.id}/experiences`,
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
