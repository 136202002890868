"use client"

import { useEffect } from "react"

import { useRouter } from "@bullseye/navigation"

type Props = {
  href: string
  push?: boolean
}
export const Redirect = ({ href, push = false }: Props) => {
  const router = useRouter()
  useEffect(() => {
    if (push) {
      router.push(href)
    } else {
      router.replace(href)
    }
  }, [href])

  return <></>
}
