"use client"

import clsx from "clsx"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  Box,
  Button,
  Experience,
  getCookieDomain,
  H4,
  isWeb,
  setCookie,
  SIGN_UP_URI,
  useExperienceUrl,
  User,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { ActivityRemainingSpots, ShareButton } from "../../../../components"

type Props = {
  experience: Experience
  className?: string
  user?: User
}

export const UnauthedCTA = ({ experience, className }: Props) => {
  const experienceUrl = useExperienceUrl(experience.slug)

  const intl = useIntl()
  const router = useRouter()
  const spots = experience.max_attendees
    ? experience.max_attendees - experience.attendees_count
    : null
  return (
    <Box
      className={twMerge(
        "flex flex-col rounded-none bg-white p-6 g-4 md:rounded-lg",
        className,
      )}
    >
      <Box className="flex flex-col g-1">
        <H4
          className={clsx({
            hidden: !experience.ticket_price,
          })}
        >
          <FormattedNumber
            style="currency"
            currency="USD"
            value={experience.ticket_price ?? 0}
          />
        </H4>
        {spots !== null && (
          <ActivityRemainingSpots
            as={experience.ticket_price ? "p" : "h4"}
            spots={spots}
          />
        )}
      </Box>
      <Box className="flex flex-col g-4">
        <Button
          as="button"
          handlePress={() => {
            const domain = getCookieDomain(process.env.NEXT_PUBLIC_VERCEL_ENV)
            setCookie({
              name: "attendActivity",
              value: experience.id,
              domain,
              expiresInSeconds: 300,
            })
            router.push(SIGN_UP_URI)
          }}
        >
          <FormattedMessage id="activity.attend.attendCta" />
        </Button>
        {isWeb() && (
          <ShareButton
            emailSubject={intl.formatMessage(
              {
                id: "activity.share.subject",
              },
              { title: experience.title },
            )}
            message={intl.formatMessage(
              {
                id: "activity.share.message",
              },
              { title: experience.title },
            )}
            url={experienceUrl}
          />
        )}
      </Box>
    </Box>
  )
}
