"use client"

import clsx from "clsx"
import { useIntl } from "react-intl"

import {
  Avatar,
  Box,
  Chip,
  H4,
  isWeb,
  useUserBlock,
  useUserContext,
} from "@bullseye/components"
import { type PublicUser, type User } from "@bullseye/types"

import { ActionButtons } from "./ActionButtons"
import { MoreButton } from "./MoreButton"
import { UserLabels } from "./UserLabels"

type UserProfileProps = {
  isSelf: boolean
  user: PublicUser | User
}

export const UserAvatarLockup = ({ isSelf, user }: UserProfileProps) => {
  const { user: self } = useUserContext()
  const isViewingSelf = user.id === self.id

  const { data: userBlock } = useUserBlock(isSelf ? undefined : user.id)

  const intl = useIntl()

  let userName = isSelf
    ? `${user.first_name} ${user.last_name}`
    : user.formatted_name

  if (user.show_age && user.age) {
    userName += `, ${user.age}`
  }

  if (user.is_suspended) {
    userName = "Hank Member"
  }
  return (
    <>
      <Box
        className={clsx(
          "flex w-full items-center bg-white px-2 g-2 md:px-4 md:pb-6 md:g-6",
          {
            "md:flex-row": !isSelf,
            "md:items-start": !isSelf,
          },
        )}
      >
        <Avatar
          name={userName ?? ""}
          className="h-32 w-32"
          imageUrl={user.avatar?.url}
          blurred={user.is_suspended}
        />
        <Box
          className={clsx("flex w-full items-center g-2 md:max-w-[400px]", {
            "md:items-start": !isSelf,
          })}
        >
          <H4>{userName}</H4>
          {userBlock?.is_blocked && (
            <Chip
              color="red"
              label={intl.formatMessage({ id: "profile.blocked" })}
            />
          )}
          {!isSelf && <UserLabels user={user} />}
          <Box className="z-10 hidden w-full flex-row items-center justify-start pt-4 g-3 md:flex">
            <ActionButtons isSelf={isSelf} user={user} />
            {!isViewingSelf && <MoreButton isSelf={isSelf} user={user} />}
          </Box>
        </Box>
      </Box>
      <Box className="w-full flex-row justify-center bg-white px-2 py-4 g-2 md:hidden">
        <ActionButtons isSelf={isSelf} user={user} />
        {isWeb() && !isViewingSelf && (
          <MoreButton isSelf={isSelf} user={user} />
        )}
      </Box>
    </>
  )
}
