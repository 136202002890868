import { Experience, MediaUpload, V1APIResponse } from "@bullseye/types"

import { useUserContext } from "../providers"
import { usePostRequest } from "./useRequest"

export function useMediaDuplicator() {
  const { accessToken } = useUserContext()
  const { postRequestAsync, postRequest, ...rest } = usePostRequest<
    V1APIResponse<MediaUpload>,
    {
      experience_id: string
    }
  >({
    path: "v1/medias/duplicate",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    duplicateMediaAsync: (experience: Experience) =>
      postRequestAsync({ body: { experience_id: experience.id } }),
    duplicateMedia: (experience: Experience) =>
      postRequest({ body: { experience_id: experience.id } }),
    ...rest,
  }
}
