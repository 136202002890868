import { useRef, useState } from "react"
import { TextInput as RNTextInput } from "react-native"
import DateTimePicker from "react-native-ui-datepicker"
import { DateTime } from "luxon"
import { FormattedMessage, useIntl } from "react-intl"

import { useTheme } from "../../providers/ThemeProvider"
import { Button } from "../Button"
import { Dropdown } from "../Dropdown"
import Icon from "../Icon"
import { Box } from "../Layout"
import { TextInput, TextInputProps } from "../TextInput"

type Props = TextInputProps & {
  value?: Date
  onSelect: (date: Date) => void
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
}

const DatePicker = ({
  id,
  onSelect,
  minDate,
  maxDate,
  ...inputProps
}: Props) => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    inputProps.value ? new Date(inputProps.value) : undefined,
  )
  const ref = useRef<RNTextInput>(null)
  const openDropdown = () => {
    ref.current?.blur()
    if (inputProps.disabled) return

    setIsOpen(true)
  }
  const theme = useTheme()
  return (
    <Box className="relative z-[1]">
      <Dropdown
        open={isOpen}
        setOpen={(open) => {
          setIsOpen(open)
        }}
        id={`${id}-datepicker`}
        title={inputProps.label || inputProps.placeholder}
        sheetSize="md"
        toggleComponent={
          <TextInput
            {...inputProps}
            value={
              inputProps.value
                ? intl.formatDate(inputProps.value, {
                    dateStyle: "long",
                  })
                : ""
            }
            ref={ref}
            onFocus={openDropdown}
            caretHidden
            showSoftInputOnFocus={false}
            editable={!inputProps.disabled}
          />
        }
      >
        <Box className="md:px-4">
          <DateTimePicker
            mode="single"
            initialView="day"
            date={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
            selectedItemColor={theme.theme.extend.colors.primary[500]}
            buttonPrevIcon={<Icon type="chevron-left" />}
            buttonNextIcon={<Icon type="chevron-right" />}
            onChange={(params) => {
              const newDate = new Date(params.date.toString())
              const formattedDate = DateTime.local(
                newDate.getFullYear(),
                newDate.getMonth() + 1,
                newDate.getDate(),
              )

              setSelectedDate(formattedDate.toJSDate())
            }}
          />
          <Button
            as="button"
            handlePress={() => {
              onSelect(selectedDate)
              setIsOpen(false)
            }}
            className="w-full"
            disabled={!selectedDate}
          >
            <FormattedMessage id="general.save" />
          </Button>
        </Box>
      </Dropdown>
    </Box>
  )
}

export default DatePicker
