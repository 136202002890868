import * as en from "./en.json"
import * as fr from "./fr.json"
import * as nl from "./nl.json"

const translations: Record<string, Record<string, string>> = {
  en,
  nl,
  fr,
}

export default translations
