import * as ImagePicker from "expo-image-picker"
import { clsx } from "clsx"
import { styled } from "nativewind"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Box,
  Icon,
  IconButton,
  Image,
  Loading,
  P,
  PressableBox,
  TextButton,
  useLoggingContext,
  useMediaUploader,
} from "@bullseye/components"
import { MediaUpload } from "@bullseye/types"

import { useToastContext } from "../../../providers"

type Props = {
  onChange: (file: MediaUpload) => void
  value: MediaUpload
  disabled?: boolean
}

const Container = styled(
  PressableBox,
  "flex items-center justify-center rounded-lg border border-solid border-gray-100 g-2 bg-white aria-disabled:bg-gray-10 aria-disabled:cursor-default",
)

export const CoverPhoto = ({ onChange, value, disabled }: Props) => {
  const intl = useIntl()
  const { uploadMediaAsync, isLoading } = useMediaUploader({
    mediaPurpose: "cover_photo",
  })
  const { openErrorToast } = useToastContext()
  const logging = useLoggingContext()

  async function handleImageSelection() {
    try {
      if (disabled) return

      const res = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 0.8,
      })

      if (res.canceled || !res.assets?.length) return
      const [image] = res.assets
      const file = await uploadMediaAsync(image)
      if (!file) {
        return
      }

      onChange(file)
    } catch (error) {
      logging.captureException(new Error(`Error selecting image: ${error}`))
      openErrorToast({
        label: intl.formatMessage({
          id: "validation.activityForm.coverPhotoUploadError",
        }),
      })
    }
  }

  if (isLoading) {
    return (
      <Container className="h-32">
        <Loading />
      </Container>
    )
  }

  if (value) {
    return (
      <Container
        onPress={handleImageSelection}
        className="aspect-[2/1] w-full"
        disabled={disabled}
      >
        <Image
          source={{ uri: value.image.url }}
          className="h-full w-full rounded-lg"
          resizeMode="cover"
        />
        <Box className="absolute right-0 top-0 p-4">
          <IconButton
            handlePress={handleImageSelection}
            iconType="edit"
            size="md"
            disabled={disabled}
          />
        </Box>
      </Container>
    )
  }

  return (
    <Container
      onPress={handleImageSelection}
      className="h-32"
      disabled={disabled}
    >
      <Icon
        type="calendar"
        size="lg"
        color={disabled ? "text-gray-300" : "text-primary-500"}
      />
      <TextButton
        as="button"
        handlePress={handleImageSelection}
        variant="primary"
        disabled={disabled}
      >
        <FormattedMessage id="activityForm.coverPhoto.cta" />
      </TextButton>
      <P
        className={clsx("max-w-[80%] text-center", {
          "text-gray-300": disabled,
        })}
      >
        <FormattedMessage id="activityForm.coverPhoto.requirements" />
      </P>
    </Container>
  )
}
