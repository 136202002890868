"use client"

import { RefreshControl } from "react-native"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  getColor,
  H2,
  H3,
  IconButton,
  ScrollBox,
  usePaymentMethods,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { ApplicationModal, useApplicationModalContext } from "../../components"
import { PageHeader } from "../../layouts"
import { PaymentMethodsList } from "./components"

export const AccountPaymentMethods = () => {
  const { openModal } = useApplicationModalContext()
  const { refetch, isRefetching } = usePaymentMethods()
  const router = useRouter()

  return (
    <>
      <Box className="flex w-full max-w-[600px] items-center bg-white px-4 pb-6 pt-0 md:px-24 md:py-6">
        <PageHeader className="px-0 md:hidden">
          <IconButton
            iconType="arrow-left"
            variant="filled"
            size="lg"
            handlePress={() => router.back()}
          />
        </PageHeader>
        <Box className="flex h-14 w-full justify-center">
          <H2>
            <FormattedMessage id="account.payments.title" />
          </H2>
        </Box>
      </Box>
      <ScrollBox
        className="h-full bg-gray-10 pt-2 md:w-[600px] md:bg-white md:px-12"
        contentContainerStyle={{
          width: "100%",
        }}
        refreshControl={
          <RefreshControl
            refreshing={isRefetching}
            onRefresh={refetch}
            tintColor={getColor("bg-primary-500")}
            style={{
              backgroundColor: getColor("bg-gray-50"),
            }}
          />
        }
      >
        <Box className="h-full g-2 md:g-0">
          <Box className="bg-white px-4 py-6 g-4 md:px-0 md:pt-0">
            <H3>
              <FormattedMessage id="navigation.paymentMethods" />
            </H3>
            <PaymentMethodsList />
            <Box className="pt-2">
              <Button
                as="button"
                handlePress={() => {
                  openModal(ApplicationModal.AddPaymentMethod, {
                    onSelect: () => refetch(),
                  })
                }}
                className="max-w-[225px]"
              >
                <FormattedMessage id="paymentMethod.addCard" />
              </Button>
            </Box>
          </Box>
        </Box>
      </ScrollBox>
    </>
  )
}
