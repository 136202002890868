export const getCookieDomain = (env?: string) => {
  switch (env) {
    case "production":
      return `.gethank.com`
    case "preview":
      return `.gethank.dev`
    case "development":
    default:
      return "localhost"
  }
}
