"use client"

import { createContext, useContext } from "react"

import { AuthContext } from "./types"

export const ErrMissingAuth0User = new Error("Auth0 User not found")
export const ErrMissingUser = new Error("User not found")

export const AuthUserContext = createContext<AuthContext | null>(null)

export const useAuthContext = () => {
  const context = useContext(AuthUserContext)
  if (!context) {
    throw new Error("useAuthContext must be used within AuthUserContext")
  }
  return context
}
