import { useEffect, useState } from "react"

import { useAnalyticsContext } from "../hooks"
import { useUserContext } from "../providers"

export const useIdentify = () => {
  const { user } = useUserContext()
  const [isIdentified, setIsIdentified] = useState(false)
  const analytics = useAnalyticsContext()

  useEffect(() => {
    if (isIdentified) return
    if (!user) {
      console.warn("useIdentify called without a user")
      return
    }
    setIsIdentified(true)

    void analytics.client.identify(user.id, {
      Admin_bool: user?.admin,
      ...user,
    })
  }, [user, isIdentified, analytics])
}
