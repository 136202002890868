"use client"

import { ProfilePhotoUploader } from "../../components"
import { OnboardingJourney } from "../../layouts"

type Props = {
  previousPage?: string
  advanceToNextStep: () => Promise<void>
}

export const OnboardingProfilePhoto = ({ advanceToNextStep }: Props) => {
  return (
    <OnboardingJourney>
      <ProfilePhotoUploader onComplete={advanceToNextStep} />
    </OnboardingJourney>
  )
}
