"use client"

import React, { PropsWithChildren, useContext } from "react"

type AppContextType = {
  facebookAppId?: string
  mapsBaseUrl?: string
  placesBaseUrl?: string
  googlePlacesApiKey?: string
  hankAppUrl?: string
  hankMarketingUrl?: string
  expoProjectId?: string
  appleAppId?: string
  stripePublishableKey?: string
  appEnv?: string
}

export const useAppConfig = () => {
  const ctx = useContext(AppContext)
  if (!ctx)
    throw new Error("useAppContext must be used within AppContextProvider")
  return ctx
}

export const AppContext = React.createContext<AppContextType | null>(null)

type AppProviderProps = PropsWithChildren<{
  config: AppContextType
}>

export function AppConfigProvider({ children, config }: AppProviderProps) {
  return <AppContext.Provider value={config}>{children}</AppContext.Provider>
}

export default AppConfigProvider
