"use client"

import { Box, H3, Loading, Sheet } from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export type LoadingStateModalProps = {
  loadingReason: string
}

export const LoadingStateModal = ({
  isOpen,
  loadingReason,
}: Modal<LoadingStateModalProps>) => {
  return (
    <Sheet
      show={isOpen}
      dismiss={() => null}
      renderContent={() => {
        return (
          <Box className="flex items-center pb-10 g-4">
            <Box className="h-14 w-full" />
            <Loading />
            <H3 className="px-4 text-center">{loadingReason}</H3>
          </Box>
        )
      }}
    />
  )
}
