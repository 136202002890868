"use client"

import { createContext, PropsWithChildren, useContext } from "react"

import { User } from "@bullseye/types"

import { LoadingFullScreen } from "../../components/LoadingFullScreen"
import { useAccessToken, useMe } from "./hooks"
import type { AuthContext, UserContext } from "./types"

const UserContext = createContext<UserContext | null>(null)

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider")
  }
  return context
}

type Props = PropsWithChildren<{
  onError: (error: Error) => void
  authCtx: Omit<AuthContext, "getCredentials">
}>

export const UserProvider = ({ children, onError, authCtx }: Props) => {
  const {
    accessToken,
    isLoading: loadingToken,
    error: tokenError,
  } = useAccessToken()
  const { user, isLoading, error, refetch, isRefetching, key } =
    useMe(accessToken)

  async function refetchUser() {
    const { data, error } = await refetch<User[]>()
    if (error) {
      throw error
    }
    return data?.data?.data?.[0]
  }

  if (isLoading || loadingToken) {
    return <LoadingFullScreen />
  }

  if (error) {
    onError(error as Error)
    return null
  }

  if (tokenError) {
    onError(tokenError as Error)
    return null
  }

  if (!user) {
    onError(new Error("Request to /me returned no user and no error"))
    return null
  }

  return (
    <UserContext.Provider
      value={{
        ...authCtx,
        user,
        refetch: refetchUser,
        isRefetching,
        accessToken,
        queryKey: key,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
