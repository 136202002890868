import { type Location } from "./Location"
import { type Model } from "./Model"

export interface UserAvatarType {
  url?: string
  thumbnail_180x180?: {
    url?: string
  }
  large?: {
    url?: string
  }
}

export enum UserState {
  Active = "active",
  Created = "created",
  Deleted = "deleted",
  Suspended = "suspended",
}

export enum REASONS_FOR_JOINING {
  close_friends = "close_friends",
  new_people = "new_people",
  relationship = "relationship",
  things_to_do = "things_to_do",
}

export interface UserNotificationSettings {
  notification_distance: number
  receive_out_of_state_notifications: boolean
  receive_order_sms_updates: boolean

  // Email settings
  receive_roundup_notifications: boolean
  receive_message_notifications: boolean
  receive_marketing_emails: boolean
  receive_new_post_notifications: boolean
  receive_new_comment_notifications: boolean
  receive_new_event_notifications: boolean
}

export enum CAREER_STATUS {
  part_time = "part_time",
  full_time = "full_time",
  retired = "retired",
  other = "other",
}

export enum RELATIONSHIP_STATUS {
  single = "single",
  widowed = "widowed",
  divorced = "divorced",
  in_relationship = "in_relationship",
  married = "married",
  other = "other",
}
export enum FAMILY {
  pets = "pets",
  children = "children",
  grandchildren = "grandchildren",
}

export interface MyLife {
  career_status?: CAREER_STATUS
  job_title?: string
  workplace?: string
  relationship_status?: RELATIONSHIP_STATUS
  family?: FAMILY[]
  school_name?: string
  graduating_year?: number
  show_age?: boolean
}

export interface User
  extends Model,
    Location,
    UserNotificationSettings,
    MyLife {
  slug: string
  first_name: string
  last_name: string
  formatted_name?: string
  email: string
  gender: "concealed" | "other" | "female" | "male"
  show_gender: boolean
  formatted_location?: string
  google_place_id?: string | null
  zendesk_id: string
  auth0_id: string
  admin: boolean
  stripe_id: string
  phone?: string
  formatted_phone?: string
  primary_address?: any
  preferred_name?: string
  notes?: string
  internal_notes?: string
  has_payment_method: boolean
  pricing_sms: boolean
  has_easy_access_boolean: boolean
  avatar?: UserAvatarType
  avatar_url?: string
  bio?: string
  dob?: string | Date
  has_easy_access?: boolean
  reasons_for_joining?: REASONS_FOR_JOINING[]
  is_ambassador?: boolean
  is_suspended?: boolean
  stream_token: string
  stripe_url?: string
  /** @deprecated */
  name: string
  tier: "free" | "premium"
  age?: number
  is_verified: boolean
  stream_id: string
  user_state: UserState
}

export type PublicUser = Pick<
  User,
  | "id"
  | "slug"
  | "first_name"
  | "last_name"
  | "formatted_name"
  | "avatar"
  | "created_at"
  | "bio"
  | "gender"
  | "show_gender"
  | "city"
  | "state"
  | "name"
  | "avatar_url"
  | "is_ambassador"
  | "updated_at"
  | "stream_token"
  | "is_suspended"
  | "age"
  | "reasons_for_joining"
  | "is_verified"
  | "stream_id"
> &
  MyLife
