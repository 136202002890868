"use client"

import { useState } from "react"
import { Image, ImageSourcePropType } from "react-native"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H4,
  Icon,
  isWeb,
  P,
  Toast,
  useInitiateVerification,
  useVerificationStatus,
} from "@bullseye/components"

import { VerificationOptions } from "../../pages"

export type VerificationProps = {
  openVerification?: (options: VerificationOptions) => Promise<void>
  embedded?: boolean
}

export const UserVerification = ({
  openVerification,
  embedded = false,
}: VerificationProps) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const { requestVerificationAsync } = useInitiateVerification()

  const { verificationStatus, refetch: refetchVerification } =
    useVerificationStatus()

  const handleVerification = async () => {
    if (!openVerification) return

    const res = await requestVerificationAsync()
    const verification = res?.data?.data[0]
    if (!verification) return

    await openVerification({
      sessionId: verification.session_id,
      clientSecret: verification.client_secret,
      ephemeralKeySecret: verification.ephemeral_key_secret,
      brandLogo: isWeb()
        ? undefined
        : Image.resolveAssetSource(
            require("../../assets/hank-icon.png") as ImageSourcePropType,
          ),
    })

    const { data } = await refetchVerification()
    const status = data?.data?.data?.[0]?.status
    if (["invalid", "requires_input"].includes(status)) {
      setShowError(true)
    } else {
      setShowSuccess(true)
    }
  }

  return (
    <>
      {verificationStatus?.status !== "verified" && (
        <Box
          className={clsx("w-full bg-gray-10", {
            "p-4 pb-6 md:bg-white": !embedded,
            "border-1 border-solid border-gray-110 md:rounded-lg md:border":
              embedded,
          })}
        >
          <Box
            className={clsx(
              "flex w-full overflow-hidden rounded-lg bg-white p-4  g-3 md:flex-row md:items-center md:justify-between md:border md:border-solid md:border-gray-50",
              {
                "rounded-none md:rounded-lg": embedded,
                "shadow-lg": !embedded,
              },
            )}
          >
            <Box className="flex flex-row g-3">
              <Box className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-blue-600">
                <Icon type="shield" color="text-white" />
              </Box>
              <Box className="flex-shrink g-1">
                <H4>
                  {verificationStatus?.status === "processing" ? (
                    <FormattedMessage id="profile.verification.processing" />
                  ) : (
                    <FormattedMessage id="profile.verification" />
                  )}
                </H4>
                <P className="text-gray-600">
                  {verificationStatus?.status === "processing" ? (
                    <FormattedMessage id="profile.verification.processing.description" />
                  ) : (
                    <FormattedMessage id="profile.verification.description" />
                  )}
                </P>
              </Box>
            </Box>
            {verificationStatus?.status !== "processing" && (
              <Box>
                <Button as="button" handlePress={handleVerification} compact>
                  <FormattedMessage id="profile.verification.cta" />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Toast
        id="verification-success-toast"
        label="Verification request successful"
        show={showSuccess}
        onDismiss={() => setShowSuccess(false)}
      />
      <Toast
        id="verification-error-toast"
        label="Verification unsuccessful. Please try again."
        show={showError}
        onDismiss={() => setShowError(false)}
        iconType="x"
        iconBgColor="bg-red-500"
      />
    </>
  )
}
