import { useState } from "react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import { useIsSm } from "../../hooks"
import { dismissKeyboardAsync } from "../../utils"
import { Button } from "../Button"
import { Icon, IconProps } from "../Icon"
import { Box } from "../Layout"
import { PressableBox } from "../PressableBox"
import { Sheet, SheetProps } from "../Sheet"
import { TextInput, TextInputProps } from "../TextInput"
import { H3, P } from "../Typography"

type Props = Partial<SheetProps> &
  TextInputProps & {
    iconType: IconProps["type"]
    submitText?: string
    clearText?: string
    secondaryAction?: (dismiss: VoidFunction) => void
    onSubmit?: (value: string) => Promise<void>
    onDismiss?: () => void
    disabled?: boolean
    disabledOnError?: boolean
  }

const SheetForm = ({
  show,
  dismiss,
  label,
  helperText,
  submitText,
  clearText,
  secondaryAction,
  value,
  onChangeText,
  onSubmit,
  onDismiss,
  disabled,
  disabledOnError = false,
  ...textInputProps
}: Omit<Props, "iconType" | "multiline" | "defaultHeight">) => {
  const isSm = useIsSm()
  const displaySize = isSm ? "lg" : "md"
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit() {
    setIsLoading(true)
    await dismissKeyboardAsync()
    if (onSubmit) {
      await onSubmit(value)
    }
    dismiss()
    setIsLoading(false)
  }

  return (
    <Sheet
      show={show}
      renderContent={() => {
        return (
          <Box className="flex g-4">
            <H3>{label}</H3>
            <P
              className={clsx({
                hidden: !helperText,
              })}
            >
              {helperText}
            </P>
            <TextInput
              {...textInputProps}
              value={value ? value.toString() : undefined}
              label={label}
              onChangeText={onChangeText}
              editable={!disabled}
            />
            <Box className="flex flex-row g-2">
              <Button
                as="button"
                handlePress={() => {
                  if (secondaryAction) {
                    secondaryAction(dismiss)
                    return
                  }
                  onChangeText("")
                  onDismiss?.()
                  dismiss()
                }}
                variant="tertiary"
                className="flex-1"
                disabled={!value || isLoading}
              >
                {clearText ? (
                  clearText
                ) : (
                  <FormattedMessage id="general.clear" />
                )}
              </Button>
              <Button
                as="button"
                handlePress={handleSubmit}
                className="flex-1"
                loading={isLoading}
                disabled={disabledOnError && !!textInputProps.errorMessage}
              >
                {submitText ? (
                  submitText
                ) : (
                  <FormattedMessage id="general.submit" />
                )}
              </Button>
            </Box>
          </Box>
        )
      }}
      dismiss={() => {
        onDismiss?.()
        dismiss()
      }}
      icon="x"
      size={displaySize}
    />
  )
}

export const InputSheet = ({
  iconType,
  errorMessage,
  enabled = true,
  helperText,
  ...textInputProps
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <PressableBox
        onPress={async () => {
          if (!enabled) return
          await dismissKeyboardAsync()

          setIsOpen(!isOpen)
        }}
        className={clsx(
          "min-h-14 flex flex h-14 max-h-14 flex-row items-center justify-between rounded-lg border border-solid border-gray-100 px-2 g-4 aria-disabled:cursor-default aria-disabled:bg-gray-10",
          {
            "border-red-500": errorMessage,
          },
        )}
        disabled={textInputProps.disabled}
      >
        <Box className="flex flex-1 flex-row items-center g-2">
          <Icon
            type={iconType}
            size="md"
            color={textInputProps.disabled ? "text-gray-300" : "text-black"}
          />
          <P
            className={clsx({
              "text-gray-300": textInputProps.disabled,
            })}
          >
            {textInputProps.label}
          </P>
        </Box>
        <Box className="flex flex-1 flex-row items-center justify-end g-2">
          <P
            className={clsx("truncate", {
              "text-gray-300": !textInputProps.value || textInputProps.disabled,
            })}
            numberOfLines={1}
            bold={!!textInputProps.value}
          >
            {textInputProps.value || textInputProps.placeholder}
          </P>
          {enabled && (
            <Box className="min-w-[20px]">
              <Icon
                type="edit"
                size="md"
                color={textInputProps.disabled ? "text-gray-300" : "text-black"}
              />
            </Box>
          )}
        </Box>
      </PressableBox>
      {(errorMessage || helperText) && (
        <P
          className={clsx("text-sm text-gray-600", {
            "text-red-600": errorMessage,
          })}
        >
          {errorMessage || helperText}
        </P>
      )}
      <SheetForm
        show={isOpen}
        dismiss={() => {
          setIsOpen(false)
        }}
        enabled={enabled}
        errorMessage={errorMessage}
        helperText={helperText}
        {...textInputProps}
      />
    </>
  )
}
