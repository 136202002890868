"use client"

import { useMemo } from "react"
import { Pressable } from "react-native"
import { Picker as RNP } from "@react-native-picker/picker"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { PickerProps } from "."
import Icon from "../Icon"
import { Box } from "../Layout"

const StyledPicker = styled(RNP<any>)

const PickerItem = styled(RNP.Item)

const StyledPressable = styled(Pressable)

function Picker<T>({
  items,
  selectedValue,
  onChange,
  className,
  placeholder,
  disabled = false,
  ...props
}: PickerProps<T>) {
  function handleChange(item: T, _index: number) {
    const value = item === "placeholder" ? undefined : item
    onChange(value)
  }

  const itemsWithPlaceholder = useMemo(() => {
    if (placeholder) {
      return [{ label: placeholder, value: "placeholder" }, ...items]
    }

    return items
  }, [items])

  return (
    <Box className={twMerge("flex flex-row items-center", className)}>
      <StyledPicker
        {...props}
        className={twMerge(
          "font-maison-book text-base text-black",
          "h-14 w-full appearance-none rounded-lg border border-solid border-gray-100 bg-white px-2.5 pr-8 focus:border-primary-900 focus:outline-none active:border-primary-900",
          clsx({
            "text-gray-500 active:border-gray-100": disabled,
          }),
        )}
        enabled={!disabled}
        selectedValue={
          placeholder ? selectedValue || "placeholder" : selectedValue
        }
        onValueChange={handleChange}
      >
        {itemsWithPlaceholder.map((item, index) => (
          <PickerItem
            key={index}
            label={item.label}
            value={item.value}
            enabled={item.value !== "placeholder"}
          />
        ))}
      </StyledPicker>
      <StyledPressable className="pointer-events-none ml-[-25px]">
        <Icon
          type="chevron-down"
          color={disabled ? "text-gray-500" : "text-gray-950"}
        />
      </StyledPressable>
    </Box>
  )
}

export default Picker
