"use client"

import { isWeb } from "@bullseye/components"
import { IPLocation } from "@bullseye/types"

import { RegionalBanners } from "./RegionalBanners"

type Props = {
  userAccessToken?: string
  userLocation?: Partial<IPLocation>
}

export const Banners = ({ userLocation, userAccessToken }: Props) => {
  if (isWeb()) {
    return (
      <>
        <RegionalBanners
          userLocation={userLocation}
          userAccessToken={userAccessToken}
        />
      </>
    )
  }

  return (
    <RegionalBanners
      userLocation={userLocation}
      userAccessToken={userAccessToken}
    />
  )
}

export { ImpersonateBanner } from "./Impersonation"
