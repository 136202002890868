import Link from "next/link"
import { twMerge } from "tailwind-merge"

import { LinkProps } from "."

const StyledLink = ({ className, ...props }: LinkProps) => {
  return <Link className={twMerge("no-underline", className)} {...props} />
}

export default StyledLink
