import type { Config } from "tailwindcss"
import plugin from "tailwindcss/plugin"

export const brandColors = {
  indigo: {
    1: "#031368",
  },
  yellow: {
    1: "#F6C042",
    2: "#FEF6C8",
  },
  orange: {
    1: "#EC5344",
    2: "#F6C3BD",
  },
}

type ColorsType =
  | "primary"
  | "gray"
  | "blue"
  | "purple"
  | "crimson"
  | "red"
  | "orange"
  | "green"

const baseColors: { [i in ColorsType]: { [k: number]: string } } = {
  primary: {
    900: "#5B26D0",
    700: "#783FDC",
    500: "#8B4FE3",
    400: "#AE84EB",
    200: "#C5A7F1",
    100: "#DCCAF7",
    50: "#EAE3F6",
  },
  gray: {
    950: "#1F1F1F",
    800: "#404041",
    600: "#5E5E5E",
    400: "#8A8097",
    300: "#A49DAF",
    200: "#C0BAC7",
    110: "#dbd8df",
    100: "#DBDBDB",
    50: "#F0F0F1",
    10: "#F3F3F5",
  },
  blue: {
    800: "#07266B",
    600: "#0D4CD6",
    400: "#4D82F4",
    200: "#95B4F9",
    50: "#DCE6FD",
  },
  purple: {
    800: "#3D226D",
    600: "#6639B5",
    400: "#9471D2",
    200: "#BFAAE4",
    50: "#EAE3F6",
  },
  crimson: {
    800: "#691D2D",
    600: "#AF304B",
    400: "#D46179",
    200: "#E5A0AF",
    50: "#F6DFE4",
  },
  red: {
    800: "#6E1607",
    600: "#B8240C",
    400: "#F14427",
    200: "#F78F7E",
    50: "#FCDAD4",
  },
  orange: {
    800: "#563100",
    500: "#AB6100",
    400: "#E38100",
    200: "#FFB656",
    50: "#FFE7C7",
  },
  green: {
    800: "#1A4222",
    600: "#2B6E39",
    400: "#43AB58",
    200: "#8AD198",
    50: "#D8F0DD",
  },
}

export const colors = { ...baseColors, brand: brandColors, white: "#fff" }

export const theme = {
  borderRadius: {
    none: "0px",
    sm: "2px",
    DEFAULT: "4px",
    md: "6px",
    lg: "8px",
    xl: "12px",
    "2xl": "16px",
    "3xl": "24px",
    full: "9999px",
  },
  screens: {
    sm: "640px",
    // => @media (min-width: 640px) { ... }

    md: "768px",
    // => @media (min-width: 768px) { ... }

    lg: "1024px",
    // => @media (min-width: 1024px) { ... }

    xl: "1200px",
    // => @media (min-width: 1200px) { ... }

    "2xl": "1536px",
    // => @media (min-width: 1536px) { ... }
  },
  fontSize: {
    xs: "12px",
    sm: "14px",
    base: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "30px",
    "4xl": "32px",
    "5xl": "48px",
    "6xl": "60px",
    "7xl": "72px",
  },
  spacing: {
    px: "1px",
    0: "0",
    0.5: "2px",
    1: "4px",
    1.5: "6px",
    2: "8px",
    2.5: "10px",
    3: "12px",
    3.5: "14px",
    4: "16px",
    5: "20px",
    6: "24px",
    7: "28px",
    8: "32px",
    9: "36px",
    10: "40px",
    11: "44px",
    12: "48px",
    14: "56px",
    16: "64px",
    20: "80px",
    24: "96px",
    28: "112px",
    32: "128px",
    36: "144px",
    40: "160px",
    44: "176px",
    48: "192px",
    52: "208px",
    56: "224px",
    60: "240px",
    64: "256px",
    72: "288px",
    80: "320px",
    96: "384px",
  },
  g: ({ theme }: { theme: (prop: string) => Record<string, string> }) =>
    theme("spacing"),
  extend: {
    lineHeight: {
      3: "12px",
      4: "16px",
      5: "20px",
      6: "24px",
      7: "28px",
      8: "32px",
      9: "36px",
      10: "40px",
    },
    fontSize: {
      "2.5xl": "26px",
      "1.5xl": "22px",
    },
    fontFamily: {
      sans: ["MaisonNeue-Book", "MaisonNeue", "sans-serif"],
      "maison-boldBody": ["MaisonNeue-Bold", "MaisonNeue", "sans-serif"],
      "maison-book": ["MaisonNeue-Book", "MaisonNeue", "sans-serif"],
      "maison-demi": ["MaisonNeue-Demi", "MaisonNeue", "sans-serif"],
      "maison-medium": ["MaisonNeue-Medium", "MaisonNeue", "sans-serif"],
    },
    colors,
  },
}

export const config = {
  content: [
    "./app/**/*.{ts,tsx}",
    "./src/**/*.{ts,tsx}",
    "../../packages/components/src/**/*.{ts,tsx}",
    "../../components/src/**/*.{ts,tsx}",
    "../../packages/ui/src/**/*.{ts,tsx}",
    "../../ui/src/**/*.{ts,tsx}",
  ],
  theme,
  plugins: [
    require("@thoughtbot/tailwindcss-aria-attributes"),
    require("tailwindcss-safe-area"),
    plugin(function ({ matchUtilities, theme }) {
      matchUtilities(
        {
          g: (value) => ({
            gap: value,
          }),
        },
        { values: theme("g") },
      )
    }),
  ],
} satisfies Config

export default config
