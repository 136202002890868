"use client"

import { createContext, ReactNode, useContext, useState } from "react"
import { useWindowDimensions } from "react-native"

import { isWeb, Toast, ToastProps } from "@bullseye/components"

type ToastProviderProps = {
  children: ReactNode
}

type PartialToast = Omit<ToastProps, "iconType" | "iconBgColor">

const ToastContext = createContext<{
  openToast: (props: ToastProps) => void
  openSuccessToast: (props: PartialToast) => void
  openErrorToast: (props: PartialToast) => void
} | null>(null)

export const useToastContext = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error("useToastContext must be used within a ToastProvider")
  }
  return context
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const { height: windowHeight } = useWindowDimensions()
  const [toasts, setToasts] = useState<ToastProps[]>([])
  const [toastPositions, setToastPositions] = useState<
    { id: string; height: number; positionY: number }[]
  >([])

  function openToast(props: ToastProps) {
    if (!props.id) {
      // generate random id if one was not provided
      props.id = Math.random().toString(36).substring(7)
    }
    setToasts((priorToasts) => {
      return [...priorToasts, props]
    })
  }

  function openErrorToast(props: PartialToast) {
    openToast({
      ...props,
      iconType: "x",
      iconBgColor: "bg-red-500",
    })
  }

  function openSuccessToast(props: PartialToast) {
    openToast({
      ...props,
      iconType: "check",
      iconBgColor: "bg-green-500",
    })
  }

  function closeToast(id: string) {
    setToasts((priorToasts) => {
      return priorToasts.filter((toast) => toast.id !== id)
    })

    setToastPositions((priorPositions) => {
      return priorPositions.filter((toast) => toast.id !== id)
    })
  }

  return (
    <ToastContext.Provider
      value={{
        openToast,
        openErrorToast,
        openSuccessToast,
      }}
    >
      {children}
      {toasts.map((toast, index) => {
        const previousToast = index > 0 ? toastPositions[index - 1] : undefined
        const firstElementBottom = isWeb() ? 16 : 0
        const marginBottom = !previousToast
          ? firstElementBottom
          : windowHeight - previousToast.positionY + 16
        return (
          <Toast
            {...toast}
            key={toast.id}
            show
            onLayout={(event) => {
              const positionY = event.nativeEvent.layout.y
              const height = event.nativeEvent.layout.height

              setToastPositions((priorPositions) => {
                return [
                  ...priorPositions,
                  {
                    id: toast.id,
                    height,
                    positionY,
                  },
                ]
              })
            }}
            onDismiss={() => {
              if (toast?.onDismiss) {
                toast.onDismiss()
              }
              closeToast(toast.id)
            }}
            toastContainerStyle={{
              marginBottom,
            }}
          />
        )
      })}
    </ToastContext.Provider>
  )
}
