"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Select,
  Toggle,
  useUpdateUser,
  useUserContext,
} from "@bullseye/components"

import { SettingElement } from "../SettingElement"

export const GeneralSettings = () => {
  const { user } = useUserContext()
  const { update } = useUpdateUser()
  return (
    <Box className="w-full">
      <SettingElement
        settingLabel={{
          label: (
            <FormattedMessage id="settings.notifications.distance.label" />
          ),
        }}
        className="z-10 border-b border-zinc-300"
      >
        <Select
          id="distance-setting"
          hideDefaultOption
          value={user.notification_distance + ""}
          onChange={async (dist) => {
            await update({ notification_distance: +dist })
          }}
          position="bottom-left"
          options={[
            { label: "2 miles", value: "2" },
            { label: "5 miles", value: "5" },
            { label: "10 miles", value: "10" },
            { label: "25 miles", value: "25" },
            { label: "50 miles", value: "50" },
          ]}
        />
      </SettingElement>
      <SettingElement
        settingLabel={{
          label: (
            <FormattedMessage id="settings.notifications.outOfState.label" />
          ),
          description: (
            <FormattedMessage id="settings.notifications.outOfState.description" />
          ),
        }}
        className="pb-0 md:border-b md:border-zinc-300 md:pb-4"
      >
        <Toggle
          value={user.receive_out_of_state_notifications}
          onChange={async (v) => {
            await update({ receive_out_of_state_notifications: v })
          }}
        />
      </SettingElement>
    </Box>
  )
}
