import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

import {
  Experience,
  MediaUpload,
  MessageChannel,
  V1APIResponse,
} from "@bullseye/types"

import { useUserContext } from "../providers/UserAuthProvider/UserProvider"
import { useLoggingContext } from "./useLoggingContext"
import {
  useDeleteRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from "./useRequest"

export const useExperience = (
  id: string | undefined | null,
  accessToken?: string,
) => {
  const { data, isLoading, error, refetch, isRefetching, ...rest } =
    useGetRequest<V1APIResponse<Experience>>(
      {
        method: "GET",
        path: `v1/experiences/${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      {
        enabled: !!id,
      },
    )

  return {
    ...rest,
    experience: data?.data?.[0],
    isLoading,
    error,
    refetch,
    isRefetching,
  }
}

export const useJoinExperienceChat = (id: string) => {
  const { accessToken } = useUserContext()

  const { putRequestAsync } = usePutRequest<MessageChannel>({
    path: `experiences/${id}/message-channels/join`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return { joinChatAsync: () => putRequestAsync(null) }
}

export function useCancelExperience(experienceId: string) {
  const { accessToken } = useUserContext()
  const { deleteRequestAsync } = useDeleteRequest({
    path: `v1/experiences/${experienceId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return { cancelExperience: () => deleteRequestAsync(null) }
}

export type CreateExperienceRequest = Partial<
  Pick<
    Experience,
    | "title"
    | "description"
    | "venue_type"
    | "google_place_id"
    | "url"
    | "start_date"
    | "timezone"
    | "ticket_url"
    | "max_attendees"
    | "no_show_fee"
    | "is_hostless"
  >
> & {
  cover_photo: MediaUpload
}

export const useCreateExperience = () => {
  const { accessToken } = useUserContext()
  const queryClient = useQueryClient()
  const { postRequestAsync, data, ...rest } = usePostRequest<
    V1APIResponse<Experience>,
    CreateExperienceRequest
  >({
    path: "/v1/experiences",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    createExperienceAsync: (req: { body: CreateExperienceRequest }) => {
      const { body } = req
      if (req.body.cover_photo?.file_name) {
        body.cover_photo = req.body.cover_photo
          .file_name as unknown as MediaUpload
      } else {
        delete req.body.cover_photo
      }
      return postRequestAsync(
        { body },
        {
          onSuccess: () => {
            return queryClient.invalidateQueries({
              queryKey: ["/experiences"],
            })
          },
        },
      )
    },
    data,
    ...rest,
  }
}
type ExperienceQueryCache = { data: V1APIResponse<Experience> }

export const useUpdateExperience = (experience?: Experience) => {
  const queryClient = useQueryClient()
  const [coverPhoto, setCoverPhoto] = useState<MediaUpload | null>(null)
  const { accessToken } = useUserContext()
  const { captureException } = useLoggingContext()
  const { key, refetch } = useExperience(experience?.slug, accessToken)
  const { putRequestAsync, data, ...rest } = usePutRequest<
    V1APIResponse<Experience>,
    CreateExperienceRequest
  >({
    path: `/v1/experiences/${experience?.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onMutate: async ({ body: experience }) => {
      await queryClient.cancelQueries({ queryKey: key })
      const { data } = queryClient.getQueryData<ExperienceQueryCache>(key)
      const oldExperience = data?.data?.[0]
      if (!oldExperience) {
        throw new Error("experience not found")
      }

      let previewPhoto: Experience["cover_photo"] = oldExperience.cover_photo
      if (coverPhoto) {
        previewPhoto = {
          url: coverPhoto.image.url,
          thumbnail_450x150: {
            url: coverPhoto.image.url,
          },
        }
      }

      const newExperienceData = {
        ...oldExperience,
        ...experience,
        cover_photo: previewPhoto,
      }

      queryClient.setQueryData<ExperienceQueryCache>(key, {
        data: {
          data: [newExperienceData],
        },
      })
    },
  })

  return {
    updateExperienceAsync: (
      req: { body: CreateExperienceRequest },
      coverPhoto?: MediaUpload,
    ) => {
      const { body } = req
      if (req.body.cover_photo?.file_name) {
        setCoverPhoto(coverPhoto)
        body.cover_photo = req.body.cover_photo
          .file_name as unknown as MediaUpload
      } else {
        delete body.cover_photo
      }
      return putRequestAsync(
        { body },
        {
          onSuccess: async (data) => {
            queryClient.setQueryData<ExperienceQueryCache>(key, data)
            await queryClient.invalidateQueries({
              queryKey: ["list-experiences"],
            })
            await queryClient.invalidateQueries({
              queryKey: ["list-attending-activities"],
            })
          },
          onError: (e) => {
            captureException(new Error(`Failed to update experience: ${e}`))
            return refetch()
          },
        },
      )
    },
    data,
    ...rest,
  }
}
