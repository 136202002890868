"use client"

import { useEffect } from "react"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H1,
  NotFoundImage,
  P,
  useLoggingContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import { ACTIVITIES_URI } from "@bullseye/routes"

type Props = {
  error?: Error & { digest?: string }
  reset: VoidFunction
}

export const ErrorScreen = ({ error, reset }: Props) => {
  const router = useRouter()
  function resetAndRedirect() {
    reset()
    router.push(ACTIVITIES_URI)
  }
  const { captureException } = useLoggingContext()

  useEffect(() => {
    if (error) {
      captureException(error)
    }
  }, [error])

  return (
    <Box className="row-auto grid h-full content-center items-center justify-center justify-items-center gap-2 p-2">
      <NotFoundImage />
      <H1>
        <FormattedMessage id="errorBoundary.title" />
      </H1>
      <P>
        <FormattedMessage id="errorBoundary.description" />
      </P>
      <Button as="button" handlePress={resetAndRedirect}>
        Continue
      </Button>
    </Box>
  )
}
