"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Experience,
  getCookieDomain,
  Icon,
  Markdown,
  P,
  setCookie,
  SIGN_UP_URI,
  TextButton,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

type Props = {
  experience: Experience
}

export const UnauthedActivityLocation = ({ experience }: Props) => {
  const router = useRouter()
  if (experience.venue_type === "online") {
    return (
      <Box className="flex flex-row g-3">
        <Box>
          <Box className="overflow-hidden rounded-lg bg-gray-10 p-1.5">
            <Icon type="monitor" size="md" />
          </Box>
        </Box>
        <Box className="flex flex-1">
          <Box className="flex flex-col items-start g-1">
            <Box>
              <P bold>
                <FormattedMessage id="activity.info.online.title" />
              </P>
            </Box>
            <Box>
              <P>
                <FormattedMessage id="activity.general.online" />
              </P>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const cityState = [experience.city, experience.state]
    .filter(Boolean)
    .join(", ")
  return (
    <Box className="flex flex-row g-3">
      <Box>
        <Box className="overflow-hidden rounded-lg bg-gray-10 p-1.5">
          <Icon type="location-pin" size="md" />
        </Box>
      </Box>
      <Box className="flex flex-1">
        <Box className="flex flex-col items-start g-1">
          <Box>
            <P bold>{cityState}</P>
          </Box>
          <Box>
            <TextButton
              as="button"
              handlePress={() => {
                const domain = getCookieDomain(
                  process.env.NEXT_PUBLIC_VERCEL_ENV,
                )
                setCookie({
                  name: "attendActivity",
                  value: experience.id,
                  expiresInSeconds: 300,
                  domain,
                })
                router.push(SIGN_UP_URI)
              }}
              variant="primary"
            >
              <FormattedMessage id="activity.location.signup" />
            </TextButton>
          </Box>
          {experience.additional_instructions && (
            <Box className="pt-2">
              <Markdown content={experience.additional_instructions} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
