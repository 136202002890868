import { useUserContext } from "../providers"
import { useGetRequest } from "./useRequest"

type VerificationStatusResponse = {
  status: "invalid" | "requires_input" | "processing" | "verified"
  last_error_code?: string
  created_at: string
  updated_at: string
}

export const useVerificationStatus = () => {
  const { accessToken } = useUserContext()
  const { data, ...rest } = useGetRequest<{
    data: VerificationStatusResponse[]
  }>({
    path: "v1/verification",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    verificationStatus: data?.data?.[0],
    ...rest,
  }
}
