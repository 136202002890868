"use client"

import { CapturePhoto } from "./components/CapturePhoto"
import { PreviewPhoto } from "./components/PreviewPhoto"
import { SelectOrCapture } from "./components/SelectOrCapture"
import {
  ProfilePhotoContextProvider,
  useProfilePhotoCtx,
} from "./providers/ProfilePhotoContext"

type Props = {
  onComplete: () => void
  avatarUrl?: string
}

export const UploaderUI = ({ avatarUrl }: { avatarUrl?: string }) => {
  const { showCamera, startOver, capturedPhoto } = useProfilePhotoCtx()

  if (capturedPhoto) {
    return <PreviewPhoto goBack={startOver} />
  }

  if (showCamera) {
    return <CapturePhoto />
  }

  return <SelectOrCapture avatarUrl={avatarUrl} />
}

export const ProfilePhotoUploader = ({ onComplete, avatarUrl }: Props) => {
  return (
    <ProfilePhotoContextProvider onComplete={onComplete}>
      <UploaderUI avatarUrl={avatarUrl} />
    </ProfilePhotoContextProvider>
  )
}
