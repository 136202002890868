import { useMemo } from "react"
import { isFuture, isPast } from "date-fns"
import { DateTime } from "luxon"
import { useIntl } from "react-intl"
import * as yup from "yup"

import { ActivityForm, Experience } from "@bullseye/types"

import { formatDate } from "./formatDate"

const validURLRegExp = new RegExp(
  /^(?:(?:https?|ftp):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/\S*)?$/i,
)

const validCurrencyRegExp = new RegExp(/^\d+(\.\d{1,2})?$/i)

type Args = {
  experience?: Experience
  isCreatingExperience?: boolean
}

export const useValidationSchema = ({ experience }: Args) => {
  const intl = useIntl()

  return useMemo(() => {
    // If an activity is in the past or canceled, we want to apply a limited set of validations
    // because either :
    // - you're an admin and should be able to change anything for the activity
    // - you're a user and any only the title and description are editable fields
    const isPastOrCanceled =
      experience?.canceled_at || isPast(new Date(experience?.start_date))
    if (isPastOrCanceled) {
      return yup.object().shape({
        title: yup
          .string()
          .required(
            intl.formatMessage({ id: "validation.activityForm.title" }),
          ),
        venue_type: yup
          .string()
          .oneOf(["in_person", "online", "tbd"])
          .required(
            intl.formatMessage({ id: "validation.activityForm.venueType" }),
          ),
        url: yup
          .string()
          .optional()
          .test(
            "is-valid-link",
            intl.formatMessage({ id: "validation.activityForm.url" }),
            (url) => {
              if (!url) return true
              return validURLRegExp.test(url)
            },
          )
          .test(
            "required-if-online",
            intl.formatMessage({ id: "validation.activityForm.url" }),
            (url, context) => {
              const venueType = (context.parent as ActivityForm).venue_type
              if (venueType !== "online") return true

              return !!url
            },
          ),
        selectedPlace: yup.object({
          place: yup
            .object({
              id: yup.string(),
              formattedPlaceName: yup.string(),
            })
            .optional(),
          google_place_id: yup.string().optional(),
          name: yup.string(),
        }),
        startDate: yup.date().optional(),
        startTime: yup.date().optional(),
        cover_photo: yup
          .object({
            file_name: yup.string(),
            image: yup.object({
              thumbnail_450x150: yup.object({
                url: yup.string(),
              }),
              url: yup.string(),
            }),
          })
          .optional(),
        timezone: yup.string().optional(),
        ticket_url: yup
          .string()
          .test(
            "is-valid-link",
            intl.formatMessage({ id: "validation.activityForm.url" }),
            (url) => {
              if (!url) return true
              return validURLRegExp.test(url)
            },
          ),
        max_attendees: yup
          .number()
          .typeError(
            intl.formatMessage(
              { id: "validation.typeError.number" },
              {
                fieldName: intl.formatMessage({
                  id: "activityForm.maxAttendees",
                }),
              },
            ),
          )
          .test(
            "is-valid-limit",
            experience?.attendees_count
              ? intl.formatMessage({
                  id: "validation.activityForm.maxAttendees.withAttendees",
                })
              : intl.formatMessage({
                  id: "validation.activityForm.maxAttendees",
                }),
            (val) => {
              if (!val) return true
              if (experience?.attendees_count) {
                return val >= experience.attendees_count
              }
              return val >= 0
            },
          ),
        no_show_fee: yup.string().test(
          "valid-currency",
          intl.formatMessage({
            id: "validation.activityForm.invalidCurrency",
          }),
          (value) => {
            if (!value) return true
            return validCurrencyRegExp.test(value)
          },
        ),
        is_hostless: yup.string().optional(),
      })
    }

    return yup.object().shape({
      title: yup
        .string()
        .required(intl.formatMessage({ id: "validation.activityForm.title" })),
      venue_type: yup
        .string()
        .oneOf(["in_person", "online", "tbd"])
        .required(
          intl.formatMessage({ id: "validation.activityForm.venueType" }),
        ),
      url: yup
        .string()
        .optional()
        .test(
          "is-valid-link",
          intl.formatMessage({ id: "validation.activityForm.url" }),
          (url) => {
            if (!url) return true
            return validURLRegExp.test(url)
          },
        )
        .test(
          "required-if-online",
          intl.formatMessage({ id: "validation.activityForm.url" }),
          (url, context) => {
            const venueType = (context.parent as ActivityForm).venue_type
            if (venueType !== "online") return true

            return !!url
          },
        ),
      selectedPlace: yup.object({
        place: yup
          .object({
            id: yup.string(),
            formattedPlaceName: yup.string(),
          })
          .optional(),
        google_place_id: yup
          .string()
          .test(
            "required",
            intl.formatMessage({ id: "validation.activityForm.placeId" }),
            (placeId, context) => {
              const venueType = (context.parent as ActivityForm).venue_type
              if (venueType === "online") return true

              return !!placeId
            },
          ),
        name: yup.string(),
      }),
      startDate: yup
        .date()
        .test(
          "required-sometimes",
          intl.formatMessage({ id: "validation.activityForm.startDate" }),
          (startDate) => {
            return !!startDate
          },
        ),
      startTime: yup
        .date()
        .test(
          "required-sometimes",
          intl.formatMessage({ id: "validation.activityForm.startTime" }),
          (startTime) => {
            return !!startTime
          },
        )
        .test(
          "has-start-date",
          intl.formatMessage({ id: "validation.activityForm.startTime" }),
          (startTime, context) => {
            const data = context.parent as ActivityForm | undefined
            return startTime && !!data?.startDate
          },
        )
        .test(
          "is-in-future",
          intl.formatMessage({
            id: "validation.activityForm.startDateInFuture",
          }),
          (startTime, context) => {
            const data = context.parent as ActivityForm | undefined
            if (!data?.startDate) return false

            const nextDate = DateTime.fromISO(
              formatDate(data?.startDate, startTime, data?.timezone),
            )
            return isFuture(nextDate.toJSDate())
          },
        ),
      cover_photo: yup
        .object({
          file_name: yup.string(),
          image: yup.object({
            thumbnail_450x150: yup.object({
              url: yup.string(),
            }),
            url: yup.string(),
          }),
        })
        .optional(),
      timezone: yup.string().optional(),
      ticket_url: yup
        .string()
        .test(
          "is-valid-link",
          intl.formatMessage({ id: "validation.activityForm.url" }),
          (url) => {
            if (!url) return true
            return validURLRegExp.test(url)
          },
        ),
      max_attendees: yup
        .number()
        .typeError(
          intl.formatMessage(
            { id: "validation.typeError.number" },
            {
              fieldName: intl.formatMessage({
                id: "activityForm.maxAttendees",
              }),
            },
          ),
        )
        .test(
          "is-valid-limit",
          experience?.attendees_count
            ? intl.formatMessage({
                id: "validation.activityForm.maxAttendees.withAttendees",
              })
            : intl.formatMessage({
                id: "validation.activityForm.maxAttendees",
              }),
          (val) => {
            if (!val) return true
            if (experience?.attendees_count) {
              return val >= experience.attendees_count
            }
            return val >= 0
          },
        ),
      no_show_fee: yup.string().test(
        "valid-currency",
        intl.formatMessage({
          id: "validation.activityForm.invalidCurrency",
        }),
        (value) => {
          if (!value) return true
          return validCurrencyRegExp.test(value)
        },
      ),
      is_hostless: yup.string().optional(),
    })
  }, [])
}
