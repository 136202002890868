import { useQueryClient } from "@tanstack/react-query"
import parsePhoneNumber from "libphonenumber-js"

import { type PublicUser, type User } from "@bullseye/types"

import { useUserContext } from "../providers/UserAuthProvider/UserProvider"
import { useGetRequest, usePutRequest } from "./useRequest"

type UserData = PublicUser | User

export const useUser = <T extends UserData>(id?: string) => {
  const { accessToken } = useUserContext()
  const { data, isLoading, error, refetch } = useGetRequest<T>(
    {
      method: "GET",
      path: `users/${id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!accessToken && !!id,
    },
  )

  return {
    user: data,
    isLoading,
    error,
    refetch,
  }
}

export const useUserByIdOrSlug = (idOrSlug?: string, accessToken?: string) => {
  const { data, ...rest } = useGetRequest<PublicUser>(
    {
      method: "GET",
      path: `users/${idOrSlug}`,
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    },
    {
      enabled: !!idOrSlug,
    },
  )

  return {
    user: data,
    ...rest,
  }
}

type UpdateUserArgs = Partial<User>

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const { user: currentUser, accessToken, queryKey, refetch } = useUserContext()

  const { putRequestAsync, isLoading, error } = usePutRequest<
    { data: User[] },
    UpdateUserArgs
  >({
    path: "v1/users/me",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    onMutate: async (updReq) => {
      await queryClient.cancelQueries({ queryKey: queryKey })
      let formattedPhone = ""
      if (updReq.body.phone) {
        try {
          formattedPhone = parsePhoneNumber(
            updReq.body.phone,
            "US",
          )?.formatNational()
        } catch {}
      }
      const newUser = {
        ...currentUser,
        ...updReq,
        formatted_phone: formattedPhone,
      }
      queryClient.setQueryData(queryKey, {
        data: {
          data: [newUser],
        },
      })
    },
  })

  const update = async (updReq: UpdateUserArgs) => {
    let formattedPhone = updReq.formatted_phone
    if (updReq.phone) {
      try {
        const parsed = parsePhoneNumber(updReq.phone, "US")
        formattedPhone = parsed?.formatNational()
        updReq.phone = parsed?.number
      } catch {}
    }
    return putRequestAsync(
      { body: updReq },
      {
        onSuccess: ({ data }) => {
          data.data[0].formatted_phone = formattedPhone
          queryClient.setQueryData(queryKey, {
            data,
          })
        },
        onError: async (err) => {
          await refetch()
          throw err
        },
      },
    )
  }
  return { update, isLoading, error }
}
