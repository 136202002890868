"use client"

import { FormattedMessage } from "react-intl"

import { Box, Button, H4, Icon, P, Sheet } from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export const WaitlistModal = ({ isOpen, dismiss }: Modal) => {
  return (
    <Sheet
      show={isOpen}
      icon="x"
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="flex-col items-start g-4">
            <Box className="mx-auto rounded-full bg-green-400 p-2">
              <Icon type="check" color="text-white" />
            </Box>
            <H4 className="w-full text-center">
              <FormattedMessage id="activity.waitlist.modal.title" />
            </H4>
            <P className="w-full text-center">
              <FormattedMessage id="activity.waitlist.modal.description" />
            </P>
          </Box>
        )
      }}
      renderFooter={() => {
        return (
          <Box className="mt-8">
            <Button
              as="button"
              disabled={false}
              variant="secondary"
              handlePress={() => {
                dismiss()
              }}
            >
              <P bold>Dismiss</P>
            </Button>
          </Box>
        )
      }}
    />
  )
}
