import { InfiniteData } from "@tanstack/react-query"

import { Experience } from "@bullseye/types"

import { PaginatedResponse } from "../types"
import { usePaginatedRequest } from "./useRequest"

const defaultOptions = {
  enabled: true,
}

type Options = {
  enabled: boolean
  accessToken?: string
  select?: (
    data: InfiniteData<PaginatedResponse<Experience>>,
  ) => InfiniteData<PaginatedResponse<Experience>>
}

export const useExperiences = (
  params: Record<string, any>,
  options: Options = defaultOptions,
) => {
  const headers: Record<string, string> = {}
  if (options.accessToken) {
    headers["Authorization"] = `Bearer ${options.accessToken}`
  }

  return usePaginatedRequest<PaginatedResponse<Experience>>(
    {
      path: "/experiences",
      headers,
      params,
      select: options.select,
    },
    options,
  )
}
