import React from "react"
import { Path, Svg } from "react-native-svg"

export const Amex = () => (
  <Svg viewBox="0 0 64 40" fill="none">
    <Path
      d="M58.572 35.556l-2.826-3.112-2.94 3.112H34.6V21.222h-5.88l7.237-16.11h7.01l2.488 5.555V5.11h8.706l1.47 4.222 1.47-4.222h6.672V2.667C63.774 1.222 62.53 0 61.06 0H2.714C1.244 0 0 1.222 0 2.667v34.666C0 38.778 1.244 40 2.714 40h58.46c1.47 0 2.713-1.222 2.713-2.667v-1.777h-5.315z"
      fill="#0071CE"
    />
    <Path
      d="M59.364 33.89h4.523l-5.993-6.334 5.992-6.111h-4.41l-3.844 4-3.618-4h-4.636l5.993 6.222-5.993 6.222h4.41l3.844-4 3.732 4z"
      fill="#0071CE"
    />
    <Path
      d="M40.028 31v-1.889h7.124v-2.888h-7.124v-1.89h7.35v-2.888H36.523v12.444h10.855V31h-7.35zM60.494 19.556h3.392V7l-5.314.111-2.827 7.778-2.94-7.778h-5.427v12.445h3.505v-8.778l3.28 8.778h3.052l3.28-8.778v8.778zM41.724 7.111h-4.523l-5.654 12.556h3.845l1.018-2.556h5.88l1.13 2.556h3.958L41.724 7.11zm-4.07 7.111l1.695-4.11 1.697 4.11h-3.393zM60.72 27.222l3.167 3.444.113-6.889-3.28 3.445z"
      fill="#0071CE"
    />
  </Svg>
)
