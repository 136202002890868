"use client"

import { useState } from "react"
import clsx from "clsx"
import { AsYouType } from "libphonenumber-js"
import { styled } from "nativewind"
import { Controller, FormProvider } from "react-hook-form"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"

import "yup-phone-lite"

import {
  Box,
  Button,
  Checkbox,
  Fade,
  Icon,
  IconButton,
  Image,
  P,
  Radio,
  TextInput,
  useAsyncStorage,
  useAttendExperience,
  useExperienceContext,
  useLoggingContext,
  useUpdateUser,
  useUserContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { useRegistrationForm } from "../../hooks"
import { useToastContext } from "../../providers"

const Section = styled(Box, "flex bg-white py-6 g-3 md:rounded-lg px-4")

export const ActivityRegistrationPage = () => {
  const { experience, refetch, waitlistRefetch } = useExperienceContext()
  const { openErrorToast } = useToastContext()
  const { attendExperienceAsync } = useAttendExperience(experience.id)
  const { setValue } = useAsyncStorage("attendActivity")
  const { user } = useUserContext()
  const intl = useIntl()
  const { captureException } = useLoggingContext()
  const [isLoading, setIsLoading] = useState(false)
  const { form, submit } = useRegistrationForm()
  const { update: updateUser } = useUpdateUser()

  function handleErrors() {
    openErrorToast({
      label: intl.formatMessage({
        id: "validation.activityForm.invalidState",
      }),
      delayMs: 3000,
    })
  }

  type Form = Parameters<typeof submit>[0]

  async function handleSubmit(data: Form) {
    try {
      setIsLoading(true)

      if (data.phone !== user.phone) {
        try {
          await updateUser({ phone: data.phone })
        } catch {}
      }

      await submit(data, experience)

      // Mark user as attending
      await attendExperienceAsync()
      await Promise.all([await refetch(), await waitlistRefetch()])

      router.back()
      await setValue(experience.id)
    } catch (error) {
      const err = error as Error
      captureException(
        new Error(`Failed to track activity registration: ${err.message}`),
      )
    }
    setIsLoading(false)
  }

  const { accepted } = form.watch("permissionToContact")
  const router = useRouter()

  return (
    <FormProvider {...form}>
      <Box className="flex bg-gray-10 g-2">
        <Section className="pt-0 md:pt-6">
          <Box className="flex-row items-center justify-between py-2 md:hidden">
            <IconButton
              iconType="arrow-left"
              as="button"
              size="lg"
              handlePress={() => {
                router.back()
              }}
            />
            <P bold>
              <FormattedMessage id="registration" />
            </P>
            <Box className="flex w-6" />
          </Box>
          <Box className="flex-row items-center g-3">
            {experience.cover_photo?.url && (
              <Image
                source={{ uri: experience.cover_photo.url }}
                className="h-12 w-12 rounded"
              />
            )}
            <P bold size="lg" className="flex-1">
              {experience.title}
            </P>
          </Box>
          <Box className="flex-row items-center g-2">
            <Icon type="calendar" size="md" />
            <P>
              <FormattedDate
                value={experience.start_date}
                timeZone={experience.timezone}
                weekday="long"
                month="long"
                year="numeric"
                day="numeric"
              />
            </P>
          </Box>
          <Box className="flex-row items-center g-2">
            <Icon type="location-pin" size="md" />
            <P ellipsis>{experience.formatted_address_string}</P>
          </Box>
        </Section>
        <Section>
          <P bold>
            <FormattedMessage id="registration.cta" />
          </P>
          <P>
            <FormattedMessage id="registration.fields.permissionToContact" />
          </P>
          <Controller
            name="permissionToContact"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Box className="g-2">
                  <Radio
                    box
                    label={<FormattedMessage id="general.yes" />}
                    checked={
                      (value as Form["permissionToContact"]).accepted === true
                    }
                    onChange={(selected) => {
                      onChange({ accepted: selected })
                    }}
                  />
                  <Radio
                    box
                    label={<FormattedMessage id="general.no" />}
                    checked={
                      (value as Form["permissionToContact"]).denied === true
                    }
                    onChange={(selected) => {
                      onChange({ denied: selected })
                    }}
                  />
                  <P
                    className={clsx("text-red-600", {
                      hidden: !error,
                    })}
                  >
                    {error?.message}
                  </P>
                </Box>
              )
            }}
          />
        </Section>

        {accepted && (
          <Fade fadeIn>
            <Box className="g-3">
              <Section>
                <P bold>
                  <FormattedMessage id="registration.fields.contactInformation" />
                </P>
                <P bold>{`${user.first_name} ${user.last_name}`}</P>
                <P className="text-gray-600">{user.email}</P>
                <P className="text-gray-600">{user.formatted_location}</P>
                <Controller
                  name="phone"
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextInput
                      label={intl.formatMessage({
                        id: "profile.phone",
                      })}
                      placeholder={intl.formatMessage({
                        id: "registration.fields.phone.placeholder",
                      })}
                      value={value}
                      onChangeText={(phone) => {
                        const priorValue = form.getValues("phone")
                        const next =
                          priorValue?.length > phone?.length
                            ? phone
                            : new AsYouType("US").input(phone)
                        onChange(next)
                      }}
                      onBlur={onBlur}
                      inputMode="tel"
                      errorMessage={error?.message}
                    />
                  )}
                />
              </Section>

              <Section>
                <P bold>
                  <FormattedMessage id="registration.fields.typesOfCoverage.cta" />
                </P>
                <P>
                  <FormattedMessage id="registration.fields.typesOfCoverage.description" />
                </P>
                <Controller
                  name="typesOfCoverage"
                  render={({ field: { value, onChange } }) => (
                    <Box className="g-4">
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            prescriptions: selected,
                          })
                        }
                        checked={
                          (value as Form["typesOfCoverage"]).prescriptions
                        }
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.prescriptions",
                        })}
                      />
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            hearing: selected,
                          })
                        }
                        checked={(value as Form["typesOfCoverage"]).hearing}
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.hearing",
                        })}
                      />
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            vision: selected,
                          })
                        }
                        checked={(value as Form["typesOfCoverage"]).vision}
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.vision",
                        })}
                      />
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            dental: selected,
                          })
                        }
                        checked={(value as Form["typesOfCoverage"]).dental}
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.dental",
                        })}
                      />
                    </Box>
                  )}
                />
              </Section>
            </Box>
          </Fade>
        )}

        <Section className="g-4">
          <Button
            as="button"
            loading={isLoading}
            handlePress={form.handleSubmit(handleSubmit, handleErrors)}
          >
            <FormattedMessage id="registration.submit" />
          </Button>
          {accepted && (
            <P className="text-gray-600">
              <FormattedMessage id="registration.tcs" />
            </P>
          )}
        </Section>
      </Box>
    </FormProvider>
  )
}
