"use client"

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react"

import {
  clearCookie,
  getCookie,
  getCookieDomain,
  isWeb,
  useAsyncStorage,
  useExperienceContext,
} from "@bullseye/components"

import { Attendees, AttendeesModalProps } from "./Attendees"
import { AddAttendees} from "./AddAttendees"
import { CancelActivity } from "./CancelActivity"
import {
  CancelAttendance,
  CancelAttendanceModalProps,
} from "./CancelAttendance"
import { CancelWaitlist, CancelWaitlistModalProps } from "./CancelWaitlist"
import { Hostless } from "./Hostless"
import { HouseRules } from "./HouseRules"
import { NoShowPolicy, NoShowPolicyModalProps } from "./NoShowPolicy"
import { NotifyListModal } from "./NotifyList"
import { WaitlistModal } from "./Waitlist"

export enum ActivityModal {
  AddAttendees = "AddAttendees",
  Attendees = "Attendees",
  CancelAttendance = "CancelAttendance",
  Hostless = "Hostless",
  HouseRules = "HouseRules",
  NoShowPolicy = "NoShowPolicy",
  Waitlist = "Waitlist",
  CancelWaitlist = "CancelWaitlist",
  NotifyList = "NotifyList",
  CancelActivity = "CancelActivity",
}

type Props = PropsWithChildren

type ModalProps = AttendeesModalProps &
  NoShowPolicyModalProps &
  CancelAttendanceModalProps

type OpenModalMethods =
  // AttendeesModal
  ((id: ActivityModal.Attendees, props: AttendeesModalProps) => void) &
    // NoShowPolicyModal
    ((id: ActivityModal.NoShowPolicy, props?: NoShowPolicyModalProps) => void) &
    // CancelAttendanceModal
    ((
      id: ActivityModal.CancelAttendance,
      props: CancelAttendanceModalProps,
    ) => void) &
    // HostlessModal
    ((id: ActivityModal.Hostless) => void) &
    // HouseRules
    ((id: ActivityModal.HouseRules) => void) &
    // Waitlist
    ((id: ActivityModal.Waitlist) => void) &
    // CancelWaitlist
    ((
      id: ActivityModal.CancelWaitlist,
      props: CancelWaitlistModalProps,
    ) => void) &
    // NotifyList
    ((id: ActivityModal.NotifyList) => void) &
    // CancelActivity
    ((id: ActivityModal.CancelActivity) => void)&
    // AddAttendees
    ((id: ActivityModal.AddAttendees) => void)

const ActivityModalContext = createContext<{
  openModal: OpenModalMethods
  closeModal: (modal: ActivityModal) => void
} | null>(null)

export const useActivityModalContext = () => {
  const ctx = useContext(ActivityModalContext)
  if (ctx === null) {
    throw new Error(
      "useActivityModalContext must be used within a ActivityModalProvider",
    )
  }
  return ctx
}

export const ActivityModalProvider = ({ children }: Props) => {
  const { data: attending, removeValue } = useAsyncStorage("attendActivity")
  const { experience } = useExperienceContext()
  const isAttending = isWeb() ? getCookie("attendActivity") : false
  const [openModals, setOpenModals] = useState<
    Partial<Record<ActivityModal, boolean>>
  >({
    HouseRules: !!isAttending,
  })
  const [extraData, setExtraData] = useState<ModalProps | null>()

  useEffect(() => {
    if (!isWeb()) {
      return
    }
    const isAttending = getCookie("attendActivity")
    if (isAttending) {
      const domain = getCookieDomain(process.env.NEXT_PUBLIC_VERCEL_ENV)
      clearCookie({
        name: "attendActivity",
        domain,
      })
    }
  }, [])

  useEffect(() => {
    if (!attending) return
    if (attending === experience.id) {
      setOpenModals({ HouseRules: true })
      void removeValue()
    }
  }, [attending])

  function closeModal(id: string) {
    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: false }
    })
  }

  function openModal(id: ActivityModal, props?: unknown) {
    if (props) {
      setExtraData(props as ModalProps)
    }
    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: true }
    })
  }

  return (
    <ActivityModalContext.Provider
      value={{
        closeModal,
        openModal,
      }}
    >
      <>
        {children}
        <NoShowPolicy
          isOpen={openModals.NoShowPolicy}
          dismiss={() => closeModal(ActivityModal.NoShowPolicy)}
          {...extraData}
        />
        <AddAttendees
          isOpen={openModals.AddAttendees}
          dismiss={() => closeModal(ActivityModal.AddAttendees)}
        />
        <Attendees
          isOpen={openModals.Attendees}
          dismiss={() => closeModal(ActivityModal.Attendees)}
          {...extraData}
        />
        <CancelAttendance
          isOpen={openModals.CancelAttendance}
          dismiss={() => closeModal(ActivityModal.CancelAttendance)}
          {...extraData}
        />
        <Hostless
          isOpen={openModals.Hostless}
          dismiss={() => closeModal(ActivityModal.Hostless)}
        />
        <HouseRules
          isOpen={openModals.HouseRules}
          dismiss={() => closeModal(ActivityModal.HouseRules)}
        />
        <WaitlistModal
          isOpen={openModals.Waitlist}
          dismiss={() => closeModal(ActivityModal.Waitlist)}
          {...extraData}
        />
        <CancelWaitlist
          isOpen={openModals.CancelWaitlist}
          dismiss={() => closeModal(ActivityModal.CancelWaitlist)}
          {...extraData}
        />
        <NotifyListModal
          isOpen={openModals.NotifyList}
          dismiss={() => closeModal(ActivityModal.NotifyList)}
        />
        <CancelActivity
          isOpen={openModals.CancelActivity}
          dismiss={() => closeModal(ActivityModal.CancelActivity)}
        />
      </>
    </ActivityModalContext.Provider>
  )
}
