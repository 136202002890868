"use client"

import { Pressable } from "react-native"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Box,
  H3,
  H4,
  Icon,
  P,
  ScrollBox,
  Sheet,
  TextButton,
  useExperienceContext,
  useExperienceMessageChannel,
  useExperienceUrl,
} from "@bullseye/components"

import { OpenActivityChatButton } from "../../OpenActivityChatButton"
import { ShareButton } from "../../ShareButton"
import { ActivityDate } from "../ActivityDate"
import { ActivityLocation } from "../ActivityLocation"
import { ActivityNoShowPolicy } from "../ActivityNoShowPolicy"
import { ActivityOrganizer } from "../ActivityOrganizer"

export type HouseRulesModalProps = {
  isOpen: boolean
  dismiss: VoidFunction
}

export const HouseRules = ({ isOpen, dismiss }: HouseRulesModalProps) => {
  const { experience } = useExperienceContext()
  const { messageChannel } = useExperienceMessageChannel(experience.id)
  const intl = useIntl()
  const experienceUrl = useExperienceUrl(experience.slug)

  return (
    <Sheet
      icon="x"
      size="lg"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <ScrollBox
            className="h-full md:pb-4"
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <Pressable>
              <Box className="flex g-6 md:pb-safe">
                <Box className="flex-col items-start g-2">
                  <P bold>
                    <FormattedMessage id="activity.attend.confirmModal.title" />
                  </P>
                  <H3>{experience.title}</H3>
                </Box>
                <Box
                  className={clsx(
                    "flex overflow-hidden rounded-lg bg-blue-100 p-4 g-3",
                    {
                      hidden: !experience.is_hostless,
                    },
                  )}
                >
                  <Box className="flex-row items-center g-2">
                    <Icon type="info-circle" size="lg" />
                    <H4>
                      <FormattedMessage id="activity.hostless" />
                    </H4>
                  </Box>
                  <P>
                    <FormattedMessage id="activity.hostless.modal.body.line1" />
                  </P>
                  <P>
                    <FormattedMessage id="activity.hostless.modal.body.line2" />
                  </P>
                  <Box className="inline">
                    <TextButton
                      as="a"
                      href="https://help.gethank.com/en/articles/8053635-how-to-find-other-hank-members-at-activities"
                      underlined
                    >
                      <FormattedMessage id="activity.hostless.modal.body.instructions" />
                    </TextButton>
                  </Box>
                </Box>
                <Box className="flex items-start px-4 g-6">
                  <ActivityOrganizer user={experience.organizer} />
                  <ActivityDate experience={experience} />
                  <ActivityLocation experience={experience} />
                  {experience.no_show_fee && (
                    <ActivityNoShowPolicy noShowFee={experience.no_show_fee} />
                  )}
                </Box>
              </Box>
            </Pressable>
          </ScrollBox>
        )
      }}
      renderFooter={() => {
        return (
          <Box className="flex items-center pb-4 g-3 md:pb-0">
            <Box className="w-full">
              <OpenActivityChatButton
                isEnabled
                channelId={messageChannel?.stream_channel_id}
              />
            </Box>
            <ShareButton
              variant="secondary"
              buttonType="button"
              emailSubject={intl.formatMessage(
                {
                  id: "activity.share.subject",
                },
                { title: experience.title },
              )}
              message={intl.formatMessage(
                {
                  id: "activity.share.message",
                },
                { title: experience.title, url: experienceUrl },
              )}
              url={experienceUrl}
            />
          </Box>
        )
      }}
    />
  )
}
