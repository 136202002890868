"use client"

import { useMemo } from "react"
import { Pressable } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { FormattedMessage } from "react-intl"

import {
  AvatarGroup,
  Box,
  Button,
  Experience,
  ExperienceAttendee,
  H4,
  Link,
  Loading,
  P,
  useExperienceAttendees,
} from "@bullseye/components"

import {
  ActivityAttendeeCount,
  ActivityModal,
  useActivityModalContext,
  UserLockup,
} from "../../../../components"

const StyledPressable = styled(Pressable)

const ATTENDEE_LIMIT = 2

function Attendees({
  attendees,
  isLoading,
  attendeeCount,
  experience,
  queryKey,
}: {
  attendees: ExperienceAttendee[]
  isLoading: boolean
  attendeeCount: number
  experience: Experience
  queryKey: string
}) {
  const { openModal } = useActivityModalContext()

  const [firstAttendees, attendeeAvatars] = useMemo(() => {
    if (isLoading || !attendees || !attendeeCount) return [[], []]

    // show 4 attendees if there are less than or equal to 4 attendees
    if (attendeeCount <= ATTENDEE_LIMIT) {
      return [attendees, []]
    }

    // otherwise, show 3 attendees and up to 4 avatars
    const avatarsStart = ATTENDEE_LIMIT - 1
    const displayedUsers = attendees.slice(0, avatarsStart)

    const avatarsEnd = avatarsStart + 3
    const avatars = attendees.slice(avatarsStart, avatarsEnd).map((a) => ({
      name: a.user.formatted_name || "",
      imageUrl: a.user?.avatar?.url || "",
    }))

    return [displayedUsers, avatars]
  }, [attendees, isLoading, attendeeCount])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box className="flex g-4">
      <H4>
        <ActivityAttendeeCount count={attendeeCount} />
      </H4>
      {firstAttendees.map((attendee) => (
        <Link key={attendee.id} href={`/p/${attendee.user.slug}`}>
          <UserLockup key={attendee.id} user={attendee.user} />
        </Link>
      ))}
      <StyledPressable
        className={clsx("flex-row items-center g-3", {
          hidden: !attendeeAvatars.length,
        })}
        onPress={() => {
          openModal(ActivityModal.Attendees, {
            experienceId: experience.id,
            organizerId: experience.organizer_id,
            queryKey: queryKey,
          })
        }}
      >
        <AvatarGroup avatars={attendeeAvatars} />
        <P bold className="lowercase">
          +{attendeeCount - firstAttendees.length}{" "}
          <FormattedMessage id="general.more" />
        </P>
      </StyledPressable>
      <Box
        className={clsx("overflow-hidden rounded-lg bg-gray-10 p-6", {
          hidden: firstAttendees.length,
        })}
      >
        <P>
          <FormattedMessage id="activity.attendees.noAttendees" />
        </P>
      </Box>
    </Box>
  )
}

export const AttendeesList = ({ experience }: { experience: Experience }) => {
  const { openModal } = useActivityModalContext()
  const { isLoading, data, key } = useExperienceAttendees(experience.id, {
    "user_id[not]": experience.organizer_id ?? "null",
  })

  const [firstPage] = data?.pages || []

  return (
    <Box className="flex flex-col p-6 g-4">
      <Attendees
        experience={experience}
        attendees={firstPage?.results}
        isLoading={isLoading}
        attendeeCount={firstPage?.metadata?.total_count}
        queryKey={key[0] as string}
      />
      <Box
        className={clsx({
          hidden: !experience.attendees_count,
        })}
      >
        <Button
          as="button"
          handlePress={() =>
            openModal(ActivityModal.Attendees, {
              experienceId: experience.id,
              organizerId: experience.organizer_id,
              queryKey: key[0] as string,
            })
          }
          variant="secondary"
        >
          <FormattedMessage id="activity.attendees.seeAll" />
        </Button>
      </Box>
    </Box>
  )
}
