"use client"

import { useBanners } from "@bullseye/components"
import { IPLocation } from "@bullseye/types"

import { CMSBanner } from "./CMSBanner"

type Props = {
  userAccessToken?: string
  userLocation?: Partial<IPLocation>
}

export const RegionalBanners = ({ userLocation, userAccessToken }: Props) => {
  const { data } = useBanners({
    latitude: userLocation?.latitude,
    longitude: userLocation?.longitude,
    accessToken: userAccessToken,
  })
  if (!userLocation || !data?.data?.length) return null
  const [banner] = data?.data
  return <CMSBanner {...banner} />
}
