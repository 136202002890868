"use client"

import { createContext, PropsWithChildren, useContext, useState } from "react"

import { dismissKeyboardAsync } from "@bullseye/components"

import {
  IncompleteFeatureModal,
  IncompleteFeatureModalProps,
} from "./IncompleteFeature"
import { ShareModal, ShareModalProps } from "./Share"

export enum UnauthedAppModal {
  IncompleteFeature = "IncompleteFeature",
  Share = "Share",
}

type OpenModalMethods =
  // IncompleteFeature
  ((
    id: UnauthedAppModal.IncompleteFeature,
    props: IncompleteFeatureModalProps,
  ) => void) &
    // Share
    ((id: UnauthedAppModal.Share, props: ShareModalProps) => void)

type ModalProps = IncompleteFeatureModalProps & ShareModalProps

const UnauthedAppModalContext = createContext<{
  openModal: OpenModalMethods
  closeModal: (modal: UnauthedAppModal) => void
} | null>(null)

export const useUnauthedAppModalContext = () => {
  const ctx = useContext(UnauthedAppModalContext)
  if (ctx === null) {
    throw new Error(
      "useUnauthedAppModalContext must be used within an UnauthedAppModalContext",
    )
  }
  return ctx
}

export const UnauthedAppModalProvider = ({ children }: PropsWithChildren) => {
  const [openModals, setOpenModals] = useState<
    Record<UnauthedAppModal, boolean>
  >({
    IncompleteFeature: false,
    Share: false,
  })

  const [extraData, setExtraData] = useState<ModalProps | null>(null)

  function closeModal(id: string) {
    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: false }
    })
  }

  async function openModal(id: string, props?: unknown) {
    await dismissKeyboardAsync()
    if (props) {
      setExtraData(props as ModalProps)
    }

    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: true }
    })
  }

  return (
    <UnauthedAppModalContext.Provider
      value={{
        closeModal,
        openModal,
      }}
    >
      {children}
      <IncompleteFeatureModal
        isOpen={openModals.IncompleteFeature}
        dismiss={() => closeModal(UnauthedAppModal.IncompleteFeature)}
        {...extraData}
      />
      <ShareModal
        isOpen={openModals.Share}
        dismiss={() => closeModal(UnauthedAppModal.Share)}
        {...extraData}
      />
    </UnauthedAppModalContext.Provider>
  )
}
