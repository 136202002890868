"use client"

import React from "react"
import { GestureResponderEvent } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { useLinkOrButtonPress } from "../../hooks"
import { Icon, IconProps } from "../Icon"
import Loading from "../Loading"
import { PressableBox } from "../PressableBox"
import { P } from "../Typography"

type BaseProps = {
  children: React.ReactNode
  variant?: "primary" | "secondary" | "tertiary" | "danger" | "highlight"
  height?: "sm" | "md" | "lg" | "xl"
  disabled?: boolean
  name?: string
  style?: Record<string, any>
  startIconType?: IconProps["type"]
  endIconType?: IconProps["type"]
  loading?: boolean
  compact?: boolean
  rounded?: boolean
  active?: boolean
  outlined?: boolean
}

export type ButtonProps = {
  as: "button"
  handlePress: (event: GestureResponderEvent) => void
} & BaseProps

export type ButtonLinkProps = {
  as: "a"
  href: string
} & BaseProps

const containerVariants: Record<string, string> = {
  "filled-primary":
    "bg-primary-500 hover:bg-primary-700 focus-visible:bg-primary-900",
  "filled-secondary":
    "bg-primary-50 hover:bg-primary-100 focus-visible:border-solid focus-visible:border focus-visible:border-primary-900 ",
  "filled-tertiary":
    "bg-gray-50 hover:bg-gray-200 focus-visible:bg-gray-200 focus-visible:border focus-visible:border-solid focus-visible:border-primary-500",
  "filled-danger":
    "bg-red-600 hover:bg-red-800 focus-visible:bg-red-600 focus-visible:border focus-visible:border-solid focus-visible:border-primary-500",
  "filled-highlight":
    "bg-brand-orange-1 hover:bg-brand-orange-2 focus-visible:bg-brand-orange-1 focus-visible:border focus-visible:border-solid focus-visible:border-brand-orange-1",

  "outlined-primary":
    "bg-white border border-solid border-primary-500 hover:border-primary-400 hover:bg-primary-50 focus-visible:bg-primary-100 focus-visible:border-gray-400",
  "outlined-secondary":
    "bg-white border border-solid border-gray-200 hover:border-gray-400 hover:bg-gray-50 focus-visible:bg-gray-100 focus-visible:border-primary-500",
  "outlined-tertiary":
    "bg-gray-100 hover:bg-gray-200 focus-visible:bg-gray-200 focus-visible:border focus-visible:border-solid focus-visible:border-primary-900",
  "outlined-danger":
    "bg-white border border-solid border-red-600 hover:border-red-800 hover:bg-red-50 focus-visible:bg-red-50 focus-visible:border focus-visible:border-solid focus-visible:border-primary-500",
  "outlined-highlight":
    "bg-gray-100 hover:bg-gray-200 focus-visible:bg-gray-200 focus-visible:border focus-visible:border-solid focus-visible:border-primary-900",
}

const disabledContainers: Record<string, string> = {
  "filled-primary": "bg-gray-10 cursor-not-allowed",
  "filled-secondary": "bg-gray-10 cursor-not-allowed",
  "filled-tertiary": "bg-gray-10 cursor-not-allowed",
  "filled-danger": "bg-gray-10 cursor-not-allowed",
  "filled-highlight":
    "bg-brand-orange-2 aria-disabled:bg-brand-orange-2 cursor-not-allowed",

  "outlined-primary": "bg-white border-gray-50 cursor-not-allowed",
  "outlined-secondary": "bg-white border-gray-50 cursor-not-allowed",
  "outlined-tertiary": "bg-gray-100 cursor-not-allowed",
  "outlined-danger": "bg-white border-gray-50 cursor-not-allowed",
  "outlined-highlight": "bg-gray-100 cursor-not-allowed",
}

const containerActiveVariants: Record<string, string> = {
  "filled-primary": "bg-primary-900",
  "filled-secondary": "border border-solid border-primary-900",
  "filled-tertiary": "bg-gray-200 border border-solid border-primary-500",
  "filled-danger": "bg-red-800 border border-solid border-primary-500",
  "filled-highlight":
    "bg-brand-orange-1 border border-solid border-brand-orange-1",
  "outlined-primary": "bg-primary-100 border-gray-400",
  "outlined-secondary": "bg-primary-50 border-primary-500",
  "outlined-tertiary": "bg-gray-200 border border-solid border-primary-500",
  "outlined-danger": "bg-red-50 border border-solid border-primary-500",
  "outlined-highlight": "bg-gray-200 border border-solid border-primary-500",
}

const containerBase = twMerge(
  "h-14",
  "flex flex-row items-center justify-center px-4 flex-nowrap g-2",
  "aria-disabled:bg-gray-50 aria-disabled:focus-visible:border-gray-50 !outline-none",
  "border border-transparent rounded-md",
)

const containerHeights = {
  sm: "h-8",
  md: "h-10",
  lg: "h-12",
  xl: "h-14",
}

const textBase = "font-semibold m-0"

const textVariants: Record<string, string> = {
  "filled-primary": "text-white",
  "filled-secondary": "text-primary-900",
  "filled-tertiary": "text-gray-950",
  "filled-danger": "text-white",
  "filled-highlight": "text-white ",
  "outlined-primary": "text-primary-500",
  "outlined-secondary": "text-gray-950",
  "outlined-tertiary": "text-gray-950",
  "outlined-danger": "text-red-600",
  "outlined-highlight": "text-gray-950",
}

const disabledTextVariants: Record<string, string> = {
  "filled-primary": "text-gray-300",
  "filled-secondary": "text-gray-300",
  "filled-tertiary": "text-gray-300",
  "filled-danger": "text-gray-300",
  "filled-highlight": "text-white",
  "outlined-primary": "text-gray-300",
  "outlined-secondary": "text-gray-300",
  "outlined-tertiary": "text-gray-300",
  "outlined-danger": "text-gray-300",
  "outlined-highlight": "text-gray-300",
}

export const Button = styled(
  ({
    children,
    variant = "primary",
    height = "md",
    disabled = false,
    style,
    startIconType,
    endIconType,
    loading,
    compact,
    rounded,
    outlined,
    active,
    ...props
  }: ButtonProps | ButtonLinkProps) => {
    const onPress = useLinkOrButtonPress({
      as: props.as,
      href: props.as === "a" ? props.href : undefined,
      onPress: props.as === "button" ? props.handlePress : undefined,
    })
    if (disabled && props.hasOwnProperty("href")) {
      Object.assign(props, { href: null })
    }

    const variantName = `${outlined ? "outlined" : "filled"}-${variant}`
    const textColor = disabled
      ? disabledTextVariants[variantName]
      : textVariants[variantName]

    return (
      <PressableBox
        className={twMerge(
          containerBase,
          containerVariants[variantName],
          containerHeights[height],
          clsx({
            "w-full": !compact,
            "rounded-full": rounded,
            "cursor-wait bg-gray-10 hover:border-transparent hover:bg-gray-10":
              loading && !outlined,
            "cursor-wait border-gray-50 bg-white hover:border-gray-50 hover:bg-white":
              loading && outlined,
            [`${containerActiveVariants[variantName]}`]: active,
            [`${disabledContainers[variantName]}`]: disabled,
          }),
        )}
        disabled={disabled}
        onPress={onPress}
        {...props}
        style={style}
        role={props.as === "button" ? "button" : "link"}
      >
        {loading ? (
          <Loading size="small" />
        ) : (
          <>
            {startIconType && (
              <Icon type={startIconType} size="sm" color={textColor} />
            )}
            <P
              bold
              className={twMerge(textBase, textColor)}
              disabled={disabled}
            >
              {children}
            </P>
            {endIconType && (
              <Icon type={endIconType} size="sm" color={textColor} />
            )}
          </>
        )}
      </PressableBox>
    )
  },
)
