"use client"

import { FormattedMessage, useIntl } from "react-intl"

import {
  Box,
  Button,
  Markdown,
  MessageChannel,
  MESSENGER_URI,
  P,
  useAcceptChat,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import {
  ApplicationModal,
  useApplicationModalContext,
} from "../../../../components"

type Props = {
  messageChannel: MessageChannel
}

const formatName = (firstName: string, lastName?: string) => {
  if (!lastName) return firstName
  return `${firstName} ${lastName[0]}`
}

export function AcceptMessagePrompt({ messageChannel }: Props) {
  const router = useRouter()
  const { openModal } = useApplicationModalContext()

  const formattedName = formatName(
    messageChannel.creator.first_name,
    messageChannel.creator.last_name,
  )
  const intl = useIntl()
  const { accept, isLoading } = useAcceptChat(messageChannel)
  return (
    <Box className="flex items-center border-t border-solid border-gray-100 p-4 g-4">
      <Box className="flex items-center g-2">
        <P bold>
          <FormattedMessage
            id="messenger.acceptMessage"
            values={{ name: formattedName }}
          />
        </P>
        <Markdown
          content={intl.formatMessage({
            id: "messenger.acceptMessage.description",
          })}
        />
      </Box>
      <Box className="flex w-full g-2 md:flex-row">
        <Button
          as="button"
          handlePress={async () => {
            await accept()
          }}
          className="flex-grow md:flex-[1/3] md:flex-shrink"
          loading={isLoading}
          variant="tertiary"
        >
          <FormattedMessage id="messenger.acceptMessage.accept" />
        </Button>
        <Button
          as="button"
          handlePress={() => {
            openModal(ApplicationModal.BlockUser, {
              userId: messageChannel.creator.id,
              onSuccess: () => {
                router.push(MESSENGER_URI)
              },
            })
          }}
          variant="tertiary"
          className="flex-grow md:flex-[1/3] md:flex-shrink"
          loading={isLoading}
        >
          <P bold className="text-red-600">
            <FormattedMessage id="messenger.modal.channelOptions.block" />
          </P>
        </Button>
        <Button
          as="button"
          handlePress={() => {
            openModal(ApplicationModal.Report, {
              contentType: "message_channel",
              contentId: messageChannel.id,
            })
          }}
          variant="tertiary"
          className="flex-grow md:flex-[1/3] md:flex-shrink"
          loading={isLoading}
        >
          <P bold className="text-red-600">
            <FormattedMessage id="messenger.modal.channelOptions.reportUser" />
          </P>
        </Button>
      </Box>
    </Box>
  )
}
