"use client"

import React, { useContext, useEffect } from "react"
import { Platform, useColorScheme } from "react-native"
import { DeviceType } from "expo-device"

import { LoadingFullScreen } from "../../components/LoadingFullScreen"
import { useLoggingContext } from "../../hooks"
import { useBrowser } from "./hooks/useBrowser"
import useDeviceDimensions from "./hooks/useDeviceDimensions"
import { useDeviceType } from "./hooks/useDeviceType"
import { DeviceContextType, DevicePlatform } from "./types"

const defaultContext = {
  os: Platform.OS,
  platform: (Platform.OS === "web" ? "web" : "native") as DevicePlatform,
  screenWidth: 0,
  screenHeight: 0,
  isDarkMode: false,
  deviceType: DeviceType.UNKNOWN,
}

export const useDeviceContext = () => {
  return useContext(DeviceContext)
}

export const useIsWeb = () => {
  const device = useDeviceContext()
  return device.os === "web"
}

export const DeviceContext =
  React.createContext<DeviceContextType>(defaultContext)

export interface DeviceProviderProps {
  children: React.ReactNode
}

export function DeviceProvider({ children }: DeviceProviderProps) {
  const colorScheme = useColorScheme()
  const { deviceType, error } = useDeviceType()
  const dimensions = useDeviceDimensions()
  const browser = useBrowser()
  const { captureException } = useLoggingContext()

  useEffect(() => {
    if (!error) return
    captureException(new Error(`Error detecting device type: ${error}`))
  }, [error])

  if (!deviceType) {
    return <LoadingFullScreen />
  }

  return (
    <DeviceContext.Provider
      value={{
        os: Platform.OS === "web" ? browser.os : Platform.OS,
        browserName: browser.name,
        platform: Platform.OS === "web" ? "web" : "native",
        isDarkMode: colorScheme === "dark",
        deviceType,
        screenWidth:
          deviceType === DeviceType.DESKTOP
            ? dimensions.window.width
            : dimensions.screen.width,
        screenHeight:
          deviceType === DeviceType.DESKTOP
            ? dimensions.window.height
            : dimensions.screen.height,
      }}
    >
      {children}
    </DeviceContext.Provider>
  )
}

export default DeviceProvider
