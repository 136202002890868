"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  ME_EDIT_URI,
  PublicUser,
  useUserBlock,
  useUserBlockedMe,
  useUserContext,
} from "@bullseye/components"

import { MessageUserButton } from "../../../components/MessageUserButton"

type Props = {
  isSelf: boolean
  user: PublicUser
}

export const ActionButtons = ({ isSelf, user }: Props) => {
  const { user: self } = useUserContext()
  const isViewingSelf = user.id === self.id
  const { data: hasBlockedUser, isLoading: loadingHasBlocked } = useUserBlock(
    isSelf ? undefined : user.id,
  )
  const { data: isBlockedByUser, isLoading: loadingIsBlocked } =
    useUserBlockedMe(isSelf ? undefined : user.id)
  if (isSelf) {
    return (
      <>
        <Box className="w-full flex-row bg-white px-4 g-2">
          <Box className="w-6/12">
            <Button variant="secondary" as="a" href={`/p/${user.slug}`}>
              <FormattedMessage id="profile.view" />
            </Button>
          </Box>
          <Box className="w-6/12 ">
            <Button as="a" variant="tertiary" href={ME_EDIT_URI}>
              <FormattedMessage id="profile.edit" />
            </Button>
          </Box>
        </Box>
      </>
    )
  }

  if (isViewingSelf) {
    return (
      <Box className="w-full flex-row justify-center md:justify-start">
        <Box className="w-6/12 max-w-[200px] ">
          <Button as="a" variant="tertiary" href={ME_EDIT_URI}>
            <FormattedMessage id="profile.edit" />
          </Button>
        </Box>
      </Box>
    )
  }

  if (loadingHasBlocked || loadingIsBlocked) {
    return null
  }

  return (
    <Box className="w-full max-w-[200px]">
      <MessageUserButton
        user={user}
        disabled={hasBlockedUser?.is_blocked || isBlockedByUser}
        label={<FormattedMessage id="profile.message" />}
      />
    </Box>
  )
}
