"use client"

import { createContext, useContext } from "react"

import { theme } from "@bullseye/tailwind-config"

const CustomTheme = createContext({
  theme,
})

export const useTheme = () => {
  return useContext(CustomTheme)
}

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <CustomTheme.Provider
      value={{
        theme,
      }}
    >
      {children}
    </CustomTheme.Provider>
  )
}
