"use client"

import { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import {
  Box,
  Button,
  Sheet,
  TextInput,
  useAddAttendeesByExperience,
  useExperienceContext,
  useInvalidateExperienceAttendees,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

import { useToastContext } from "../../../providers"

export const AddAttendees = ({ isOpen, dismiss }: Modal) => {
  const { experience } = useExperienceContext()
  const { addByEmails } = useAddAttendeesByExperience(experience.id)
  const [isLoading, setIsLoading] = useState(false)
  const { openSuccessToast, openErrorToast } = useToastContext()
  const intl = useIntl()
  const invalidate = useInvalidateExperienceAttendees(experience.id)

  const schema = yup.object().shape({
    emails: yup.string().required(),
  })

  type Form = yup.InferType<typeof schema>

  const form = useForm<Form>({
    defaultValues: { emails: "" },
    mode: "onChange",
    resolver: yupResolver(schema),
  })

  async function submit({ emails }: Form) {
    try {
      setIsLoading(true)
      // remove whitespaces and any duplicate or invalid commas
      const formattedEmails = emails
        .replace(/\s/g, ",")
        .replace(/,{2,}/g, ",")
        .replace(/^,|,$/g, "")

      const data = await addByEmails(
        formattedEmails.split(",").map((email) => email.trim()),
      )
      if (data.created) {
        openSuccessToast({
          label: intl.formatMessage(
            { id: "activity.manageActivity.addAttendees.success" },
            {
              count: data.created,
            },
          ),
        })
        await invalidate()
        form.reset()
      }
      if (data.invalid) {
        openErrorToast({
          label: intl.formatMessage(
            { id: "activity.manageActivity.addAttendees.error" },
            {
              emails: data.invalid.join(", "),
            },
          ),
        })
      }
    } catch (error) {
      const err = error as Error
      openErrorToast({ label: err?.message })
    }
    dismiss()
    setIsLoading(false)
  }

  return (
    <Sheet
      title={<FormattedMessage id="activity.manageActivity.addAttendees" />}
      icon="x"
      size="lg"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <FormProvider {...form}>
            <Box className="g-4">
              <Controller
                name="emails"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextInput
                      value={value}
                      label="Emails"
                      onChangeText={onChange}
                      errorMessage={error?.message}
                      multiline
                      maxHeight={150}
                    />
                  )
                }}
              />
              <Box className="flex-row g-2">
                <Button
                  as="button"
                  variant="tertiary"
                  handlePress={() => dismiss()}
                  disabled={isLoading}
                  className="flex-shrink"
                >
                  <FormattedMessage id="general.dismiss" />
                </Button>
                <Button
                  as="button"
                  loading={isLoading}
                  handlePress={form.handleSubmit(submit)}
                  className="flex-shrink"
                >
                  <FormattedMessage id="general.confirm" />
                </Button>
              </Box>
            </Box>
          </FormProvider>
        )
      }}
    />
  )
}
