"use client"

import React, { PropsWithChildren } from "react"
import clsx from "clsx"
import { twMerge } from "tailwind-merge"

import { useIsSm } from "../../hooks"
import { isWeb } from "../../utils"
import { Box } from "../Layout"
import { PressableBox } from "../PressableBox"
import { Sheet } from "../Sheet"

type WrapperProps = PropsWithChildren<{
  isOpen: boolean
  close: () => void
  position: string
  title?: React.ReactNode
  sheetSize?: React.ComponentProps<typeof Sheet>["size"]
  dropdownClassname?: string
  sheetDragDisabled?: boolean
}>

function ModalVariant({
  sheetSize,
  isOpen,
  close,
  title,
  children,
  sheetDragDisabled,
}: WrapperProps) {
  return (
    <Sheet
      show={isOpen}
      renderContent={() => children}
      dismiss={close}
      title={title}
      icon="x"
      size={sheetSize}
      sheetDragDisabled={sheetDragDisabled}
    />
  )
}

const InlineVariant = ({
  isOpen,
  close,
  children,
  position,
  dropdownClassname: className,
}: WrapperProps) => {
  const dropdownClassname = twMerge(
    clsx(
      "z-10 bg-white rounded-lg shadow-md w-[360px] min-w-[360px] absolute py-4 px-0 border border-gray-50",
      {
        "top-10 left-0 right-auto mt-1": position === "bottom-right",
        "top-10 right-0 left-auto mt-1": position === "bottom-left",
        "bottom-10 left-0 right-auto mb-1": position === "top-right",
        "bottom-10 right-0 left-auto mb-1": position === "top-left",
      },
    ),
    className,
  )

  if (!isOpen) {
    return null
  }

  return (
    <>
      <PressableBox
        className="fixed inset-0"
        onPress={() => {
          close()
        }}
      />
      <Box className={dropdownClassname}>{children}</Box>
    </>
  )
}

export type DropdownProps = PropsWithChildren<{
  className?: string
  open?: boolean
  setOpen(state: boolean): void
  position?: "bottom-left" | "bottom-right" | "top-left" | "top-right" | "modal"
  disabled?: boolean
  toggleComponent?: React.ReactNode
  title?: React.ReactNode
  sheetSize?: WrapperProps["sheetSize"]
  dropdownClassname?: string
  containerTabIndex?: number
  sheetDragDisabled?: boolean
  onDismiss?(): void
  // deprecated
  id?: string
}>

export const Dropdown = ({
  className,
  open = false,
  setOpen,
  position = "bottom-right",
  disabled,
  toggleComponent,
  children,
  title,
  sheetSize,
  dropdownClassname,
  containerTabIndex,
  sheetDragDisabled = false,
  onDismiss,
}: DropdownProps) => {
  const isSm = useIsSm()

  const close = () => {
    onDismiss?.()
    setOpen(false)
  }

  const Wrapper = !isWeb() || isSm ? ModalVariant : InlineVariant

  return (
    <PressableBox
      className={twMerge(
        clsx(
          "relative flex justify-start g-3",
          {
            "cursor-not-allowed text-gray-110": disabled,
            "z-100": open,
          },
          className,
        ),
      )}
      aria-disabled={disabled}
      style={{ zIndex: 1 }}
      tabIndex={containerTabIndex}
    >
      {toggleComponent}
      <Wrapper
        isOpen={open}
        close={close}
        title={title}
        position={position}
        sheetSize={sheetSize}
        dropdownClassname={dropdownClassname}
        sheetDragDisabled={sheetDragDisabled}
      >
        {children}
      </Wrapper>
    </PressableBox>
  )
}

export default Dropdown
