"use client"

import { createContext, PropsWithChildren, useContext, useState } from "react"

import { dismissKeyboardAsync } from "@bullseye/components"

import {
  AddPaymentMethod,
  AddPaymentMethodModalProps,
} from "./AddPaymentMethod"
import { BlockUserModal, BlockUserModalProps } from "./BlockUser"
import {
  IncompleteFeatureModal,
  IncompleteFeatureModalProps,
} from "./IncompleteFeature"
import { LoadingStateModal, LoadingStateModalProps } from "./LoadingState"
import { ProfilePhotoModal, ProfilePhotoModalProps } from "./ProfilePhoto"
import { ReportModal, ReportModalProps } from "./ReportModal"
import { ShareModal, ShareModalProps } from "./Share"

export enum ApplicationModal {
  AddPaymentMethod = "AddPaymentMethod",
  BlockUser = "BlockUser",
  IncompleteFeature = "IncompleteFeature",
  LoadingState = "LoadingState",
  Report = "Report",
  Share = "Share",
  ProfilePhoto = "ProfilePhoto",
}

type OpenModalMethods =
  // AddPaymentMethod
  ((
    id: ApplicationModal.AddPaymentMethod,
    props: AddPaymentMethodModalProps,
  ) => void) &
    // BlockUser
    ((id: ApplicationModal.BlockUser, props: BlockUserModalProps) => void) &
    // Report
    ((id: ApplicationModal.Report, props: ReportModalProps) => void) &
    // LoadingState
    ((
      id: ApplicationModal.LoadingState,
      props: LoadingStateModalProps,
    ) => void) &
    // IncompleteFeature
    ((
      id: ApplicationModal.IncompleteFeature,
      props: IncompleteFeatureModalProps,
    ) => void) &
    // Share
    ((id: ApplicationModal.Share, props: ShareModalProps) => void) &
    // ProfilePhoto
    ((id: ApplicationModal.ProfilePhoto, props: ProfilePhotoModalProps) => void)

type ModalProps = AddPaymentMethodModalProps &
  BlockUserModalProps &
  IncompleteFeatureModalProps &
  LoadingStateModalProps &
  ReportModalProps &
  ShareModalProps &
  ProfilePhotoModalProps

const ApplicationModalContext = createContext<{
  openModal: OpenModalMethods
  closeModal: (modal: ApplicationModal) => void
} | null>(null)

export const useApplicationModalContext = () => {
  const ctx = useContext(ApplicationModalContext)
  if (ctx === null) {
    throw new Error(
      "useApplicationModalContext must be used within a ApplicationModalProvider",
    )
  }
  return ctx
}

export const ApplicationModalProvider = ({ children }: PropsWithChildren) => {
  const [openModals, setOpenModals] = useState<
    Record<ApplicationModal, boolean>
  >({
    AddPaymentMethod: false,
    BlockUser: false,
    IncompleteFeature: false,
    LoadingState: false,
    Report: false,
    Share: false,
    ProfilePhoto: false,
  })

  const [extraData, setExtraData] = useState<ModalProps | null>(null)

  function closeModal(id: string) {
    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: false }
    })
  }

  async function openModal(id: string, props?: unknown) {
    await dismissKeyboardAsync()
    if (props) {
      setExtraData(props as ModalProps)
    }

    setOpenModals((priorModals) => {
      return { ...priorModals, [id]: true }
    })
  }

  return (
    <ApplicationModalContext.Provider
      value={{
        closeModal,
        openModal,
      }}
    >
      {children}
      <AddPaymentMethod
        isOpen={openModals.AddPaymentMethod}
        dismiss={() => closeModal(ApplicationModal.AddPaymentMethod)}
        {...extraData}
      />
      <BlockUserModal
        isOpen={openModals.BlockUser}
        dismiss={() => closeModal(ApplicationModal.BlockUser)}
        {...extraData}
      />
      <ReportModal
        isOpen={openModals.Report}
        dismiss={() => closeModal(ApplicationModal.Report)}
        {...extraData}
      />
      <LoadingStateModal
        isOpen={openModals.LoadingState}
        dismiss={() => closeModal(ApplicationModal.LoadingState)}
        {...extraData}
      />
      <IncompleteFeatureModal
        isOpen={openModals.IncompleteFeature}
        dismiss={() => closeModal(ApplicationModal.IncompleteFeature)}
        {...extraData}
      />
      <ShareModal
        isOpen={openModals.Share}
        dismiss={() => closeModal(ApplicationModal.Share)}
        {...extraData}
      />
      <ProfilePhotoModal
        isOpen={openModals.ProfilePhoto}
        dismiss={() => closeModal(ApplicationModal.ProfilePhoto)}
        {...extraData}
      />
    </ApplicationModalContext.Provider>
  )
}

export * from "./UnauthedModals"
