"use client"

import { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"

import { PressableBox } from "../../PressableBox"

type AutocompleteItemProps<T> = PropsWithChildren<{
  item: T
  onPress: (item: T) => void
  className?: string
}>

export function AutocompleteItem<T>({
  item,
  onPress,
  className,
  children,
}: AutocompleteItemProps<T>) {
  return (
    <PressableBox
      onPress={() => {
        onPress(item)
      }}
      className={twMerge("cursor-pointer p-2 hover:bg-primary-50", className)}
    >
      {children}
    </PressableBox>
  )
}
