import { useEffect, useState } from "react"

export const useErrorBoundary = () => {
  const [err, setErr] = useState<Error | null>(null)
  useEffect(() => {
    if (err) throw new Error(err.message)
  }, [err])

  return {
    catchError: setErr,
  }
}
