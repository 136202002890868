import { useUserContext } from "../providers"
import { usePutRequest } from "./useRequest"

type RegisterDeviceRequest = {
  device_token: string
}

export const useRegisterDevice = () => {
  const { accessToken } = useUserContext()
  const {
    putRequest: registerDevice,
    putRequestAsync: registerDeviceAsync,
    ...rest
  } = usePutRequest<any, RegisterDeviceRequest>({
    path: "v1/notifications/register-device",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    ...rest,
    registerDevice,
    registerDeviceAsync,
  }
}
