"use client"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import { Box, Icon, P, PublicUser } from "@bullseye/components"

export function UserLabels({ user }: { user: PublicUser }) {
  const userLocation = [user.city, user.state].filter(Boolean).join(", ")

  return (
    <Box
      className="flex-row flex-wrap justify-center md:justify-start"
      style={{
        rowGap: 8,
      }}
    >
      <Box
        className={clsx("flex-row items-center pr-4 g-1", {
          hidden: !userLocation,
        })}
      >
        <Icon type="location-pin" />
        <P>{userLocation}</P>
      </Box>
      {user.gender && (
        <Box
          className={clsx("flex-row items-center pr-4 g-1", {
            hidden: !user.gender || !user.show_gender,
          })}
        >
          <Icon type="user-outline" />
          <P>
            <FormattedMessage id={`genders.${user.gender}`} />
          </P>
        </Box>
      )}
      <Box
        className={clsx("flex-row items-center pr-4 g-1", {
          hidden: !user.school_name,
        })}
      >
        <Icon type="graduation-cap" />
        <P>{user.school_name}</P>
      </Box>
      <Box
        className={clsx("flex-row items-center pr-4 g-1", {
          hidden: !user.job_title,
        })}
      >
        <Icon type="briefcase" />
        <P>{user.job_title}</P>
      </Box>
    </Box>
  )
}
