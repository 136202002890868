import { MessageChannel } from "@bullseye/types"

import { useUserContext } from "../providers"
import { usePostRequest } from "./useRequest"

type CreateMessageChannelRequest = {
  is_group_message: boolean
  users: { user_id: string }[]
}

export const useCreateMessageChannel = () => {
  const { accessToken } = useUserContext()
  const { postRequest, postRequestAsync, data, ...rest } = usePostRequest<
    { data: MessageChannel },
    CreateMessageChannelRequest
  >({
    path: `users/message-channels`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    createMessageChannel: postRequest,
    createMessageChannelAsync: postRequestAsync,
    data: data?.data,
    ...rest,
  }
}
