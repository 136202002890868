import React from "react"
import { twMerge } from "tailwind-merge"

import { Button, type ButtonProps } from "../Button"
import Icon, { type IconProps } from "../Icon"
import { Box } from "../Layout"
import { P } from "../Typography"

type Props = {
  icon?: IconProps["type"]
  header?: React.ReactNode
  description?: React.ReactNode
  buttonProps?: Partial<ButtonProps> & Pick<ButtonProps, "handlePress">
  className?: string
}

export const EmptyState = ({
  icon,
  header,
  description,
  buttonProps,
  className,
}: Props) => {
  return (
    <Box
      className={twMerge(
        "flex flex-col items-center gap-2 rounded-lg p-4",
        className,
      )}
      style={{ gap: 8 }}
    >
      {icon && <Icon type={icon} size="lg" />}
      {header && <P className="text-center font-semibold">{header}</P>}
      {description && <P>{description}</P>}
      {buttonProps && (
        <Box className="pt-2">
          <Button
            {...{
              as: "button",
              children: "Action",
              compact: true,
              ...buttonProps,
            }}
          />
        </Box>
      )}
    </Box>
  )
}
