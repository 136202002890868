type getPublicNameUserType = {
  formatted_name?: string
  first_name?: string
  last_name?: string
  name?: string
  is_suspended?: boolean
  banned?: boolean
}

export function getPublicName(user: getPublicNameUserType | undefined | null) {
  if (!user) {
    return ""
  }
  if (user.is_suspended || user.banned) {
    return "Hank Member"
  }
  const { formatted_name, first_name, last_name, name } = user
  let publicName = formatted_name
  if (!publicName) {
    if (first_name) {
      publicName = first_name + (last_name ? ` ${last_name[0]}` : "")
    } else if (name) {
      publicName = name
    } else {
      publicName = ""
    }
  }
  return ["Hank T", ""].includes(publicName) ? "Hank" : publicName
}

export function formatOrdinal(number: number) {
  switch (number) {
    case 11:
    case 12:
    case 13:
      return `${number}th`
  }

  const lastDigit = number % 10
  switch (lastDigit) {
    case 1:
      return `${number}st`
    case 2:
      return `${number}nd`
    case 3:
      return `${number}rd`
    default:
      return `${number}th`
  }
}
