export { default as AmbassadorIcon } from "./ambassador.svg"
export { default as ArrowLeftIcon } from "./arrow-left.svg"
export { default as ArrowRightIcon } from "./arrow-right.svg"
export { default as BellIcon } from "./bell.svg"
export { default as BellStrikethroughIcon } from "./bell-strikethrough.svg"
export { default as BriefcaseIcon } from "./briefcase.svg"
export { default as CalendarIcon } from "./calendar.svg"
export { default as CameraIcon } from "./camera.svg"
export { default as ChatIcon } from "./chat.svg"
export { default as CheckCircleIcon } from "./check-circle.svg"
export { default as CheckIcon } from "./check.svg"
export { default as ChevronDownIcon } from "./chevron-down.svg"
export { default as ChevronLeftIcon } from "./chevron-left.svg"
export { default as ChevronRightIcon } from "./chevron-right.svg"
export { default as ChevronUpIcon } from "./chevron-up.svg"
export { default as ClockIcon } from "./clock.svg"
export { default as DollarIcon } from "./dollar.svg"
export { default as DownloadIcon } from "./download.svg"
export { default as EditIcon } from "./edit.svg"
export { default as ExclamationIcon } from "./exclamation.svg"
export { default as ExploreIcon } from "./explore.svg"
export { default as ExternalLinkIcon } from "./external-link.svg"
export { default as FacebookIcon } from "./facebook.svg"
export { default as FacebookMessengerIcon } from "./facebook-messenger.svg"
export { default as FlagIcon } from "./flag.svg"
export { default as GearIcon } from "./gear.svg"
export { default as GlobeIcon } from "./globe.svg"
export { default as GraduationCapIcon } from "./graduation-cap.svg"
export { default as GridIcon } from "./grid.svg"
export { default as GroupIcon } from "./group.svg"
export { default as HeartIcon } from "./heart.svg"
export { default as HomeIcon } from "./home.svg"
export { default as ImageIcon } from "./image.svg"
export { default as InfoCircleIcon } from "./info-circle.svg"
export { default as LinkIcon } from "./link.svg"
export { default as LocationPinIcon } from "./location-pin.svg"
export { default as LockIcon } from "./lock.svg"
export { default as LogOutIcon } from "./log-out.svg"
export { default as MagnifyingGlassIcon } from "./magnifying-glass.svg"
export { default as MailIcon } from "./mail.svg"
export { default as MenuIcon } from "./menu.svg"
export { default as MinusIcon } from "./minus.svg"
export { default as MonitorIcon } from "./monitor.svg"
export { default as MoreIcon } from "./more.svg"
export { default as PhoneIcon } from "./phone.svg"
export { default as PlaceholderIcon } from "./placeholder.svg"
export { default as PlusIcon } from "./plus.svg"
export { default as QuestionCircleIcon } from "./question-circle.svg"
export { default as SendIcon } from "./send.svg"
export { default as ShareIcon } from "./share.svg"
export { default as ShieldIcon } from "./shield.svg"
export { default as ShieldOutlineIcon } from "./shield-outline.svg"
export { default as SlidersIcon } from "./sliders.svg"
export { default as TicketIcon } from "./ticket.svg"
export { default as UserOutlineIcon } from "./user-outline.svg"
export { default as XIcon } from "./x.svg"

export const iconTypes = [
  "ambassador",
  "arrow-left",
  "arrow-right",
  "bell",
  "bell-strikethrough",
  "briefcase",
  "calendar",
  "camera",
  "chat",
  "check",
  "check-circle",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "clock",
  "dollar",
  "download",
  "edit",
  "exclamation",
  "explore",
  "external-link",
  "facebook",
  "facebook-messenger",
  "flag",
  "gear",
  "globe",
  "graduation-cap",
  "grid",
  "group",
  "heart",
  "home",
  "image",
  "info-circle",
  "link",
  "location-pin",
  "lock",
  "log-out",
  "magnifying-glass",
  "mail",
  "menu",
  "minus",
  "monitor",
  "more",
  "phone",
  "placeholder",
  "plus",
  "question-circle",
  "send",
  "share",
  "shield",
  "shield-outline",
  "sliders",
  "ticket",
  "user-outline",
  "x",
] as const
