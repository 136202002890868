"use client"

import { useEffect, useState } from "react"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Box,
  Chip,
  CreditCard,
  Dropdown,
  IconButton,
  List,
  ListItem,
  Loading,
  P,
  PaymentMethod,
  useCreditCardInfo,
  useDefaultPaymentMethod,
  useDeletePaymentMethod,
  usePaymentMethods,
  useSetDefaultPaymentMethod,
} from "@bullseye/components"

function PaymentMethodRow({
  paymentMethod,
  index,
  isDefault,
}: {
  paymentMethod: PaymentMethod
  index: number
  isDefault: boolean
}) {
  const cc = useCreditCardInfo(paymentMethod)

  const [openDropdown, setOpenDropdown] = useState(false)
  const [isMutating, setIsMutating] = useState(false)
  // after the dropdown gets closed, we imperatively change the mutating state
  // so that the loading spinner stops showing only after the dropdown is closed
  useEffect(() => {
    if (!openDropdown && isMutating) {
      setTimeout(() => {
        setIsMutating(false)
      }, 10)
    }
  }, [openDropdown, setIsMutating])

  const { refetch } = usePaymentMethods()
  const { setDefaultPaymentMethod } = useSetDefaultPaymentMethod()
  const { refetch: refetchDefaultPM } = useDefaultPaymentMethod()
  const { deletePaymentMethod } = useDeletePaymentMethod(paymentMethod.id)

  const intl = useIntl()
  return (
    <Box
      className={clsx(
        "border-1 flex w-full flex-row justify-between border-b border-solid border-gray-100 py-4",
      )}
      style={{ zIndex: index }}
    >
      <Box className="flex-0 flex flex-row items-center justify-start g-2">
        <CreditCard
          brand={paymentMethod?.card?.brand}
          className="max-h-10 w-10"
        />
        <P>{cc.number} </P>
        <P className="text-gray-600">{cc.date}</P>
        {isDefault && (
          <Chip
            label={intl.formatMessage({ id: "account.payments.default" })}
            color="gray"
          />
        )}
      </Box>
      <Box className="flex justify-center">
        <Dropdown
          open={openDropdown}
          setOpen={setOpenDropdown}
          id={`edit-pm-${paymentMethod.id}`}
          title={<FormattedMessage id="activity.noShowPolicy.manage" />}
          toggleComponent={
            <IconButton
              color="bg-white"
              iconType="more"
              handlePress={() => setOpenDropdown(!openDropdown)}
            />
          }
        >
          {isMutating ? (
            <Loading />
          ) : (
            <List>
              <ListItem
                onPress={async () => {
                  setIsMutating(true)
                  await setDefaultPaymentMethod(paymentMethod.id)
                  await refetchDefaultPM()
                  setOpenDropdown(false)
                }}
              >
                <P>
                  <FormattedMessage id="account.payments.setDefault" />
                </P>
              </ListItem>
              <ListItem
                onPress={async () => {
                  setIsMutating(true)
                  await deletePaymentMethod()
                  await refetch()
                  setOpenDropdown(false)
                }}
              >
                <P className="text-red-600">
                  <FormattedMessage id="account.payments.delete" />
                </P>
              </ListItem>
            </List>
          )}
        </Dropdown>
      </Box>
    </Box>
  )
}

export const PaymentMethodsList = () => {
  const { paymentMethods = [], isLoading } = usePaymentMethods()
  const { data } = useDefaultPaymentMethod()

  if (isLoading) return <Loading />

  if (!paymentMethods.length) {
    return (
      <Box className="flex w-full items-center overflow-hidden rounded-lg bg-gray-10 p-4">
        <P>
          <FormattedMessage id="account.payments.noPaymentMethods" />
        </P>
      </Box>
    )
  }

  return (
    <Box className="border-1 z-[-1] flex w-full border-t border-solid border-gray-100">
      {paymentMethods.map((pm, i) => (
        <PaymentMethodRow
          paymentMethod={pm}
          key={pm.id}
          index={paymentMethods.length - i}
          isDefault={data?.id === pm.id}
        />
      ))}
    </Box>
  )
}
