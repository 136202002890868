"use client"

import clsx from "clsx"
import { Chat } from "stream-chat-react"

import {
  Box,
  useDeviceContext,
  useMessagingContext,
} from "@bullseye/components"
import { useSearchParams } from "@bullseye/navigation"

import { Props } from "."
import { MessengerModalProvider } from "../../components"

const MessengerLayout = ({ children }: Props) => {
  const { client } = useMessagingContext()
  const sp = useSearchParams()
  const channel = sp.get("channel")
  const { os } = useDeviceContext()
  return (
    <Chat client={client as any}>
      <MessengerModalProvider>
        <Box
          className={clsx(
            "z-[-1] flex h-screen w-screen flex-row p-0 md:h-[calc(100vh-56px)]",
            {
              "!h-[calc(100vh-75px)]": !!channel && os === "ios",
            },
          )}
        >
          {children}
        </Box>
      </MessengerModalProvider>
    </Chat>
  )
}

export default MessengerLayout
