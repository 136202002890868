"use client"

import { Box, P, PublicUser, TextButton, UserAvatar } from "@bullseye/components"

export const ActivityOrganizer = ({ user }: { user?: PublicUser }) => {
  if (!user) return null

  return (
    <Box className="flex flex-row items-center g-3">
      <UserAvatar user={user} size="md" />
      <Box className="flex-row">
        <P className="flex flex-row items-center justify-center">Hosted by </P>
        <TextButton as="a" href={`/p/${user.slug}`}>
          {user.formatted_name}
        </TextButton>
      </Box>
    </Box>
  )
}
