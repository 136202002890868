"use client"

import { useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H2,
  IconButton,
  isWeb,
  useUserContext,
} from "@bullseye/components"
import { POST_ACTIVITY_URI } from "@bullseye/routes"

import { AttendingActivityList, PostActivityButton } from "../../components"

export const AttendingPage = () => {
  const { user } = useUserContext()
  const [variant, setVariant] = useState<"upcoming" | "past">("upcoming")
  const [now] = useState(new Date())

  const filters = useMemo(() => {
    if (variant === "upcoming") {
      return {
        "start_date[gte]": now.toISOString(),
        limit: isWeb() ? "25" : "5",
        order_by: "start_date",
        direction: "asc",
      }
    }

    return {
      "start_date[lte]": now.toISOString(),
      "start_date[not]": "null",
      order_by: "start_date",
      direction: "desc",
      limit: isWeb() ? "25" : "5",
    }
  }, [variant])

  return (
    <>
      <Box className="flex w-full bg-gray-50 md:bg-white md:pb-4">
        <Box className="flex w-full items-center bg-white px-4 pb-4 pt-2 md:py-6">
          <Box className="w-full max-w-[1000px] bg-white g-6">
            <Box className="w-full flex-row items-center justify-between">
              <H2>
                <FormattedMessage id="attending.title" />
              </H2>
              <Box className="md:hidden">
                <IconButton
                  iconType="plus"
                  size="lg"
                  as="a"
                  href={POST_ACTIVITY_URI}
                />
              </Box>
              <Box className="hidden md:flex">
                <PostActivityButton />
              </Box>
            </Box>
            <Box className="flex flex-row g-2">
              <Button
                active={variant === "upcoming"}
                as="button"
                compact
                outlined
                rounded
                variant="secondary"
                handlePress={() => setVariant("upcoming")}
              >
                <FormattedMessage id="general.upcoming" />
              </Button>
              <Button
                active={variant === "past"}
                as="button"
                compact
                outlined
                rounded
                variant="secondary"
                handlePress={() => setVariant("past")}
              >
                <FormattedMessage id="general.past" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <AttendingActivityList
        user={user}
        emptyStateMessage={
          <FormattedMessage
            id={
              variant === "upcoming"
                ? "attending.upcoming-empty"
                : "attending.past-empty"
            }
          />
        }
        params={filters}
      />
    </>
  )
}
