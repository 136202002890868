"use client"

import { FormattedMessage } from "react-intl"

import { Box, Button, Experience, H4, P, SIGN_UP_URI } from "@bullseye/components"

import { UserLockup } from "../../../../components"

export const UnauthedHost = ({ experience }: { experience: Experience }) => {
  if (!experience.organizer) {
    let mailToURL = "mailto:hello@gethank.com"
    mailToURL += `?subject=${encodeURIComponent(
      `Question about ${experience.title}`,
    )}`
    return (
      <Box className="flex flex-col p-6 g-4">
        <H4>
          <FormattedMessage id="activity.host" />
        </H4>
        <Box className="flex-row items-center overflow-hidden rounded-lg bg-gray-10 p-4">
          <Box className="flex flex-1 items-start g-2">
            <P>
              <FormattedMessage id="activity.hostless" />
            </P>
          </Box>
        </Box>
        <Button as="a" href={mailToURL.toString()} variant="secondary">
          <FormattedMessage id="activity.contactHank" />
        </Button>
      </Box>
    )
  }

  return (
    <Box className="flex flex-col p-6 g-4">
      <H4>
        <FormattedMessage id="activity.host" />
      </H4>
      <Box className="flex flex-row items-center">
        <Box className="g-2">
          <UserLockup user={experience.organizer} />
        </Box>
      </Box>
      <Button as="a" href={SIGN_UP_URI} variant="secondary">
        <FormattedMessage id="activity.contactHost" />
      </Button>
    </Box>
  )
}
