"use client"

import { useRef, useState } from "react"
import { TextInput as RNTextInput } from "react-native"
import clsx from "clsx"

import { useIsSm } from "../../hooks"
import { isWeb } from "../../utils"
import { Dropdown, DropdownProps } from "../Dropdown"
import { Box } from "../Layout"
import Loading from "../Loading"
import { ScrollBox } from "../ScrollBox"
import { TextInput, TextInputProps } from "../TextInput"

type ItemRenderProps<T> = {
  item: T
  index: number
  dismiss: () => void
}

type ToggleItemProps = {
  open: () => void
}

type NoResultsRenderProps = {
  dismiss: () => void
}

type AutocompleteProps<T> = Omit<
  TextInputProps,
  "multiline" | "defaultHeight"
> & {
  uniqueModalId?: string
  data?: T[]
  renderItem: (props: ItemRenderProps<T>) => React.ReactNode
  position?: DropdownProps["position"]
  dropdownClassname?: DropdownProps["dropdownClassname"]
  toggleComponent?: (props: ToggleItemProps) => React.ReactNode
  renderNoResults?: (props: NoResultsRenderProps) => React.ReactNode
  isLoading?: boolean
  onDismiss?: () => void
  disabled?: boolean
}

type DropdownInnerProps<T> = Omit<
  AutocompleteProps<T>,
  "position" | "uniqueModalId"
> & {
  setIsOpen: (open: boolean) => void
}

function DropdownInner<T>({
  onChangeText,
  inputMode,
  returnKeyType,
  isLoading,
  data,
  renderItem,
  renderNoResults,
  setIsOpen,
  ...inputProps
}: DropdownInnerProps<T>) {
  const isSm = useIsSm()
  const shouldShowInput = !isWeb() || isSm

  function dismiss() {
    setIsOpen(false)
  }

  return (
    <Box>
      {shouldShowInput && (
        <TextInput
          {...inputProps}
          onChangeText={onChangeText}
          inputMode={inputMode}
          returnKeyType={returnKeyType}
          multiline={false}
          autoFocus
        />
      )}
      {inputProps?.value && (
        <Box
          className={clsx({
            hidden: !isLoading,
          })}
        >
          <Loading />
        </Box>
      )}
      <ScrollBox
        className={clsx({
          hidden: isLoading || !inputProps.value,
        })}
        keyboardShouldPersistTaps="always"
      >
        {data?.length
          ? data?.map((item: T, index: number) =>
              renderItem({
                item,
                index,
                dismiss,
              }),
            )
          : renderNoResults?.({ dismiss })}
      </ScrollBox>
    </Box>
  )
}

export function Autocomplete<T>({
  uniqueModalId,
  renderItem,
  data,
  onChangeText,
  inputMode,
  returnKeyType,
  dropdownClassname,
  toggleComponent,
  isLoading = false,
  renderNoResults = () => null,
  position,
  onDismiss,
  disabled,
  ...inputProps
}: AutocompleteProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const isSm = useIsSm()
  const ref = useRef<RNTextInput>(null)

  return (
    <Box className="relative z-[100]">
      <Dropdown
        open={isOpen}
        setOpen={(open) => {
          setIsOpen(open)
        }}
        id={uniqueModalId}
        title={inputProps.label || inputProps.placeholder}
        sheetSize="lg"
        position={position}
        dropdownClassname={dropdownClassname}
        containerTabIndex={-1}
        onDismiss={onDismiss}
        toggleComponent={
          toggleComponent ? (
            toggleComponent({ open: () => setIsOpen(true) })
          ) : (
            <TextInput
              {...inputProps}
              editable={!disabled}
              ref={ref}
              onFocus={() => {
                if (!isWeb() || isSm) {
                  ref.current?.blur()
                  setIsOpen(true)
                }
              }}
              caretHidden={!isWeb() || isSm}
              inputMode={inputMode}
              returnKeyType={returnKeyType}
              showSoftInputOnFocus={isWeb() && !isSm}
              onChangeText={(value: string) => {
                if (!isWeb()) return

                setIsOpen(true)
                onChangeText?.(value)
              }}
            />
          )
        }
      >
        {isOpen && (
          <DropdownInner<T>
            {...inputProps}
            onChangeText={onChangeText}
            inputMode={inputMode}
            returnKeyType={returnKeyType}
            isLoading={isLoading}
            data={data}
            renderItem={renderItem}
            renderNoResults={renderNoResults}
            setIsOpen={setIsOpen}
          />
        )}
      </Dropdown>
    </Box>
  )
}

export * from "./components"
