import React from "react"
import Svg, { Rect } from "react-native-svg"

export const Fallback = () => (
  <Svg viewBox="0 0 64 40" fill="none">
    <Rect width="64" height="40" rx="4" fill="#DBDBDB" />
    <Rect x="6" y="28" width="10" height="4" rx="2" fill="#878787" />
    <Rect x="20" y="28" width="10" height="4" rx="2" fill="#878787" />
    <Rect x="34" y="28" width="10" height="4" rx="2" fill="#878787" />
    <Rect x="48" y="28" width="10" height="4" rx="2" fill="#878787" />
    <Rect x="6" y="10" width="15" height="11" rx="2" fill="#fff" />
  </Svg>
)
