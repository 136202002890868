"use client"

import { FlatList, FlatListProps } from "react-native"
import clsx from "clsx"

import {
  ACTIVITIES_URI,
  Box,
  Divider,
  EmptyState,
  isWeb,
  Loading,
  useUserExperiences,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import { Experience, PublicUser } from "@bullseye/types"

import { ActivityCard, ActivityCardCompact } from "../Activity"

type Props = {
  user: PublicUser
  className?: string
  params?: Record<string, string>
  title?: React.ReactNode
  emptyStateMessage: React.ReactNode
  onScroll?: FlatListProps<Experience>["onScroll"]
  compact?: boolean
}

export const AttendingActivityList = ({
  user,
  emptyStateMessage,
  params,
  onScroll,
  compact = false,
}: Props) => {
  const router = useRouter()

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useUserExperiences(user, params)

  if (isLoading) {
    return (
      <Box className="flex h-full w-full items-center justify-center p-4">
        <Loading />
      </Box>
    )
  }
  if (!data?.pages?.[0].results?.length) {
    return (
      <EmptyState
        icon="calendar"
        buttonProps={{
          handlePress: () => {
            void router.push(ACTIVITIES_URI)
          },
          children: "Explore activities",
        }}
        description={emptyStateMessage}
      />
    )
  }

  const Card = compact ? ActivityCardCompact : ActivityCard
  const experiences = data?.pages?.flatMap((page) => page.results)
  return (
    <FlatList<Experience>
      data={experiences}
      renderItem={({ item: exp, index: idx }) => (
        <Box
          key={exp.id}
          className={clsx("flex w-full items-center", {
            "mt-2": idx === 0,
          })}
        >
          <Card
            data={exp}
            currentUserId={user.id}
            className={clsx("max-w-[1000px]", {
              "md:rounded-t-lg": idx === 0,
              "md:rounded-b-lg": idx === experiences.length - 1,
            })}
          />
          {idx < experiences.length - 1 && (
            <Box className="w-full max-w-[1000px] bg-white px-4">
              <Divider />
            </Box>
          )}
        </Box>
      )}
      onEndReached={() => {
        if (hasNextPage && !isFetchingNextPage) {
          return fetchNextPage()
        }
      }}
      ListFooterComponent={() => {
        if (hasNextPage && isFetchingNextPage) {
          return (
            <Box className="flex h-[100px] w-full items-center justify-center">
              <Loading />
            </Box>
          )
        }
        if (isWeb()) {
          return <Box className="w-full md:h-10 md:bg-gray-50" />
        }

        return null
      }}
      onScroll={onScroll}
    />
  )
}
