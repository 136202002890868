"use client"

import { useState } from "react"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Autocomplete,
  AutocompleteItem,
  Box,
  Icon,
  IconButton,
  Image,
  isWeb,
  P,
  useExperiences,
  useIsSm,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import { ACTIVITIES_URI } from "@bullseye/routes"
import { Experience } from "@bullseye/types"

export function ActivitySearch() {
  const [search, setSearch] = useState("")
  const [now] = useState(new Date())
  const { data, isLoading } = useExperiences(
    {
      limit: 5,
      "start_date[gte]": now.toISOString(),
      "start_date[not]": "null",
      canceled_at: "null",
      order_by: "start_date",
      "title[like]": search,
    },
    {
      enabled: !!search?.length,
    },
  )
  const router = useRouter()
  const intl = useIntl()
  const isSm = useIsSm()
  return (
    <Autocomplete<Experience>
      data={data?.pages?.[0].results ?? undefined}
      id="activity-search"
      uniqueModalId="activity-search"
      value={search}
      onChangeText={setSearch}
      position="bottom-left"
      isLoading={isLoading}
      toggleComponent={
        isSm || !isWeb()
          ? ({ open }) => (
              <IconButton
                iconType="magnifying-glass"
                size="lg"
                handlePress={() => {
                  open()
                }}
              />
            )
          : undefined
      }
      placeholder={intl.formatMessage({ id: "general.searchActivities" })}
      size={isSm ? "lg" : "sm"}
      className={clsx("w-full", {
        "md:w-[300px]": isWeb(),
      })}
      renderNoResults={() => (
        <Box className="m-4 rounded-lg bg-gray-10 p-4">
          <P>
            <FormattedMessage id="activity.list.empty-state.description" />
          </P>
        </Box>
      )}
      renderItem={({ item, dismiss }) => (
        <AutocompleteItem
          key={item.id}
          item={item}
          onPress={(item) => {
            router.push(`${ACTIVITIES_URI}/${item.slug}`)
            dismiss()
          }}
        >
          <Box className="flex flex-row g-3">
            <Box className="flex items-center">
              {item.cover_photo?.url ? (
                <Image
                  source={{ uri: item.cover_photo.url }}
                  alt={item.title}
                  className="h-12 w-12 rounded-lg"
                />
              ) : (
                <Box className="flex h-12 w-12 items-center justify-center rounded-lg border border-solid border-gray-100 bg-primary-100">
                  <Icon type="calendar" color="text-primary-900" size="lg" />
                </Box>
              )}
            </Box>
            <Box className="flex flex-1">
              <Box className="flex flex-row">
                <P>
                  {intl.formatDate(item.start_date, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </P>
                <P>{" • "}</P>
                <P>
                  {intl.formatTime(item.start_date, {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </P>
              </Box>
              <Box className="flex-1">
                <P bold>{item.title}</P>
              </Box>
              <Box>
                <P className="text-gray-600">{item.formatted_address_string}</P>
              </Box>
            </Box>
          </Box>
        </AutocompleteItem>
      )}
    />
  )
}
