"use client"

import { Pressable } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { useIntl } from "react-intl"

import {
  Box,
  Chip,
  dateFromNowInWords,
  isWeb,
  makeMessengerUri,
  P,
  useMessageChannelByStreamId,
} from "@bullseye/components"
import { useRouter, useSearchParams } from "@bullseye/navigation"

import { MessengerChannelIcon } from "../MessengerChannelIcon"

const Container = styled(Pressable, "flex flex-row px-4 py-2 g-2")

type Props = {
  channel?: { id: string }
  displayImage?: string
  displayTitle?: string
  unread?: number
  lastMessage?: {
    text?: string
    created_at?: string | number | Date
  }
}

export const MessengerChannelPreview = ({
  lastMessage,
  channel,
  unread = 0,
}: Props) => {
  const { messageChannel, isLoading } = useMessageChannelByStreamId(channel?.id)

  const intl = useIntl()
  const suspendedMemberName = intl.formatMessage({
    id: "general.suspendedMemberName",
  })
  const title =
    (messageChannel?.dm_user?.is_suspended
      ? suspendedMemberName
      : messageChannel?.pretty_title) || "Hank Member"
  const router = useRouter()
  const sp = useSearchParams()
  const active = sp.get("channel") === channel?.id
  return (
    <Container
      className={clsx("flex flex-row px-4 py-2 g-2", {
        "bg-primary-50": active,
      })}
      onPress={() => {
        if (isWeb()) {
          router.replace(makeMessengerUri(channel?.id))
          return
        }
        router.push(makeMessengerUri(channel?.id))
      }}
    >
      <MessengerChannelIcon
        isSuspended={messageChannel?.dm_user?.is_suspended}
        isExperience={!!messageChannel?.experience}
        isGroupChat={
          messageChannel?.is_group_message && !messageChannel?.experience
        }
        imageUrl={
          messageChannel?.experience?.cover_photo?.url ||
          messageChannel?.dm_user?.avatar?.url
        }
        title={title}
        isLoading={isLoading}
      />
      <Box
        className={clsx("flex-1", {
          hidden: isLoading,
        })}
      >
        <P bold>{title}</P>
        <Box
          className={clsx("flex-row g-1", {
            hidden: !lastMessage,
          })}
        >
          <P className="text-gray-500" ellipsis>
            {lastMessage?.text}
            {" • "}
            {dateFromNowInWords(lastMessage?.created_at as string)}
          </P>
        </Box>
      </Box>
      <Box
        className={clsx({
          hidden: !unread,
        })}
      >
        <Chip color="blue" label={unread > 9 ? "9+" : unread} />
      </Box>
    </Container>
  )
}
