"use client"

import { useEffect } from "react"

const useFocusEffect = (fn: VoidFunction) => {
  const hasFocus = document.hasFocus()
  useEffect(() => {
    if (hasFocus) fn()
  }, [hasFocus])
}

export default useFocusEffect
