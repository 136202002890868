"use client"

import { useState } from "react"
import { RefreshControl } from "react-native"
import { FormattedMessage, useIntl } from "react-intl"

import {
  ACTIVITIES_URI,
  Box,
  getColor,
  IconButton,
  TextButton,
  useExperienceContext,
  useExperienceUrl,
  User,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import {
  ActivityCoverPhoto,
  ActivityDescription,
  ActivityModalProvider,
  ApplicationModal,
  PageContent,
  ShareButton,
  useApplicationModalContext,
} from "../../components"
import { PageHeader } from "../../layouts"
import {
  ActivityAttendees,
  ActivityChat,
  ActivityCTA,
  ActivityInfo,
} from "./components"

type Props = {
  currentUser?: User
}

export const ActivityDetailsPage = ({ currentUser }: Props) => {
  const { experience, refetch, waitlistRefetch } = useExperienceContext()
  const { openModal } = useApplicationModalContext()
  const router = useRouter()

  const intl = useIntl()
  const url = useExperienceUrl(experience.slug)
  const message = intl.formatMessage(
    { id: "activity.share.message" },
    {
      title: experience.title,
      url,
    },
  )
  const subject = intl.formatMessage(
    { id: "activity.share.subject" },
    {
      title: experience.title,
    },
  )

  const [isRefreshing, setIsRefreshing] = useState(false)
  async function refresh() {
    try {
      setIsRefreshing(true)
      await Promise.all([refetch(), waitlistRefetch()])
    } catch {}
    setIsRefreshing(false)
  }

  return (
    <ActivityModalProvider>
      <PageHeader className="md:hidden">
        <IconButton
          iconType="arrow-left"
          variant="filled"
          size="lg"
          handlePress={() => {
            router.canGoBack?.() ? router.back() : router.push(ACTIVITIES_URI)
          }}
        />
        <ShareButton
          message={message}
          url={url}
          emailSubject={subject}
          buttonType="icon"
        />
      </PageHeader>
      <PageContent
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={refresh}
            tintColor={getColor("bg-primary-500")}
          />
        }
      >
        <Box className="flex w-full max-w-[1000px] flex-col pb-16 md:mt-6 md:px-[20px] md:pb-10 md:g-4">
          <ActivityCoverPhoto imageUrl={experience.cover_photo?.url} />
          <Box className="flex flex-col g-2 md:flex-row md:g-4">
            <Box className="flex shrink flex-grow flex-col g-2 md:g-4">
              <ActivityInfo experience={experience} />
              <ActivityCTA
                experience={experience}
                className="flex md:hidden"
                user={currentUser}
              />
              <ActivityDescription
                description={experience.description}
                categories={experience.categories}
              />
              <ActivityAttendees
                experience={experience}
                className="flex md:hidden"
              />
              <ActivityChat experience={experience} userId={currentUser?.id} />
            </Box>
            <Box className="flex shrink-0 grow-0 flex-col g-2 md:basis-[360px] md:g-4">
              <ActivityCTA
                experience={experience}
                user={currentUser}
                className="hidden md:flex"
              />
              <ActivityAttendees
                experience={experience}
                className="z-[-1] hidden md:flex"
              />
              <Box className="flex flex-row justify-center pt-2">
                <Box className="flex flex-row items-center g-2">
                  <TextButton
                    as="button"
                    startIconType="flag"
                    handlePress={() => {
                      openModal(ApplicationModal.Report, {
                        contentType: "experience",
                        contentId: experience.id,
                      })
                    }}
                    variant="tertiary"
                  >
                    <FormattedMessage id="general.report.activity" />
                  </TextButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PageContent>
    </ActivityModalProvider>
  )
}
