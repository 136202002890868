"use client"

import { Image } from "react-native"
import clsx from "clsx"
import initialize from "initials"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { Box } from "../Layout"
import { P, type PProps } from "../Typography"

type SizeType = "sm" | "md" | "lg" | "xl"

const sizeToTailwind: { [key in SizeType]: string } = {
  sm: "w-6 h-6",
  md: "w-8 h-8",
  lg: "w-10 h-10",
  xl: "w-12 h-12",
}

const textSizeMap: { [key in SizeType]: PProps["size"] } = {
  sm: "xs",
  md: "sm",
  lg: "md",
  xl: "lg",
}

const ImageStyled = styled(Image, "")

export type AvatarProps = {
  name: string
  imageUrl?: string
  size?: SizeType
  className?: string
  blurred?: boolean
  borderless?: boolean
}

export const Avatar = ({
  name,
  imageUrl,
  size = "md",
  className,
  blurred,
  borderless = false,
  ...rest
}: AvatarProps) => {
  const initials = initialize(name).toUpperCase()
  return (
    <Box
      className={twMerge(
        "relative aspect-square h-full w-full overflow-hidden rounded-full",
        sizeToTailwind[size],
        clsx({
          "border border-2 border-white": !borderless,
          "blur-sm": blurred,
        }),
        className,
      )}
      {...rest}
    >
      <ImageStyled
        source={{
          uri: imageUrl,
        }}
        resizeMode="cover"
        className="border-r- z-10 h-full w-full overflow-hidden rounded"
      />
      <Box className="justify z absolute bottom-0 top-0 flex h-full w-full items-center justify-center bg-primary-50">
        <P
          className={"fallback-text text-gray-950"}
          size={textSizeMap[size]}
          adjustsFontSizeToFit
        >
          {initials}
        </P>
      </Box>
    </Box>
  )
}
