"use client"

import React from "react"
import { Image } from "react-native"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  ACTIVITIES_URI,
  Box,
  Chip,
  Experience,
  formatCurrency,
  getPublicName,
  Link,
  P,
  presentDateTime,
  UserAvatar,
} from "@bullseye/components"

type Props = React.ComponentProps<typeof Box> & {
  data: Experience
  currentUserId?: string
  compact?: boolean
}

export const ActivityCardCompact = ({
  className,
  data,
  currentUserId,
}: Props) => {
  const thumbnailUrl =
    data.cover_photo?.url ?? data.event_category?.cover_photo?.url

  const pills: {
    color: React.ComponentProps<typeof Chip>["color"]
    label: string
  }[] = []
  if (currentUserId && data.organizer_id === currentUserId) {
    pills.push({ color: "blue", label: "Host" })
  }
  if (data.requester?.is_attending) {
    pills.push({ color: "green", label: "Going" })
  }
  if (data.canceled_at) {
    pills.push({ color: "red", label: "Canceled" })
  }

  const cityState = [data.city, data.state].filter(Boolean).join(", ")

  return (
    <Link href={`${ACTIVITIES_URI}/${data.slug}`} className="w-full">
      <Box
        className={twMerge(
          "flex w-full flex-row bg-white py-4 g-4 md:rounded-md",
          className,
        )}
      >
        <Box className="flex w-full flex-1 flex-row-reverse g-3 md:flex-row">
          <Box className="flex-column flex min-w-0 flex-shrink flex-grow g-1">
            <P className="text-primary-500" ellipsis>
              {presentDateTime(data?.start_date, "Coming soon")}
            </P>
            <P className="text-lg">{data.title}</P>
            {data.venue_type === "in_person" &&
              data.single_line_address_string && (
                <P className="text-gray-600" ellipsis>
                  {!currentUserId ? cityState : data.single_line_address_string}
                </P>
              )}
            {data.venue_type === "online" && (
              <P className="text-gray-600" ellipsis>
                <FormattedMessage id="activity.general.online" />
              </P>
            )}
            {data.organizer && !data.is_hostless && (
              <Box className="flex min-w-0 flex-row items-center g-2">
                <UserAvatar size="sm" user={data.organizer} />
                <P ellipsis>{getPublicName(data.organizer)}</P>
              </Box>
            )}
            {(data.ticket_price || false) && (
              <P>
                {formatCurrency(data.ticket_price)}
                {"/"}
                <FormattedMessage id="activity.general.person" />
              </P>
            )}
          </Box>
          {thumbnailUrl && (
            <Box>
              <Box className="border-px flex h-20 w-20 items-center justify-center rounded-md border border-gray-100">
                <Image
                  resizeMode="cover"
                  source={{ uri: thumbnailUrl ? thumbnailUrl : undefined }}
                  style={{ width: "100%", height: "100%", borderRadius: 6 }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Link>
  )
}
