"use client"

import { ReactNode } from "react"
import Animated, { FadeIn, FadeOut } from "react-native-reanimated"

type Props = {
  children: ReactNode
  fadeIn?: boolean
  fadeOut?: boolean
}

export const Fade = ({ children, fadeIn = false, fadeOut = false }: Props) => {
  return (
    <Animated.View
      entering={fadeIn ? FadeIn : undefined}
      exiting={fadeOut ? FadeOut : undefined}
    >
      {children}
    </Animated.View>
  )
}
