"use client"

import React, { Component, ErrorInfo, ReactNode } from "react"
import * as Sentry from "@sentry/nextjs"

import { ErrorScreen } from "../ErrorScreen"

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const formattedError = new Error(
      `Error: ${error.message}\n${errorInfo.componentStack}`,
    )
    Sentry.captureException(formattedError)
  }

  reset = () => {
    this.setState({ hasError: false })
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorScreen reset={this.reset} />
    }

    return this.props.children
  }
}
