"use client";

import React from "react";
import { IconButton } from '../IconButton';
import type { IconButtonProps } from '../IconButton';

const AddButton = (props: Omit<IconButtonProps, 'iconType' | 'size' | 'variant' | 'hoverColor' | 'borderColor' | 'active'>) => {
  return (
    <IconButton size="xl" iconType="plus" color="bg-primary-500 hover:bg-primary-700" iconColor="text-white" className="shadow-lg" {...props} />
  );
};

export default AddButton;
