"use client"

import { useEffect, useState } from "react"
import { Pressable } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Box,
  Button,
  Dropdown,
  Icon,
  Loading,
  P,
  ScrollBox,
  SelectProps,
  TextInput,
  useAutocompleteSearch,
  useCurrentUserCity,
  useGooglePlace,
  User,
  useUserLocation,
} from "@bullseye/components"

type Props = Omit<
  SelectProps,
  "id" | "onChange" | "options" | "defaultValue" | "defaultLabel"
> & {
  user?: User
  onChange: (location: Record<string, string>) => void
}

type LocationSearchProps = {
  onSelect: (placeId: string) => void
}

const StyledPressable = styled(Pressable)

function LocationSearch({ onSelect }: LocationSearchProps) {
  const [value, setValue] = useState("")
  const { data, isLoading: loadingPlaces } = useAutocompleteSearch(value)
  const { data: currentCity } = useCurrentUserCity()
  const intl = useIntl()

  return (
    <Box className="h-[50%] md:px-4">
      <Box className="pb-2">
        <TextInput
          value={value}
          onChangeText={setValue}
          placeholder={intl.formatMessage({
            id: "explore.filters.location.searchPlaceholder",
          })}
          autoFocus
          borderless
        />
      </Box>
      <StyledPressable
        onPress={() => {
          setValue("")
          onSelect(currentCity?.id )
        }}
        className={clsx("flex-row items-center py-3.5 g-1", {
          hidden: !!value || !currentCity,
        })}
      >
        <Icon type="location-pin" color="text-primary-500" />
        <P>
          <FormattedMessage
            id="explore.filters.location.currentLocation"
            values={{ currentLocation: currentCity?.shortFormattedAddress }}
          />
        </P>
      </StyledPressable>
      <Box
        className={clsx("py-4", {
          hidden: !value || data?.predictions?.length || !loadingPlaces,
        })}
      >
        <Loading size="small" />
      </Box>
      <ScrollBox
        className={clsx({
          hidden: !data?.predictions?.length,
        })}
        contentContainerStyle={{
          paddingVertical: 14,
          gap: 27,
        }}
      >
        {data?.predictions?.map((place) => (
          <Box key={place.place_id}>
            <StyledPressable
              onPress={() => {
                setValue("")
                onSelect(place.place_id)
              }}
              className="flex flex-row items-center g-1"
            >
              <Icon type="location-pin" color="text-primary-500" />
              <P>{place.description}</P>
            </StyledPressable>
          </Box>
        ))}
      </ScrollBox>
      <Box
        className={clsx("pt-3.5", {
          hidden: data?.predictions?.length || !value || loadingPlaces,
        })}
      >
        <P>
          <FormattedMessage id="explore.filters.location.noLocations" />
        </P>
      </Box>
    </Box>
  )
}

export const LocationFilter = ({ onChange, user }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [placeId, setPlaceId] = useState("")
  const { data: userLocation, setLocation } = useUserLocation()
  const { data: userHome } = useGooglePlace(
    user?.google_place_id ,
  )
  const { data: place } = useGooglePlace(placeId)

  useEffect(() => {
    if (!place) return
    void setLocation({
      latitude: place.location.latitude,
      longitude: place.location.longitude,
      formattedAddress: place.shortFormattedAddress,
    })
  }, [place])

  useEffect(() => {
    if (!userLocation) return
    onChange({
      "origin[lat]": `${userLocation.latitude}`,
      "origin[long]": `${userLocation.longitude}`,
    })
  }, [userLocation])

  return (
    <Dropdown
      id="explore-location-filter"
      open={isOpen}
      setOpen={(open) => setIsOpen(open)}
      sheetSize="lg"
      toggleComponent={
        <Button
          as="button"
          startIconType="location-pin"
          endIconType="chevron-down"
          handlePress={() => setIsOpen(!isOpen)}
          rounded
          outlined
          variant="secondary"
          active={
            isOpen ||
            (userHome &&
              userLocation &&
              userLocation?.formattedAddress !==
                userHome?.shortFormattedAddress)
          }
          compact
        >
          {userLocation?.formattedAddress || (
            <FormattedMessage id="filters.location.options.unknown" />
          )}
        </Button>
      }
      title={<FormattedMessage id="filters.location.options.unknown" />}
    >
      {isOpen && (
        <LocationSearch
          onSelect={(placeId) => {
            setPlaceId(placeId)
            setIsOpen(false)
          }}
        />
      )}
    </Dropdown>
  )
}
