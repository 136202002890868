"use client"

import React, { useEffect } from "react"
import { AppState, AppStateStatus, Platform } from "react-native"
import NetInfo from "@react-native-community/netinfo"
import {
  focusManager,
  onlineManager,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 2 } },
})

type HTTPProviderProps = {
  children: React.ReactNode
  config: {
    apiBase: string
  }
}

const defaultContext = {
  apiBase: "https://apollo-staging.gethank.com",
}

type HTTPContextType = {
  apiBase: string
}

export const HTTPContext = React.createContext<HTTPContextType>(defaultContext)

export const useHTTPContext = () => React.useContext(HTTPContext)

if (Platform.OS !== "web") {
  // When the file gets loaded, set an event listener
  // which can be used to determine if the user is online or offline
  onlineManager.setEventListener((setOnline) => {
    return NetInfo.addEventListener((state) => {
      setOnline(!!state.isConnected)
    })
  })
}

function onAppStateChange(status: AppStateStatus) {
  if (Platform.OS !== "web") {
    focusManager.setFocused(status === "active")
  }
}

export const HTTPProvider = ({ children, config }: HTTPProviderProps) => {
  useEffect(() => {
    const subscription = AppState.addEventListener("change", onAppStateChange)

    return () => subscription.remove()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <HTTPContext.Provider value={config}>{children}</HTTPContext.Provider>
    </QueryClientProvider>
  )
}
