import { useQueryClient } from "@tanstack/react-query"

import { useGetRequest, usePutRequest } from "./useRequest"

type NotificationChannels = {
  email: boolean
  in_app: boolean
  sms: boolean
  push: boolean
}

type GlobalSettingsResponse = {
  data: Array<{
    channels: NotificationChannels
    enabled: boolean
  }>
}

type GlobalSettingsQueryCache = {
  data: GlobalSettingsResponse | null
}

type Preferences = Array<{ enabled: boolean; type: string }>
type GlobalSettingsRequestBody = {
  preferences: Preferences
}

export const useGlobalSettings = (accessToken: string) => {
  const queryClient = useQueryClient()
  const { data, error, isLoading, key, refetch } =
    useGetRequest<GlobalSettingsResponse>({
      path: "v1/notifications/settings/global",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

  const { putRequestAsync: updateGlobalSettings } = usePutRequest<
    GlobalSettingsResponse,
    GlobalSettingsRequestBody
  >({
    path: "v1/notifications/settings/global",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onMutate: async (newData) => {
      if (!newData?.body?.preferences.length) {
        return
      }
      await queryClient.cancelQueries({ queryKey: key })
      const { data: settings } =
        queryClient.getQueryData<GlobalSettingsQueryCache>(key)!
      if (!settings?.data?.[0]) return
      const type = newData?.body?.preferences[0]?.type
      const enabled = newData?.body?.preferences[0]?.enabled
      settings.data[0].channels[type as keyof NotificationChannels] =
        Boolean(enabled)
      queryClient.setQueryData<GlobalSettingsQueryCache>(key, {
        data: settings,
      })
    },
  })

  const updateChannel = async (k: keyof NotificationChannels, v: boolean) => {
    await updateGlobalSettings(
      { body: { preferences: [{ enabled: v, type: k }] } },
      {
        onSuccess: (data) => {
          queryClient.setQueryData<GlobalSettingsQueryCache>(key, data)
        },
        onError: async () => {
          await refetch()
        },
      },
    )
  }

  return {
    data: data?.data[0] || null,
    isLoading,
    updateChannel,
    error,
  }
}

export type WorkflowSetting = {
  template_id: string
  trigger: string
  preference: {
    channels: Record<keyof NotificationChannels, boolean>
    enabled: boolean
  }
}

type WorkflowSettingsResponse = {
  data: Array<WorkflowSetting>
}

type WorkflowSettingsQueryCache = {
  data: WorkflowSettingsResponse | null
}

export const useWorkflowSettings = (accessToken: string) => {
  const { data, error, isLoading, key, refetch } =
    useGetRequest<WorkflowSettingsResponse>({
      path: "v1/notifications/settings/workflow",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

  const queryClient = useQueryClient()
  const {
    putRequestAsync: updateWorkflowSettings,
    isLoading: isUpdateLoading,
  } = usePutRequest<
    WorkflowSettingsResponse,
    {
      settings: Array<{
        template_id: string
        preference: { channel: { type: string; enabled: boolean } }
      }>
    }
  >({
    path: "v1/notifications/settings/workflow",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    onMutate: async (newData) => {
      // Optimistic update
      await queryClient.cancelQueries({ queryKey: key })
      const qd = queryClient.getQueryData<WorkflowSettingsQueryCache>(key)
      const cachedSettings = qd?.data?.data || []
      for (const newSetting of newData?.body?.settings ?? []) {
        const existing = cachedSettings.find(
          (s) => s.template_id === newSetting.template_id,
        )
        if (existing) {
          const { type, enabled } = newSetting.preference.channel
          existing.preference.channels[type as keyof NotificationChannels] =
            enabled
        }
      }

      if (cachedSettings.length > 0) {
        queryClient.setQueryData<WorkflowSettingsQueryCache>(key, {
          data: { data: cachedSettings },
        })
      }
    },
  })

  const updateTemplates = async (
    template_ids: string[],
    v: boolean,
    type: keyof NotificationChannels,
  ) => {
    const newSettings = []
    for (const template_id of template_ids) {
      newSettings.push({
        template_id,
        preference: { channel: { type: type, enabled: v } },
      })
    }

    await updateWorkflowSettings(
      { body: { settings: newSettings } },
      {
        onSuccess: (data) => {
          queryClient.setQueryData<WorkflowSettingsQueryCache>(key, data)
        },
        onError: async () => {
          await refetch()
        },
      },
    )
  }

  return {
    data: data?.data || null,
    error,
    isLoading: isLoading,
    isUpdateLoading,
    updateTemplates,
  }
}

export function triggerToTemplateIdMap(workflowData: WorkflowSetting[]) {
  return workflowData.reduce(
    (acc, workflow) => ({
      ...acc,
      [workflow.trigger]: workflow,
    }),
    {} as Record<string, WorkflowSetting>,
  )
}

export function areTriggersEnabled(
  triggers: string[],
  type: keyof NotificationChannels,
  templateIdMap: Record<string, WorkflowSetting>,
) {
  const templateIds: string[] = []
  let enabled = false
  for (const trigger of triggers) {
    const workflow = templateIdMap[trigger]
    if (workflow) {
      if (typeof workflow.preference.channels[type] !== "undefined") {
        templateIds.push(workflow.template_id)
        enabled ||= workflow.preference.channels[type]
      }
    }
  }
  return {
    templateIds,
    enabled,
  }
}
