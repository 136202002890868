"use client"

import { useState } from "react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Box,
  H2,
  IconButton,
  isWeb,
  LoadingFullScreen,
  ScrollBox,
  User,
  useUserByIdOrSlug,
  useUserContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { UserSettingsModal } from "../../components"
import {
  UserVerification,
  VerificationProps,
} from "../../components/UserVerification"
import { PageHeader } from "../../layouts"
import { UserAvatarLockup } from "./components/UserAvatarLockup"
import { UserDetails } from "./components/UserDetails"
import { UserSettingsLinks } from "./components/UserSettingsLinks"

type Props = {
  slug?: string
} & VerificationProps

const ProfileWithNav = ({ slug, openVerification }: Props) => {
  const isOwnProfile = slug === "me"
  const { accessToken } = useUserContext()
  const { user } = useUserByIdOrSlug(slug, accessToken)

  if (!user) {
    return <LoadingFullScreen />
  }

  if (isOwnProfile) {
    return (
      <>
        <UserAvatarLockup isSelf user={user} />
        <UserVerification openVerification={openVerification} />
        <UserSettingsLinks user={user as User} />
      </>
    )
  }

  return (
    <>
      <UserAvatarLockup isSelf={false} user={user} />
      <UserDetails user={user} />
    </>
  )
}

export const ProfilePage = (props: Props) => {
  const router = useRouter()
  const [modalOpen, setModalOpen] = useState(false)
  const { user: self, accessToken } = useUserContext()
  const { user } = useUserByIdOrSlug(props.slug, accessToken)

  if (props.slug && !user) {
    return <LoadingFullScreen />
  }

  const isSelf = self.id === user?.id

  return (
    <>
      <Box className="flex flex-col bg-white pb-6">
        <Box className="flex w-full justify-center bg-white px-4 md:hidden">
          {props.slug !== "me" && !!user?.id && (
            <PageHeader className="px-0">
              <IconButton
                iconType="arrow-left"
                variant="filled"
                size="lg"
                handlePress={() => router.back()}
              />
              {!isSelf && (
                <IconButton
                  iconType="more"
                  variant="filled"
                  size="lg"
                  handlePress={() => setModalOpen(true)}
                />
              )}
            </PageHeader>
          )}
          <Box className="flex h-14 justify-center">
            <H2>
              <FormattedMessage id="navigation.profile" />
            </H2>
          </Box>
        </Box>
      </Box>
      <ScrollBox contentContainerStyle={{ width: "100%" }}>
        <Box
          className={clsx("flex w-full max-w-[800px] self-center md:pt-6", {
            "pb-16": isWeb(),
          })}
        >
          <ProfileWithNav {...props} />
        </Box>
      </ScrollBox>
      <UserSettingsModal
        user={user}
        isOpen={modalOpen}
        dismiss={() => setModalOpen(false)}
      />
    </>
  )
}
