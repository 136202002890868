"use client"

import { useEffect, useState } from "react"
import { useCalendars } from "expo-localization"
import { roundToNearestMinutes } from "date-fns"
import { DateTime } from "luxon"
import { FormattedMessage } from "react-intl"

import {
  Loading,
  useExperienceContext,
  useLoggingContext,
  useMediaDuplicator,
} from "@bullseye/components"
import { MediaUpload } from "@bullseye/types"

import { useToastContext } from "../../providers"
import { ActivityCreatePage } from "../ActivityCreatePage"

export type EditPageProps = {
  scrollToTop?: VoidFunction
  isDuplicating?: boolean
}

export const ActivityEditPage = ({
  isDuplicating,
  scrollToTop,
}: EditPageProps) => {
  const { experience } = useExperienceContext()
  const [userCal] = useCalendars()
  const { duplicateMediaAsync } = useMediaDuplicator()
  const [isLoading, setIsLoading] = useState(true)
  const [coverPhoto, setCoverPhoto] = useState<MediaUpload | undefined>()
  const { openErrorToast } = useToastContext()
  const { captureException } = useLoggingContext()

  const timeShiftedForUser = DateTime.fromISO(experience.start_date)
    .setZone(experience.timezone || userCal.timeZone)
    .toFormat("yyyy-MM-dd'T'HH:mm:ss")

  const [duplicatingDate] = useState(
    roundToNearestMinutes(DateTime.local().toJSDate(), {
      nearestTo: 30,
      roundingMethod: "ceil",
    }),
  )

  useEffect(() => {
    setIsLoading(true)
    if (!experience.cover_photo?.url) {
      setIsLoading(false)
      return
    }

    if (!isDuplicating) {
      setCoverPhoto({
        image: {
          url: experience.cover_photo?.url,
        },
      } as MediaUpload)
      setIsLoading(false)
      return
    }

    if (coverPhoto) {
      setIsLoading(false)
      return
    }

    duplicateMediaAsync(experience)
      .then(({ data: { data: media } }) => {
        setCoverPhoto(media[0])
      })
      .catch((error) => {
        openErrorToast({
          label: <FormattedMessage id="errors.unknown" />,
        })
        captureException(new Error(`Error duplicating media: ${error}`))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [experience.cover_photo?.url])

  if (isLoading) return <Loading />

  return (
    <ActivityCreatePage
      scrollToTop={scrollToTop}
      experience={isDuplicating ? null : experience}
      defaultValues={{
        title: experience.title,
        description: experience.description ?? "",
        venue_type: experience.venue_type,
        url: experience.url ?? "",
        timezone: experience.timezone ?? userCal.timeZone,
        cover_photo: coverPhoto,
        startDate: isDuplicating
          ? duplicatingDate
          : new Date(timeShiftedForUser),
        startTime: isDuplicating
          ? duplicatingDate
          : new Date(timeShiftedForUser),
        is_hostless: `${experience.is_hostless}`,
        ticket_url: experience.ticket_url ?? "",
        max_attendees: experience?.max_attendees ?? 0,
        no_show_fee: experience?.no_show_fee ?? "",
        selectedPlace: {
          place: {
            id: experience.google_place_id ?? "",
            formattedPlaceName: experience.single_line_address_string ?? "",
          },
          google_place_id: experience.google_place_id,
          name: experience.single_line_address_string,
        },
      }}
    />
  )
}
