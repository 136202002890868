"use client"

import { ACTIVITIES_URI, Box, Experience, Link, P } from "@bullseye/components"

type Props = {
  activity: Experience
}

export const ActivityDetails = ({ activity }: Props) => {
  return (
    <Link href={`${ACTIVITIES_URI}/${activity.id}`}>
      <Box className="space-between flex h-[100px] flex-row justify-between bg-white p-5">
        <P size="lg">{activity.title}</P>
      </Box>
    </Link>
  )
}
