import React from "react"
import { ClipPath, Defs, G, Path, Svg } from "react-native-svg"

import { colors } from "@bullseye/tailwind-config"

import { ImageProps } from "./types"

type Props = ImageProps & {
  backgroundColor?: string
}

export const AlertImage = ({
  scale = 1,
  backgroundColor = colors.brand.orange[1],
  color = "white",
}: Props) => (
  <Svg width={scale * 40} height={scale * 40} fill="none">
    <G clipPath="url(#a)">
      <Path
        fill={backgroundColor}
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
      />
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M20 9v15M20 30v.5"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill={color} d="M0 0h40v40H0z" />
      </ClipPath>
    </Defs>
  </Svg>
)
