import { useQuery } from "@tanstack/react-query"
import { StreamChat } from "stream-chat"

import { isWeb } from "../../../utils/device"
import { useUserContext } from "../../UserAuthProvider/UserProvider"

export const useUnreadCount = (client?: StreamChat<any>) => {
  const { user } = useUserContext()

  const { data, isLoading, error } = useQuery(
    ["messengerUnreadCount"],
    () => {
      return client?.getUnreadCount(user.stream_token)
    },
    {
      enabled: client && !!user.stream_token,
      refetchInterval: isWeb() ? 0 : 60 * 1000,
    },
  )

  return {
    unreadCount: data?.total_unread_count,
    isLoading,
    error,
  }
}
