"use client"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import { Box, Button, Icon, P } from "@bullseye/components"

import { MessengerModal, useMessengerModalContext } from "../../components"

export function NoChannelSelected({ hidden }: { hidden: boolean }) {
  const { openModal } = useMessengerModalContext()
  return (
    <Box
      className={clsx(
        "hidden w-[70%] flex-1 items-center justify-center g-4 md:flex",
        {
          "md:hidden": hidden,
        },
      )}
    >
      <Box className="overflow-hidden rounded-full bg-gray-50 p-4">
        <Icon type="chat" size="xxl" className="text-black" />
      </Box>
      <P bold>
        <FormattedMessage id="messenger.newChat.instructions" />
      </P>
      <Box className="inline">
        <Button
          as="button"
          handlePress={() => openModal(MessengerModal.NewMessage)}
        >
          <FormattedMessage id="messenger.sendMessage" />
        </Button>
      </Box>
    </Box>
  )
}
