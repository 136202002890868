"use client"

import { FormattedMessage } from "react-intl"

import {
  ACTIVITIES_URI,
  Box,
  Button,
  H3,
  HELP_CENTER_URL,
  HOME_URI,
  isWeb,
  LocalizationProvider,
  NotFoundImage,
  P,
  TextButton,
} from "@bullseye/components"

import { SecurityLayout } from "../../layouts/SecurityLayout"

export const NotFoundPage = () => {
  return (
    <LocalizationProvider>
      <SecurityLayout>
        <Box className="h-full w-full">
          <Box className="flex items-center py-10 g-1">
            <NotFoundImage />
            <Box className="pb-2 pt-1">
              <H3>
                <FormattedMessage id="notFound.title" />
              </H3>
            </Box>
            <Box className="max-w-[350px]">
              <P className="text-center">
                <FormattedMessage id="notFound.description" />
              </P>
            </Box>
            <Box className="flex w-full max-w-[250px] items-center justify-start pt-2 g-2">
              <Button as="a" href={ACTIVITIES_URI}>
                <FormattedMessage id="notFound.viewActivities" />
              </Button>
              <TextButton as="a" href={HELP_CENTER_URL}>
                <FormattedMessage id="navigation.help-center" />
              </TextButton>
              {isWeb() && (
                <TextButton as="a" href={HOME_URI}>
                  <FormattedMessage id="notFound.home" />
                </TextButton>
              )}
            </Box>
          </Box>
        </Box>
      </SecurityLayout>
    </LocalizationProvider>
  )
}
