import clsx from "clsx"
import { twMerge } from "tailwind-merge"

import { Avatar, AvatarProps } from "../Avatar"
import { Box } from "../Layout"

type Props = {
  avatars: Omit<AvatarProps, "size" | "className" | "blurred">[]
  max?: number
  size?: AvatarProps["size"]
  blurred?: AvatarProps["blurred"]
  className?: string
}

const SizeMap: { [key in Required<AvatarProps>["size"]]: string } = {
  sm: "-ml-2",
  md: "-ml-2",
  lg: "-ml-3",
  xl: "-ml-4",
}

export const AvatarGroup = ({
  avatars,
  max = 5,
  size = "lg",
  blurred,
  className,
}: Props) => {
  return (
    <Box className={twMerge("flex flex-row", className)}>
      {avatars.slice(0, max).map((a, idx) => (
        <Avatar
          key={idx}
          {...a}
          size={size}
          blurred={blurred}
          className={clsx({
            [SizeMap[size]]: idx > 0,
          })}
        />
      ))}
    </Box>
  )
}
