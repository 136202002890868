import { twMerge } from "tailwind-merge"

import { colors } from "@bullseye/tailwind-config"

import { useAsyncStorage } from "../../hooks/useAsyncStorage"
import { Gradient } from "../Gradient"
import { IconButton } from "../IconButton"
import { Box } from "../Layout"

type Props = {
  uniqueId: string
  nondismissable?: boolean
  children: React.ReactNode
  variant?: "solid" | "gradient"
  cacheKeyExpiresInMs?: number
  className?: string
}

export const Banner = ({
  variant = "solid",
  uniqueId,
  nondismissable,
  children,
  cacheKeyExpiresInMs,
  className,
}: Props) => {
  const { data, isLoading, setValue, error } = useAsyncStorage(uniqueId)

  if (data === "viewed" || isLoading || error) return null

  return (
    <Gradient
      colors={
        variant === "solid"
          ? [colors.brand.indigo[1], colors.brand.indigo[1]]
          : undefined
      }
      className={twMerge(
        "flex items-start px-4 py-3 md:items-center",
        className,
      )}
    >
      {!nondismissable && <Box className="hidden h-8 w-8 md:flex" />}
      <Box className="flex-1">{children}</Box>
      {!nondismissable && (
        <Box className="flex items-start">
          <IconButton
            iconType="x"
            handlePress={() => {
              const metadata = cacheKeyExpiresInMs
                ? {
                    expiresInMs: cacheKeyExpiresInMs,
                  }
                : undefined

              void setValue("viewed", metadata)
            }}
            color="bg-transparent"
            iconColor="text-white"
          />
        </Box>
      )}
    </Gradient>
  )
}
