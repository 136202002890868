"use client"

import React from "react"
import { GestureResponderEvent, Pressable } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import type { IconProps } from ".."
import { useLinkOrButtonPress } from "../../hooks"
import { Icon } from "../Icon"
import { Box } from "../Layout"

export type IconButtonProps = {
  as?: "button" | "a"
  iconType: IconProps["type"]
  variant?: "filled" | "plain"
  size?: "sm" | "md" | "lg" | "xl"
  disabled?: boolean
  active?: boolean
  name?: string
  handlePress?: (e: GestureResponderEvent) => void
  href?: string
  className?: string
  color?: string
  hoverColor?: string
  iconColor?: string
  borderColor?: string
  testID?: string
}

const StyledButton = styled(Pressable)

const containerSize = {
  sm: "h-6 w-6",
  md: "h-8 w-8",
  lg: "h-10 w-10",
  xl: "h-14 w-14",
}

const iconSize = {
  sm: "sm",
  md: "md",
  lg: "md",
  xl: "lg",
}

const iconSizePlain = {
  sm: "sm",
  md: "lg",
  lg: "xl",
  xl: "xxl",
}

const containerBase =
  "border cursor-pointer border-transparent rounded-full items-center justify-center aria-disabled:bg-gray-10 aria-disabled:cursor-not-allowed"

export const IconButton = ({
  iconType,
  variant = "filled",
  size = "md",
  color = "bg-gray-10",
  hoverColor = "hover:bg-gray-10",
  iconColor = "text-gray-950",
  borderColor = "border-primary-500",
  disabled = false,
  handlePress,
  className,
  active,
  as = "button",
  testID,
  ...props
}: IconButtonProps) => {
  const onPress = useLinkOrButtonPress({
    as: as,
    href: props.href,
    onPress: handlePress,
  })

  if (disabled && props.hasOwnProperty("href")) {
    Object.assign(props, { href: null })
  }

  const containerVariants = {
    filled: color,
    plain: hoverColor ?? color,
  }

  return (
    <StyledButton
      testID={testID}
      className={twMerge(
        containerBase,
        clsx({
          [borderColor]: active,
          [hoverColor]: variant === "plain",
        }),
        containerVariants[variant],
        containerSize[size],
        className,
      )}
      onPress={onPress}
      disabled={disabled}
      role={as === "button" ? "button" : "link"}
      href={props.href}
      {...props}
    >
      <Box
        className={twMerge(
          variant === "filled" ? iconSize[size] : iconSizePlain[size],
        )}
      >
        <Icon
          type={iconType}
          size={
            (variant === "filled"
              ? iconSize[size]
              : iconSizePlain[size]) as IconProps["size"]
          }
          color={iconColor}
        />
      </Box>
    </StyledButton>
  )
}
