"use client"

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { Camera } from "expo-camera"
import * as ImagePicker from "expo-image-picker"
import { FormattedMessage } from "react-intl"

import {
  Button,
  ErrMediaInappropriate,
  ErrMediaInvalidFormat,
  ErrMediaMultipleFaces,
  ErrMediaNoFaces,
  ErrMediaTooLarge,
  useCheckCameraPermissions,
  useMediaUploader,
  useRequestCameraPermissions,
  useSetAvatar,
  useUserContext,
} from "@bullseye/components"
import { MediaUpload } from "@bullseye/types"

import { OnboardingStep } from "../../OnboardingStep"
import { PhotoError } from "../assets/PhotoError"

type Props = {
  children: ReactNode
  onComplete: () => void
}

export enum CameraUploadError {
  CameraPermissionDenied = "CAMERA_PERMISSION_DENIED",
  MediaTooLarge = "MEDIA_TOO_LARGE",
  MediaInappropriate = "MEDIA_INAPPROPRIATE",
  MediaNoFaces = "MEDIA_NO_FACES",
  MediaMultipleFaces = "MEDIA_MULTIPLE_FACES",
  MediaGeneralError = "MEDIA_GENERAL_ERROR",
  MediaInvalidFormat = "MEDIA_INVALID_FORMAT",
}

type ProfilePhotoCtx = {
  showError: (error: CameraUploadError) => void
  showCamera: boolean
  setShowCamera: (show: boolean) => void
  capturedPhoto: MediaUpload | null
  selectImageFromLibrary: () => Promise<string | undefined>
  initiateCamera: () => Promise<void>
  takePhoto: (camera: Camera) => Promise<void>
  setAvatar: () => Promise<void>
  startOver: VoidFunction
  uploadingStatus: "error" | "idle" | "loading" | "success"
}

const ProfilePhotoContext = createContext<ProfilePhotoCtx | null>(null)

export const useProfilePhotoCtx = () => {
  const ctx = useContext(ProfilePhotoContext)
  if (!ctx) {
    throw new Error(
      "useProfilePhotoCtx must be used within a ProfilePhotoContextProvider",
    )
  }
  return ctx
}

export const ProfilePhotoContextProvider = ({
  onComplete,
  children,
}: Props) => {
  const { refetch: refetchUser } = useUserContext()
  const [showCamera, setShowCamera] = useState(false)
  const [capturedPhoto, setCapturedPhoto] = useState<MediaUpload | null>(null)

  const [displayError, setError] = useState<CameraUploadError | null>(null)

  const [title, setTitle] = useState<ReactNode>(null)
  const [description, setDescription] = useState<ReactNode>(null)

  const { requestCameraPermissionsAsync } = useRequestCameraPermissions()
  const { data: permissions } = useCheckCameraPermissions()

  const { uploadMediaAsync, status } = useMediaUploader({
    mediaPurpose: "avatar",
  })

  const { setAvatarAsync } = useSetAvatar()

  function startOver() {
    setShowCamera(false)
    setCapturedPhoto(null)
    setError(null)
    setTitle(null)
    setDescription(null)
  }
  async function setAvatar() {
    if (!capturedPhoto) return
    await setAvatarAsync(capturedPhoto.file_name)
    await refetchUser()
    onComplete()
  }

  const showError = (error: CameraUploadError) => {
    if (displayError) return

    switch (error) {
      case CameraUploadError.CameraPermissionDenied:
        setTitle(
          <FormattedMessage id="photoUpload.errors.cameraPermissionsDenied.title" />,
        )
        setDescription(
          <FormattedMessage id="photoUpload.errors.cameraPermissionsDenied.description" />,
        )
        break
      case CameraUploadError.MediaTooLarge:
        setTitle(
          <FormattedMessage id="photoUpload.errors.mediaTooLarge.title" />,
        )
        setDescription(
          <FormattedMessage id="photoUpload.errors.mediaTooLarge.description" />,
        )
        break
      case CameraUploadError.MediaInappropriate:
        setTitle(
          <FormattedMessage id="photoUpload.errors.mediaInappropriate.title" />,
        )
        setDescription(
          <FormattedMessage id="photoUpload.errors.mediaInappropriate.description" />,
        )
        break
      case CameraUploadError.MediaNoFaces:
        setTitle(
          <FormattedMessage id="photoUpload.errors.mediaNoFaces.title" />,
        )
        setDescription(
          <FormattedMessage id="photoUpload.errors.mediaNoFaces.description" />,
        )
        break
      case CameraUploadError.MediaMultipleFaces:
        setTitle(
          <FormattedMessage id="photoUpload.errors.mediaMultipleFaces.title" />,
        )
        setDescription(
          <FormattedMessage id="photoUpload.errors.mediaMultipleFaces.description" />,
        )
        break
      case CameraUploadError.MediaInvalidFormat:
        setTitle(
          <FormattedMessage id="photoUpload.errors.mediaInvalidFormat.title" />,
        )
        setDescription(
          <FormattedMessage id="photoUpload.errors.mediaInvalidFormat.description" />,
        )
        break
      default:
        setTitle(<FormattedMessage id="photoUpload.errors.mediaError.title" />)
        setDescription(
          <FormattedMessage id="photoUpload.errors.mediaError.description" />,
        )
        break
    }
    setError(error)
  }

  function handleMediaUpload(media: ImagePicker.ImagePickerAsset) {
    return uploadMediaAsync(media).catch((err: any) => {
      switch (err) {
        case ErrMediaTooLarge:
          showError(CameraUploadError.MediaTooLarge)
          return
        case ErrMediaInappropriate:
          showError(CameraUploadError.MediaInappropriate)
          return
        case ErrMediaNoFaces:
          showError(CameraUploadError.MediaNoFaces)
          return
        case ErrMediaMultipleFaces:
          showError(CameraUploadError.MediaMultipleFaces)
          return
        case ErrMediaInvalidFormat:
          showError(CameraUploadError.MediaInvalidFormat)
          return
        default:
          showError(CameraUploadError.MediaGeneralError)
          return
      }
    })
  }

  async function selectImageFromLibrary() {
    try {
      const selection = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.8,
      })
      if (selection.canceled || !selection.assets?.length) return

      const [image] = selection.assets
      if (!image) {
        setError(CameraUploadError.MediaGeneralError)
        return
      }

      const file = await handleMediaUpload(image)
      if (!file) {
        setError(CameraUploadError.MediaGeneralError)

        return
      }

      setCapturedPhoto(file)
      return file.image.url || ""
    } catch {
      setError(CameraUploadError.MediaGeneralError)
    }
  }

  async function initiateCamera() {
    try {
      const { status } = await requestCameraPermissionsAsync()
      if (status !== "granted") {
        showError(CameraUploadError.CameraPermissionDenied)
        return
      }
      if (status === "granted") {
        setShowCamera(true)
      }
    } catch {
      setError(CameraUploadError.CameraPermissionDenied)
    }
  }

  async function takePhoto(camera: Camera) {
    const photo = await camera.takePictureAsync()
    const avatar = await handleMediaUpload(photo)
    setCapturedPhoto(avatar as MediaUpload)
  }

  useEffect(() => {
    if (!permissions?.status) return
    if (permissions.status === "denied") {
      showError(CameraUploadError.CameraPermissionDenied)
    }
  }, [permissions?.status])

  if (displayError) {
    return (
      <OnboardingStep
        imageSource={{ uri: PhotoError }}
        imageAlt="Camera Error"
        title={title}
        description={description}
        primaryAction={
          <Button
            as="button"
            handlePress={() => {
              setShowCamera(false)
              setCapturedPhoto(null)
              setError(null)
            }}
            height="lg"
          >
            <FormattedMessage id="photoUpload.errors.mediaError.retry" />
          </Button>
        }
      />
    )
  }

  return (
    <ProfilePhotoContext.Provider
      value={{
        showError,
        showCamera,
        setShowCamera,
        capturedPhoto,
        selectImageFromLibrary,
        initiateCamera,
        takePhoto,
        setAvatar,
        startOver,
        uploadingStatus: status,
      }}
    >
      {children}
    </ProfilePhotoContext.Provider>
  )
}
