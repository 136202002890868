"use client"

import React from "react"
import clsx from "clsx"

import { Box } from "../Layout"

type Props = React.ComponentProps<typeof Box>

export const CardContainer = ({ className, ...props }: Props) => {
  return (
    <Box
      {...props}
      className={clsx(
        "text-md",
        "flex-column flex w-full bg-gray-50 g-2 md:g-4",
        className,
      )}
    />
  )
}
