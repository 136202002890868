"use client"

import { PropsWithChildren, ReactElement } from "react"
import { RefreshControlProps } from "react-native"
import clsx from "clsx"
import { StyledProps } from "nativewind"

import { isWeb, ScrollBox } from "@bullseye/components"

type Props = StyledProps<
  PropsWithChildren<{ refreshControl?: ReactElement<RefreshControlProps> }>
>

export const PageContent = ({ children, className, refreshControl }: Props) => (
  <ScrollBox
    className={clsx("flex w-full flex-1 bg-gray-50", className, {
      "md:items-center": isWeb(),
    })}
    contentContainerStyle={{
      width: "100%",
      alignItems: "center",
    }}
    refreshControl={refreshControl}
  >
    {children}
  </ScrollBox>
)
