"use client"

import React from "react"
import { BR } from "@expo/html-elements"
import SimpleMarkdown, {
  ParserRules,
  SingleASTNode,
} from "@khanacademy/simple-markdown"
import { styled, StyledProps } from "nativewind"
import { twMerge } from "tailwind-merge"

import { HR } from "../HR"
import { Box } from "../Layout"
import { UL } from "../List"
import { A, EM, H4, P, Span, Strong } from "../Typography"

const rules: typeof SimpleMarkdown.defaultRules = {
  Array: {
    ...SimpleMarkdown.defaultRules.Array,
  },
  heading: {
    ...SimpleMarkdown.defaultRules.heading,
    react: (node, output, state) => (
      // only H4 allowed not to break the layout
      <H4 className="block" key={state.key}>
        {output(node.content, state)}
      </H4>
    ),
  },
  lheading: {
    ...SimpleMarkdown.defaultRules.lheading,
  },
  hr: {
    ...SimpleMarkdown.defaultRules.hr,
    react: (node, output, state) => <HR key={state.key} />,
  },
  list: {
    ...SimpleMarkdown.defaultRules.list,
    react: (node, output, state) => {
      return (
        <Box key={state.key}>
          <UL ordered={node.ordered}>
            {node.items.map((item: SingleASTNode[], i: number) => {
              const nestedState = { ...state, key: `${state.key}-${i}-n` }
              return (
                <P key={`${state.key}-${i}`}>{output(item, nestedState)}</P>
              )
            })}
          </UL>
        </Box>
      )
    },
  },
  def: {
    ...SimpleMarkdown.defaultRules.def,
  },
  newline: {
    ...SimpleMarkdown.defaultRules.newline,

    // @ts-ignore
    react: (node, output, state) => <BR key={state.key} />,
  },
  paragraph: {
    ...SimpleMarkdown.defaultRules.paragraph,
    react: (node, output, state) => (
      <P key={state.key} className="block">
        {output(node.content, state)}
      </P>
    ),
  },
  escape: {
    ...SimpleMarkdown.defaultRules.escape,
  },
  autolink: {
    ...SimpleMarkdown.defaultRules.autolink,
  },
  mailto: {
    ...SimpleMarkdown.defaultRules.mailto,
  },
  url: {
    ...SimpleMarkdown.defaultRules.url,
  },
  link: {
    ...SimpleMarkdown.defaultRules.link,

    react: (node, output, state) => (
      <A
        key={state.key}
        target="__blank"
        href={node.target}
        className="hover:underlined text-primary-500"
      >
        {output(node.content, state)}
      </A>
    ),
  },
  reflink: {
    ...SimpleMarkdown.defaultRules.reflink,
  },
  em: {
    ...SimpleMarkdown.defaultRules.em,
    react: (node, output, state) => (
      <EM key={state.key}>{output(node.content, state)}</EM>
    ),
  },
  strong: {
    ...SimpleMarkdown.defaultRules.strong,
    react: (node, output, state) => {
      return <Strong key={state.key}>{output(node.content, state)}</Strong>
    },
  },
  u: {
    ...SimpleMarkdown.defaultRules.u,
    react: (node, output, state) => (
      <EM key={state.key} className="underlined">
        {output(node.content, state)}
      </EM>
    ),
  },
  del: {
    ...SimpleMarkdown.defaultRules.del,
    react: (node, output, state) => (
      <Span key={state.key} className="line-through">
        {output(node.content, state)}
      </Span>
    ),
  },
  br: {
    ...SimpleMarkdown.defaultRules.br,
    react: (node, output, state) => <BR key={state.key} />,
  },
  text: {
    ...SimpleMarkdown.defaultRules.text,
  },
}

const parser = SimpleMarkdown.parserFor(rules as unknown as ParserRules)
const reactOutput = SimpleMarkdown.outputFor(rules, "react")

const blockParseAndOutput = function (source: string) {
  // Many rules require content to end in \n\n to be interpreted
  // as a block.
  const blockSource = source + "\n\n"
  const parseTree = parser(blockSource, { inline: false })
  const outputResult = reactOutput(parseTree)

  return outputResult as React.ReactNode
}

type Props = StyledProps<{
  content: string
}>

export const Markdown = styled(({ content, className, ...props }: Props) => {
  return (
    <Box className={twMerge("g-6", className)} {...props}>
      {blockParseAndOutput(content)}
    </Box>
  )
})
