"use client"

import { forwardRef } from "react"
import { LinearGradient } from "expo-linear-gradient"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

type Props = {
  children: React.ReactNode
  colors?: string[]
  locations?: number[]
  className?: string
  reversed?: boolean
}

const StyledGradient = styled(LinearGradient)

const defaultGradient = ["rgba(51, 65, 255, 1)", "rgba(139, 79, 227, 1)"]

const defaultLocations = [0, 1]

export const Gradient = forwardRef(
  (
    {
      children,
      className,
      colors = defaultGradient,
      locations = defaultLocations,
    }: Props,
    ref,
  ) => {
    return (
      <StyledGradient
        colors={colors}
        locations={locations}
        start={{
          x: -0.2,
          y: -0.2,
        }}
        end={{
          x: 1,
          y: 1.8,
        }}
        className={twMerge(
          "w-full flex-row items-center justify-center bg-brand-indigo-1",
          className,
        )}
        ref={ref}
      >
        {children}
      </StyledGradient>
    )
  },
)

Gradient.displayName = "Gradient"
