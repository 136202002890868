"use client"

import { useQueryClient } from "@tanstack/react-query"
import { FormattedMessage } from "react-intl"

import {
  Avatar,
  Banner,
  Box,
  Button,
  P,
  useUserByIdOrSlug,
} from "@bullseye/components"
import { usePathname, useRouter, useSearchParams } from "@bullseye/navigation"

type Props = {
  impersonate: (userId: string) => Promise<void>
  stopImpersonating?: () => Promise<void>
  impersonateId?: string
}

export function ImpersonateBanner({
  impersonate,
  stopImpersonating,
  impersonateId,
}: Props) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const sp = useSearchParams()
  const { user } = useUserByIdOrSlug(impersonateId || sp.get("impersonate_id"))
  const pathname = usePathname()

  if (!user) return
  return (
    <Banner
      uniqueId={`impersonate-${user.id}`}
      variant={impersonateId ? "gradient" : "solid"}
      nondismissable
    >
      <Box className="flex-row items-center justify-between">
        <Box className="flex-row items-center g-2">
          <Avatar imageUrl={user.avatar?.url} name={user.formatted_name} />
          <P bold className="text-white">
            <FormattedMessage
              id={
                impersonateId
                  ? "impersonate.banner.active"
                  : "impersonate.banner.inactive"
              }
              values={{ name: user.formatted_name }}
            />
          </P>
        </Box>
        {impersonateId ? (
          <Button
            as="button"
            handlePress={async () => {
              await stopImpersonating()
              queryClient.clear()
              router.push(pathname)
            }}
            height="sm"
            variant="highlight"
            compact
          >
            <FormattedMessage id="impersonate.end" />
          </Button>
        ) : (
          <Button
            as="button"
            handlePress={async () => {
              await impersonate(user.id)
              queryClient.clear()
              router.push(pathname)
            }}
            height="sm"
            variant="highlight"
            compact
          >
            <FormattedMessage id="impersonate.start" />
          </Button>
        )}
      </Box>
    </Banner>
  )
}
