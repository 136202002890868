"use client"

import clsx from "clsx"
import { twMerge } from "tailwind-merge"

import { Box, Category, Chip, H4, Markdown } from "@bullseye/components"

type Props = {
  description?: string
  categories?: Category[]
}

export const ActivityDescription = ({
  description = "",
  categories = [],
}: Props) => {
  return (
    <Box
      className={twMerge(
        "flex flex-col rounded-none bg-white p-6 g-4 md:rounded-lg",
        clsx({
          hidden: !description,
        }),
      )}
    >
      <H4>Activity details</H4>
      <Box className={clsx({ hidden: !description })}>
        <Markdown content={description} />
      </Box>
      <Box
        className={clsx("flex-row g-2", {
          hidden: !categories?.length,
        })}
      >
        {categories?.map((category) => (
          <Chip key={category.id} color="gray" label={category.title} />
        ))}
      </Box>
    </Box>
  )
}
