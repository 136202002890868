"use client"

import { createContext, PropsWithChildren, useContext, useEffect } from "react"
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "@tanstack/react-query"

import { Experience, V1APIResponse, WaitlistElement } from "@bullseye/types"

import { LoadingFullScreen } from "../../components/LoadingFullScreen"
import { useWaitlist } from "../../hooks"
import { useErrorBoundary } from "../../hooks/useErrorBoundary"
import { useExperience } from "../../hooks/useExperience"

type Props = PropsWithChildren<{
  idOrSlug?: string
  accessToken?: string
}>

type Context = {
  experience: Experience
  isFull: boolean
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<
      | {
          data: null
          status: number
        }
      | {
          data: V1APIResponse<Experience>
          status: number
        },
      unknown
    >
  >

  waitlist?: WaitlistElement[]
  waitlistRefetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<
      | {
          data: null
          status: number
        }
      | {
          data: { data: WaitlistElement[] }
          status: number
        },
      unknown
    >
  >
  waitlistIndex: number | null
  isLoading: boolean
  isRefetching: boolean
}

const ExperienceContext = createContext<Context | null>(null)

export const useExperienceContext = () => {
  const context = useContext(ExperienceContext)
  if (!context) {
    throw new Error(
      "useExperienceContext must be used within an ExperienceProvider",
    )
  }
  return context
}

export const ExperienceProvider = ({
  children,
  idOrSlug,
  accessToken,
}: Props) => {
  const { experience, isLoading, error, refetch, isRefetching } = useExperience(
    idOrSlug,
    accessToken,
  )
  const {
    waitlist,
    isLoading: isWaitlistLoading,
    waitlistIndex,
    refetch: waitlistRefetch,
    isRefetching: isWaitlistRefetching,
    enabled: waitlistEnabled,
  } = useWaitlist(experience)
  const { catchError } = useErrorBoundary()
  useEffect(() => {
    if (error) {
      catchError(new Error(`Error fetching experience: ${error}`))
    }
  }, [error])

  const isGlobalLoading =
    isLoading || waitlistEnabled ? isWaitlistLoading : false

  if (isGlobalLoading || !experience) return <LoadingFullScreen />

  return (
    <ExperienceContext.Provider
      value={{
        experience,
        waitlist,
        waitlistIndex,
        isFull:
          experience.max_attendees > 0 &&
          experience.attendees_count >= experience.max_attendees,
        refetch,
        waitlistRefetch: waitlistRefetch,
        isLoading: isGlobalLoading,
        isRefetching: isRefetching || isWaitlistRefetching,
      }}
    >
      {children}
    </ExperienceContext.Provider>
  )
}
