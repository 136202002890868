"use client"

import { Sheet } from "@bullseye/components"
import type { Modal } from "@bullseye/types"

import { ProfilePhotoUploader } from "../ProfilePhotoUploader"

export type ProfilePhotoModalProps = {
  avatarUrl: string
}

export const ProfilePhotoModal = ({
  isOpen,
  dismiss,
  avatarUrl,
}: Modal<ProfilePhotoModalProps>) => {
  return (
    <Sheet
      show={isOpen}
      size="lg"
      dismiss={dismiss}
      renderContent={() => {
        return (
          <ProfilePhotoUploader onComplete={dismiss} avatarUrl={avatarUrl} />
        )
      }}
    />
  )
}
