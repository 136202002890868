"use client"

import React from "react"
import { Pressable, PressableProps } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import Icon from "../Icon"
import { Box } from "../Layout"
import { P } from "../Typography"

export type CheckboxProps = {
  className?: string
  disabled?: boolean
  checked?: boolean
  indeterminate?: boolean
  name?: string
  onChange(checked: boolean): void
  fullWidth?: boolean
  reverse?: boolean
  label?: string
  children?: PressableProps["children"]
}

const Container = styled(Pressable)

export const Checkbox = ({
  className,
  onChange,
  fullWidth,
  reverse,
  checked = false,
  disabled,
  indeterminate,
  label,
  children,
}: CheckboxProps) => {
  const toggleCheckbox = () => {
    onChange(!checked)
  }

  const onPress = () => {
    !disabled && toggleCheckbox()
  }

  const containerClassName = twMerge(
    clsx(
      "flex flex-row justify-start gap-3 items-center",
      {
        "flex-grow justify-between": fullWidth,
        "cursor-not-allowed text-gray-110": disabled,
        "flex-row-reverse": reverse,
      },
      className,
    ),
  )

  const toggleClassName = twMerge(
    clsx(
      "flex h-6 w-6 items-center justify-center rounded border border-2 border-gray-110 g-3",
      "aria-checked:border-transparent aria-checked:bg-primary-500 aria-disabled:border-gray-110 aria-checked:aria-disabled:bg-gray-110",
      {
        "border-transparent bg-primary-500": checked,
        "border-gray-110": disabled,
        "bg-gray-110": checked && disabled,
      },
    ),
  )

  return (
    <Container
      className={containerClassName}
      onPress={onPress}
      aria-checked={checked}
      aria-disabled={disabled}
      role="checkbox"
    >
      <>
        <Box
          aria-checked={checked}
          aria-disabled={disabled}
          className={toggleClassName}
        >
          {checked && (
            <Icon
              type={indeterminate ? "minus" : "check"}
              size="sm"
              color="text-white"
            />
          )}
        </Box>
        {label && <P>{label}</P>}
        {children}
      </>
    </Container>
  )
}
