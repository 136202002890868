"use client"

import {
  ACTIVITIES_URI,
  Box,
  Link,
  MessageChannel,
  P,
  PROFILE_URI,
  TextButton,
} from "@bullseye/components"

import { MessengerChannelIcon } from "../MessengerChannelIcon"

export function MessageChannelTitle({
  messageChannel,
}: {
  messageChannel?: MessageChannel
}) {
  if (!messageChannel) return null

  const title = messageChannel?.pretty_title || "Hank Member"
  const link = messageChannel.experience
    ? `${ACTIVITIES_URI}/${messageChannel.experience.slug}`
    : `${PROFILE_URI}/${messageChannel.dm_user?.slug}`

  return (
    <Box className="flex flex-row items-center g-2">
      <Link href={link} className="flex flex-col items-center justify-center">
        <MessengerChannelIcon
          isSuspended={messageChannel?.dm_user?.is_suspended}
          isExperience={!!messageChannel?.experience}
          isGroupChat={
            messageChannel?.is_group_message && !messageChannel?.experience
          }
          imageUrl={
            messageChannel?.experience?.cover_photo?.url ||
            messageChannel?.dm_user?.avatar?.url
          }
          title={title}
          size="sm"
        />
      </Link>
      <TextButton as="a" href={link}>
        <P bold ellipsis>
          {title}
        </P>
      </TextButton>
    </Box>
  )
}
