"use client"

import React, { PropsWithChildren } from "react"
import decimal from "@jsamr/counter-style/presets/decimal"
import disc from "@jsamr/counter-style/presets/disc"
import MarkedList from "@jsamr/react-native-li"
import clsx from "clsx"
import { styled } from "nativewind"

import { Box } from "../Layout"

type Props = React.ComponentProps<typeof Box>

export const List = ({ className, ...props }: Props) => {
  return (
    <Box
      {...props}
      className={clsx("text-md", "flex-column flex", className)}
    />
  )
}

export type ULProps = PropsWithChildren<{
  ordered?: boolean
}>

const UnstyledUL: React.FC<ULProps> = ({ children, ordered = false }) => {
  return (
    <MarkedList counterRenderer={ordered ? decimal : disc}>
      {children}
    </MarkedList>
  )
}

export const UL = styled(UnstyledUL)
