import { useContext } from "react"

import { LoggingContext } from "../providers/LoggingProvider/LoggingContextProvider"

export const useLoggingContext = () => {
  const context = useContext(LoggingContext)
  if (!context) {
    throw new Error("useLoggingContext must be used within a LoggingProvider")
  }
  return context
}
