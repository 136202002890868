"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H3,
  LOGOUT_URI,
  P,
  Sheet,
  useDeleteUser,
  useLoggingContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import type { Modal } from "@bullseye/types"

export type DeleteProfileModalProps = {
  userId: string
}

export const DeleteProfileModal = ({
  isOpen,
  dismiss,
  userId,
}: Modal<DeleteProfileModalProps>) => {
  const { deleteUserAsync, status } = useDeleteUser(userId)
  const router = useRouter()
  const { captureException } = useLoggingContext()

  return (
    <Sheet
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="flex justify-center pb-6 g-4">
            <H3>
              <FormattedMessage id="profile.settings.deleteProfileModal.title" />
            </H3>
            <P>
              <FormattedMessage id="profile.settings.deleteProfileModal.warning" />
            </P>
          </Box>
        )
      }}
      renderFooter={() => {
        return (
          <Box className="flex-row items-center justify-around pt-3 g-2">
            <Box className="flex-1">
              <Button
                as="button"
                variant="tertiary"
                handlePress={() => dismiss()}
                disabled={status === "loading"}
              >
                <FormattedMessage id="general.dismiss" />
              </Button>
            </Box>
            <Box className="flex-1">
              <Button
                as="button"
                variant="danger"
                loading={status === "loading"}
                handlePress={async () => {
                  try {
                    await deleteUserAsync({})
                    router.replace(LOGOUT_URI)
                  } catch (error) {
                    captureException(
                      new Error(`Failed to delete user: ${error}`),
                    )
                  }
                }}
              >
                <FormattedMessage id="general.confirm" />
              </Button>
            </Box>
          </Box>
        )
      }}
    />
  )
}
