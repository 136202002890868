"use client"

import { FormattedMessage } from "react-intl"

import {
  Button,
  IconButton,
  P,
  TextButton,
  TextButtonProps,
} from "@bullseye/components"

import { useHandleShare } from "../../hooks"

type Props = {
  url: string
  emailSubject: string
  message: string
  variant?: TextButtonProps["variant"]
  buttonType?: "text" | "button" | "icon"
}

export const ShareButton = ({
  emailSubject,
  message,
  url,
  variant = "tertiary",
  buttonType = "text",
}: Props) => {
  const handleShare = useHandleShare()

  if (buttonType === "icon") {
    return (
      <IconButton
        iconType="share"
        handlePress={() => handleShare({ emailSubject, message, url })}
      />
    )
  }

  const ButtonElement = buttonType === "text" ? TextButton : Button

  return (
    <ButtonElement
      as="button"
      startIconType="share"
      variant={variant}
      handlePress={(e) => {
        e.preventDefault()
        return handleShare({ emailSubject, message, url })
      }}
    >
      <P bold>
        <FormattedMessage id="general.share" />
      </P>
    </ButtonElement>
  )
}
