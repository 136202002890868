import * as Linking from "expo-linking"
import { useQuery } from "@tanstack/react-query"

import { PlaceResult } from "@bullseye/types"

import { useUserContext } from "../providers"
import { useAppConfig } from "../providers/AppConfigProvider"
import { isWeb } from "../utils/device"
import { useSupportedLanguage } from "./useSupportedLanguage"

export type GooglePlacePrediction = {
  description: string
  place_id: string
  matched_substrings: Array<{
    length: number
    offset: number
  }>
  structured_formatting: {
    main_text: string
    main_text_substrings: Array<{
      length: number
      offset: number
    }>
    secondary_text: string
  }

  terms: Array<{
    offset: number
    value: string
  }>
  types: string[]
}

type PredictionsResponse = {
  status: string
  predictions: Array<GooglePlacePrediction>
}

type AutcompleteSearchOptions = {
  types: string
}
type SearchRequestOptions = {
  language?: string
  mapsBaseUrl?: string
  placesBaseUrl?: string
  googlePlacesApiKey?: string
}
type Timezone = {
  dstOffset: number
  rawOffset: number
  status: string
  timeZoneId: string
  timeZoneName: string
}

const defaultOptions: AutcompleteSearchOptions = {
  types: "(cities)",
}

export const useAutocompleteSearch = (
  query?: string,
  options = defaultOptions,
) => {
  const { mapsBaseUrl, googlePlacesApiKey } = useAppConfig()
  const language = useSupportedLanguage()
  return useQuery(
    ["autocomplete", query],
    () => {
      return getAutocompleteSearchResults({
        query,
        options,
        mapsBaseUrl,
        googlePlacesApiKey,
        language,
      })
    },
    {
      enabled: !!query,
    },
  )
}

export const getAutocompleteSearchResults = async ({
  query,
  options,
  googlePlacesApiKey,
  language,
  mapsBaseUrl,
}: {
  query?: string
  options: AutcompleteSearchOptions
} & SearchRequestOptions) => {
  const params = new URLSearchParams({
    input: query,
    key: googlePlacesApiKey,
    language: language,
    types: options.types,
    components: "country:us",
  })

  const url = `${mapsBaseUrl}/maps/api/place/autocomplete/json?${params.toString()}`
  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return res.json() as Promise<PredictionsResponse>
}

export type Place = {
  id: string
  displayName: {
    text: string
    languageCode: string
  }
  shortFormattedAddress: string
  formattedAddress: string
  location: {
    latitude: number
    longitude: number
  }
}

export const getPlaceFromId = async ({
  placeId,
  placesBaseUrl,
  googlePlacesApiKey,
}: {
  placeId?: string
} & SearchRequestOptions) => {
  const url = `${placesBaseUrl}/v1/places/${placeId}`

  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "X-Goog-Api-Key": googlePlacesApiKey,
      "X-Goog-FieldMask": "*",
      "Access-Control-Allow-Origin": "*",
    },
  })

  return res.json() as Promise<Place>
}

function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const usePlaceImage = (
  place?: Place,
  options = {
    width: 400,
    height: 400,
  },
) => {
  const { mapsBaseUrl, googlePlacesApiKey } = useAppConfig()

  return useQuery(
    ["placeImage", place?.id],
    async () => {
      const baseURL = `${mapsBaseUrl}/maps/api/staticmap`
      const params = new URLSearchParams({
        key: googlePlacesApiKey,
        size: `${options.width}x${options.height}`,
        zoom: "14",
        scale: "2",
        center: `${place?.location.latitude},${place?.location.longitude}`,
        markers: `color:red|${place?.location.latitude},${place?.location.longitude}`,
        style: "feature:poi|visibility:off",
      })

      const url = `${baseURL}?${params.toString()}`
      if (isWeb() && typeof window !== "undefined") {
        const protocol = window.location.protocol
        const host = window.location.host
        return `${protocol}//${host}${url}`
      }

      const res = await fetch(url)
      return blobToBase64(await res.blob())
    },
    {
      enabled: !!place,
    },
  )
}

export const useGooglePlace = (placeId?: string) => {
  const { placesBaseUrl, googlePlacesApiKey } = useAppConfig()
  return useQuery(
    ["googlePlace", placeId],
    () =>
      getPlaceFromId({
        placeId,
        placesBaseUrl,
        googlePlacesApiKey,
      }),
    {
      enabled: !!placeId,
    },
  )
}
type Coordinates = {
  latitude: number
  longitude: number
}

type Options = {
  enabled?: boolean
}

type TimezoneArgs = Partial<Coordinates> & {
  timestamp: number
}

export const useTimezone = ({
  latitude,
  longitude,
  timestamp,
}: TimezoneArgs) => {
  const { mapsBaseUrl, googlePlacesApiKey } = useAppConfig()
  return useQuery(
    ["timezone", latitude, longitude, timestamp],
    async () => {
      const params = new URLSearchParams({
        location: `${latitude},${longitude}`,
        key: googlePlacesApiKey,
        timestamp: `${timestamp}`,
      })

      const url = `${mapsBaseUrl}/maps/api/timezone/json?${params.toString()}`
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      return res.json() as Promise<Timezone>
    },
    {
      enabled: !!latitude && !!longitude,
    },
  )
}

export const useNearestCity = (
  { latitude, longitude }: Coordinates,
  options?: Options,
) => {
  const { placesBaseUrl, googlePlacesApiKey } = useAppConfig()
  return useQuery(
    ["nearestCity", latitude, longitude],
    async () => {
      const url = `${placesBaseUrl}/v1/places:searchNearby`
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "X-Goog-Api-Key": googlePlacesApiKey,
          "X-Goog-FieldMask": "*",
        },
        body: JSON.stringify({
          includedTypes: ["locality"],
          maxResultCount: 1,
          locationRestriction: {
            circle: {
              center: {
                latitude,
                longitude,
              },
              radius: 500,
            },
          },
        }),
      })

      const { places } = (await res.json()) as { places: Place[] }
      return places?.[0]
    },
    {
      enabled: options?.enabled ?? true,
    },
  )
}

export const usePlacesTextSearch = (query?: string) => {
  const { placesBaseUrl, googlePlacesApiKey } = useAppConfig()
  const { user } = useUserContext()
  return useQuery(
    ["placesTextSearch", query],
    async () => {
      const url = `${placesBaseUrl}/v1/places:searchText`
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "X-Goog-Api-Key": googlePlacesApiKey,
          "X-Goog-FieldMask": "*",
        },
        body: JSON.stringify({
          textQuery: query,
          locationBias: {
            circle: {
              center: {
                latitude: user.lat,
                longitude: user.long,
              },
              radius: 50000,
            },
          },
        }),
      })

      const data = (await res.json()) as { places: PlaceResult[] }
      return data.places
    },
    {
      enabled: !!query,
    },
  )
}

export const useMapsUrl = (place: {
  latitude: number | null
  longitude: number | null
  placeId: string | null
  formattedAddress: string | null
}) => {
  return useQuery(
    ["maps-url", place],
    async () => {
      if (!isWeb()) {
        const iosParams = new URLSearchParams()
        if (place.latitude && place.longitude) {
          iosParams.append("daddr", `${place.latitude},${place.longitude}`)
        } else if (place.formattedAddress) {
          iosParams.append("daddr", place.formattedAddress)
        }
        const hasGoogleMaps = await Linking.canOpenURL("comgooglemaps://")
        if (hasGoogleMaps) {
          return `comgooglemaps://?${iosParams.toString()}`
        }

        const hasAppleMaps = await Linking.canOpenURL("maps://")
        if (hasAppleMaps) {
          return `maps://?${iosParams.toString()}`
        }
      }

      const defaultUrl = `https://www.google.com/maps/dir/?api=1&`
      const webParams = new URLSearchParams()
      if (place.formattedAddress) {
        webParams.append("destination", place.formattedAddress)
      }

      if (place.placeId) {
        webParams.append("destination_place_id", place.placeId)
      }

      return defaultUrl + webParams.toString()
    },
    {
      enabled:
        (!!place?.latitude && !!place?.longitude) || !!place?.formattedAddress,
    },
  )
}
