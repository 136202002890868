import { useEffect, useState } from "react"
import { Dimensions } from "react-native"

const windowDimensions = Dimensions.get("window")
const screenDimensions = Dimensions.get("screen")

const useDeviceDimensions = () => {
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
    screen: screenDimensions,
  })

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions({ window, screen })
      },
    )

    return () => subscription?.remove()
  }, [])

  return dimensions
}

export default useDeviceDimensions
