"use client"

import React, { ReactNode, useEffect, useState } from "react"
import { Modal, Pressable, useWindowDimensions } from "react-native"
import { Gesture, GestureDetector } from "react-native-gesture-handler"
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated"
import * as Haptics from "expo-haptics"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { usePathname } from "@bullseye/navigation"

import { isWeb } from "../../utils"
import { IconButton } from "../IconButton"
import { Box } from "../Layout"
import { P } from "../Typography"

export type SheetProps = {
  renderContent: (show: boolean) => ReactNode
  renderFooter?: (show: boolean) => ReactNode
  show: boolean
  title?: ReactNode
  icon?: "x" | "arrow-left"
  iconVariant?: "plain" | "filled"
  onIconPress?: () => void
  dismiss?: () => void
  size?: "md" | "lg"
  contentContainerStyle?: string
  footerContainerStyle?: string
  disableSafeArea?: boolean
  sheetDragDisabled?: boolean
}

const StyledPressable = styled(Animated.createAnimatedComponent(Pressable))

const emptyFooter = () => <></>

const SHEET_DISMISS_THRESHOLD = 100

export const Sheet = styled(
  ({
    renderContent,
    renderFooter = emptyFooter,
    size = "md",
    show,
    title,
    icon,
    iconVariant = "plain",
    dismiss,
    onIconPress,
    contentContainerStyle = "",
    footerContainerStyle = "",
    disableSafeArea = false,
    sheetDragDisabled = false,
  }: SheetProps) => {
    const { height: windowHeight } = useWindowDimensions()

    const pathname = usePathname()
    useEffect(() => {
      dismissWithAnimations()
    }, [pathname])

    const translateY = useSharedValue(size === "lg" ? 0 : windowHeight)
    const opacity = useSharedValue(0)

    const modalBodyStyles = useAnimatedStyle(() => {
      return {
        transform: [
          {
            translateY: translateY.value,
          },
        ],
      }
    }, [translateY])

    const modalContainerStyles = useAnimatedStyle(() => {
      return {
        backgroundColor: `rgba(0, 0, 0, ${opacity.value})`,
      }
    }, [opacity])

    function dismissWithAnimations() {
      if (!dismiss) return
      if (!show) return // modal already closed
      if (!isWeb() && size === "lg") {
        dismiss()
        return
      }

      opacity.value = withTiming(0, { duration: 299 })
      translateY.value = withTiming(
        windowHeight,
        {
          duration: 300,
        },
        () => {
          runOnJS(dismiss)()
        },
      )
    }

    useEffect(() => {
      if (show) {
        translateY.value = withTiming(0, {
          duration: 300,
        })
        opacity.value = withTiming(0.5, {
          duration: 300,
        })
      } else {
        dismissWithAnimations()
      }
    }, [show])

    const [impact, setImpact] = useState(false)
    async function triggerImpactAt(
      currentPosition: number,
      maxPosition: number,
    ) {
      if (isWeb()) return

      if (currentPosition > maxPosition) {
        if (impact) return

        setImpact(true)
        await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)

        return
      }

      setImpact(false)
    }

    function handleDismissSwipe() {
      setImpact(false)
      dismissWithAnimations()
    }

    const pan = Gesture.Pan()
      .onChange((event) => {
        if (sheetDragDisabled) return
        if (event.translationY < 0) return

        translateY.value = event.translationY

        if (event.translationY > SHEET_DISMISS_THRESHOLD) {
          runOnJS(triggerImpactAt)(event.absoluteY, SHEET_DISMISS_THRESHOLD)
        } else {
          runOnJS(setImpact)(false)
        }
      })
      .onFinalize((event) => {
        if (event.translationY > SHEET_DISMISS_THRESHOLD) {
          runOnJS(handleDismissSwipe)()
        } else {
          translateY.value = withTiming(0)
        }
      })
      .enabled(isWeb() || size !== "lg")

    return (
      <Modal
        onRequestClose={async () => {
          if (sheetDragDisabled) return
          if (!isWeb()) {
            await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
          }
          dismiss?.()
        }}
        onDismiss={() => {
          dismiss?.()
        }}
        visible={!!show}
        animationType={isWeb() || size !== "lg" ? "none" : "slide"}
        transparent={isWeb() || size !== "lg"}
        presentationStyle={size === "lg" ? "pageSheet" : "overFullScreen"}
      >
        <StyledPressable
          className={clsx(
            "flex h-full w-full cursor-default md:items-center md:justify-center md:py-4",
            {
              "flex-col-reverse": show && size !== "lg",
              "bg-black/50": isWeb() && show,
            },
          )}
          onPress={() => {
            dismissWithAnimations()
          }}
          style={modalContainerStyles}
        >
          <GestureDetector gesture={pan}>
            <StyledPressable
              pointerEvents="auto"
              className={clsx(
                "flex-column flex w-full cursor-default bg-white p-4 drop-shadow g-4 md:w-[576px] md:rounded-2xl",
                {
                  "h-full": size === "lg",
                  "rounded-t-2xl": size !== "lg",
                  "pb-10": !disableSafeArea && size !== "lg",
                },
              )}
              style={modalBodyStyles}
            >
              {(title || icon) && (
                <Box
                  className={clsx(
                    "flex-0 flex flex-row items-center justify-center",
                  )}
                >
                  {icon && (
                    <IconButton
                      name="close"
                      handlePress={() => {
                        if (icon === "x") {
                          dismissWithAnimations()
                        } else {
                          onIconPress?.()
                        }
                      }}
                      iconType={icon}
                      variant={iconVariant}
                      hoverColor="bg-white"
                      className="flex-grow-0"
                    />
                  )}
                  <Box className="flex-[2] justify-self-center">
                    {title && (
                      <P bold className="text-center !font-semibold">
                        {title}
                      </P>
                    )}
                  </Box>
                  {icon && title && <Box className="flex-grow-1 w-[32px]" />}
                </Box>
              )}
              <Box
                className={twMerge(
                  clsx({
                    "flex-1": size === "lg",
                    "pb-5": size === "lg",
                  }),
                  contentContainerStyle,
                )}
              >
                {renderContent(show)}
              </Box>
              <Box
                className={twMerge(
                  "flex-0 justify-center",
                  footerContainerStyle,
                )}
              >
                {renderFooter(show)}
              </Box>
            </StyledPressable>
          </GestureDetector>
        </StyledPressable>
      </Modal>
    )
  },
)
