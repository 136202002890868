"use client"

import React from "react"
import { twMerge } from "tailwind-merge"

import { Box } from "../Layout"

type Props = React.ComponentProps<typeof Box>

export const Divider = ({ className, ...props }: Props) => {
  return (
    <Box
      {...props}
      className={twMerge("text-md", "h-px w-full bg-gray-100", className)}
    />
  )
}
