import { useUserContext } from "../providers"
import { useDeleteRequest } from "./useRequest"

export const useCancelAttendance = (experienceId: string, userId: string) => {
  const { accessToken } = useUserContext()
  const { deleteRequest, deleteRequestAsync, ...rest } = useDeleteRequest({
    path: `/v1/experiences/${experienceId}/attendees/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    cancelAttendance: deleteRequest,
    cancelAttendanceAsync: deleteRequestAsync,
    ...rest,
  }
}
