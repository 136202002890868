"use client"

import clsx from "clsx"
import {
  Channel,
  ChannelList,
  MessageInput,
  Thread,
  VirtualizedMessageList,
} from "stream-chat-react"

import {
  Box,
  Loading,
  MESSENGER_URI,
  Redirect,
  useBreakpoint,
  useChannel,
  useMessageChannelByStreamId,
  useUserContext,
} from "@bullseye/components"
import { useSearchParams } from "@bullseye/navigation"

import {
  MessageChannelHeader,
  MessengerChannelPreview,
  MessengerInput,
  MessengerUserSuspended,
  NoChannelSelected,
} from "../../components"

type Channel = {
  id: string
  data?: {
    hidden?: boolean
  }
}

const MessengerPage = () => {
  const { user } = useUserContext()

  const isSm = useBreakpoint("md", "down")

  const sp = useSearchParams()
  const channelId = sp?.get("channel") ?? undefined
  const { channel: data, isLoading: channelLoading } = useChannel(channelId)
  const channel = data as Channel
  const { messageChannel } = useMessageChannelByStreamId(channel?.id)

  const userIsSuspended = messageChannel?.dm_user?.is_suspended || false

  if (channel?.data?.hidden) {
    return <Redirect href={MESSENGER_URI} push />
  }

  return (
    <Box className="flex h-full w-full flex-col overflow-scroll">
      <Box className="hidden md:flex">
        <MessageChannelHeader channelId={channelId} />
      </Box>
      <Box className="flex h-full w-full flex-1 flex-row">
        <Box
          className={clsx("flex w-full overflow-hidden md:w-[30%]", {
            hidden: isSm && channelId,
          })}
        >
          <Box className="flex md:hidden">
            <MessageChannelHeader channelId={channelId} />
          </Box>
          <ChannelList
            filters={{
              members: {
                $in: [user.stream_id],
              },
              last_message_at: { $gte: "2010-04-01T00:00:00.00Z" },
            }}
            sort={{
              last_message_at: -1,
            }}
            Preview={MessengerChannelPreview}
            customActiveChannel={channelId}
            setActiveChannelOnMount={false}
          />
        </Box>
        <NoChannelSelected hidden={!!channelId} />
        <Box
          className={clsx("w-full overflow-hidden md:flex md:w-[70%]", {
            hidden: isSm && !channelId,
            "md:hidden": !channel,
          })}
        >
          <Channel channel={channel as any}>
            <Box className="dvh-full h-full w-full md:h-full">
              <Box className="flex md:hidden">
                <MessageChannelHeader channelId={channelId} />
              </Box>
              <MessengerUserSuspended hidden={!userIsSuspended} />
              <Box
                className={clsx("flex-1 pt-4", {
                  hidden: userIsSuspended,
                })}
              >
                {channelLoading ? (
                  <Loading />
                ) : (
                  <VirtualizedMessageList scrollToLatestMessageOnFocus />
                )}
              </Box>
              <MessengerInput
                channelId={channelId}
                InputComponent={MessageInput}
              />
            </Box>
            <Thread />
          </Channel>
        </Box>
      </Box>
    </Box>
  )
}

export default MessengerPage
