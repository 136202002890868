"use client"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import { Box, H3, Loading, P, Toggle } from "@bullseye/components"

import { SettingElement, SingleSetting } from "../SettingElement"

type ChannelSettingsProps = {
  title: JSX.Element | string
  all?: SingleSetting
  allOn: boolean
  setAllOn: (v: boolean) => void
  isAllLoading: boolean
  elements: SingleSetting[]
  toggleElement: (elementIdx: number, v: boolean) => void
  elementsLoading: boolean
}

export const ChannelSettings = ({
  title,
  all,
  elements,
  allOn,
  setAllOn,
  toggleElement,
  elementsLoading,
  isAllLoading,
}: ChannelSettingsProps) => {
  return (
    <Box className="w-full">
      <H3>{title}</H3>
      {all && (
        <SettingElement
          settingLabel={all}
          className="border-b border-zinc-300 py-4"
          disabled={isAllLoading}
        >
          {isAllLoading ? (
            <Loading size="small" />
          ) : (
            <>
              {allOn ? (
                <P className="text-violet-600">
                  <FormattedMessage id="settings.notifications.all-on" />
                </P>
              ) : (
                <P className="text-gray-600">
                  <FormattedMessage id="settings.notifications.all-off" />
                </P>
              )}
              <Toggle
                value={allOn}
                onChange={(v) => {
                  if (isAllLoading) return
                  setAllOn(v)
                }}
              />
            </>
          )}
        </SettingElement>
      )}
      {elements.map((element, idx) => (
        <SettingElement
          disabled={!allOn || elementsLoading}
          key={idx}
          settingLabel={element}
          className={clsx(
            idx !== elements.length - 1
              ? "border-b border-zinc-300"
              : "pb-0 md:border-b md:border-zinc-300 md:pb-4",
          )}
        >
          {elementsLoading ? (
            <Loading size="small" />
          ) : (
            <Toggle
              value={!!element.enabled}
              onChange={(v) => {
                if (!allOn || elementsLoading) return
                toggleElement(idx, v)
              }}
            />
          )}
        </SettingElement>
      ))}
    </Box>
  )
}
