export const APP_URL = "https://app.gethank.com"
export const MARKETING_URL = "https://www.gethank.com"

export const ACTIVITIES_URI = `/activities`
export const POST_ACTIVITY_URI = "/create-activity"
export const DUPLICATE_ACTIVITY_UI = "/duplicate-activity"

export const ATTENDING_URI = "/attending"

export const MEMBERS_URI = "/members"
export const MESSENGER_URI = "/messenger"
export const USER_HOME_URI = "/"
export const PROFILE_URI = "/p"

export const HOME_URI = "/"
export const PRICING_URI = "/pricing"

export const NOT_FOUND = "/404"
export const LOGIN_URI = "/login"
export const LOGIN_ERROR_URI = "/login-error"
export const LOGIN_SUCCESS_URI = "/login-success"
export const SIGN_UP_URI = "/signup"
export const LOGOUT_URI = "/logout"
export const ME_EDIT_URI = "/account/edit"
export const ME_URI = "/account"
export const SETTINGS_URI = "/account/payments"
export const SUBSCRIPTION_URI = "/account/subscription"
export const ACCOUNT_REFERRALS_URI = "/referrals"
export const NOTIFICATIONS_URI = "/notifications"
export const WELCOME_BACK_URI = "/welcome-back"
export const LOGIN_AUTH0_URI = `/api/auth/login?returnTo=${LOGIN_SUCCESS_URI}`
export const LOGOUT_AUTH0_URI = "/api/auth/logout"
export const SIGN_UP_AUTH0_URI = `${LOGIN_AUTH0_URI}&screen_hint=signup`
export const NOTIFICATION_SETTINGS_URI = "/account/notification-settings"

export const NATIVE_LOCATION_PERMISSIONS = "/get-started/location"
export const NATIVE_NOTIFICATION_PERMISSIONS = "/get-started/notifications"

export const ONBOARDING_URI = "/get-started"
export const ONBOARDING_SUCCESS = "/get-started/success"
export const ONBOARDING_SUBSCRIPTION = "/get-started/subscription"
export const ONBOARDING_PROFILE_PHOTO = "/get-started/profile-photo"
export const ONBOARDING_VERIFICATION = "/get-started/verification"
export const ONBOARDING_REGISTRATION = "/get-started/registration"

// External routes
export const BLOG_URL = "https://gethank.com/blog"
export const ABOUT_US_URL = "https://gethank.com/company/about"
export const COMMUNITY_GUIDELINES_URL =
  "https://gethank.com/company/community-guidelines"
export const HELP_CENTER_URL = "https://help.gethank.com/"
export const HANK_ATTENDANCE_POLICY_URL =
  "https://www.gethank.com/company/attendance-policy"
export const HANK_BADGE_INFO_URL =
  "https://help.gethank.com/en/articles/8053635-how-to-find-other-hank-members-at-activities"
export const HANK_BADGE_URL =
  "https://help.gethank.com/en/articles/8053635-how-to-find-other-hank-members-at-activities#h_79ed2e6a53"
export const IP_GEOLOCATION_V2 = "/api/localize"
export const HANK_HOST_PROGRAM_URL = "https://gethank.com/hank-hosts-program"

export const PRIVACY_POLICY_URI = "/legal/privacy"
export const TERMS_OF_SERVICE_URI = "/legal/terms"

// API Routes
export const CLEAR_SESSION_HANDLER = "/api/clear-session"
