import { Box, Button, H4, P } from "@bullseye/components"

const HelpCenterCard = () => {
  return (
    <Box className="border-solid border-gray-110 bg-white p-6 md:rounded-lg md:border">
      <Box className="flex flex-row items-center justify-between">
        <H4>Help center</H4>
      </Box>
      <Box className="g-4">
        <P>Find articles and videos on how to get the most out of Hank</P>
        <Button as="a" href="https://help.gethank.com/en">
          Go to Help Center
        </Button>
      </Box>
    </Box>
  )
}

export default HelpCenterCard
