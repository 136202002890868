"use client"

import * as Notifications from "expo-notifications"
import { useQuery } from "@tanstack/react-query"

import { useUserContext } from "../providers"

export const useCheckNotificationPermissions = () => {
  const { user } = useUserContext()
  return useQuery(["check-notification-permissions", user.id], () =>
    Notifications.getPermissionsAsync(),
  )
}
