"use client"

import { useMemo } from "react"
import { FormattedMessage } from "react-intl"

import {
  areTriggersEnabled,
  triggerToTemplateIdMap,
  useGlobalSettings,
  useUserContext,
  useWorkflowSettings,
} from "@bullseye/components"

import { ChannelSettings } from "../ChannelSettings"
import { SingleSetting } from "../SettingElement"

interface PushNotificationElement extends SingleSetting {
  triggers: string[]
}

type PushSettingsProps = {
  elements: PushNotificationElement[]
}

export const PushSettings = ({
  elements: notificationPushElements,
}: PushSettingsProps) => {
  const { accessToken } = useUserContext()
  const {
    isLoading: isGlobalLoading,
    data: allData,
    updateChannel,
  } = useGlobalSettings(accessToken)

  const {
    data: workflowData,
    isLoading: elementsLoading,
    isUpdateLoading: elementsUpdating,
    updateTemplates,
  } = useWorkflowSettings(accessToken)

  const elements = useMemo(() => {
    if (elementsLoading || !workflowData) {
      return null
    }
    const templateIdMap = triggerToTemplateIdMap(workflowData)
    return notificationPushElements.map((element) => {
      const { enabled, templateIds } = areTriggersEnabled(
        element.triggers,
        "push",
        templateIdMap,
      )
      return {
        ...element,
        enabled,
        templateIds,
      }
    })
  }, [elementsLoading, elementsUpdating, workflowData])

  return (
    <ChannelSettings
      title={<FormattedMessage id="settings.notifications.push.title" />}
      all={{
        label: <FormattedMessage id="settings.notifications.push.label" />,
      }}
      allOn={allData?.channels.push || false}
      setAllOn={async (t) => {
        await updateChannel("push", t)
      }}
      isAllLoading={isGlobalLoading}
      toggleElement={async (elIdx, v) => {
        const tIds = elements?.[elIdx]?.templateIds
        if (!tIds || tIds.length === 0) return
        await updateTemplates(tIds, v, "push")
      }}
      elementsLoading={elementsLoading || elements === null}
      elements={elements ?? notificationPushElements}
    />
  )
}
