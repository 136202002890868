"use client"

import * as Linking from "expo-linking"
import { FormattedMessage } from "react-intl"

import { Box, Button, H3, Sheet } from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export type IncompleteFeatureModalProps = {
  href: string
}

export const IncompleteFeatureModal = ({
  isOpen,
  dismiss,
  href,
}: Modal<IncompleteFeatureModalProps>) => {
  return (
    <Sheet
      icon="x"
      show={isOpen}
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="flex justify-center pb-6 g-4">
            <H3>
              <FormattedMessage id="profile.modal.incompleteFeature.title" />
            </H3>
          </Box>
        )
      }}
      renderFooter={() => {
        return (
          <Box className="flex items-center pt-3 g-2">
            <Button
              as="button"
              variant="primary"
              handlePress={async () => {
                await Linking.openURL(href)
                dismiss()
              }}
            >
              <FormattedMessage id="profile.modal.incompleteFeature.continue" />
            </Button>
            <Button
              as="button"
              variant="tertiary"
              handlePress={() => {
                return dismiss()
              }}
            >
              <FormattedMessage id="general.dismiss" />
            </Button>
          </Box>
        )
      }}
    />
  )
}
