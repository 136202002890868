import * as Clipboard from "expo-clipboard"

export const useCopyToClipboard = () => {
  const copy = (text: string) => {
    return Clipboard.setStringAsync(text)
  }

  return {
    copy,
  }
}
