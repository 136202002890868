import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { StreamChat } from "stream-chat"

import { StreamType } from "@bullseye/types"

import { useUserContext } from "../../UserAuthProvider/UserProvider"

export const useClient = (apiKey: string) => {
  const { user } = useUserContext()

  const client = useMemo(
    () => StreamChat.getInstance<StreamType>(apiKey),
    [apiKey],
  )

  const { isLoading, error } = useQuery(
    ["client", apiKey, user.stream_token],
    () => {
      return client.connectUser(
        {
          id: user.stream_id,
          name: user.formatted_name || user.first_name,
          image: user?.avatar?.url,
        },
        user.stream_token,
      )
    },
    {
      enabled: !!client && !!user.stream_token,
      staleTime: Infinity, // only connect the user once
      cacheTime: Infinity, // never clear the client once we have one
    },
  )

  return {
    client,
    isLoading,
    error,
  }
}
