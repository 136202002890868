"use client"

import clsx from "clsx"

import { Box, Divider, Experience, H4, User } from "@bullseye/components"

import { ActivityCard } from "../../../components"

type Props = {
  experience: Experience
  index: number
  displayedDates: Record<string, number>
  isLastElement?: boolean
  user?: User
}

export const ActivityCardGroup = ({
  experience: exp,
  index,
  displayedDates,
  user,
  isLastElement,
}: Props) => {
  const isFirstGroupedElement = displayedDates[exp.formattedDate] === index
  const isLastGroupedElement = Object.values(displayedDates).includes(index + 1)

  return (
    <Box
      className="border-b-md flex flex w-full flex-grow items-center bg-gray-50 md:px-4"
      key={exp.id}
    >
      {isFirstGroupedElement && (
        <Box className="mt-2 w-full max-w-[1200px] bg-white px-4 pb-2 pt-4 md:mt-4 md:rounded-t-md">
          <H4>{exp.formattedDate}</H4>
        </Box>
      )}
      <Box className="w-full max-w-[1200px] bg-white px-4">
        <Divider />
      </Box>
      <ActivityCard
        data={exp}
        className={clsx("w-full max-w-[1200px]", {
          "md:rounded-b": isLastGroupedElement || isLastElement,
        })}
        currentUserId={user?.id}
      />
    </Box>
  )
}
