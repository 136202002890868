"use client"

import { twMerge } from "tailwind-merge"

import { Box, Experience, H3 } from "@bullseye/components"

import {
  ActivityDate,
  ActivityLocation,
  ActivityNoShowPolicy,
  ActivityOrganizer,
} from "../../../../components/Activity"

type Props = {
  experience: Experience
  className?: string
}

export const ActivityInfo = ({ experience }: Props) => {
  return (
    <Box
      className={twMerge(
        "flex flex-col rounded-none bg-white p-6 g-6 md:rounded-lg",
      )}
    >
      <Box className="flex flex-col g-6">
        <H3>{experience.title}</H3>
        <ActivityOrganizer user={experience.organizer} />
        <ActivityDate experience={experience} />
        <ActivityLocation experience={experience} />
        {experience.no_show_fee && (
          <ActivityNoShowPolicy noShowFee={experience.no_show_fee} />
        )}
      </Box>
    </Box>
  )
}
