"use client"

import * as Linking from "expo-linking"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Experience,
  Icon,
  isWeb,
  Markdown,
  P,
  TextButton,
  useMapsUrl,
} from "@bullseye/components"

type Props = {
  experience: Experience
}

const formatEventOnlineLink = (url: string | undefined | null): string => {
  if (!url) {
    return ""
  }
  try {
    const urlWithProtocol = url.startsWith("http") ? url : `https://${url}`
    const formattedUrl = new URL(urlWithProtocol)
    return formattedUrl.hostname
  } catch (e) {
    return ""
  }
}

const addressParts = (event: Experience) => {
  const [line1, ...rest] = event.formatted_address_string?.split("\n") || []
  return {
    line1,
    line2: rest.join("\n"),
  }
}

export const ActivityLocation = ({ experience }: Props) => {
  const { data: placeUrl } = useMapsUrl({
    latitude: experience.lat,
    longitude: experience.long,
    placeId: experience.google_place_id,
    formattedAddress: experience.formatted_address_string,
  })

  if (experience.venue_type === "online") {
    const link = formatEventOnlineLink(experience.url)
    return (
      <Box className="flex flex-row g-3">
        <Box>
          <Box className="overflow-hidden rounded-lg bg-gray-10 p-1.5">
            <Icon type="monitor" size="sm" />
          </Box>
        </Box>
        <Box className="flex flex-1">
          <Box className="flex flex-col items-start g-1">
            <Box>
              <P bold>
                <FormattedMessage id="activity.info.online.title" />
              </P>
            </Box>
            <Box
              className={clsx({
                hidden: !link,
              })}
            >
              <TextButton
                as="a"
                variant="primary"
                href={
                  experience.url?.includes("http")
                    ? experience.url
                    : `https://${experience.url}`
                }
              >
                <P>{link}</P>
              </TextButton>
            </Box>
            <Box
              className={clsx({
                hidden: experience.url,
              })}
            >
              <P>
                <FormattedMessage id="activity.info.online.missingUrl" />
              </P>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const { line1, line2 } = addressParts(experience)
  return (
    <Box className="flex flex-row g-3">
      <Box>
        <Box className="overflow-hidden rounded-lg bg-gray-10 p-1.5">
          <Icon type="location-pin" size="md" />
        </Box>
      </Box>
      <Box className="flex flex-1">
        <Box
          className={clsx("flex flex-col items-start g-1", {
            hidden: !line1 && !line2,
          })}
        >
          <Box
            className={clsx({
              hidden: !line1,
            })}
          >
            <P bold>{line1}</P>
          </Box>
          <Box
            className={clsx({
              hidden: !line2,
            })}
          >
            <P>{line2}</P>
          </Box>
          {placeUrl && (
            <Box>
              {isWeb() ? (
                <TextButton as="a" href={placeUrl} variant="primary">
                  <FormattedMessage id="activity.location.getDirections" />
                </TextButton>
              ) : (
                <TextButton
                  as="button"
                  handlePress={() => Linking.openURL(placeUrl)}
                  variant="primary"
                >
                  <FormattedMessage id="activity.location.getDirections" />
                </TextButton>
              )}
            </Box>
          )}
          {experience.additional_instructions && (
            <Box className="pt-2">
              <Markdown content={experience.additional_instructions} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
