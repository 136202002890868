import { getLocales } from "expo-localization"

const supportedLanguages = ["en", "fr", "nl"]

export const useSupportedLanguage = (defaultLang?: string) => {
  const userLocales = getLocales()

  if (defaultLang && supportedLanguages.includes(defaultLang)) {
    return defaultLang
  }

  const supportedLocale = userLocales.find((locale) => {
    return supportedLanguages.includes(locale.languageCode)
  })

  return supportedLocale ? supportedLocale.languageCode : "en"
}
