"use client"

import React from "react"
import { GestureResponderEvent, TouchableOpacity } from "react-native"
import clsx from "clsx"
import { styled, StyledProps } from "nativewind"
import { twMerge } from "tailwind-merge"

import { useLinkOrButtonPress } from "../../hooks"
import { Icon, IconProps } from "../Icon"
import Loading from "../Loading"
import { P } from "../Typography"

type BaseProps = StyledProps<{
  children: React.ReactNode
  variant?: "primary" | "secondary" | "tertiary" | "danger"
  height?: "sm" | "md" | "lg" | "xs"
  disabled?: boolean
  name?: string
  style?: Record<string, any>
  startIconType?: IconProps["type"]
  endIconType?: IconProps["type"]
  loading?: boolean
  underlined?: boolean
}>

export type TextButtonProps = {
  as: "button"
  handlePress: (event: GestureResponderEvent) => void
} & BaseProps

export type TextButtonLinkProps = {
  as: "a"
  href: string
} & BaseProps

const StyledButton = styled(TouchableOpacity)

const textBase = "font-semibold m-0"

const textVariants: Record<string, string> = {
  primary: "text-primary-500",
  secondary: "text-gray-950",
  tertiary: "text-gray-600",
  danger: "text-red-600",
}

const containerBase =
  "inline-flex flex-row items-center justify-center p-0 bg-transparent g-2"

export const TextButton = ({
  children,
  variant = "secondary",
  height = "md",
  disabled = false,
  startIconType,
  endIconType,
  loading,
  underlined,
  className,
  ...props
}: TextButtonProps | TextButtonLinkProps) => {
  const onPress = useLinkOrButtonPress({
    as: props.as,
    href: props.as === "a" ? props.href : undefined,
    onPress: props.as === "button" ? props.handlePress : undefined,
  })
  if (disabled && props.hasOwnProperty("href")) {
    Object.assign(props, { href: null })
  }

  const textColor = disabled ? "text-gray-300" : textVariants[variant]

  return (
    <StyledButton
      className={twMerge(
        containerBase,
        clsx({
          "cursor-wait": loading,
        }),
        className,
      )}
      disabled={disabled}
      onPress={onPress}
      {...props}
      role={props.as === "button" ? "button" : "link"}
    >
      {loading ? (
        <Loading size="small" />
      ) : (
        <>
          {startIconType && (
            <Icon type={startIconType} size="sm" color={textColor} />
          )}
          <P
            bold
            className={twMerge(
              textBase,
              textColor,
              clsx({
                "underline hover:no-underline": underlined && !disabled,
                "hover:underline": !underlined && !disabled,
                "cursor-not-allowed": disabled,
              }),
            )}
            disabled={disabled}
            size={height}
          >
            {children}
          </P>
          {endIconType && (
            <Icon type={endIconType} size="sm" color={textColor} />
          )}
        </>
      )}
    </StyledButton>
  )
}
