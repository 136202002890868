"use client"

import { Box, Image } from "@bullseye/components"

type Props = {
  imageUrl?: string
}
export const ActivityCoverPhoto = ({ imageUrl }: Props) => {
  if (!imageUrl) return null
  return (
    <Box className="flex items-center justify-center overflow-hidden bg-white px-4 pb-6 md:rounded-lg md:px-0 md:pb-0">
      <Image
        className="aspect-[2/1] w-full rounded-lg"
        source={{ uri: imageUrl }}
        alt="Experience cover photo"
        resizeMode="cover"
      />
    </Box>
  )
}
