import { useUserContext } from "../providers"
import { useDeleteRequest } from "./useRequest"

export const useDeleteUser = (userId: string) => {
  const { accessToken } = useUserContext()
  const { deleteRequest, deleteRequestAsync, ...rest } = useDeleteRequest({
    path: `v1/users/${userId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    deleteUser: deleteRequest,
    deleteUserAsync: deleteRequestAsync,
    ...rest,
  }
}
