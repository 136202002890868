import { PaymentMethod } from "@bullseye/types"

import { useUserContext } from "../providers/UserAuthProvider/UserProvider"
import { useDeleteRequest, useGetRequest, usePostRequest } from "./useRequest"

type PaymentsResponse = { data: PaymentMethod[] }

export const usePaymentMethods = ({ enabled } = { enabled: true }) => {
  const { accessToken } = useUserContext()
  const { data, ...rest } = useGetRequest<PaymentsResponse>(
    {
      method: "GET",
      path: `v1/payment-methods`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    { enabled },
  )

  return {
    paymentMethods: data?.data,
    ...rest,
  }
}

export const useDefaultPaymentMethod = ({ enabled } = { enabled: true }) => {
  const { accessToken } = useUserContext()
  const { data, isLoading, ...rest } = useGetRequest<PaymentsResponse>(
    {
      method: "GET",
      path: `v1/payment-methods/default`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    { enabled },
  )

  return {
    data: data?.data?.find((pm) => pm?.card),
    isLoading,
    ...rest,
  }
}

export const useSetDefaultPaymentMethod = () => {
  const { accessToken } = useUserContext()
  const { postRequestAsync, isLoading, error } = usePostRequest<
    PaymentsResponse,
    null,
    {
      id: string
    }
  >({
    method: "POST",
    path: `v1/payment-methods/default`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  function setDefaultPaymentMethod(id: string) {
    return postRequestAsync({ params: { id } })
  }

  return {
    setDefaultPaymentMethod,
    isLoading,
    error,
  }
}

type Response = {
  data: {
    secret: string
  }[]
}

export const useSetupPaymentMethod = () => {
  const { accessToken } = useUserContext()
  return useGetRequest<Response>({
    method: "POST",
    path: "v1/payment-methods/setup",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const useDeletePaymentMethod = (id: string) => {
  const { accessToken } = useUserContext()
  const { deleteRequestAsync, ...rest } = useDeleteRequest<null, null>({
    path: `v1/payment-methods/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  function handleDelete() {
    return deleteRequestAsync(null)
  }

  return {
    deletePaymentMethod: handleDelete,
    ...rest,
  }
}
