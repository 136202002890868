import { Partnership, V1APIResponse } from "@bullseye/types"

import { useUserContext } from "../providers"
import { useGetRequest } from "./useRequest"

export const useAvailablePartnerships = () => {
  const { accessToken } = useUserContext()
  const { data, isLoading, error, refetch } = useGetRequest<
    V1APIResponse<Partnership>
  >({
    method: "GET",
    path: `v1/subscriptions/partnerships`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  async function refetchPartnerships() {
    const { data } = await refetch()

    return data?.data?.data?.[0]
  }

  return {
    data: data?.data?.[0],
    isLoading,
    error,
    refetch: refetchPartnerships,
  }
}
