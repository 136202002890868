"use client"

import { IntlProvider } from "react-intl"

import { useSupportedLanguage } from "../../hooks/useSupportedLanguage"
import translations from "./translations"

type Props = {
  children: React.ReactNode
  detectedLang?: string
}

export const LocalizationProvider = ({ children, detectedLang }: Props) => {
  const languageCode = useSupportedLanguage(detectedLang)

  const messages = Object.assign(
    {},
    translations.en,
    translations[languageCode],
  )

  return (
    <IntlProvider locale={languageCode} defaultLocale="en" messages={messages}>
      {children}
    </IntlProvider>
  )
}
