"use client"

import { useState } from "react"
import { isPast } from "date-fns"
import { FormattedMessage } from "react-intl"

import {
  ACTIVITIES_URI,
  Button,
  P,
  useAddToWaitlist,
  useAttendExperience,
  useExperienceContext,
  useInvalidateExperienceAttendees,
  useLoggingContext,
  useTracking,
  useUserContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { ActivityModal, useActivityModalContext } from "../../../../components"

export const AttendButton = () => {
  const router = useRouter()
  const { openModal } = useActivityModalContext()
  const { experience, isFull, waitlistRefetch, waitlistIndex, refetch } =
    useExperienceContext()
  const { attendExperienceAsync } = useAttendExperience(experience.id)
  const invalidateAttendees = useInvalidateExperienceAttendees(experience.id)
  const { addToWaitlistAsync } = useAddToWaitlist(experience)
  const [loading, setLoading] = useState(false)
  const { captureException } = useLoggingContext()
  const { user } = useUserContext()

  const track = useTracking()

  if (isPast(new Date(experience.start_date))) {
    return (
      <Button as="button" handlePress={() => null} disabled>
        <P bold>
          <FormattedMessage id="activity.attend.isPast" />
        </P>
      </Button>
    )
  }

  if (experience.canceled_at) {
    return (
      <Button as="button" handlePress={() => null} disabled>
        <P bold>
          <FormattedMessage id="activity.attend.canceled" />
        </P>
      </Button>
    )
  }

  if (!experience.is_hostless && user?.id === experience.organizer_id)
    return null

  if (experience.requester?.is_attending) {
    return (
      <Button
        as="button"
        handlePress={() => {
          openModal(ActivityModal.CancelAttendance, {
            refetch: async () => {
              await refetch()
              await waitlistRefetch()
            },
            userId: user.id,
          })
        }}
      >
        <P bold>
          <FormattedMessage id="activity.attend.cancelAttendance" />
        </P>
      </Button>
    )
  }

  if (!isFull) {
    return (
      <>
        <Button
          as="button"
          handlePress={async () => {
            try {
              setLoading(true)
              await track("open_attend_modal", {
                experience_id: experience.id,
                no_show_fee: experience.no_show_fee,
              })
              const humanaAccounts = [
                // taylor+humana - prod
                "74c82da0-7d72-4624-89d1-c808f4a1f6d8",
                // taylor+humana - staging
                "65b7f02c-101b-48ad-b639-b3b11479389f",
              ]
              const showRegistration = humanaAccounts.includes(
                experience.organizer_id,
              )
              if (showRegistration) {
                router.push(`${ACTIVITIES_URI}/${experience.slug}/register`)
                return
              }
              if (experience.no_show_fee) {
                openModal(ActivityModal.NoShowPolicy, {
                  onAccept: async () => {
                    await attendExperienceAsync()
                    await refetch()
                    await waitlistRefetch()
                    openModal(ActivityModal.HouseRules)
                  },
                })
                return
              }
              await attendExperienceAsync()
              await refetch()
              await waitlistRefetch()
              await invalidateAttendees()
              openModal(ActivityModal.HouseRules)
            } catch (error) {
              captureException(
                new Error(`Error creating experience attendee: ${error}`),
              )
            } finally {
              setLoading(false)
            }
          }}
          loading={loading}
        >
          <P bold>
            <FormattedMessage id="activity.attend.attendCta" />
          </P>
        </Button>
      </>
    )
  }

  if (!waitlistIndex && waitlistIndex !== 0) {
    return (
      <Button
        as="button"
        startIconType="bell"
        handlePress={async () => {
          setLoading(true)
          await addToWaitlistAsync()
          await waitlistRefetch()
          openModal(ActivityModal.Waitlist)
          setLoading(false)
        }}
        loading={loading}
      >
        <P bold>
          <FormattedMessage id="activity.waitlist.join" />
        </P>
      </Button>
    )
  }

  return (
    <Button
      as="button"
      startIconType="bell-strikethrough"
      variant="primary"
      handlePress={() => {
        setLoading(true)
        openModal(ActivityModal.CancelWaitlist, {
          refetch: waitlistRefetch,
        })
        setLoading(false)
      }}
      loading={loading}
    >
      <P bold>
        <FormattedMessage id="activity.waitlist.leave" />
      </P>
    </Button>
  )
}
