"use client"

import React from "react"
import { camelCase, upperFirst } from "lodash"
import { twMerge } from "tailwind-merge"

import * as icons from "../../icons"

const sizeMap = {
  sm: "h-4 w-4 text-base",
  md: "h-5 w-5 text-xl",
  lg: "h-6 w-6 text-2xl",
  xl: "h-7 w-7 text-3xl",
  xxl: "h-8 w-8 text-4xl",
}

export type IconProps = {
  type: (typeof icons.iconTypes)[number]
  color?: string
  size?: keyof typeof sizeMap
  className?: string
}

export const Icon = ({
  className,
  type,
  color = "text-gray-950",
  size = "sm",
  ...props
}: IconProps) => {
  const Component: React.FunctionComponent<React.SVGAttributes<SVGElement>> =
    icons[
      `${upperFirst(camelCase(type || "plus"))}Icon` as keyof Omit<
        typeof icons,
        "iconTypes"
      >
    ]
  if (!Component) {
    throw new Error(`Icon type ${type} is not supported`)
  }

  return (
    <Component
      className={twMerge(sizeMap[size], color, className)}
      {...props}
    />
  )
}

export default Icon
