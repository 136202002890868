"use client"

import React, { ComponentType } from "react"
import { twMerge } from "tailwind-merge"

import { Box } from "../Layout"
import { Amex, Discover, Fallback, Mastercard, Visa } from "./logos"

const Icons: Record<string, ComponentType> = {
  amex: Amex,
  discover: Discover,
  mastercard: Mastercard,
  visa: Visa,
}

type Props = {
  brand?: string
  className?: string
}

export const CreditCard = ({ brand, className }: Props) => {
  const Icon = Icons[brand as string] || Fallback

  return (
    <Box className={twMerge("max-h-16 w-16", className)}>
      <Icon />
    </Box>
  )
}
