"use client"

import { FormattedMessage } from "react-intl"

import { H4 } from "@bullseye/components"

const AttendeeHeaderText = ({ count }: { count: number }) => {
  switch (count) {
    case 0:
      return <FormattedMessage id="activity.attendees.header.none" />
    case 1:
      return <FormattedMessage id="activity.attendees.header.one" />
    default:
      return (
        <FormattedMessage
          id="activity.attendees.header.other"
          values={{ count: count }}
        />
      )
  }
}

export const ActivityAttendeeCount = ({ count }: { count: number }) => {
  return (
    <H4>
      <AttendeeHeaderText count={count} />
    </H4>
  )
}
