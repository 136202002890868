"use client"

import { Keyboard } from "react-native"

import { isWeb } from "./device"

export function dismissKeyboardAsync() {
  return new Promise((resolve) => {
    if (isWeb() || !Keyboard.isVisible()) {
      resolve(true)
      return
    }

    const timeout = setTimeout(() => {
      resolve(true)
    }, 500)

    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        clearTimeout(timeout)
        keyboardDidHideListener.remove()
        resolve(true)
      },
    )

    Keyboard.dismiss()
  })
}
