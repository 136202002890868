"use client"

import React, { PropsWithChildren, ReactNode, useState } from "react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Dropdown,
  IconButton,
  List,
  ListDivider,
  ListItem,
  P,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

type MenuItem = {
  title: ReactNode
  titleKey: string
  href?: string
  action?: () => void
  className?: string
}

type NavBarMenuProps = PropsWithChildren<{
  items: MenuItem[][]
}>

export const TopNavBarMenu: React.FC<NavBarMenuProps> = ({
  items,
  children,
}) => {
  const router = useRouter()
  const [open, setOpen] = useState(false)

  const onPress = (item: MenuItem) => {
    setOpen(false)
    if (item.href) {
      return router.push(item.href)
    }
    item.action && item.action()
  }

  return (
    <Dropdown
      id="hamburger-menu"
      title="Menu"
      open={open}
      setOpen={(state) => setOpen(state)}
      position="bottom-left"
      toggleComponent={
        <IconButton
          iconType={"menu"}
          size={"lg"}
          handlePress={() => setOpen(!open)}
        />
      }
    >
      <List className="g-2">
        {items.map((group, gidx) => (
          <React.Fragment key={gidx}>
            {group.map((item, idx) => (
              <ListItem
                key={idx}
                onPress={() => onPress(item)}
                className={clsx("px-0.5 sm:px-4", item.className)}
              >
                {typeof item.title === "string" ? (
                  <P>
                    <FormattedMessage
                      id={item.titleKey}
                      defaultMessage={item.title}
                      description="Navigation link"
                    />
                  </P>
                ) : (
                  item.title
                )}
              </ListItem>
            ))}
            {items.length - gidx > 1 && <ListDivider />}
          </React.Fragment>
        ))}
        {children}
      </List>
    </Dropdown>
  )
}
