"use client"

import { createContext } from "react"

export const LoggingContext = createContext<{
  captureMessage: (message: string) => void
  captureException: (error: Error) => void
} | null>(null)

type ProviderProps = {
  children: React.ReactNode
  captureMessage: (message: string) => void
  captureException: (error: Error) => void
}

export const LoggingContextProvider = ({
  children,
  captureMessage,
  captureException,
}: ProviderProps) => {
  function handleCaptureMessage(message: string) {
    console.debug(message)
    captureMessage(message)
  }

  function handleCaptureException(error: Error) {
    console.error(error)
    captureException(error)
  }

  return (
    <LoggingContext.Provider
      value={{
        captureMessage: handleCaptureMessage,
        captureException: handleCaptureException,
      }}
    >
      {children}
    </LoggingContext.Provider>
  )
}
