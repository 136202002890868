"use client"

import * as Notification from "expo-notifications"
import { FormattedMessage } from "react-intl"

import { Button } from "@bullseye/components"

import { OnboardingStep } from "../../components/OnboardingStep"
import { OnboardingJourney } from "../../layouts"
import { Notification as NotificationImg } from "./assets/Notification"

type Props = {
  advanceToNextStep: () => Promise<void>
  previousPage?: string
}

export const OnboardingNotifications = ({
  previousPage,
  advanceToNextStep,
}: Props) => {
  async function requestPermissions() {
    await Notification.requestPermissionsAsync()
    return advanceToNextStep()
  }

  return (
    <OnboardingJourney previousPage={previousPage}>
      <OnboardingStep
        imageSource={{ uri: NotificationImg }}
        imageAlt="Notifications"
        title={
          <FormattedMessage id="onboarding.notificationPermissions.title" />
        }
        description={
          <FormattedMessage id="onboarding.notificationPermissions.descriptions" />
        }
        primaryAction={
          <Button
            as="button"
            height="lg"
            handlePress={() => {
              return requestPermissions()
            }}
          >
            <FormattedMessage id="onboarding.notificationPermissions.cta" />
          </Button>
        }
      />
    </OnboardingJourney>
  )
}
