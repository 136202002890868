import { useAsyncStorage } from "@bullseye/components"
import { Experience } from "@bullseye/types"

const RECENTLY_VIEWED_EXP_KEY = "RECENTLY_VIEWED_EXP_KEY"

export const useViewExperience = () => {
  const {
    data: experiences,
    isLoading,
    setValue,
  } = useAsyncStorage(RECENTLY_VIEWED_EXP_KEY)

  async function addExperience(exp: Experience) {
    const alreadyViewed = ((experiences || []) as Experience[]).findIndex(
      (e: Experience) => e.id === exp.id,
    )

    if (alreadyViewed !== -1) return

    return setValue([exp, ...((experiences as Experience[]) || [])].slice(0, 5))
  }

  return {
    experiences: (experiences || []) as Experience[],
    isLoading,
    addExperience,
  }
}
