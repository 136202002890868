"use client";

import React from "react";
import { IconButton } from '../IconButton';
import type { IconButtonProps } from '../IconButton';

const BackButton = (props: Omit<IconButtonProps, 'iconType' | 'size' | 'variant' | 'hoverColor' | 'borderColor' | 'active'>) => {
  return (
    <IconButton size="md" variant="plain" iconType="arrow-left" {...props} />
  );
};

export default BackButton;
