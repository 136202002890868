import React from "react"
import clsx from "clsx"

import {
  Box,
  Divider,
  H4,
  TextButton,
  useExperiences,
  useUserContext,
} from "@bullseye/components"
import { ACTIVITIES_URI } from "@bullseye/routes"

import { ActivityCard } from "../../../components"

const SuggestedEventsCard = () => {
  const { user } = useUserContext()

  const { data } = useExperiences({
    suggested: true,
    "origin[lat]": user.lat,
    "origin[long]": user.long,
    "distance[lte]": user.notification_distance,
    limit: 4,
  })

  if (!data?.pages?.[0]?.results) {
    return null
  }

  return (
    <Box className="border-solid border-gray-110 bg-white py-6 md:rounded-lg md:border">
      <Box className="flex flex-row items-center justify-between px-4">
        <H4>Suggested activities</H4>
        <TextButton variant="primary" as="a" href={ACTIVITIES_URI}>
          See all
        </TextButton>
      </Box>
      <Box className="g-4">
        {data?.pages[0].results.map((exp, index) => (
          <Box key={exp.id}>
            <ActivityCard data={exp} currentUserId={user?.id} />
            <Box
              className={clsx("px-4", {
                hidden: index === data?.pages?.[0].results.length - 1,
              })}
            >
              <Divider />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default SuggestedEventsCard
