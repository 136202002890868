"use client"

import { useEffect, useMemo, useState } from "react"

import {
  AlertImage,
  Box,
  Button,
  H3,
  LOGOUT_URI,
  P,
  useLoggingContext,
} from "@bullseye/components"
import { useRouter, useSearchParams } from "@bullseye/navigation"

const useTimedRedirect = (seconds: number, path: string): number => {
  const router = useRouter()
  const [count, setCount] = useState(seconds)

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => Math.max(prevCount - 1, 0))
    }, 1000)
    return () => clearInterval(timer as NodeJS.Timeout)
  }, [router, path])

  useEffect(() => {
    count === 0 && router.push(path)
  }, [count])

  return count
}

const useErrorMessage = () => {
  const sp = useSearchParams()
  const error = sp?.get("error_description")
  const err = Array.isArray(error) ? (error[0] as string) : error
  const { captureException } = useLoggingContext()

  return useMemo(() => {
    let helperText = "An unknown error occurred. Please try again later."
    let helperSubtext = ""
    let buttonHelper = "Log out now"
    let redirectUri = LOGOUT_URI
    switch (err) {
      case "duplicate_account_google":
        helperText = "Please log in with your Google account"
        helperSubtext =
          "Looks like you’ve already signed up with your Google account in the past. Please log in with your Google account to continue."
        buttonHelper = "Log in with Google"
        break
      case "duplicate_account_email":
        helperText = "Please log in with your email and password"
        helperSubtext =
          "Looks like you’ve already signed up with your email and password in the past. Please log in with your email and password to continue."
        buttonHelper = "Log in with email and password"
        break
      case "user_suspended":
        helperText = "Your account has been suspended."
        redirectUri = "/"
        break
      default:
        captureException(new Error(`unhandled login error (${err}): ${error}`))
    }
    return { helperText, helperSubtext, buttonHelper, redirectUri }
  }, [err])
}

export const LoginErrorPage = () => {
  const { helperText, helperSubtext, buttonHelper, redirectUri } =
    useErrorMessage()
  const timeRemaining = useTimedRedirect(30, redirectUri)

  return (
    <Box className="pb-10 pt-24">
      <Box className="pb-15 flex items-center px-4 g-2">
        <AlertImage />
        <Box className="flex items-center justify-center text-center">
          <H3 className="text-center">{helperText}</H3>
        </Box>
        {helperSubtext && (
          <P className="max-w-full text-center md:max-w-[50%]">
            {helperSubtext}
          </P>
        )}
        <P>You will be redirected in {timeRemaining} seconds</P>
        <Box className="flex items-center py-4">
          <Box className="pb-1">
            <Button as="a" href={redirectUri}>
              {buttonHelper}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
