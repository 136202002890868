"use client"

import React from "react"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  ACTIVITIES_URI,
  Box,
  Button,
  HankLogo,
  HOME_URI,
  Link,
  LOGIN_URI,
  P,
  SIGN_UP_URI,
} from "@bullseye/components"

import { TopNavbarContainer } from "../components/TopNavbarContainer"
import { UnauthedMenu } from "./UnauthedMenu"

const menuItems = [
  {
    title: "Activities",
    titleKey: "navigation.explore",
    uri: ACTIVITIES_URI,
    extraClassName: "sm:block hidden",
  },
]

export const UnauthedNavbar = () => {
  return (
    <TopNavbarContainer>
      <Box role="menu" className="flex flex-row items-center gap-6">
        <Box role="menuitem" className="list-none">
          <HankLogo href={HOME_URI} />
        </Box>
        {menuItems.map((item, idx) => (
          <Box
            role="menuitem"
            key={idx}
            className={twMerge("list-none", item.extraClassName)}
          >
            <Link href={item.uri}>
              <P className="font-semibold text-gray-950 no-underline">
                <FormattedMessage
                  id={item.titleKey}
                  defaultMessage={item.title}
                  description="Navigation link"
                />
              </P>
            </Link>
          </Box>
        ))}
      </Box>
      <Box className="flex flex-row flex-nowrap items-center gap-2">
        <Box className="hidden sm:block">
          <Button variant="tertiary" as="a" href={LOGIN_URI}>
            Log in
          </Button>
        </Box>
        <Box>
          <Button as="a" href={SIGN_UP_URI}>
            Get started
          </Button>
        </Box>
        <UnauthedMenu />
      </Box>
    </TopNavbarContainer>
  )
}
