"use client"

import { FormattedMessage } from "react-intl"

import { Box, H4, P } from "@bullseye/components"

export function UnauthedActivityChat() {
  return (
    <Box className="rounded-none bg-white p-6 g-4 md:rounded-lg">
      <H4>
        <FormattedMessage id="activity.chat.title" />
      </H4>
      <P>
        <FormattedMessage id="activity.chat.unauthed" />
      </P>
    </Box>
  )
}
