"use client"

import { createContext } from "react"

export const AnalyticsContext = createContext<{
  client: {
    identify: (userId: string, traits?: any) => Promise<void>
    track: (event: string, properties?: any) => Promise<void>
    page: (name: string, properties?: any) => Promise<void>
    screen: (name: string, properties?: any) => Promise<void>
  }
  config?: Record<string, any>
} | null>(null)
