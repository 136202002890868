import { ProfilePrompt } from "@bullseye/types"

import { useGetRequest } from "./useRequest"

export const useUserPrompts = (userId?: string) => {
  const { data, isLoading, error, refetch } = useGetRequest<{
    data: ProfilePrompt[]
  }>(
    {
      method: "GET",
      path: `v1/users/${userId}/profile-prompts`,
    },
    {
      enabled: !!userId,
    },
  )

  return {
    data: data?.data,
    isLoading,
    error,
    refetch,
  }
}
