"use client"

import React from "react"
import { Image } from "react-native"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  ACTIVITIES_URI,
  Box,
  Button,
  Chip,
  Experience,
  formatCurrency,
  getPublicName,
  P,
  presentDateTime,
  PressableBox,
  useExperienceUrl,
  UserAvatar,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { ShareButton } from "../../ShareButton"

type Props = React.ComponentProps<typeof Box> & {
  data: Experience
  currentUserId?: string
}

export const ActivityCard = ({ className, data, currentUserId }: Props) => {
  const intl = useIntl()
  const router = useRouter()
  const experienceUrl = useExperienceUrl(data.slug)

  const thumbnailUrl =
    data.cover_photo?.url ?? data.event_category?.cover_photo?.url

  const pills: {
    color: React.ComponentProps<typeof Chip>["color"]
    label: string
  }[] = []
  if (currentUserId && data.organizer_id === currentUserId) {
    pills.push({ color: "blue", label: "Host" })
  }
  if (data.requester?.is_attending) {
    pills.push({ color: "green", label: "Going" })
  }
  if (data.canceled_at) {
    pills.push({ color: "red", label: "Canceled" })
  }

  const cityState = [data.city, data.state].filter(Boolean).join(", ")

  return (
    <PressableBox
      role="link"
      onPress={(e) => {
        e.preventDefault()
        return router.push(`${ACTIVITIES_URI}/${data.slug}`)
      }}
      className={twMerge("flex w-full flex-row bg-white p-4 g-4", className)}
    >
      <Box className="flex w-full flex-1 flex-row-reverse g-3 md:flex-row">
        {thumbnailUrl && (
          <Box>
            <Box className="border-px flex h-20 w-20 items-center justify-center rounded-md border border-gray-100 md:h-[102px] md:w-48">
              <Image
                resizeMode="cover"
                source={{ uri: thumbnailUrl ? thumbnailUrl : undefined }}
                style={{ width: "100%", height: "100%", borderRadius: 6 }}
              />
            </Box>
          </Box>
        )}
        <Box className="flex-column flex min-w-0 flex-shrink flex-grow g-1">
          <P className="text-primary-500" ellipsis>
            {presentDateTime(data?.start_date, "Coming soon")}
          </P>
          <P className="text-lg">{data.title}</P>
          {data.venue_type === "in_person" &&
            data.single_line_address_string && (
              <P className="text-gray-600" ellipsis>
                {!currentUserId ? cityState : data.single_line_address_string}
              </P>
            )}
          {data.venue_type === "online" && (
            <P className="text-gray-600" ellipsis>
              <FormattedMessage id="activity.general.online" />
            </P>
          )}
          {data.organizer && !data.is_hostless && (
            <Box className="flex min-w-0 flex-row items-center g-2">
              <UserAvatar size="sm" user={data.organizer} />
              <P ellipsis>{getPublicName(data.organizer)}</P>
            </Box>
          )}
          {(data.ticket_price || false) && (
            <P>
              {formatCurrency(data.ticket_price)}
              {"/"}
              <FormattedMessage id="activity.general.person" />
            </P>
          )}
          <Box
            className={clsx("flex flex-row g-2", {
              hidden: pills.length === 0,
            })}
          >
            {pills.map((p) => (
              <Chip color={p.color} key={p.label} label={p.label} />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="flex-column hidden g-2 md:flex">
        <Button
          as="a"
          href={`${ACTIVITIES_URI}/${data.slug || data.id}`}
          variant="tertiary"
          compact
        >
          <FormattedMessage id="activity.general.view" />
        </Button>
        <ShareButton
          emailSubject={intl.formatMessage(
            {
              id: "activity.share.subject",
            },
            { title: data.title },
          )}
          message={intl.formatMessage(
            {
              id: "activity.share.message",
            },
            { title: data.title, url: experienceUrl },
          )}
          url={experienceUrl}
        />
      </Box>
    </PressableBox>
  )
}
