"use client"

import { Box, P } from "@bullseye/components"

export function NoReplyMessage({ message }: { message: React.ReactNode }) {
  return (
    <Box className="flex h-12 w-full items-center justify-center border-t border-solid border-gray-50 bg-white">
      <P className="text-gray-600">{message}</P>
    </Box>
  )
}
