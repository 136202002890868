"use client"

import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Button,
  Dropdown,
  Experience,
  List,
  ListDivider,
  ListItem,
  P,
  useUserContext,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"
import { ACTIVITIES_URI, DUPLICATE_ACTIVITY_UI } from "@bullseye/routes"

import { ActivityModal, useActivityModalContext } from "../../../../components"
import { CancelActivity } from "./CancelActivity"
import { DownloadCSV } from "./DownloadActivityCSV"
import { JoinActivityChat } from "./JoinActivityChat"
import { NotifyList } from "./NotifyList"

interface FeaturesProps {
  experience: Experience
  dismiss: () => void
}

const OrganizerFeatures = ({ experience, dismiss }: FeaturesProps) => {
  const router = useRouter()
  return (
    <>
      <ListItem
        onPress={() => {
          dismiss()
          router.push(`${ACTIVITIES_URI}/${experience.slug}/edit`)
        }}
      >
        <P>
          <FormattedMessage id="activity.manageActivity.edit" />
        </P>
      </ListItem>
      <ListItem
        onPress={() => {
          dismiss()
          router.push(`${DUPLICATE_ACTIVITY_UI}/${experience.slug}`)
        }}
      >
        <P>
          <FormattedMessage id="activity.manageActivity.duplicate" />
        </P>
      </ListItem>
      <DownloadCSV experience={experience} />
      <NotifyList dismiss={dismiss} />
      <ListDivider />
    </>
  )
}

const AdminFeatures = ({ experience, dismiss }: FeaturesProps) => {
  const { user } = useUserContext()
  const { openModal } = useActivityModalContext()
  if (!user.admin) return null

  return (
    <>
      <P className="w-full cursor-pointer px-4 py-2 text-zinc-600">
        <FormattedMessage id="activity.manageActivity.adminFeatures" />
      </P>
      <JoinActivityChat experience={experience} dismiss={dismiss} />
      <ListItem
        onPress={() => {
          openModal(ActivityModal.AddAttendees)
          dismiss()
        }}
      >
        <P>
          <FormattedMessage id="activity.manageActivity.addAttendees" />
        </P>
      </ListItem>
      <ListDivider />
    </>
  )
}

export const ManageActivityButton = ({
  experience,
}: {
  href: string
  experience: Experience
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const title = intl.formatMessage({ id: "activity.manageActivity.cta" })

  const featuresProps: FeaturesProps = {
    experience,
    dismiss: () => setIsOpen(false),
  }
  return (
    <Dropdown
      id="manage-activity-dropdown"
      open={isOpen}
      setOpen={(open) => setIsOpen(open)}
      sheetSize="md"
      title={title}
      toggleComponent={
        <Button
          as="button"
          endIconType="chevron-down"
          handlePress={() => setIsOpen(!isOpen)}
          variant="primary"
          compact
          active={isOpen}
        >
          {title}
        </Button>
      }
    >
      <List className="-mx-4 g-2 md:mx-0">
        <OrganizerFeatures {...featuresProps} />
        <AdminFeatures {...featuresProps} />
        <CancelActivity dismiss={featuresProps.dismiss} />
      </List>
    </Dropdown>
  )
}
