"use client"

import React, { useState } from "react"
import { FlatList, RefreshControl, useWindowDimensions } from "react-native"
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl"

import {
  Box,
  getColor,
  H3,
  IconButton,
  Link,
  makeMessengerUri,
  Modal,
  P,
  Sheet,
  useCreateMessageChannel,
  useExperienceContext,
  WaitlistElement,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { UserLockup } from "../../UserLockup"

export const NotifyListModal = ({ isOpen, dismiss }: Modal) => {
  const router = useRouter()
  const { createMessageChannelAsync, status } = useCreateMessageChannel()
  const { height } = useWindowDimensions()
  const { waitlist, waitlistRefetch } = useExperienceContext()
  const [refreshing, setRefreshing] = useState(false)

  if (!waitlist) {
    return null
  }
  return (
    <Sheet
      show={isOpen}
      icon="x"
      onIconPress={dismiss}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="overflow-scroll" style={{ maxHeight: height * 0.8 }}>
            <H3 className="pb-2">
              <FormattedMessage
                id="general.people"
                values={{ count: waitlist.length }}
              />
            </H3>

            <FlatList<WaitlistElement>
              data={waitlist}
              renderItem={({ item }) => (
                <Box className="w-full flex-row justify-between py-2">
                  <Link href={`/p/${item.user.slug}`}>
                    <UserLockup
                      key={item.user.id}
                      user={item.user}
                      subtext={
                        <P className="text-gray-600">
                          <FormattedDate
                            value={new Date(item.created_at)}
                            year="numeric"
                            month="long"
                            day="numeric"
                          />
                          {", "}
                          <FormattedTime value={new Date(item.created_at)} />
                        </P>
                      }
                    />
                  </Link>
                  <IconButton
                    disabled={status === "loading"}
                    iconType="chat"
                    size="md"
                    handlePress={async () => {
                      const { data: mc } = await createMessageChannelAsync({
                        body: {
                          is_group_message: false,
                          users: [{ user_id: item.user.id }],
                        },
                      })
                      dismiss()
                      router.push(makeMessengerUri(mc?.data.stream_channel_id))
                    }}
                  />
                </Box>
              )}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={async () => {
                    setRefreshing(true)
                    await waitlistRefetch()
                    setRefreshing(false)
                  }}
                  tintColor={getColor("bg-primary-500")}
                />
              }
            />
          </Box>
        )
      }}
    />
  )
}
