"use client"

import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import {
  addWeeks,
  endOfToday,
  endOfTomorrow,
  endOfWeek,
  startOfHour,
  startOfTomorrow,
  startOfWeek,
} from "date-fns"
import { FormattedMessage } from "react-intl"

import { useAsyncStorage } from "../../hooks/useAsyncStorage"
import { Select, SelectProps } from "../Select"

export enum DateRange {
  ALL = "any-day",
  TODAY = "today",
  TOMORROW = "tomorrow",
  THIS_WEEK = "this-week",
  NEXT_WEEK = "next-week",
}

const dateOptions = [
  {
    label: <FormattedMessage id={`filters.date.options.any-day`} />,
    value: DateRange.ALL,
  },
  {
    label: <FormattedMessage id={`filters.date.options.today`} />,
    value: DateRange.TODAY,
  },
  {
    label: <FormattedMessage id={`filters.date.options.tomorrow`} />,
    value: DateRange.TOMORROW,
  },
  {
    label: <FormattedMessage id={`filters.date.options.this-week`} />,
    value: DateRange.THIS_WEEK,
  },
  {
    label: <FormattedMessage id={`filters.date.options.next-week`} />,
    value: DateRange.NEXT_WEEK,
  },
]

type Props = Omit<
  SelectProps,
  "onChange" | "options" | "defaultValue" | "defaultLabel"
> & {
  onChange(val: DateRange): void
}

export const DateFilter = (props: Props) => {
  return (
    <Select
      name={<FormattedMessage id="filters.date.header" />}
      defaultLabel={<FormattedMessage id="filters.date.options.any-day" />}
      defaultValue={DateRange.ALL}
      options={dateOptions}
      {...props}
    />
  )
}

type PersistentProps = Props & {
  storageKey: string
}

export const PersistentDateFilter = forwardRef(
  (
    { onChange, storageKey = "date-filter", value, ...props }: PersistentProps,
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      reset() {
        handleChange(DateRange.ALL)
      },
    }))

    const { data: storedValue, setValue } = useAsyncStorage(
      storageKey,
      DateRange.ALL,
    )

    useEffect(() => {
      onChange(storedValue as DateRange)
    }, [storedValue])

    const handleChange = (value: DateRange) => {
      void setValue(value)
    }

    return <DateFilter value={value} onChange={handleChange} {...props} />
  },
)

PersistentDateFilter.displayName = "PersistentDateFilter"

type FormattedProps = Omit<PersistentProps, "onChange" | "id"> & {
  onChange(
    val: DateRange,
    formattedVal: { [k: string]: string | undefined },
  ): void
}

export const FormattedDateFilter = forwardRef(
  ({ onChange, ...props }: FormattedProps, ref) => {
    const [value, setValue] = useState<DateRange>(DateRange.ALL)

    const getQueryByType = (val: DateRange) => {
      if (!val) {
        return {}
      }

      switch (val) {
        case DateRange.TODAY:
          return {
            "start_date[gte]": startOfHour(new Date()).toISOString(),
            "start_date[lte]": endOfToday().toISOString(),
            "start_date[not]": "null",
          }
        case DateRange.TOMORROW:
          return {
            "start_date[gte]": startOfTomorrow().toISOString(),
            "start_date[lte]": endOfTomorrow().toISOString(),
            "start_date[not]": "null",
          }
        case DateRange.THIS_WEEK:
          return {
            "start_date[gte]": startOfHour(new Date()).toISOString(),
            "start_date[lte]": endOfWeek(new Date(), {
              weekStartsOn: 0,
            }).toISOString(),
            "start_date[not]": "null",
          }
        case DateRange.NEXT_WEEK:
          return {
            "start_date[gte]": startOfWeek(addWeeks(new Date(), 1), {
              weekStartsOn: 0,
            }).toISOString(),
            "start_date[lte]": endOfWeek(addWeeks(new Date(), 1), {
              weekStartsOn: 0,
            }).toISOString(),
            "start_date[not]": "null",
          }
        case DateRange.ALL:
        default:
          return {
            "start_date[gte]": startOfHour(new Date()).toISOString(),
          }
      }
    }

    const handleChange = (val: DateRange) => {
      onChange(val, getQueryByType(val))
      setValue(val)
    }

    return (
      <PersistentDateFilter
        id={props.storageKey}
        onChange={handleChange}
        value={value}
        ref={ref}
        {...props}
      />
    )
  },
)

FormattedDateFilter.displayName = "FormattedDateFilter"
