import { FormattedMessage } from "react-intl"

import { ListItem, P } from "@bullseye/components"

import { ActivityModal, useActivityModalContext } from "../../../../components"

export function CancelActivity({ dismiss }: { dismiss: VoidFunction }) {
  const { openModal } = useActivityModalContext()
  return (
    <ListItem
      onPress={() => {
        dismiss()
        openModal(ActivityModal.CancelActivity)
      }}
    >
      <P className="text-red-700">
        <FormattedMessage id="activity.manageActivity.cancelActivity" />
      </P>
    </ListItem>
  )
}
