"use client"

import { PropsWithChildren, useEffect } from "react"
import { Pressable } from "react-native"
import { useIntercom } from "react-use-intercom"

import { User } from "@bullseye/types"

import { HelpCircle } from "../Image"
import { Box } from "../Layout"

const IntercomHelperContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Box className="z-9 fixed bottom-20 right-3 cursor-pointer pb-safe md:bottom-5 md:right-5">
    {children}
  </Box>
)

const AvatarContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Box className="transition-height transition-width flex h-[48px] w-[48px] items-center justify-center rounded-full bg-white shadow-md md:h-[60px] md:w-[60px]">
    {children}
  </Box>
)

type Props = {
  userRequired?: boolean
  user?: User
}

export const IntercomHelper = ({ userRequired = false, user }: Props) => {
  const { boot, show } = useIntercom()

  useEffect(() => {
    if (userRequired && !user) return
    boot(
      user
        ? {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            userId: user.id,
            createdAt: user.created_at,
            avatar: {
              type: "avatar",
              imageUrl: user.avatar?.url,
            },
          }
        : undefined,
    )
  }, [user, boot])

  return (
    <IntercomHelperContainer>
      <Pressable onPress={show}>
        <AvatarContainer>
          <HelpCircle />
        </AvatarContainer>
      </Pressable>
    </IntercomHelperContainer>
  )
}
