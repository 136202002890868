import { useQuery } from "@tanstack/react-query"

import { StreamError } from "@bullseye/types"

import { useMessagingContext } from "../providers/MessagingProvider"

export const useMutualChannel = (
  streamIds: Array<string | undefined | null> = [],
) => {
  const { client } = useMessagingContext()
  const res = useQuery(
    ["mutualChannels", ...streamIds],
    async () => {
      return client.queryChannels({
        members: { $eq: streamIds },
        joined: { $eq: true },
        type: "messaging",
        limit: 1,
      })
    },
    {
      enabled: !!streamIds.length,
    },
  )

  return {
    ...res,
    channel: res.data?.[0],
    error: res.error as StreamError,
  }
}
