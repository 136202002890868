import { MessageChannel } from "@bullseye/types"

import { useUserContext } from "../providers"
import { useGetRequest } from "./useRequest"

export const useExperienceMessageChannel = (id: string | undefined | null) => {
  const { accessToken } = useUserContext()
  const { data, isLoading, error, refetch, isRefetching } = useGetRequest<{
    data: MessageChannel
  }>(
    {
      method: "GET",
      path: `experiences/${id}/message-channels`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!id && !!accessToken,
    },
  )

  return {
    messageChannel: data?.data,
    isLoading,
    error,
    refetch,
    isRefetching,
  }
}
