import React from "react"
import { clsx } from "clsx"

import { Box, Divider, H4, useUserContext } from "@bullseye/components"

import { ActivityCard } from "../../../components"
import { useViewExperience } from "../../../hooks"

const RecentlyViewedEventsCard = () => {
  const { experiences } = useViewExperience()
  const { user } = useUserContext()

  if (!experiences.length) {
    return null
  }

  return (
    <Box className="border-solid border-gray-110 bg-white py-6 md:rounded-lg md:border">
      <Box className="flex flex-row items-center justify-between px-4">
        <H4>Recently viewed</H4>
      </Box>
      <Box className="g-4">
        {experiences.map((event, index) => (
          <Box key={event.id}>
            <ActivityCard data={event} currentUserId={user?.id} />
            <Box
              className={clsx("px-4", {
                hidden: index === experiences.length - 1,
              })}
            >
              <Divider />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default RecentlyViewedEventsCard
