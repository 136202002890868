"use client"

import { yupResolver } from "@hookform/resolvers/yup"
import { addDays } from "date-fns"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import * as yup from "yup"

import { useTracking, useUserContext } from "@bullseye/components"
import { Experience } from "@bullseye/types"

import "yup-phone-lite"

export const useRegistrationForm = () => {
  const { user } = useUserContext()

  const intl = useIntl()
  const track = useTracking()

  const schema = yup.object().shape({
    permissionToContact: yup
      .object()
      .shape({
        accepted: yup.boolean(),
        denied: yup.boolean(),
      })
      .test(
        "required",
        intl.formatMessage({ id: "registration.validation.selectToContinue" }),
        (value) => {
          return value.accepted || value.denied
        },
      ),
    phone: yup
      .string()
      .phone("US", intl.formatMessage({ id: "validation.phone" }))
      .nullable(),
    typesOfCoverage: yup.object().shape({
      prescriptions: yup.boolean(),
      hearing: yup.boolean(),
      vision: yup.boolean(),
      dental: yup.boolean(),
    }),
  })
  const form = useForm({
    defaultValues: {
      permissionToContact: {
        accepted: undefined,
        denied: undefined,
      },
      phone: user.formatted_phone,
      typesOfCoverage: {
        prescriptions: undefined,
        hearing: undefined,
        vision: undefined,
        dental: undefined,
      },
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  })

  type Form = yup.InferType<typeof schema>

  async function submit(data: Form, experience?: Experience) {
    const typesOfCoverage = Object.keys(data.typesOfCoverage).filter(
      (option) => (data.typesOfCoverage as Record<string, boolean>)[option],
    )
    const accepted = data.permissionToContact.accepted === true

    const properties = {
      user_id: user.id,
      experience_id: experience?.id,
      sponsoring_organization: experience?.organizer?.formatted_name,
      accepted,
    }
    if (accepted) {
      const acceptedProps = {
        phone: data.phone,
        email: user.email,
        dob: user.dob,
        typesOfCoverage,
        date_signed: new Date().toISOString(),
        expiration_date: addDays(new Date(), 90).toISOString(),
      }
      Object.assign(properties, acceptedProps)
    }

    return track("activity_registration", properties)
  }

  return {
    form,
    submit,
  }
}
