"use client"

import React, { ReactNode } from "react"
import { Pressable, PressableProps } from "react-native"
import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { Box } from "../Layout"
import { P } from "../Typography"

export type RadioProps = {
  className?: string
  disabled?: boolean
  checked?: boolean
  name?: string
  onChange(checked: boolean): void
  fullWidth?: boolean
  reverse?: boolean
  label?: ReactNode
  box?: boolean
  children?: PressableProps["children"]
  testID?: string
}

const Container = styled(Pressable)

export const Radio = ({
  className,
  onChange,
  fullWidth,
  reverse,
  checked = false,
  disabled,
  label,
  box = false,
  children,
  testID,
}: RadioProps) => {
  const toggle = () => {
    onChange(!checked)
  }

  const onPress = () => {
    !disabled && toggle()
  }

  const containerClassName = twMerge(
    clsx(
      "flex flex-row justify-start gap-3 items-center",
      {
        "flex-grow justify-between": fullWidth,
        "cursor-not-allowed text-gray-110": disabled,
        "flex-row-reverse": reverse,
        "rounded-lg border border-gray-100 p-4": box,
      },
      className,
    ),
  )

  const toggleClassName = twMerge(
    clsx(
      "p-1 border border-2 rounded-full border-gray-110 flex items-center justify-center w-6 h-6 bg-white g-3",
      "aria-checked:border-primary-500 aria-disabled:border-gray-110",
      {
        "border-primary-500": checked,
        "border-gray-110": disabled,
      },
    ),
  )

  return (
    <Container
      className={containerClassName}
      onPress={onPress}
      aria-checked={checked}
      aria-disabled={disabled}
      role="radio"
      testID={testID}
    >
      <>
        <Box
          aria-checked={checked}
          aria-disabled={disabled}
          className={toggleClassName}
        >
          {checked && (
            <Box
              className="h-full w-full rounded-full bg-primary-500 aria-disabled:bg-gray-110"
              aria-disabled={disabled}
            />
          )}
        </Box>
        {label && <P>{label}</P>}
        {children}
      </>
    </Container>
  )
}
