"use client"

import { FormattedMessage } from "react-intl"

import { Button } from "@bullseye/components"

import { OnboardingStep } from "../../components/OnboardingStep"
import { OnboardingJourney } from "../../layouts"
import { Wave } from "./assets/Wave"

type Props = {
  nextPage: string
  previousPage?: string
}

export function OnboardingSuccess({ nextPage, previousPage }: Props) {
  return (
    <OnboardingJourney previousPage={previousPage}>
      <OnboardingStep
        imageSource={{ uri: Wave }}
        imageAlt="Success"
        title={<FormattedMessage id="onboarding.success.title" />}
        description={<FormattedMessage id="onboarding.success.description" />}
        primaryAction={
          <Button as="a" height="lg" href={nextPage}>
            <FormattedMessage id="onboarding.success.cta" />
          </Button>
        }
      />
    </OnboardingJourney>
  )
}
