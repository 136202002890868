"use client"

import { useContext } from "react"
import {
  UserProvider as Auth0UserProvider,
  useUser,
} from "@auth0/nextjs-auth0/client"

import { LoadingFullScreen } from "../../components/LoadingFullScreen"
import { AccessTokenContext } from "./AccessTokenProvider"
import { AuthUserContext, ErrMissingAuth0User } from "./AuthContextProvider"
import { Props } from "./index.d"
import { UserProvider } from "./UserProvider"

const AuthProvider = ({ children, onAuthError }: Props) => {
  const { user: auth0User, isLoading, error } = useUser()
  const { getAccessToken } = useContext(AccessTokenContext)
  if (isLoading) {
    return <LoadingFullScreen />
  }

  if (error) {
    onAuthError(error)
    return null
  }

  if (!auth0User) {
    onAuthError(ErrMissingAuth0User)
    return null
  }

  return (
    <AuthUserContext.Provider
      value={{
        auth0User,
        getCredentials: getAccessToken,
      }}
    >
      <UserProvider
        onError={onAuthError}
        authCtx={{
          auth0User,
        }}
      >
        {children}
      </UserProvider>
    </AuthUserContext.Provider>
  )
}

export default function UserAuthProvider(props: Props) {
  return (
    <Auth0UserProvider>
      <AuthProvider {...props} />
    </Auth0UserProvider>
  )
}

export { useUserContext } from "./UserProvider"
