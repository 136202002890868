"use client"

import { PropsWithChildren } from "react"
import { IntercomProvider as ReactIntercom } from "react-use-intercom"

import { User } from "@bullseye/types"

import { IntercomHelper } from "../../components/IntercomHelper"

export type IntercomProviderProps = PropsWithChildren<{
  appId: string
  enabled?: boolean
  userRequired?: boolean
  user?: User
}>

export const IntercomProvider = ({
  children,
  appId,
  enabled,
  userRequired,
  user,
}: IntercomProviderProps) => {
  return (
    <ReactIntercom appId={appId}>
      {children}
      {enabled && <IntercomHelper user={user} userRequired={userRequired} />}
    </ReactIntercom>
  )
}
