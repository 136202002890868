"use client"

import { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { FormattedMessage, useIntl } from "react-intl"

import { BirthdatePickerProps } from "."
import { useLocale } from "../../hooks"
import { Box } from "../Layout"
import Picker from "../Picker"
import { P } from "../Typography"

function years(): { label: string; value: string }[] {
  const years = []
  const fiftyFiveYearsAgo = new Date().getFullYear() - 55
  for (let i = fiftyFiveYearsAgo; i > fiftyFiveYearsAgo - 45; i--) {
    years.push({ label: i.toString(), value: i.toString() })
  }
  return years
}

function monthsForLocale(locale = "en"): { label: string; value: string }[] {
  const months = []

  for (let i = 1; i <= 12; i++) {
    const label = new Date(2020, i - 1, 1).toLocaleString(locale, {
      month: "short",
    })

    months.push({ label, value: i.toString() })
  }
  return months
}

function daysForMonthInYear(
  month: number,
  year: number,
): { label: string; value: string }[] {
  const days = []
  const lastDay = new Date(year, month, 0).getDate()
  for (let i = 1; i <= lastDay; i++) {
    days.push({ label: i.toString(), value: i.toString() })
  }
  return days
}

function BirthdatePicker({
  onChange,
  selectedValue,
  required,
  disabled,
  errorMessage,
}: BirthdatePickerProps) {
  const [year, setYear] = useState<number | undefined>(
    selectedValue?.getFullYear(),
  )
  const [month, setMonth] = useState<number | undefined>(
    selectedValue ? selectedValue.getMonth() + 1 : undefined,
  )
  const [day, setDay] = useState<number | undefined>(selectedValue?.getDay())

  const firstRender = useRef(true)
  useEffect(() => {
    // prevent calling on change method during first render,
    // particularly helpful when providing a default value.
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    if (!month || !year || !day) return

    const date = new Date(year, month - 1, day)
    onChange(date)
  }, [month, year, day])

  const locale = useLocale()
  const months = monthsForLocale(locale?.code)
  const intl = useIntl()
  return (
    <Box className="flex g-2">
      <Box className="flex w-full g-1">
        <Box className="flex w-full flex-row items-center g-2">
          <Box className="flex-1">
            <Picker
              placeholder={intl.formatMessage({
                id: "general.month",
                defaultMessage: "Month",
              })}
              selectedValue={month?.toString()}
              onChange={setMonth}
              items={months}
              required={required}
              disabled={disabled}
              className={clsx({
                "border-red-600 text-red-600": errorMessage,
              })}
            />
          </Box>
          <Box className="flex-1">
            <Picker
              placeholder={intl.formatMessage({
                id: "general.day",
                defaultMessage: "Day",
              })}
              selectedValue={day?.toString()}
              onChange={setDay}
              items={daysForMonthInYear(
                Number(month || 1),
                Number(year || 1970),
              )}
              required={required}
              disabled={disabled}
              className={clsx({
                "border-red-600 text-red-600": errorMessage,
              })}
            />
          </Box>
          <Box className="flex-1">
            <Picker
              placeholder={intl.formatMessage({
                id: "general.year",
                defaultMessage: "Year",
              })}
              selectedValue={year?.toString()}
              onChange={setYear}
              items={years()}
              required={required}
              disabled={disabled}
              className={clsx({
                "border-red-600 text-red-600": errorMessage,
              })}
            />
          </Box>
        </Box>
        {errorMessage && (
          <P
            className={clsx("text-sm text-gray-600", {
              "border-red-600 text-red-600": errorMessage,
            })}
          >
            {errorMessage}
          </P>
        )}
        <P className="text-gray-600" size="sm">
          <FormattedMessage id="general.minAgeRequirement" />
        </P>
      </Box>
    </Box>
  )
}
export default BirthdatePicker
