import { Options } from "."
import { useHTTPContext, useUserContext } from "../../providers"
import { useImpersonationCookie } from "../useImpersonationCookie"
import { MediaMutationOptions, useMediaMutation } from "./useMediaMutation"

const useMediaUploader = (options: Options) => {
  const { accessToken } = useUserContext()
  const { apiBase } = useHTTPContext()
  const cookie = useImpersonationCookie()
  const mutationArgs: MediaMutationOptions = {
    ...options,
    apiBase,
    accessToken,
  }
  if (cookie.impersonate_id) {
    mutationArgs.impersonateId = cookie.impersonate_id
  }

  return useMediaMutation(mutationArgs, (form, file, blob) => {
    const randomId = Math.random().toString(36).substring(7)
    // get file extension from uri (starts with data:image/jpeg;base64, or similar)
    const regExp = new RegExp(/data:image\/([a-zA-Z]*);base64/)
    const extension = file.uri.match(regExp)?.[1]
    const fileName = `${randomId}.${extension}`
    form.append("file", blob, fileName)
  })
}

export default useMediaUploader
