"use client"

import { Pressable } from "react-native"
import { styled } from "nativewind"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import { PaymentMethod } from "@bullseye/types"

import { useCreditCardInfo } from "../../hooks"
import { CreditCard } from "../CreditCard"
import { P } from "../Typography"

type Props = {
  handlePress: (paymentMethod?: PaymentMethod) => void
  paymentMethod?: PaymentMethod
}

const StyledButton = styled(
  Pressable,
  twMerge(
    "flex flex-row g-2 items-center",
    "max-h-12",
    "border-1 border border-solid border-gray-100 p-4 rounded-lg",
    "focus-visible:border-primary-900 focus-visible:bg-primary-100",
  ),
)

export function PaymentMethodButton({ paymentMethod, handlePress }: Props) {
  const cc = useCreditCardInfo(paymentMethod)

  if (!paymentMethod) {
    return (
      <StyledButton role="button" onPress={() => handlePress()}>
        <CreditCard className="w-10" />
        <P>
          <FormattedMessage id="paymentMethod.addCard" />
        </P>
      </StyledButton>
    )
  }

  return (
    <StyledButton
      className="cursor-default"
      role="button"
      onPress={() => handlePress(paymentMethod)}
    >
      <CreditCard brand={paymentMethod?.card?.brand} className="w-10" />
      <P>{cc.number}</P>
      <P className="text-gray-600">{cc.date}</P>
    </StyledButton>
  )
}
