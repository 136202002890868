"use client"

import React from "react"

import {
  Box,
  HankLogo,
  HOME_URI,
  IconButton,
  POST_ACTIVITY_URI,
} from "@bullseye/components"

import { ActivitySearch, UserVerification } from "../../components"
import { PageHeader } from "../../layouts"
import { VerificationOptions } from "../../pages"
import HelpCenterCard from "./components/HelpCenterCard"
import RecentlyViewedEventsCard from "./components/RecentlyViewedEventsCard"
import SuggestedEventsCard from "./components/SuggestedEventsCard"
import UserActivityCta from "./components/UserActivityCta"
import UserEventsCard from "./components/UserEventsCard"

type Props = {
  openVerification: (options: VerificationOptions) => Promise<void>
}

export const HomePage = ({ openVerification }: Props) => {
  return (
    <>
      <PageHeader className="md:hidden">
        <HankLogo href={HOME_URI} />
        <Box className="flex-row items-center g-2">
          <ActivitySearch />
          <Box className="flex">
            <IconButton
              iconType="plus"
              size="lg"
              as="a"
              href={POST_ACTIVITY_URI}
            />
          </Box>
        </Box>
      </PageHeader>
      <Box className="flex w-full grow items-center bg-gray-10">
        <Box className="flex w-full max-w-[1200px] g-2 md:flex-row md:p-4 md:g-8">
          <Box className="w-full g-2 md:w-2/6 md:g-4">
            <UserActivityCta />
            <UserEventsCard />
          </Box>
          <Box className="w-full min-w-0 g-2 md:flex-1 md:g-4">
            <UserVerification embedded openVerification={openVerification} />
            <SuggestedEventsCard />
            <RecentlyViewedEventsCard />
            <HelpCenterCard />
          </Box>
        </Box>
      </Box>
    </>
  )
}
