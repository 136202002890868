"use client"

import { useEffect } from "react"
import clsx from "clsx"
import { FormattedMessage } from "react-intl"
import { twMerge } from "tailwind-merge"

import {
  Avatar,
  Box,
  Experience,
  H4,
  P,
  useChannel,
  useExperienceContext,
  useExperienceMessageChannel,
  useLoggingContext,
  useMessages,
} from "@bullseye/components"

import { OpenActivityChatButton } from "../../../../components/OpenActivityChatButton"

type Props = {
  experience: Experience
  className?: string
  userId?: string
}

export const ActivityChat = ({ className, userId }: Props) => {
  const { experience, isRefetching: refetchingExperience } =
    useExperienceContext()
  const { messageChannel } = useExperienceMessageChannel(experience.id)
  const { channel } = useChannel(messageChannel?.stream_channel_id)
  const {
    messages,
    refetch,
    isRefetching: refetchingMessages,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  } = useMessages(channel as any)
  const isOrganizer = experience.organizer_id === userId
  const isEnabled = experience.requester?.is_attending || isOrganizer
  const { captureException } = useLoggingContext()

  useEffect(() => {
    if (refetchingExperience && !refetchingMessages) {
      refetch().catch((err) => {
        captureException(new Error(`Error refetching messages: ${err}`))
      })
    }
  }, [refetch, refetchingExperience, refetchingMessages])

  return (
    <Box
      className={twMerge(
        "flex flex-col rounded-none bg-white p-6 g-4 md:rounded-lg",
        className,
      )}
    >
      <H4>
        <FormattedMessage id="activity.chat.title" />
      </H4>
      <Box
        className={clsx("g-3", {
          hidden: !messages?.length,
        })}
      >
        {messages?.map((message) => {
          return (
            <Box key={message.id} className="flex-row items-center g-3">
              <Avatar
                imageUrl={(message.user?.image as string) ?? ""}
                name={message.user?.name ?? ""}
                blurred={message.user?.banned}
              />
              <Box className="flex-1">
                <P numberOfLines={2}>{message.text}</P>
              </Box>
            </Box>
          )
        })}
      </Box>
      <Box
        className={clsx({
          hidden: messages?.length,
        })}
      >
        <P>
          <FormattedMessage id="activity.chat.noMessages" />
        </P>
      </Box>
      <OpenActivityChatButton isEnabled={isEnabled} channelId={channel?.id} />
    </Box>
  )
}
