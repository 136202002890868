"use client"

import { useState } from "react"
import { Pressable } from "react-native"
import clsx from "clsx"
import { isAfter, lastDayOfMonth } from "date-fns"
import { styled } from "nativewind"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H3,
  Loading,
  P,
  PaymentMethod,
  PaymentMethodButton,
  ScrollBox,
  SETTINGS_URI,
  Sheet,
  TextButton,
  useExperienceContext,
  usePaymentMethods,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

import PaymentMethodForm from "../../PaymentMethodForm"

export type NoShowPolicyModalProps = {
  onAccept: () => Promise<void>
}

function checkPMValid(pm: PaymentMethod) {
  if (pm.type !== "card" || !pm.card?.exp_month || !pm.card?.exp_year) {
    return false
  }

  const expirationDate = new Date(
    pm.card.exp_year,
    pm.card.exp_month - 1,
    lastDayOfMonth(new Date(pm.card.exp_year, pm.card.exp_month - 1)).getDate(),
  )

  return isAfter(expirationDate, new Date())
}

function sortDefaultFirst(a: PaymentMethod, b: PaymentMethod) {
  if (a.default) {
    return -1
  }
  if (b.default) {
    return 1
  }
  return 0
}

const StyledPressable = styled(Pressable, "cursor-default")

export const NoShowPolicy = ({
  isOpen,
  dismiss,
  onAccept,
}: Modal<NoShowPolicyModalProps>) => {
  const {
    paymentMethods,
    refetch,
    isLoading: loadingPms,
  } = usePaymentMethods({
    enabled: isOpen,
  })

  const { experience } = useExperienceContext()

  const validCards =
    paymentMethods?.filter(checkPMValid).sort(sortDefaultFirst) || []
  const [firstCard] = validCards
  const [isCreating, setIsCreating] = useState(false)
  const [isAccepting, setIsAccepting] = useState(false)

  return (
    <Sheet
      icon={isCreating ? "arrow-left" : "x"}
      show={isOpen}
      size={isCreating ? "lg" : "md"}
      onIconPress={isCreating ? () => setIsCreating(false) : dismiss}
      dismiss={dismiss}
      renderContent={() => {
        if (isCreating) {
          return (
            <ScrollBox
              className="h-full md:pb-4"
              contentContainerStyle={{ flexGrow: 1 }}
            >
              <PaymentMethodForm
                onSuccess={async () => {
                  await refetch()
                  setIsCreating(false)
                }}
              />
            </ScrollBox>
          )
        }
        if (loadingPms) return <Loading />
        return (
          <ScrollBox className="pb-10" contentContainerStyle={{ flexGrow: 1 }}>
            <StyledPressable>
              <Box className="flex g-4">
                <H3>
                  <FormattedMessage id="activity.noShowPolicy.title" />
                </H3>
                <P>
                  <FormattedMessage
                    id="activity.noShowPolicy.description"
                    values={{ price: experience.no_show_fee }}
                  />
                </P>
                <Box className="flex items-start g-4">
                  <Box className="w-full">
                    <PaymentMethodButton
                      handlePress={(pm) => {
                        if (pm) {
                          return
                        }

                        setIsCreating(true)
                      }}
                      paymentMethod={firstCard}
                    />
                  </Box>
                  <Box
                    className={clsx({
                      hidden: !paymentMethods?.length,
                    })}
                  >
                    <TextButton as="a" underlined href={SETTINGS_URI}>
                      <FormattedMessage id="activity.noShowPolicy.manage" />
                    </TextButton>
                  </Box>
                </Box>
              </Box>
            </StyledPressable>
          </ScrollBox>
        )
      }}
      renderFooter={() => {
        if (loadingPms || isCreating) return null
        if (!firstCard) {
          return (
            <Box className="flex items-center g-3">
              <Button as="button" handlePress={() => null} disabled>
                <FormattedMessage id="paymentMethod.addToContinue" />
              </Button>
            </Box>
          )
        }

        return (
          <Box className="flex items-center g-3">
            <Button
              as="button"
              handlePress={async () => {
                setIsAccepting(true)
                await onAccept()
                dismiss()
                setIsAccepting(false)
              }}
              loading={isAccepting}
              height="lg"
            >
              <FormattedMessage id="activity.noShowPolicy.completeRegistration" />
            </Button>
          </Box>
        )
      }}
    />
  )
}
