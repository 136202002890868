import { differenceInMinutes, format } from "date-fns"
import { DateTime } from "luxon"

type dateInput = DateTime | string | null | undefined

const dateInputToDt = (ts: dateInput) => {
  if (!ts) return null
  return typeof ts === "string" ? DateTime.fromISO(ts) : ts
}

export const presentDateTime = (ts: dateInput, emptyState = "") => {
  const t = dateInputToDt(ts)
  if (!t) return emptyState
  return t.toFormat("ccc, LLL d • h:mm a ZZZZ")
}

export function dateFromNowInWords(
  datetime: string | number,
  {
    isAgo = false,
    isFull = false,
    maxPeriod = "days",
  }: {
    isAgo?: boolean
    isFull?: boolean
    maxPeriod?: "days" | "weeks" | "months" | "years"
  } = {},
) {
  const date = new Date(datetime)
  const now = Date.now()
  const minutesAgo = differenceInMinutes(now, date)

  switch (true) {
    case minutesAgo < 1:
      return isFull ? `a few seconds${isAgo ? " ago" : ""}` : "Just now"
    case minutesAgo < 60:
      const minutes = Math.floor(minutesAgo)
      return `${minutes}${isFull ? ` minute${minutes > 1 ? "s" : ""}` : "m"}${
        isAgo ? " ago" : ""
      }`
    case minutesAgo < 24 * 60:
      const hours = Math.floor(minutesAgo / 60)
      return `${hours}${isFull ? ` hour${hours > 1 ? "s" : ""}` : "h"}${
        isAgo ? " ago" : ""
      }`
    case minutesAgo < 24 * 60 * 7 || maxPeriod === "days":
      const days = Math.floor(minutesAgo / (60 * 24))
      return `${days}${isFull ? ` day${days > 1 ? "s" : ""}` : "d"}${
        isAgo ? " ago" : ""
      }`
    case minutesAgo < 24 * 60 * 30 || maxPeriod === "weeks":
      const weeks = Math.floor(minutesAgo / (60 * 24 * 7))
      return `${weeks}${isFull ? ` week${weeks > 1 ? "s" : ""}` : "w"}${
        isAgo ? " ago" : ""
      }`
    case minutesAgo < 24 * 60 * 365 || maxPeriod === "months":
      const months = Math.floor(minutesAgo / (60 * 24 * 30))
      return `${months}${isFull ? ` month${months > 1 ? "s" : ""}` : "mth"}${
        isAgo ? " ago" : ""
      }`
    case minutesAgo >= 24 * 60 * 365 || maxPeriod === "years":
      const years = Math.floor(minutesAgo / (60 * 24 * 365))
      return `${years}${isFull ? ` year${years > 1 ? "s" : ""}` : "y"}${
        isAgo ? " ago" : ""
      }`
    default:
      return format(date, "MMM d 'at' p")
  }
}
