"use client"

import { useState } from "react"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H3,
  Loading,
  useAppConfig,
  useSetupPaymentMethod,
} from "@bullseye/components"
import { usePathname } from "@bullseye/navigation"

import { PaymentMethodFormProps } from "."

function FormInner({ onSuccess }: PaymentMethodFormProps) {
  const elements = useElements()
  const stripe = useStripe()
  const [isLoading, setLoading] = useState(false)
  const pathname = usePathname()

  async function handleSubmit() {
    if (!stripe || !elements) {
      throw new Error("Form submission initiated before Stripe loaded")
    }

    setLoading(true)
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.hostname}/${pathname}`,
      },
      redirect: "if_required",
    })

    onSuccess()
    setLoading(false)
    if (error) {
      throw new Error("Unable to set new payment method")
    }
  }

  if (!stripe || !elements) {
    return <Loading />
  }

  return (
    <Box className="flex h-full justify-between">
      <Box className="g-6">
        <H3>
          <FormattedMessage id="paymentMethod.add.title" />
        </H3>
        <PaymentElement />
      </Box>
      <Button as="button" handlePress={handleSubmit} loading={isLoading}>
        <FormattedMessage id="general.submit" />
      </Button>
    </Box>
  )
}

const PaymentMethodForm = (props: PaymentMethodFormProps) => {
  const { stripePublishableKey } = useAppConfig()
  const [stripe] = useState(() => {
    if (!stripePublishableKey) {
      throw new Error("Stripe publishable key is not set")
    }
    return loadStripe(stripePublishableKey)
  })

  const { data, isLoading } = useSetupPaymentMethod()

  if (isLoading || !data?.data) return <Loading />

  return (
    <Elements
      stripe={stripe as any}
      options={{ clientSecret: data.data?.[0]?.secret }}
    >
      <FormInner {...props} />
    </Elements>
  )
}

export default PaymentMethodForm
