"use client"

import { useState } from "react"
import { useCalendars } from "expo-localization"
import { yupResolver } from "@hookform/resolvers/yup"
import clsx from "clsx"
import { isPast, startOfDay } from "date-fns"
import { styled } from "nativewind"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import {
  Box,
  Button,
  DatePicker,
  H4,
  IconButton,
  Image,
  InputSheet,
  isWeb,
  Loading,
  P,
  Picker,
  TextInput,
  TimePicker,
  useCreateExperience,
  useGooglePlace,
  useLoggingContext,
  usePlaceImage,
  useUpdateExperience,
  useUserContext,
} from "@bullseye/components"
import { ErrBadGateway } from "@bullseye/components/src/hooks/useRequest"
import { useRouter } from "@bullseye/navigation"
import { ACTIVITIES_URI } from "@bullseye/routes"
import { ActivityForm, Experience, V1APIResponse } from "@bullseye/types"

import { ApplicationModal, useApplicationModalContext } from "../../components"
import { SearchPlaces } from "../../components/SearchPlaces"
import { useToastContext } from "../../providers"
import { CoverPhoto } from "./components/CoverPhoto"
import { TimeZone } from "./components/TimeZone"
import { formatDate } from "./utils/formatDate"
import { useValidationSchema } from "./utils/validation"

const Section = styled(Box, "bg-white p-4 g-3 md:rounded-lg")

type Props = {
  experience?: Experience
  scrollToTop?: VoidFunction
  defaultValues?: Partial<ActivityForm>
}

export const ActivityCreatePage = ({
  experience,
  scrollToTop,
  defaultValues,
}: Props) => {
  const [userCal] = useCalendars()
  const { user } = useUserContext()
  const { openModal, closeModal } = useApplicationModalContext()
  const { openErrorToast } = useToastContext()
  const intl = useIntl()
  const router = useRouter()

  const [today] = useState(startOfDay(new Date()))
  const isCreatingExperience = !experience

  const schema = useValidationSchema({ experience, isCreatingExperience })

  const form = useForm<yup.InferType<typeof schema>>({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const venueType = form.watch("venue_type", defaultValues.venue_type)
  const selectedPlace = form.watch("selectedPlace", defaultValues.selectedPlace)
  const startDate = form.watch("startDate", defaultValues.startDate)
  const { data: place } = useGooglePlace(selectedPlace?.google_place_id)
  const { data: image, isLoading: loadingPlace } = usePlaceImage(place, {
    width: 686,
    height: 240,
  })
  const showImage = selectedPlace?.google_place_id && (image || loadingPlace)

  const { createExperienceAsync } = useCreateExperience()
  const { updateExperienceAsync } = useUpdateExperience(experience)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const logging = useLoggingContext()

  async function submit(data: ActivityForm) {
    try {
      setIsSubmitting(true)
      if (isCreatingExperience) {
        openModal(ApplicationModal.LoadingState, {
          loadingReason: intl.formatMessage({ id: "activityForm.loading" }),
        })
      }
      const { startDate, startTime, selectedPlace } = data

      const coverPhoto = data.cover_photo
      const formattedData = {
        title: data.title.trim().replace(/\s+/g, " "),
        description: data.description,
        venue_type: data.venue_type,
        google_place_id:
          data.venue_type === "in_person" ? selectedPlace.google_place_id : "",
        url: data.venue_type === "online" ? data.url : "",
        start_date:
          startDate && startTime ? formatDate(startDate, startTime) : undefined,
        timezone: data.venue_type === "online" ? data.timezone : undefined,
        ticket_url: data.ticket_url,
        max_attendees: data.max_attendees,
        no_show_fee: user.admin ? data.no_show_fee : undefined,
        is_hostless: user.admin ? data.is_hostless === "true" : undefined,
        cover_photo: coverPhoto,
      }

      let res: V1APIResponse<Experience>
      if (isCreatingExperience) {
        const { data } = await createExperienceAsync({ body: formattedData })
        res = data

        router.replace(`${ACTIVITIES_URI}/${res?.data?.[0].slug}`)
        form.reset()
      } else {
        const { data } = await updateExperienceAsync(
          {
            body: formattedData,
          },
          coverPhoto,
        )
        res = data
        router.canGoBack?.()
          ? router.back()
          : router.replace(`${ACTIVITIES_URI}/${res?.data?.[0].slug}`)
        form.reset()
      }
    } catch (error: any) {
      let label = intl.formatMessage({ id: "errors.unknown" })
      let delayMs = 3000
      if (error === ErrBadGateway) {
        label = intl.formatMessage({ id: "errors.inputError" })
        delayMs = 5000
      }
      logging.captureException(
        new Error(
          `An error occurred while ${
            isCreatingExperience ? "creating" : "updating"
          } an experience: ${error}`,
        ),
      )
      openErrorToast({
        label,
        delayMs,
      })
    } finally {
      if (isCreatingExperience) {
        closeModal(ApplicationModal.LoadingState)
      }
      setIsSubmitting(false)
    }
  }

  const isPastOrCanceled = isPast(startDate) || !!experience?.canceled_at
  // If a user is not an admin and the experience is in the past
  // or canceled, only certain fields should be editable
  const disableFields = !user.admin && experience && isPastOrCanceled

  return (
    <FormProvider {...form}>
      <Box className="flex w-full items-center bg-gray-10 md:py-10">
        <Box className="flex w-full items-center bg-gray-10 md:max-w-[640px]">
          <Box className="flex w-full bg-gray-10 g-2 md:g-4">
            <Section>
              <Box
                className={clsx(
                  "flex w-full flex-row items-center justify-between md:hidden",
                )}
              >
                <Box className="flex-1 flex-row items-center justify-start">
                  <IconButton
                    iconType="arrow-left"
                    as="button"
                    handlePress={() => {
                      router.canGoBack?.()
                        ? router.back()
                        : router.push(ACTIVITIES_URI)
                    }}
                    size="lg"
                    name="go-back"
                    testID="go-back"
                  />
                </Box>
                <Box className="flex-1 flex-row items-center justify-center">
                  <P bold>
                    <FormattedMessage
                      id={
                        isCreatingExperience
                          ? "general.postActivity"
                          : "general.updateActivity"
                      }
                    />
                  </P>
                </Box>
                <Box className="flex-1" />
              </Box>
              <Controller
                name="cover_photo"
                disabled={disableFields}
                render={({ field: { onChange, value, disabled } }) => (
                  <CoverPhoto
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                  />
                )}
              />
              <Controller
                name="title"
                render={({
                  field: { onChange, onBlur, value, disabled },
                  fieldState: { error },
                }) => {
                  return (
                    <TextInput
                      label={<FormattedMessage id="activityForm.title" />}
                      onChangeText={onChange}
                      value={value}
                      onBlur={onBlur}
                      editable={!disabled}
                      errorMessage={error?.message}
                      maxLength={100}
                    />
                  )
                }}
              />
              {user.admin && (
                <Controller
                  name="venue_type"
                  disabled={disableFields}
                  render={({
                    field: { onChange, value, disabled },
                    fieldState: { error },
                  }) => {
                    return (
                      <Picker
                        placeholder={intl.formatMessage({
                          id: "activityForm.venue_type",
                        })}
                        onChange={(newValue) => {
                          onChange(newValue)
                          if (newValue === "online") {
                            form.setValue("selectedPlace", null)
                            form.setValue("timezone", userCal.timeZone)
                          } else {
                            form.setValue("url", null)
                            form.setValue("timezone", userCal.timeZone)
                          }
                        }}
                        selectedValue={value}
                        items={[
                          {
                            label: "In person",
                            value: "in_person",
                          },
                          {
                            label: "Online",
                            value: "online",
                          },
                        ]}
                        selectionMode="radio"
                        errorMessage={error?.message}
                        disabled={disabled}
                      />
                    )
                  }}
                />
              )}
              {venueType === "in_person" ? (
                <Controller
                  name="selectedPlace"
                  disabled={disableFields}
                  render={({
                    field: { onChange, value, onBlur, disabled },
                  }) => {
                    return (
                      <>
                        <SearchPlaces
                          disabled={disabled}
                          label={intl.formatMessage({
                            id: "activityForm.location",
                          })}
                          value={(value as ActivityForm["selectedPlace"])?.name}
                          onSelect={(place) => {
                            onChange({
                              place,
                              google_place_id: place.id,
                              name: place.formattedPlaceName,
                            })
                            onBlur()
                          }}
                          onChangeText={(text) => {
                            onChange({
                              ...value,
                              name: text,
                            })
                          }}
                          onDismiss={() => {
                            if (selectedPlace?.place) {
                              onChange({
                                place: selectedPlace.place,
                                google_place_id: selectedPlace.place.id,
                                name: selectedPlace.place.formattedPlaceName,
                              })
                            } else {
                              onChange({
                                place: undefined,
                                google_place_id: undefined,
                                name: "",
                              })
                            }
                            onBlur()
                          }}
                          errorMessage={
                            form.formState.errors?.selectedPlace?.message ||
                            form.formState.errors?.selectedPlace
                              ?.google_place_id?.message
                          }
                        />
                        <Box
                          className={clsx(
                            "flex h-[120px] w-full items-center justify-center overflow-hidden rounded-lg border border-solid border-gray-100 bg-gray-10",
                            {
                              hidden: !showImage,
                            },
                          )}
                        >
                          {loadingPlace && <Loading />}
                          {image && (
                            <Image
                              source={isWeb() ? image : { uri: image }}
                              className="h-full w-full"
                            />
                          )}
                        </Box>
                      </>
                    )
                  }}
                />
              ) : (
                <Controller
                  name="url"
                  disabled={disableFields}
                  render={({
                    field: { onChange, onBlur, value, disabled },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextInput
                        label={<FormattedMessage id="activityForm.url" />}
                        onChangeText={onChange}
                        value={value}
                        onBlur={onBlur}
                        editable={!disabled}
                        errorMessage={error?.message}
                      />
                    )
                  }}
                />
              )}
            </Section>

            <Section className="z-[-1]">
              <Controller
                name="startDate"
                disabled={disableFields}
                render={({ field: { onChange, value, onBlur, disabled } }) => {
                  return (
                    <DatePicker
                      id="start_date"
                      label={intl.formatMessage({
                        id: "activityForm.startDate",
                      })}
                      disabled={disabled}
                      value={value}
                      minDate={today}
                      onSelect={(date) => {
                        onChange(date)
                        onBlur()
                      }}
                      className="flex-grow"
                      errorMessage={form.formState.errors?.startDate?.message}
                      helperText={
                        !isCreatingExperience &&
                        form.formState.dirtyFields["startDate"]
                          ? intl.formatMessage(
                              {
                                id: "activityForm.notificationWarning",
                              },
                              {
                                field: intl
                                  .formatMessage({
                                    id: "activityForm.startDate",
                                  })
                                  .toLowerCase(),
                              },
                            )
                          : undefined
                      }
                    />
                  )
                }}
              />
              <Controller
                name="startTime"
                disabled={disableFields}
                render={({ field: { onChange, onBlur, value, disabled } }) => {
                  return (
                    <Box className="z-[-2]">
                      <TimePicker
                        id="start_time"
                        disabled={disabled}
                        label={intl.formatMessage({
                          id: "activityForm.startTime",
                        })}
                        value={value}
                        onChange={(v) => {
                          onChange(v)
                          onBlur()
                        }}
                        errorMessage={form.formState.errors?.startTime?.message}
                        helperText={
                          !isCreatingExperience &&
                          form.formState.dirtyFields["startTime"]
                            ? intl.formatMessage(
                                {
                                  id: "activityForm.notificationWarning",
                                },
                                {
                                  field: intl
                                    .formatMessage({
                                      id: "activityForm.startTime",
                                    })
                                    .toLowerCase(),
                                },
                              )
                            : undefined
                        }
                      />
                    </Box>
                  )
                }}
              />
              <Controller
                name="timezone"
                disabled={disableFields}
                render={({ field: { onChange, value, disabled } }) => {
                  return (
                    <Box className="z-[-3]">
                      <TimeZone
                        date={startDate}
                        coordinates={place?.location}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                      />
                    </Box>
                  )
                }}
              />
            </Section>

            <Section className="z-[-3]">
              <Controller
                name="description"
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextInput
                      label={<FormattedMessage id="activityForm.description" />}
                      onChangeText={onChange}
                      value={value}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      multiline
                      grow
                      defaultHeight={120}
                      maxHeight={9999}
                    />
                  )
                }}
              />
            </Section>

            <Section className="z-[-2] flex-1">
              <H4>
                <FormattedMessage id="activityForm.advancedOptions" />
              </H4>
              <Box className="hidden">
                <Controller
                  name="ticket_url"
                  disabled={disableFields}
                  render={({
                    field: { onChange, onBlur, value, disabled },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputSheet
                        iconType="ticket"
                        value={value}
                        onChangeText={(url) => {
                          onChange(url)
                        }}
                        onDismiss={onBlur}
                        label={<FormattedMessage id="activityForm.tickets" />}
                        placeholder={intl.formatMessage({
                          id: "activityForm.ticketsPlaceholder",
                        })}
                        helperText={intl.formatMessage({
                          id: "activityForm.ticketsHelperText",
                        })}
                        errorMessage={error?.message}
                        inputMode="url"
                        disabled={disabled}
                      />
                    )
                  }}
                />
              </Box>
              <Controller
                name="max_attendees"
                disabled={disableFields}
                render={({
                  field: { onChange, onBlur, value, disabled },
                  fieldState: { error },
                }) => {
                  return (
                    <InputSheet
                      iconType="group"
                      value={value}
                      onChangeText={(attendees) => {
                        onChange(attendees ? parseInt(attendees) : 0)
                      }}
                      onDismiss={onBlur}
                      label={
                        <FormattedMessage id="activityForm.maxAttendees" />
                      }
                      placeholder={intl.formatMessage({
                        id: "activityForm.maxAttendeesPlaceholder",
                      })}
                      helperText={intl.formatMessage({
                        id: "activityForm.maxAttendeesHelperText",
                      })}
                      clearText={intl.formatMessage({
                        id: "activityForm.ticketUrl.clear",
                      })}
                      submitText={intl.formatMessage({
                        id: "activityForm.ticketUrl.submit",
                      })}
                      errorMessage={error?.message}
                      inputMode="numeric"
                      disabled={disabled}
                    />
                  )
                }}
              />
            </Section>
            {user.admin && (
              <Section className="z-[-2]">
                <H4>
                  <FormattedMessage id="activityForm.adminOptions" />
                </H4>
                <Controller
                  name="no_show_fee"
                  disabled={disableFields}
                  render={({
                    field: { onChange, onBlur, value, disabled },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputSheet
                        iconType="dollar"
                        value={value}
                        onChangeText={(fee) => {
                          onChange(fee)
                        }}
                        onDismiss={onBlur}
                        label={<FormattedMessage id="activityForm.noShowFee" />}
                        placeholder={intl.formatMessage({
                          id: "activityForm.noShowFeePlaceholder",
                        })}
                        helperText={intl.formatMessage({
                          id: "activityForm.noShowFeeHelperText",
                        })}
                        errorMessage={error?.message}
                        inputMode="decimal"
                        disabled={disabled}
                      />
                    )
                  }}
                />
                <Controller
                  name="is_hostless"
                  disabled={disableFields}
                  render={({
                    field: { onChange, onBlur, value, disabled },
                    fieldState: { error },
                  }) => {
                    return (
                      <Picker
                        uniqueModalId="activity-form-hostless"
                        onChange={(hostless) => {
                          onChange(hostless)
                          onBlur()
                        }}
                        selectedValue={value}
                        items={[
                          {
                            label: "Hostless activity",
                            value: "true",
                          },
                          {
                            label: "Hosted activity",
                            value: "false",
                          },
                        ]}
                        selectionMode="radio"
                        errorMessage={error?.message}
                        disabled={disabled}
                      />
                    )
                  }}
                />
              </Section>
            )}
            <Section className="z-[-4]">
              <Button
                as="button"
                handlePress={async () => {
                  return form.handleSubmit(
                    (data) => submit(data as ActivityForm),
                    () => {
                      scrollToTop?.()
                      openErrorToast({
                        label: intl.formatMessage({
                          id: "validation.activityForm.invalidState",
                        }),
                        delayMs: 3000,
                      })
                      return
                    },
                  )()
                }}
                loading={isSubmitting}
              >
                <FormattedMessage
                  id={
                    isCreatingExperience
                      ? "general.postActivity"
                      : "general.saveChanges"
                  }
                />
              </Button>
            </Section>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  )
}
