"use client"

import { createContext } from "react"

interface AccessTokenContextProps {
  getAccessToken: () => Promise<{ accessToken: string }>
}

export const AccessTokenContext = createContext<AccessTokenContextProps>(
  {} as AccessTokenContextProps,
) // ts hack :/

export const AccessTokenProvider: React.FC<
  React.PropsWithChildren<AccessTokenContextProps>
> = ({ children, getAccessToken }) => (
  <AccessTokenContext.Provider value={{ getAccessToken }}>
    {children}
  </AccessTokenContext.Provider>
)
