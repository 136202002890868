import { UserSubscription, V1APIResponse } from "@bullseye/types"

import { useUserContext } from "../providers"
import { usePostRequest } from "./useRequest"

type RequestBody = {
  partnership_id?: string
}

export const useInitiateSubscription = () => {
  const { accessToken } = useUserContext()
  const { data, isLoading, error, postRequestAsync, status } = usePostRequest<
    V1APIResponse<UserSubscription>,
    RequestBody | undefined | null
  >({
    method: "POST",
    path: "v1/subscriptions/initiate",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    data: data?.data?.[0],
    isLoading,
    error,
    initiateSubscriptionAsync: (partnershipId?: string) =>
      postRequestAsync(
        partnershipId ? { body: { partnership_id: partnershipId } } : null,
      ),
    status,
  }
}
