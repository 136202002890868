import { enUS, fr, nl } from "date-fns/locale"

import { useSupportedLanguage } from "./useSupportedLanguage"

const dateLocales: Record<string, Locale> = {
  en: enUS,
  fr,
  nl,
}

export const useLocale = () => {
  const languageCode = useSupportedLanguage()

  return dateLocales[languageCode]
}
