import { DateTime } from "luxon"

export function formatDate(
  startDate: Date,
  startTime: Date,
  timezone?: string,
): string {
  const mergedDate = DateTime.local(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    startDate.getDate(),
    startTime.getHours(),
    startTime.getMinutes(),
  )

  if (timezone) {
    return mergedDate.setZone(timezone, { keepLocalTime: true }).toISO()
  }
  return mergedDate.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'")
}
