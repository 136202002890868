"use client"

import { useState } from "react"
import {
  Image,
  ImageResolvedAssetSource,
  ImageSourcePropType,
} from "react-native"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  Icon,
  isWeb,
  P,
  useInitiateVerification,
  VerificationResponse,
} from "@bullseye/components"
import { ErrConflict } from "@bullseye/components/src/hooks/useRequest"

import { OnboardingStep } from "../../components"
import { OnboardingJourney } from "../../layouts"
import { useToastContext } from "../../providers"

export type VerificationOptions = {
  sessionId: string
  clientSecret: string
  ephemeralKeySecret: string
  brandLogo: ImageResolvedAssetSource
}

type Props = {
  advanceToNextStep: () => Promise<void>
  openVerificationWindow: (
    options: VerificationOptions,
  ) => Promise<{ status: string; error?: string }>
}

function ListItem({ children }: { children: React.ReactNode }) {
  return (
    <Box className="w-full flex-row items-center justify-start g-3">
      <Icon type="check" color="text-primary-500" size="lg" />
      <P className="flex-1">{children}</P>
    </Box>
  )
}

export function OnboardingVerification({
  advanceToNextStep,
  openVerificationWindow,
}: Props) {
  const { requestVerificationAsync } = useInitiateVerification()
  const { openToast, openErrorToast } = useToastContext()
  const [isLoading, setIsLoading] = useState(false)

  async function handleCtaPress() {
    setIsLoading(true)
    const res = await requestVerificationAsync().catch(async (err: Error) => {
      if (err === ErrConflict) {
        openToast({
          label: (
            <FormattedMessage id="onboarding.verification.toasts.conflict" />
          ),
        })
        await advanceToNextStep()
      }
      return {
        data: {
          data: undefined as VerificationResponse[] | undefined,
        },
        status: undefined,
      }
    })
    const verification = res?.data?.data?.[0]
    if (!verification) {
      setIsLoading(false)
      return
    }

    const { status, error } = await openVerificationWindow({
      sessionId: verification.session_id,
      clientSecret: verification.client_secret,
      ephemeralKeySecret: verification.ephemeral_key_secret,
      brandLogo: isWeb()
        ? undefined
        : Image.resolveAssetSource(
            require("../../assets/hank-icon.png") as ImageSourcePropType,
          ),
    })

    switch (status) {
      case "FlowFailed":
        openErrorToast({
          label: <FormattedMessage id="errors.unknown" />,
        })
        setIsLoading(false)
        return
      case "FlowCanceled":
        setIsLoading(false)
        return
    }

    if (error) {
      openErrorToast({
        label: <FormattedMessage id="errors.unknown" />,
      })
      setIsLoading(false)
      return
    }

    await advanceToNextStep()
    setIsLoading(false)
  }

  async function skipVerification() {
    setIsLoading(true)
    await advanceToNextStep().catch(() => {
      return
    })
    setIsLoading(false)
  }

  return (
    <OnboardingJourney>
      <OnboardingStep
        title={<FormattedMessage id="onboarding.verification.title" />}
        secondaryAction={
          <Button
            as="button"
            height="lg"
            variant="secondary"
            handlePress={skipVerification}
            disabled={isLoading}
          >
            <FormattedMessage id="onboarding.verification.secondaryCta" />
          </Button>
        }
        primaryAction={
          <Button
            as="button"
            height="lg"
            handlePress={handleCtaPress}
            disabled={isLoading}
          >
            <FormattedMessage id="onboarding.verification.primaryCta" />
          </Button>
        }
      >
        <Box className="g-4">
          <FormattedMessage
            id="onboarding.verification.checklist"
            values={{ ul: (text) => <ListItem>{text}</ListItem> }}
          />
        </Box>
      </OnboardingStep>
    </OnboardingJourney>
  )
}
