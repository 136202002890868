"use client"

import { useState } from "react"
import { AsYouType } from "libphonenumber-js"
import { Controller, FormProvider } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"

import {
  Box,
  Button,
  Checkbox,
  P,
  TextInput,
  useUpdateUser,
} from "@bullseye/components"

import { OnboardingStep } from "../../components/OnboardingStep"
import { useRegistrationForm } from "../../hooks"
import { OnboardingJourney } from "../../layouts"
import { useToastContext } from "../../providers"

type Props = {
  advanceToNextStep: () => void
}

export const OnboardingRegistrationPage = ({ advanceToNextStep }: Props) => {
  const [submitting, setSubmitting] = useState(false)
  const { form, submit } = useRegistrationForm()
  const [showSecondStep, setShowSecondStep] = useState(false)

  const { update: updateUser } = useUpdateUser()

  const { openErrorToast } = useToastContext()
  const intl = useIntl()

  type Form = Parameters<typeof submit>[0]

  async function handleSubmit(data: Form) {
    try {
      setSubmitting(true)
      if (!showSecondStep) {
        if (data.phone) {
          await updateUser({ phone: data.phone })
          setShowSecondStep(true)
        }
      } else {
        await submit(data)
        advanceToNextStep()
      }
    } catch {
      openErrorToast({
        label: intl.formatMessage({ id: "errors.unknown" }),
      })
    }
    setSubmitting(false)
  }

  return (
    <FormProvider {...form}>
      <OnboardingJourney>
        <OnboardingStep
          title={
            !showSecondStep ? (
              <FormattedMessage id="onboarding.registration.title" />
            ) : (
              <FormattedMessage id="registration.fields.typesOfCoverage.cta" />
            )
          }
          description={
            !showSecondStep ? (
              <FormattedMessage id="onboarding.registration.subtitle" />
            ) : (
              <FormattedMessage id="registration.fields.typesOfCoverage.description" />
            )
          }
        >
          <Box className="w-full pb-10">
            <Box className="g-10">
              {!showSecondStep ? (
                <Controller
                  name="phone"
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <Box className="g-4">
                      <TextInput
                        label={intl.formatMessage({
                          id: "profile.phone",
                        })}
                        placeholder={intl.formatMessage({
                          id: "registration.fields.phone.placeholder",
                        })}
                        value={value}
                        onChangeText={(phone) => {
                          const priorValue = form.getValues("phone")
                          const next =
                            priorValue?.length > phone?.length
                              ? phone
                              : new AsYouType("US").input(phone)
                          if (next?.length) {
                            form.setValue("permissionToContact", {
                              accepted: true,
                            })
                          } else {
                            form.setValue("permissionToContact", undefined)
                          }
                          onChange(next)
                        }}
                        onBlur={onBlur}
                        inputMode="tel"
                        errorMessage={error?.message}
                        enterKeyHint="done"
                      />
                      <P className="text-center text-gray-600" size="xs">
                        <FormattedMessage id="registration.tcs" />
                      </P>
                    </Box>
                  )}
                />
              ) : (
                <Controller
                  name="typesOfCoverage"
                  render={({ field: { value, onChange } }) => (
                    <Box className="g-4">
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            prescriptions: selected,
                          })
                        }
                        checked={
                          (value as Form["typesOfCoverage"]).prescriptions
                        }
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.prescriptions",
                        })}
                        className="border-1 border-b border-solid border-gray-100 pb-4"
                      />
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            hearing: selected,
                          })
                        }
                        checked={(value as Form["typesOfCoverage"]).hearing}
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.hearing",
                        })}
                        className="border-1 border-b border-solid border-gray-100 pb-4"
                      />
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            vision: selected,
                          })
                        }
                        checked={(value as Form["typesOfCoverage"]).vision}
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.vision",
                        })}
                        className="border-1 border-b border-solid border-gray-100 pb-4"
                      />
                      <Checkbox
                        onChange={(selected) =>
                          onChange({
                            ...value,
                            dental: selected,
                          })
                        }
                        checked={(value as Form["typesOfCoverage"]).dental}
                        label={intl.formatMessage({
                          id: "registration.fields.typesOfCoverage.dental",
                        })}
                      />
                    </Box>
                  )}
                />
              )}
              <Box className="g-2">
                <Button
                  as="button"
                  handlePress={form.handleSubmit(handleSubmit, () => {
                    openErrorToast({
                      label: intl.formatMessage({
                        id: "validation.general",
                      }),
                      delayMs: 3000,
                    })
                  })}
                  loading={submitting}
                  height="lg"
                  variant="primary"
                >
                  <FormattedMessage id="onboarding.registration.primaryCta" />
                </Button>
                <Button
                  as="button"
                  handlePress={advanceToNextStep}
                  disabled={submitting}
                  height="lg"
                  variant="secondary"
                >
                  <FormattedMessage id="onboarding.registration.secondaryCta" />
                </Button>
              </Box>
            </Box>
          </Box>
        </OnboardingStep>
      </OnboardingJourney>
    </FormProvider>
  )
}
