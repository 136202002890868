import { useQuery } from "@tanstack/react-query"

import { useAuthContext } from "../AuthContextProvider"
import { HankCredentials } from "../types"

export const useAccessToken = () => {
  const { getCredentials } = useAuthContext()

  async function getAccessToken(): Promise<HankCredentials | undefined> {
    try {
      const credentials = await getCredentials()
      if (!credentials) {
        throw new Error("Credentials not returned from getCredentials")
      }
      return credentials
    } catch (error) {
      throw error
    }
  }

  const { data, isLoading, error } = useQuery(["accessToken"], getAccessToken, {
    cacheTime: 1000 * 59,
  })

  return {
    accessToken: data?.accessToken || "",
    isLoading,
    error,
  }
}
