"use client"

import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  isWeb,
  Loading,
  useLoggingContext,
} from "@bullseye/components"

import { OnboardingStep } from "../../OnboardingStep"
import { PhotoAdd } from "../assets/PhotoAdd"
import { useProfilePhotoCtx } from "../providers/ProfilePhotoContext"

export const SelectOrCapture = ({ avatarUrl }: { avatarUrl?: string }) => {
  const { captureException } = useLoggingContext()
  const { initiateCamera, selectImageFromLibrary, uploadingStatus } =
    useProfilePhotoCtx()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(uploadingStatus === "loading")
  }, [uploadingStatus])

  async function handleSelection() {
    setIsLoading(true)

    if (isWeb()) {
      // On web, there is a bug with selectImageFromLibrary which prevents
      // the promise from resolving. To get around this, we will set a timeout
      // so the ui doesn't remain permanently in a loading state.
      setTimeout(() => {
        if (uploadingStatus !== "loading") {
          setIsLoading(false)
        }
      }, 10000)
    }

    await selectImageFromLibrary().catch((error) => {
      captureException(
        new Error(`Error selecting image from library: ${error}`),
      )
    })
    setIsLoading(false)
  }

  async function handleCameraInitiation() {
    setIsLoading(true)
    await initiateCamera().catch((error) => {
      captureException(new Error(`Error initiating camera: ${error}`))
    })
    setIsLoading(false)
  }

  const imageSource = !isLoading ? { uri: avatarUrl || PhotoAdd } : undefined
  return (
    <OnboardingStep
      imageSource={imageSource}
      imageAlt="Camera Add"
      imageComponent={
        isLoading && (
          <Box className="flex h-[200px] w-[200px] items-center justify-center overflow-hidden rounded-full bg-gray-100">
            <Loading />
          </Box>
        )
      }
      title={
        avatarUrl ? (
          <FormattedMessage id="photoUpload.edit.title" />
        ) : (
          <FormattedMessage id="photoUpload.status.default.title" />
        )
      }
      description={
        avatarUrl ? (
          <FormattedMessage id="photoUpload.edit.description" />
        ) : (
          <FormattedMessage id="photoUpload.status.default.description" />
        )
      }
      primaryAction={
        <Button
          as="button"
          height="lg"
          handlePress={handleSelection}
          disabled={isLoading}
        >
          <FormattedMessage id="photoUpload.selectImage.status.default" />
        </Button>
      }
      secondaryAction={
        <Button
          variant="secondary"
          as="button"
          height="lg"
          handlePress={handleCameraInitiation}
          disabled={isLoading}
        >
          <FormattedMessage id="photoUpload.captureCta.status.default" />
        </Button>
      }
    />
  )
}
