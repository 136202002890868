import { FormattedMessage } from "react-intl"

import {
  Box,
  H4,
  ME_URI,
  TextButton,
  UserAvatar,
  useUserContext,
} from "@bullseye/components"

import { PostActivityButton } from "../../../components"

const UserActivityCta = () => {
  const { user } = useUserContext()

  return (
    <Box className="border-solid border-gray-110 bg-white p-6 g-2 md:rounded-lg md:border">
      <Box className="flex flex-row items-center justify-between">
        <H4>
          <FormattedMessage
            id="home.welcomeBack"
            values={{ name: user.first_name }}
          />
        </H4>
      </Box>
      <Box className="flex flex-row g-2">
        <UserAvatar user={user} size="md" />
        <TextButton as="a" href={ME_URI} variant="primary" underlined>
          <FormattedMessage id="home.viewProfile" />
        </TextButton>
      </Box>
      <Box>
        <PostActivityButton />
      </Box>
    </Box>
  )
}

export default UserActivityCta
