"use client"

import React, { ReactNode, useEffect, useState } from "react"

import { Button } from "../Button"
import { Dropdown, DropdownProps } from "../Dropdown"
import { List } from "../List"
import { ListItem } from "../ListItem"
import { Radio } from "../Radio"

export type SelectProps = {
  id: string
  className?: string
  disabled?: boolean
  name?: ReactNode
  onChange(val: string | number): void
  defaultLabel?: ReactNode
  defaultValue?: string | number
  options?: { value: string | number; label: ReactNode }[]
  value?: string | number
  position?: DropdownProps["position"]
  hideDefaultOption?: boolean
}

const DEFAULT_VALUE = -1

export const Select = ({
  id,
  className,
  onChange,
  disabled,
  defaultLabel = "Select",
  defaultValue = DEFAULT_VALUE,
  options,
  value = DEFAULT_VALUE,
  position = "bottom-right",
  name,
  hideDefaultOption,
}: SelectProps) => {
  const [isOpen, setOpen] = useState(false)
  const [label, setLabel] = useState(defaultLabel)

  useEffect(() => {
    const selectedOption = options?.find((o) => o.value === value)
    setLabel(selectedOption?.label || defaultLabel)
  }, [value, defaultLabel, options])

  const toggleDropdown = () => {
    setOpen(!isOpen)
  }

  const onSelect = (val: string | number) => () => {
    const newVal = val == value ? defaultValue : val
    newVal !== undefined && onChange(newVal)
    setOpen(false)
  }

  const onCheckboxClick = (targetValue: string | number) => (val: boolean) => {
    val && onSelect(targetValue)()
  }

  return (
    <Dropdown
      id={id}
      className={className}
      toggleComponent={
        <Button
          as="button"
          endIconType="chevron-down"
          handlePress={() => toggleDropdown()}
          rounded
          outlined
          variant="secondary"
          active={isOpen || defaultValue !== value}
          disabled={disabled}
          compact
        >
          {label}
        </Button>
      }
      open={isOpen}
      setOpen={setOpen}
      position={position}
      title={name}
    >
      <List className="mx-[-16px] md:mx-0">
        {!hideDefaultOption ? (
          <ListItem onPress={onSelect(defaultValue)} className="px-5 md:px-4">
            <Radio
              fullWidth
              reverse
              checked={defaultValue == value || !value}
              label={defaultLabel}
              onChange={onCheckboxClick(defaultValue)}
            />
          </ListItem>
        ) : (
          ""
        )}
        {options
          ?.filter((o) => o.value !== defaultValue)
          .map((o) => (
            <ListItem
              onPress={onSelect(o.value)}
              key={o.value}
              className="px-5 md:px-4"
            >
              <Radio
                fullWidth
                reverse
                checked={o.value === value}
                label={o.label}
                onChange={onCheckboxClick(o.value)}
              />
            </ListItem>
          ))}
      </List>
    </Dropdown>
  )
}
