"use client"

import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { Button, Dropdown, PublicUser, useUserContext } from "@bullseye/components"

import { MoreSheet } from "../../../components/UserSettingsModal"

type Props = {
  isSelf: boolean
  user: PublicUser
}

export const MoreButton = ({ isSelf, user }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { user: self } = useUserContext()
  const isViewingSelf = user.id === self.id
  return (
    <Dropdown
      id="desktop-user-profile-more-button"
      open={isOpen}
      setOpen={(open) => setIsOpen(open)}
      sheetSize="lg"
      toggleComponent={
        <Button
          as="button"
          startIconType="more"
          handlePress={() => setIsOpen(!isOpen)}
          outlined
          variant="secondary"
          active={isOpen}
          disabled={isSelf || isViewingSelf}
          compact
        >
          <FormattedMessage id="general.more" />
        </Button>
      }
    >
      <MoreSheet dismiss={() => setIsOpen(false)} user={user} />
    </Dropdown>
  )
}
