"use client"

import { useState } from "react"
import clsx from "clsx"

import {
  Autocomplete,
  AutocompleteItem,
  Box,
  GooglePlacePrediction,
  P,
  TextInputProps,
  useAutocompleteSearch,
} from "@bullseye/components"

type Props = TextInputProps & {
  onSelect: (place: GooglePlacePrediction) => void
  types: "postal_code" | "(cities)"
  selectedTextFormat?: "short" | "long"
  search?: string
  setSearch?: (search: string) => void
  data?: GooglePlacePrediction[]
}

export const CityZipSearch = ({
  onSelect,
  value,
  onChangeText,
  types,
  selectedTextFormat = "short",
  ...inputProps
}: Props) => {
  const [search, setSearch] = useState(value)
  const { data } = useAutocompleteSearch(search, {
    types: types,
  })
  return (
    <Autocomplete<GooglePlacePrediction>
      value={search}
      uniqueModalId="city-zip-search"
      onChangeText={(text) => {
        setSearch(text)
        onChangeText?.(text)
      }}
      inputMode={types === "postal_code" ? "numeric" : "text"}
      returnKeyType="done"
      data={data?.predictions}
      dropdownClassname="w-full mt-4 cursor-pointer py-0"
      renderItem={({ item, index, dismiss }) => {
        if (!data) return null

        const isFirst = index === 0
        const isLast = index === data.predictions.length - 1
        return (
          <Box
            className={clsx("border-b border-solid border-gray-100", {
              "border-t-0 md:border-t-0": isFirst,
              "md:border-b-0": isLast,
            })}
            key={item.place_id}
          >
            <AutocompleteItem
              item={item}
              onPress={(place) => {
                onSelect(place)
                const shortName = place.structured_formatting.main_text
                const fullName =
                  shortName + ", " + place.structured_formatting.secondary_text

                setSearch(selectedTextFormat === "short" ? shortName : fullName)
                dismiss()
              }}
              className={clsx({
                "md:rounded-t-lg": isFirst,
                "md:rounded-b-lg": isLast,
              })}
            >
              <P>{item.structured_formatting.main_text}</P>
              <P size="sm" className="text-gray-600">
                {item.structured_formatting.secondary_text}
              </P>
            </AutocompleteItem>
          </Box>
        )
      }}
      {...inputProps}
    />
  )
}
