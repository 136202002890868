"use client"

import { ReactNode } from "react"
import { Switch as S, SwitchProps } from "react-native"
import { styled } from "nativewind"

import { colors } from "@bullseye/tailwind-config"

import { Box } from "../Layout"
import { P } from "../Typography"

// extends SwitchProps with properties available using rnw
// see: https://necolas.github.io/react-native-web/docs/switch/
type WebSwitch = SwitchProps & {
  activeThumbColor?: string
  activeTrackColor?: string
}

const Switch = styled(S as React.ComponentType<WebSwitch>)

type Props = {
  value: boolean
  onChange: (value: boolean) => void
}

type Switch = SwitchProps & {
  activeThumbColor?: string
}

export const Toggle = ({ value, onChange }: Props) => {
  return (
    <Switch
      trackColor={{ false: colors.gray[50], true: colors.primary[700] }}
      thumbColor={colors.white}
      ios_backgroundColor={colors.gray[50]}
      activeThumbColor={colors.white}
      onValueChange={onChange}
      value={value}
    />
  )
}

type ToggleWithLabelProps = Props & {
  label: ReactNode
}

export const ToggleWithLabel = ({ label, ...rest }: ToggleWithLabelProps) => {
  return (
    <Box className="min-h-14 h-14 flex-row items-center justify-between rounded-lg border border-solid border-gray-100 px-2 g-4">
      <P>{label}</P>
      <Toggle {...rest} />
    </Box>
  )
}
