import { CMSBanner, V1APIResponse } from "@bullseye/types"

import { useGetRequest } from "./useRequest"

type Args = {
  accessToken?: string
  latitude?: number
  longitude?: number
}

export const useBanners = ({ accessToken, latitude, longitude }: Args) => {
  const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {}

  return useGetRequest<V1APIResponse<CMSBanner>>(
    {
      method: "GET",
      path: `v1/cms/banners`,
      headers,
      params: {
        latitude,
        longitude,
      },
    },
    {
      enabled: !!latitude && !!longitude,
    },
  )
}
