"use client"

import { Banner, Box, Button, Image, P } from "@bullseye/components"
import { type CMSBanner } from "@bullseye/types"

export function CMSBanner(props: CMSBanner) {
  const url = props["relative-link-path"]

  // dismissable is a nullable time in hours.
  // convert to ms, if possible.
  const cacheTimeMs = props.dismissable
    ? props.dismissable * 60 * 60 * 1000
    : undefined

  return (
    <Banner
      uniqueId={props.slug}
      variant={props["banner-style"]}
      nondismissable={!props.dismissable}
      cacheKeyExpiresInMs={cacheTimeMs}
    >
      <Box className="flex-grow flex-col items-start g-4 md:flex-row md:items-center md:justify-center">
        <Box className="hidden md:flex">
          {props.image.url && (
            <Image
              source={{ uri: props.image.url }}
              alt={props.image.alt}
              className="h-12 w-12"
            />
          )}
        </Box>
        <Box className="flex flex-grow items-center justify-center md:flex-initial">
          <P bold className="text-white">
            {props["banner-text"]}
          </P>
        </Box>
        {url && (
          <Box className="inline flex w-fit items-start justify-start">
            <Button
              as="a"
              href={props["relative-link-path"]}
              height="sm"
              variant="highlight"
              compact
            >
              {props["button-cta"]}
            </Button>
          </Box>
        )}
      </Box>
    </Banner>
  )
}
