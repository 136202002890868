"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Fade,
  HankLogo,
  LocalizationProvider,
  P,
  ScrollBox,
} from "@bullseye/components"

export const GoodbyePage = () => {
  return (
    <LocalizationProvider>
      <ScrollBox className="h-full min-h-screen w-full bg-primary-50 p-4 md:pt-10">
        <Fade fadeIn>
          <Box className="w-full flex-col items-center justify-center">
            <Box className="mb-[125px] max-w-[960px] flex-col items-center rounded-lg bg-white p-6 g-6 md:mb-0 md:p-10">
              <HankLogo />
              <Box className="g-6">
                <FormattedMessage
                  id="goodbye"
                  values={{
                    p: (text) => <P>{text}</P>,
                    div: (text) => <Box>{text}</Box>,
                    strong: (text) => (
                      <P bold className="text-primary-500">
                        {text}
                      </P>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </ScrollBox>
    </LocalizationProvider>
  )
}
