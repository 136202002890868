import { useQuery } from "@tanstack/react-query"

import { ChatChannel } from "@bullseye/types"

import { useMessagingContext } from "../providers/MessagingProvider"
import { SortOrder } from "../providers/MessagingProvider/types"

export type PaginationOptions = {
  limit: number
}

const defaultOptions = {
  limit: 5,
}

export const useMessages = (
  channel?: ChatChannel | null,
  options: PaginationOptions = defaultOptions,
) => {
  const { client } = useMessagingContext()
  const res = useQuery(
    ["messages", channel?.id],
    async () => {
      if (!channel) return []

      const { results } = await client.search(
        {
          cid: {
            $eq: channel.cid,
          },
        },
        {
          type: { $exists: true },
        },
        {
          limit: 1,
          sort: {
            created_at: SortOrder.DESC,
          },
        },
      )

      if (!results.length || !results[0]?.message) {
        return []
      }

      const { message } = results[0]

      const chan = await channel.query({
        messages: { limit: options.limit, id_lte: message.id },
      })

      const bannedMembers: Record<string, boolean> = {}
      chan.members.map((member) => {
        if (member.user?.banned) {
          bannedMembers[member.user_id || ""] = true
        }
      })

      return chan.messages.filter((msg) => {
        const userId = msg.user?.id
        if (!userId) {
          return false
        }

        return !bannedMembers[userId]
      })
    },
    {
      enabled: !!channel,
    },
  )

  return {
    messages: res.data,
    ...res,
  }
}
