"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  Button,
  H4,
  Loading,
  P,
  Sheet,
  useLoggingContext,
  useUserBlock,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

export type BlockUserModalProps = {
  userId: string
  onSuccess?: VoidFunction
}

export const BlockUserModal = ({
  isOpen,
  dismiss,
  userId,
  onSuccess,
}: Modal<BlockUserModalProps>) => {
  const { captureException } = useLoggingContext()

  const { data, isLoading, createBlock, deleteBlock } = useUserBlock(userId)
  return (
    <Sheet
      icon="x"
      show={isOpen}
      size="md"
      onIconPress={dismiss}
      dismiss={dismiss}
      contentContainerStyle="overflow-hidden"
      renderContent={() => {
        if (isLoading) {
          return (
            <Box className="flex h-full w-full items-center justify-center">
              <Loading />
            </Box>
          )
        }
        return (
          <Box className="flex-col items-start g-2">
            <Box className="flex-col items-start pb-2 g-2">
              <H4>
                {!data?.is_blocked ? (
                  <FormattedMessage id="applicationModals.blockUser.block.title" />
                ) : (
                  <FormattedMessage id="applicationModals.blockUser.unblock.title" />
                )}
              </H4>
              <P size="sm">
                {!data?.is_blocked ? (
                  <FormattedMessage id="applicationModals.blockUser.block.description" />
                ) : (
                  <FormattedMessage id="applicationModals.blockUser.unblock.description" />
                )}
              </P>
            </Box>
            <Box className="w-full flex-row justify-between g-2">
              <Box className="flex-1">
                <Button as="button" variant="tertiary" handlePress={dismiss}>
                  <FormattedMessage id="app.modal.report.cancel" />
                </Button>
              </Box>
              <Box className="flex-1">
                <Button
                  as="button"
                  variant="danger"
                  handlePress={() => {
                    try {
                      if (data?.is_blocked) {
                        void deleteBlock()
                      } else {
                        void createBlock()
                      }
                      onSuccess?.()
                    } catch (error) {
                      captureException(
                        new Error(`Failed to block user ${error}`),
                      )
                    } finally {
                      dismiss()
                    }
                  }}
                >
                  {!data?.is_blocked ? (
                    <FormattedMessage id="applicationModals.blockUser.block.cta" />
                  ) : (
                    <FormattedMessage id="applicationModals.blockUser.unblock.cta" />
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        )
      }}
    />
  )
}
