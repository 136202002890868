import { FormattedMessage } from "react-intl"

import {
  ATTENDING_URI,
  Box,
  H4,
  TextButton,
  useUserContext,
} from "@bullseye/components"

import { AttendingActivityList } from "../../../components"

const UserEventsCard = () => {
  const { user } = useUserContext()

  return (
    <Box className="border-solid border-gray-110 bg-white p-6 md:rounded-lg md:border">
      <Box className="flex flex-row items-center justify-between">
        <H4>Your activities</H4>
        <TextButton variant="primary" as="a" href={ATTENDING_URI}>
          See all
        </TextButton>
      </Box>
      <Box className="w-full">
        <AttendingActivityList
          user={user}
          emptyStateMessage={<FormattedMessage id="attending.upcoming-empty" />}
          compact
          params={{
            "start_date[gte]": new Date().toISOString(),
            limit: "5",
            order_by: "start_date",
            direction: "asc",
          }}
        />
      </Box>
    </Box>
  )
}

export default UserEventsCard
