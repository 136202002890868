import { HankNotification, PaginatedResponse } from "@bullseye/types"

import { useUserContext } from "../providers/UserAuthProvider/UserProvider"
import { useGetRequest } from "./useRequest"

export const useUnseenNotificationCount = (): {
  count: number
} => {
  const { accessToken } = useUserContext()
  const { data } = useGetRequest<PaginatedResponse<HankNotification>>(
    {
      path: "users/notifications?seen_at=null&exclude_results=true",
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    { enabled: !!accessToken },
  )

  if (data) {
    return { count: data.metadata?.total_count || 0 }
  }

  return { count: 0 }
}
