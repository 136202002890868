"use client"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Box,
  H2,
  IconButton,
  makeMessengerUri,
  useBreakpoint,
  useMessageChannelByStreamId,
  useUser,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import { MessengerModal, useMessengerModalContext } from "../../"
import { MessageChannelTitle } from "../ChannelTitle"

export function MessageChannelHeader({ channelId }: { channelId?: string }) {
  const { openModal } = useMessengerModalContext()
  const isSm = useBreakpoint("md", "down")
  const { messageChannel } = useMessageChannelByStreamId(channelId)
  const { user: selectedUser } = useUser(
    !messageChannel?.experience_id ? messageChannel?.dm_user?.id : undefined,
  )
  const router = useRouter()
  return (
    <Box className="flex w-full flex-row border-b border-solid border-gray-100 bg-white md:border-t">
      <Box className="flex h-14 w-full flex-row items-center justify-between border-r border-solid border-gray-100 px-4 md:w-[30%]">
        <H2
          className={clsx({
            hidden: isSm && channelId,
          })}
        >
          <FormattedMessage id="messenger.title" />
        </H2>
        <Box
          className={clsx(
            "flex max-w-[80%] flex-row items-center g-2 md:hidden",
            {
              hidden: !channelId,
            },
          )}
        >
          <IconButton
            iconType="arrow-left"
            variant="filled"
            handlePress={() => {
              if (router.canGoBack?.()) {
                return router.back()
              }
              return router.replace(makeMessengerUri())
            }}
          />
          <Box className="flex-1 overflow-x-hidden">
            <MessageChannelTitle messageChannel={messageChannel} />
          </Box>
        </Box>
        <IconButton
          iconType="edit"
          variant="filled"
          size="lg"
          handlePress={() => openModal(MessengerModal.NewMessage)}
          className={clsx({
            hidden: isSm && channelId,
          })}
        />
        <IconButton
          variant="filled"
          iconType="info-circle"
          handlePress={() => {
            openModal(MessengerModal.ChannelOptions, {
              user: selectedUser,
              streamChannelId: channelId,
              messageChannelId: messageChannel?.id,
            })
          }}
          className={clsx("md:hidden", {
            hidden: isSm && !channelId,
          })}
        />
      </Box>
      <Box
        className={clsx(
          "hidden flex-row items-center justify-between p-4 md:flex md:w-[70%]",
          {
            "md:hidden": !channelId,
          },
        )}
      >
        <MessageChannelTitle messageChannel={messageChannel} />
        <IconButton
          variant="filled"
          iconType="info-circle"
          handlePress={() => {
            openModal(MessengerModal.ChannelOptions, {
              user: selectedUser,
              streamChannelId: channelId,
              messageChannelId: messageChannel?.id,
            })
          }}
        />
      </Box>
    </Box>
  )
}
