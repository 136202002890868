"use client"

import * as Linking from "expo-linking"
import * as Location from "expo-location"
import { FormattedMessage } from "react-intl"

import {
  Button,
  LoadingFullScreen,
  useCheckLocationPermissions,
  useLoggingContext,
} from "@bullseye/components"

import { OnboardingStep } from "../../components/OnboardingStep"
import { OnboardingJourney } from "../../layouts"
import { LocationMap } from "./assets/LocationMap"

type Props = {
  advanceToNextStep?: () => Promise<void>
  previousPage?: string
}

export const OnboardingLocation = ({
  previousPage,
  advanceToNextStep,
}: Props) => {
  const { captureException } = useLoggingContext()
  const { data, isLoading } = useCheckLocationPermissions()
  async function requestPermissions() {
    try {
      const { status } = await Location.requestForegroundPermissionsAsync()
      if (status !== "granted") {
        await advanceToNextStep?.()
        return
      }
      await Location.requestBackgroundPermissionsAsync()
      return advanceToNextStep?.()
    } catch (err) {
      captureException(
        new Error(`Error requesting location permissions: ${err}`),
      )
    }
  }
  const permissionDenied = data?.status === "denied"

  if (isLoading) {
    return <LoadingFullScreen />
  }

  return (
    <OnboardingJourney previousPage={previousPage}>
      <OnboardingStep
        imageSource={{ uri: LocationMap }}
        imageAlt="Location"
        title={
          permissionDenied ? (
            <FormattedMessage id="onboarding.requestPermissions.errorTitle" />
          ) : (
            <FormattedMessage id="onboarding.requestPermissions.title" />
          )
        }
        description={
          permissionDenied ? (
            <FormattedMessage id="onboarding.requestPermissions.errorDescription" />
          ) : (
            <FormattedMessage id="onboarding.requestPermissions.description" />
          )
        }
        primaryAction={
          <Button
            as="button"
            height="lg"
            handlePress={() => {
              if (permissionDenied) {
                return Linking.openSettings()
              }
              return requestPermissions()
            }}
          >
            {permissionDenied ? (
              <FormattedMessage id="onboarding.requestPermissions.allowSettingsCta" />
            ) : (
              <FormattedMessage id="onboarding.requestPermissions.allowCta" />
            )}
          </Button>
        }
      />
    </OnboardingJourney>
  )
}
