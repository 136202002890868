import { useUserContext } from "../providers"
import { useDeleteRequest } from "./useRequest"

export const useDeleteMessageChannel = (channelId: string) => {
  const { accessToken } = useUserContext()
  const { deleteRequest, deleteRequestAsync, ...rest } = useDeleteRequest({
    path: `users/message-channels/${channelId}/leave`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    deleteMessageChannel: deleteRequest,
    deleteMessageChannelAsync: deleteRequestAsync,
    ...rest,
  }
}
