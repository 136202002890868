"use client"

import clsx from "clsx"
import { FormattedMessage } from "react-intl"

import {
  Box,
  Icon,
  P,
  Sheet,
  TextButton,
  useAppConfig,
  useCopyToClipboard,
  useLoggingContext,
} from "@bullseye/components"
import type { Modal } from "@bullseye/types"

function makeFBShareLink(url: string, appId?: string) {
  if (!appId) return ""

  const link = encodeURIComponent(url)

  const facebookLink = new URL("https://www.facebook.com/dialog/share")
  facebookLink.searchParams.append("app_id", appId)
  facebookLink.searchParams.append("href", link)
  facebookLink.searchParams.append("display", "popup")
  facebookLink.searchParams.append("redirect_uri", link)

  return facebookLink.toString()
}

function makeFBMessengerShareLink(url: string, appId?: string) {
  if (!appId) return ""

  const link = encodeURIComponent(url)

  const messengerLink = new URL("https://www.facebook.com/dialog/send")
  messengerLink.searchParams.append("app_id", appId)
  messengerLink.searchParams.append("href", link)
  messengerLink.searchParams.append("display", "popup")
  messengerLink.searchParams.append("redirect_uri", link)

  return messengerLink.toString()
}

function makeEmailShareLink(url: string, subject: string, body: string) {
  const emailLink = new URL("mailto:")
  emailLink.searchParams.append("subject", subject)
  emailLink.searchParams.append("body", body + "\n\n" + url)

  return emailLink.toString()
}

export type ShareModalProps = {
  onCopy: (success: boolean) => void

  url: string
  message: string
  emailSubject: string
}

export const ShareModal = ({
  isOpen,
  dismiss,
  onCopy,
  url,
  message,
  emailSubject,
}: Modal<ShareModalProps>) => {
  const { facebookAppId } = useAppConfig()
  const fbShareLink = makeFBShareLink(url, facebookAppId)
  const messengerShareLink = makeFBMessengerShareLink(url, facebookAppId)
  const emailShareLink = makeEmailShareLink(url, emailSubject, message)
  const { copy } = useCopyToClipboard()
  const { captureException } = useLoggingContext()

  return (
    <Sheet
      title={<FormattedMessage id="general.share" />}
      icon="x"
      show={isOpen}
      onIconPress={() => dismiss()}
      dismiss={dismiss}
      renderContent={() => {
        return (
          <Box className="flex items-start g-4">
            <TextButton
              as="a"
              href={fbShareLink}
              className={clsx("w-full justify-start", {
                hidden: !fbShareLink,
              })}
            >
              <Box className="flex-row items-center gap-4">
                <Icon type="facebook" size="xl" />
                <P bold>Share to Facebook</P>
              </Box>
            </TextButton>
            <TextButton
              as="a"
              href={messengerShareLink}
              className={clsx("w-full justify-start", {
                hidden: !messengerShareLink,
              })}
            >
              <Box className="flex-row items-center gap-4">
                <Icon type="facebook-messenger" size="xl" />
                <P bold>Share to Messenger</P>
              </Box>
            </TextButton>
            <TextButton
              as="a"
              href={emailShareLink}
              className="w-full justify-start"
            >
              <Box className="flex-row items-center gap-4">
                <Icon type="mail" size="lg" />
                <P bold>Share via email</P>
              </Box>
            </TextButton>
            <TextButton
              as="button"
              handlePress={async () => {
                try {
                  const copied = await copy(url)
                  onCopy(copied)
                } catch (error) {
                  captureException(
                    new Error(`Failed to copy to clipboard: ${error}`),
                  )
                  onCopy(false)
                } finally {
                  dismiss()
                }
              }}
              className="w-full justify-start"
            >
              <Box className="flex-row items-center gap-4">
                <Icon type="link" size="lg" />
                <P bold>Copy link</P>
              </Box>
            </TextButton>
          </Box>
        )
      }}
    />
  )
}
