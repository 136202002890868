"use client"

import React from "react"
import { FormattedMessage } from "react-intl"

import {
  ABOUT_US_URL,
  BLOG_URL,
  Box,
  Button,
  COMMUNITY_GUIDELINES_URL,
  HELP_CENTER_URL,
  ListDivider,
  LOGIN_URI,
  SIGN_UP_URI,
} from "@bullseye/components"

import { TopNavBarMenu } from "../components/TopNavBarMenu"

export const UnauthedMenu = () => {
  return (
    <TopNavBarMenu
      items={[
        [
          {
            title: "About us",
            titleKey: "navigation.about",
            href: ABOUT_US_URL,
          },
          {
            title: "Blog",
            titleKey: "navigation.blog",
            href: BLOG_URL,
          },
        ],
        [
          {
            href: COMMUNITY_GUIDELINES_URL,
            title: "Community Guidelines",
            titleKey: "navigation.guidelines",
          },
          {
            href: HELP_CENTER_URL,
            title: "Help center",
            titleKey: "navigation.help-center",
          },
        ],
      ]}
    >
      <ListDivider className="block sm:hidden" />
      <Box className="flex flex-col gap-4 px-0.5 pt-2 sm:hidden sm:px-3">
        <Button as="a" variant="tertiary" href={LOGIN_URI}>
          <FormattedMessage id="general.logIn" />
        </Button>
        <Button as="a" variant="primary" href={SIGN_UP_URI}>
          <FormattedMessage id="general.signUp" />
        </Button>
      </Box>
    </TopNavBarMenu>
  )
}
