import { useEffect } from "react"
import * as Location from "expo-location"
import { useQuery } from "@tanstack/react-query"

import { IPLocation } from "@bullseye/types"

import { useUserContext } from "../providers"
import { isWeb } from "../utils/device"
import { IP_GEOLOCATION_V2 } from "../utils/routes"
import { useAsyncStorage } from "./useAsyncStorage"
import { useNearestCity } from "./useGooglePlaces"

const userGeoStorageKey = "user-geo"
const userLocationStorageKey = "user-location"

export const PermissionDeniedError = new Error(
  "Permission to access location was denied",
)

type StoredLocation = {
  latitude: number
  longitude: number
}

export type CurrentUserLocation = {
  latitude: number
  longitude: number
  formattedAddress: string
}

async function getIPLocation() {
  const res = await fetch(IP_GEOLOCATION_V2)
  return res.json() as Promise<IPLocation>
}

export function useCheckLocationPermissions() {
  const { user } = useUserContext()
  return useQuery(["check-permissions", user.id], () =>
    Location.getForegroundPermissionsAsync(),
  )
}

export async function getUserGeo() {
  try {
    // First attempt to get user location based on location permissions
    const { status } = await Location.requestForegroundPermissionsAsync()
    if (status !== "granted") {
      throw PermissionDeniedError
    }
    const location = await Location.getCurrentPositionAsync()

    return {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    }
  } catch (err) {
    // If this fails on iOS, throw an error because we require this permission
    if (!isWeb()) throw err

    // On the web, we can use IP geolocation as a fallback
    const data = await getIPLocation()

    return {
      latitude: data.latitude,
      longitude: data.longitude,
    }
  }
}

export const useUserIPLocation = () => {
  const { data, isLoading, error } = useQuery(
    ["userIPLocation"],
    () => getIPLocation(),
    {
      enabled: isWeb(),
    },
  )

  return {
    data,
    isLoading,
    error,
  }
}

export const useLocationPermissions = () => {
  return useQuery(["currentUserGeo"], getUserGeo)
}

export const useCurrentUserCity = () => {
  const { data } = useLocationPermissions()

  return useNearestCity(
    {
      latitude: data?.latitude,
      longitude: data?.longitude,
    },
    { enabled: !!data },
  )
}

export const useStoredGeo = () => {
  return useAsyncStorage(userGeoStorageKey)
}

const useUserGeo = () => {
  const {
    data,
    isLoading: loadingStoredLocation,
    setValue,
    error: storedLocationError,
  } = useStoredGeo()
  const storedLocation = data as StoredLocation | undefined

  const { isLoading: loadingExactLocation, error: exactLocationError } =
    useQuery(
      ["exactLocation"],
      async () => {
        const geo = await getUserGeo()
        await setValue(geo)
        return geo
      },
      {
        enabled: !storedLocation && !loadingStoredLocation,
      },
    )

  return {
    data: storedLocation,
    isLoading: loadingStoredLocation || loadingExactLocation,
    error: storedLocationError || exactLocationError,
  }
}

export const useUserLocation = () => {
  const { data: userGeo, isLoading } = useUserGeo()
  const {
    data,
    isLoading: storageLoading,
    setValue,
  } = useAsyncStorage(userLocationStorageKey)
  const storedLocation = data as CurrentUserLocation | undefined

  const { data: nearestCity, isLoading: loadingNearestCity } = useNearestCity(
    {
      latitude: storedLocation?.latitude || userGeo?.latitude,
      longitude: storedLocation?.longitude || userGeo?.longitude,
    },
    {
      enabled: !storageLoading && !isLoading,
    },
  )

  useEffect(() => {
    if (!userGeo || !nearestCity || storedLocation) {
      return
    }

    void setValue({
      latitude: nearestCity.location.latitude,
      longitude: nearestCity.location.longitude,
      formattedAddress: nearestCity.shortFormattedAddress,
    })
  }, [storedLocation, userGeo, nearestCity])

  return {
    data: storedLocation,
    setLocation: setValue,
    isLoading: isLoading || loadingNearestCity || storageLoading,
  }
}
