import { useMemo } from "react"
import { useWindowDimensions } from "react-native"

import { theme } from "@bullseye/tailwind-config"

const { screens } = theme

type Direction =
  | // Up matches screen widths greater than the screen size given by the breakpoint key (inclusive).
  "up"
  // Down matches screen widths less than the screen size given by the breakpoint key (exclusive).
  | "down"
  // Not matches screen widths not matching the screen size given by the breakpoint key.
  | "not"
  // Only matches screen widths between the beginning of that breakpoint and the next one.
  | "only"
  | undefined

type Breakpoint = keyof typeof screens
const sortedBreakpoints = (Object.keys(screens) as Array<Breakpoint>).sort(
  (a, b) => parseInt(screens[a]) - parseInt(screens[b]),
)

export const useIsSm = () => useBreakpoint("md", "down")

export const useBreakpoint = (
  breakpoint: Breakpoint,
  direction: Direction = "up",
) => {
  const { width } = useWindowDimensions()

  const breakpoints = useMemo(() => {
    return {
      current: parseInt(screens[breakpoint]),
      next:
        parseInt(
          screens[sortedBreakpoints[sortedBreakpoints.indexOf(breakpoint) + 1]],
        ) || Number.POSITIVE_INFINITY,
      prior:
        parseInt(
          screens[sortedBreakpoints[sortedBreakpoints.indexOf(breakpoint) - 1]],
        ) || 0,
    }
  }, [breakpoint])

  switch (direction) {
    case "up":
      return width >= breakpoints.current
    case "down":
      return width < breakpoints.current
    case "not":
      return width >= breakpoints.next || width <= breakpoints.prior
    case "only":
      return width >= breakpoints.current && width <= breakpoints.next
  }
}
