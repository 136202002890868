"use client"

import { Box, Experience } from "@bullseye/components"

import {
  ActivityCoverPhoto,
  ActivityDescription,
  PageContent,
} from "../../components"
import {
  UnauthedActivityChat,
  UnauthedActivityInfo,
  UnauthedAttendeeCount,
  UnauthedCTA,
  UnauthedHost,
} from "./components"

export const UnauthedActivityPage = ({
  experience,
}: {
  experience: Experience
}) => {
  return (
    <PageContent className="md:pb-10">
      <Box className="flex w-full max-w-[1000px] flex-col pb-16 md:mt-6 md:px-[20px] md:pb-1 md:g-4">
        <ActivityCoverPhoto imageUrl={experience.cover_photo?.url ?? ""} />
        <Box className="flex flex-col g-2 md:flex-row md:g-4">
          <Box className="flex shrink flex-grow flex-col g-2 md:g-4">
            <UnauthedActivityInfo experience={experience} />
            <UnauthedCTA experience={experience} className="flex md:hidden" />
            <ActivityDescription
              description={experience.description}
              categories={experience.categories}
            />
            <Box className="rounded-none bg-white md:hidden md:rounded-lg">
              <UnauthedHost experience={experience} />
              <UnauthedAttendeeCount
                experienceId={experience.id}
                organizerId={experience.organizer_id}
              />
            </Box>
            <Box className="hidden md:flex">
              <UnauthedActivityChat />
            </Box>
          </Box>
          <Box className="flex shrink-0 grow-0 flex-col g-2 md:basis-[360px] md:g-4">
            <UnauthedCTA experience={experience} className="hidden md:flex" />
            <Box className="hidden rounded-none bg-white md:flex md:rounded-lg">
              <UnauthedHost experience={experience} />
              <UnauthedAttendeeCount
                experienceId={experience.id}
                organizerId={experience.organizer_id}
              />
            </Box>
            <Box className="flex md:hidden">
              <UnauthedActivityChat />
            </Box>
          </Box>
        </Box>
      </Box>
    </PageContent>
  )
}
