import { MessageChannel } from "@bullseye/types"

import { useUserContext } from "../providers"
import { useGetRequest } from "./useRequest"

export const useMessageChannelByStreamId = (id: string | undefined | null) => {
  const { accessToken } = useUserContext()
  const { data, isLoading, error, refetch, isRefetching, key } = useGetRequest<{
    data: MessageChannel
  }>(
    {
      method: "GET",
      path: `message-channels/${id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    {
      enabled: !!id && !!accessToken,
    },
  )

  return {
    messageChannel: data?.data,
    isLoading,
    error,
    refetch,
    isRefetching,
    key,
  }
}
