"use client"

import { TextProps } from "react-native"
import {
  A as ExpoA,
  B as ExpoB,
  EM as ExpoEM,
  H1 as ExpoH1,
  H2 as ExpoH2,
  H3 as ExpoH3,
  H4 as ExpoH4,
  I as ExpoI,
  Span as ExpoSpan,
  Strong as ExpoStrong,
  P as Paragraph,
} from "@expo/html-elements"
import clsx from "clsx"
import { styled } from "nativewind"

export const B = styled(ExpoB, "font-maison-demi font-semibold")
export const I = styled(ExpoI, "font-maison-book italic")
export const H1 = styled(
  ExpoH1,
  "font-maison-demi font-semibold text-4xl md:text-5xl m-0",
)
export const H2 = styled(
  ExpoH2,
  "font-maison-demi font-semibold text-2.5xl md:text-4xl m-0",
)
export const H3 = styled(
  ExpoH3,
  "font-maison-demi font-semibold text-1.5xl md:text-2xl m-0",
)
export const H4 = styled(
  ExpoH4,
  "font-maison-demi font-semibold text-lg md:text-xl m-0",
)

const BodyClasses = ["font-maison-book", "font-normal", "m-0"]

export const Span = styled(ExpoSpan, clsx(BodyClasses, "text-size-unset"))

export const Strong = styled(
  ExpoStrong,
  clsx(BodyClasses, "text-size-unset", "font-maison-demi font-semibold"),
)

export const EM = styled(ExpoEM, clsx(BodyClasses, "text-size-unset", "italic"))

export const A = styled(
  ExpoA,
  clsx(BodyClasses, "text-primary-500", "hover:underline"),
)

const Body = styled(Paragraph, clsx(BodyClasses))

export type PProps = {
  size?: "lg" | "md" | "sm" | "xs"
  className?: string
  ellipsis?: boolean
  bold?: boolean
} & Omit<TextProps, "style">

export const P = styled(
  ({
    size = "md",
    className,
    children,
    ellipsis,
    bold = false,
    onLayout,
    ...rest
  }: PProps) => {
    return (
      <Body
        className={clsx(className, {
          "text-lg": size == "lg",
          "text-base": size == "md",
          "text-sm": size == "sm",
          "text-xs": size == "xs",
          truncate: ellipsis,
          "font-maison-demi font-semibold": bold,
        })}
        numberOfLines={ellipsis ? 1 : undefined}
        onLayout={onLayout}
        {...rest}
      >
        {children}
      </Body>
    )
  },
)
