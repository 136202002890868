import { ExperienceAttendee } from "@bullseye/types"

import { useUserContext } from "../providers"
import { usePostRequest } from "./useRequest"

type ExperienceAttendeeRequest = {
  user_id: string
}

export const useAttendExperience = (experienceId: string) => {
  const { accessToken, user } = useUserContext()
  const { postRequest, postRequestAsync, ...rest } = usePostRequest<
    ExperienceAttendee,
    ExperienceAttendeeRequest
  >({
    path: `v1/experiences/${experienceId}/attendees`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    attendExperience: () => postRequest({ body: { user_id: user?.id } }),
    attendExperienceAsync: () =>
      postRequestAsync({ body: { user_id: user?.id } }),
    ...rest,
  }
}
