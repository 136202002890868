"use client"

import { ReactNode } from "react"
import { clsx } from "clsx"

import { Box, Icon, P, PublicUser, UserAvatar } from "@bullseye/components"

type Props = {
  user: PublicUser
  subtext?: ReactNode
}

export const UserLockup = ({ user, subtext }: Props) => {
  const userLocation = [user.city, user.state].filter(Boolean).join(", ")
  return (
    <Box className="inline-flex flex-row g-3">
      <UserAvatar user={user} size="xl" />
      <Box className="g-1">
        <Box className="flex-row items-center g-1">
          <P bold>{user.formatted_name}</P>
          <Box
            className={clsx("overflow-hidden rounded-full bg-blue-700 p-px", {
              hidden: !user.is_verified,
            })}
          >
            <Icon type="shield" size="sm" className="text-white" />
          </Box>
        </Box>
        {subtext ? (
          subtext
        ) : (
          <P className="text-gray-600">{userLocation ?? "Unknown"}</P>
        )}
        <Box
          className={clsx("flex-row items-center", {
            hidden: !user.is_ambassador,
          })}
        >
          <Icon type="ambassador" size="sm" />
          <P className="text-primary-500"> Ambassador</P>
        </Box>
      </Box>
    </Box>
  )
}
