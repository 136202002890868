"use client"

import clsx from "clsx"
import { styled } from "nativewind"
import { twMerge } from "tailwind-merge"

import { default as Link, LinkProps } from "../Link"
import { P, PProps } from "../Typography"

const StyledLink = styled(Link)

type TextLinkProps = LinkProps & {
  className?: string
  label: string | React.ReactNode | React.ReactNode[]
  variant?: "primary" | "plain"
  size?: PProps["size"]
}

export const TextLink = ({
  variant = "plain",
  label,
  className,
  size = "md",
  ...rest
}: TextLinkProps) => {
  return (
    <StyledLink {...rest}>
      <P
        bold={variant === "primary"}
        className={twMerge(
          clsx({
            "text-primary-500 underline hover:text-primary-700 focus-visible:text-primary-700":
              variant == "primary",
          }),
          className,
        )}
        size={size}
      >
        {label}
      </P>
    </StyledLink>
  )
}
