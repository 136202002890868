import { Category } from "./Category"
import { ExperienceAttendee } from "./ExperienceAttendee"
import { Coordinate } from "./Location"
import { Model } from "./Model"
import { PublicUser } from "./User"

export enum FILTER_TYPES {
  ALL = "any-day",
  TODAY = "today",
  TOMORROW = "tomorrow",
  THIS_WEEK = "this-week",
}

interface CoverPhoto {
  url: string
  thumbnail_450x150: {
    url: string
  }
}

export interface ExperienceData {
  title: string
  description: string
  start_date: string
  end_date: string | null
  group_id: string | null
  formatted_address_string?: string
  single_line_address_string?: string
  address_line1?: string | null
  address_line2?: string | null
  city: string | null
  state: string | null
  zipcode: string | null
  lat: Coordinate
  long: Coordinate
  url?: string | null
  venue: string | null
  timezone: string | null
  google_place_id: string | null
  max_attendees: number | null
  ticket_price: number | null
  service_fee: number | null
  remote_cover_photo_url: string | null
  is_hostless?: boolean | null
  additional_instructions: string | null
}

export interface Experience extends ExperienceData, Model {
  slug: string
  cover_photo: CoverPhoto | null
  event_category_id?: string
  event_category?: Category
  organizer_id: string
  organizer?: PublicUser
  attendees_count: number
  order_id?: string
  canceled_at: string | null
  requester_has_purchased: boolean
  requester_is_attending: boolean
  venue_type: "in_person" | "online" | "tbd"
  requester_experience_attendee: ExperienceAttendee[]
  sold_out: boolean
  group_experiences?: any[]
  visibility?: "PRIVATE" | "PUBLIC"
  source_experience_id?: string
  experience_attendees?: ExperienceAttendee[]
  categories?: Category[]
  post_experience_modal_answered?: boolean
  no_show_fee?: string
  ticket_url?: string
  requester?: {
    is_attending?: boolean
  }
  formattedDate?: string
}

export type HankEventDocumentModel = Pick<
  Experience,
  | "id"
  | "title"
  | "slug"
  | "description"
  | "city"
  | "state"
  | "address_line1"
  | "zipcode"
  | "start_date"
  | "end_date"
> & { attendees: number }
