import { useQuery } from "@tanstack/react-query"

import { StreamError } from "@bullseye/types"

import { useMessagingContext } from "../providers/MessagingProvider"
import { useUserContext } from "../providers/UserAuthProvider/UserProvider"

export const useChannelById = (id?: string) => {
  const { client } = useMessagingContext()
  const { user } = useUserContext()
  const res = useQuery(
    ["channel", id],
    async () => {
      return client.queryChannels({
        id: { $eq: id },
        members: { $in: [user.stream_id] },
        type: "messaging",
      })
    },
    {
      enabled: !!id,
    },
  )

  return {
    ...res,
    channel: res.data?.[0],
    error: res.error as StreamError,
  }
}
