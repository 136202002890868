"use client"

import { FormattedMessage } from "react-intl"

import {
  Box,
  H2,
  IconButton,
  isWeb,
  NotificationTrigger,
  ScrollBox,
} from "@bullseye/components"
import { useRouter } from "@bullseye/navigation"

import {
  EmailNotificationElement,
  EmailSettings,
  GeneralSettings,
  PushSettings,
} from "./components"

const notificationElements = [
  {
    label: (
      <FormattedMessage id="settings.notifications.directMessages.label" />
    ),
    description: (
      <FormattedMessage id="settings.notifications.directMessages.description" />
    ),
  },
  {
    label: (
      <FormattedMessage id="settings.notifications.recommendations.label" />
    ),
    description: (
      <FormattedMessage id="settings.notifications.recommendations.description" />
    ),
  },
  {
    label: <FormattedMessage id="settings.notifications.reminders.label" />,
    description: (
      <FormattedMessage id="settings.notifications.reminders.description" />
    ),
  },
  {
    label: <FormattedMessage id="settings.notifications.feedback.label" />,
    description: (
      <FormattedMessage id="settings.notifications.feedback.description" />
    ),
  },
  {
    label: <FormattedMessage id="settings.notifications.hosting.label" />,
    description: (
      <FormattedMessage id="settings.notifications.hosting.description" />
    ),
  },
  {
    label: (
      <FormattedMessage id="settings.notifications.activityUpdates.label" />
    ),
    description: (
      <FormattedMessage id="settings.notifications.activityUpdates.description" />
    ),
  },
]

const pushElements = [
  { ...notificationElements[0]!, triggers: [NotificationTrigger.MessageNew] },
  {
    ...notificationElements[5]!,
    triggers: [NotificationTrigger.WaitlistSelected],
  },
]

const emailElements: EmailNotificationElement[] = [
  { ...notificationElements[0]!, userProps: ["receive_message_notifications"] },
  {
    ...notificationElements[1]!,
    userProps: ["receive_new_event_notifications"],
  },
  {
    ...notificationElements[5]!,
    userProps: [
      "receive_roundup_notifications",
      "receive_new_comment_notifications",
    ],
    triggers: ["waitlist.selected"],
  },
  {
    label: <FormattedMessage id="settings.notifications.hankUpdates.label" />,
    description: (
      <FormattedMessage id="settings.notifications.hankUpdates.description" />
    ),
    userProps: ["receive_marketing_emails"],
  },
]

export const NotificationSettingsPage = () => {
  const router = useRouter()
  return (
    <>
      <Box className="flex w-full max-w-[600px] items-center bg-white md:px-24">
        <Box className="w-full max-w-[600px] px-4 pb-6 pt-0 md:px-0 md:py-6">
          <Box className="flex h-14 justify-center md:hidden">
            <IconButton
              iconType="arrow-left"
              variant="filled"
              size="lg"
              handlePress={() => router.back()}
            />
          </Box>
          <Box className="flex h-14 justify-center">
            <H2>
              <FormattedMessage id="settings.notifications.title" />
            </H2>
          </Box>
        </Box>
      </Box>
      <ScrollBox
        contentContainerStyle={{
          width: "100%",
        }}
      >
        <Box className="flex w-full max-w-[800px] px-4 g-6 md:px-2 md:px-24 md:pb-10">
          <GeneralSettings />
          {!isWeb() && <PushSettings elements={pushElements} />}
          <EmailSettings elements={emailElements} />
        </Box>
      </ScrollBox>
    </>
  )
}
