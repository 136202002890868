"use client"

import { FormattedMessage } from "react-intl"

import { EmptyState, type ButtonProps } from "@bullseye/components"

type Props = {
  handlePress: ButtonProps["handlePress"]
}

export const ActivityEmptyState = ({ handlePress }: Props) => {
  return (
    <EmptyState
      icon="calendar"
      header={<FormattedMessage id="activity.list.empty-state.description" />}
      buttonProps={{
        children: <FormattedMessage id="activity.list.empty-state.button" />,
        handlePress,
      }}
      className="h-full justify-center pt-4"
    />
  )
}
