"use client"

import { FormattedMessage } from "react-intl"

import { H4, P } from "@bullseye/components"

type Props = {
  spots: number
  as: "h4" | "p"
}

export const ActivityRemainingSpots = ({ spots, as }: Props) => {
  const TextElement = as === "h4" ? H4 : P
  switch (spots) {
    case 0:
      return (
        <TextElement>
          <FormattedMessage id="activity.cta.remainingSpots.none" />
        </TextElement>
      )
    case 1:
      return (
        <TextElement>
          <FormattedMessage id="activity.cta.remainingSpots.one" />
        </TextElement>
      )
    default:
      return (
        <TextElement>
          <FormattedMessage
            id="activity.cta.remainingSpots.other"
            values={{ count: spots }}
          />
        </TextElement>
      )
  }
}
