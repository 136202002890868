"use client"

import clsx from "clsx"

import { Avatar, Box, Icon, IconProps } from "@bullseye/components"

type Props = {
  isGroupChat: boolean
  isExperience: boolean
  title?: string
  imageUrl?: string
  size?: "sm" | "md"
  isSuspended?: boolean
  isLoading?: boolean
}

function ImageIcon({
  type,
  size,
}: {
  type: IconProps["type"]
  size: Props["size"]
}) {
  return (
    <Box
      className={clsx(
        "flex items-center justify-center overflow-hidden rounded-full border-2 border-solid border-white bg-primary-50",
        {
          "h-8 w-8": size === "sm",
          "h-12 w-12": size === "md",
        },
      )}
    >
      <Icon color="text-primary-900" type={type} size={size} />
    </Box>
  )
}

export const MessengerChannelIcon = ({
  isGroupChat,
  isExperience,
  imageUrl,
  title = "No content",
  size = "md",
  isSuspended = false,
  isLoading = false,
}: Props) => {
  if (isGroupChat) {
    return <ImageIcon type="group" size={size} />
  }
  if (isExperience && !imageUrl) {
    return <ImageIcon type="calendar" size={size} />
  }

  if (isSuspended) {
    return <ImageIcon type="user-outline" size={size} />
  }

  return (
    <Avatar
      size={size === "md" ? "xl" : "md"}
      imageUrl={imageUrl}
      name={title}
      blurred={isLoading}
    />
  )
}
