import { useMemo, useRef, useState } from "react"
import { TextInput as RNTextInput } from "react-native"
import { useCalendars } from "expo-localization"
import { Picker as RNP } from "@react-native-picker/picker"
import clsx from "clsx"
import { DateTime } from "luxon"
import { styled } from "nativewind"
import { FormattedMessage, useIntl } from "react-intl"

import { isWeb } from "../../utils"
import { Button } from "../Button"
import { Dropdown } from "../Dropdown"
import { Box } from "../Layout"
import WebPicker from "../Picker"
import { TextInput } from "../TextInput"
import { TimePickerProps } from "./index.d"

const StyledPicker = styled(RNP<any>)

const Item = styled(RNP.Item)

function hoursForLocale(is24h: boolean) {
  const hours = []
  for (let i = 0; i < (is24h ? 24 : 12); i++) {
    is24h ? hours.push(i < 10 ? `0${i}` : `${i}`) : hours.push(`${i + 1}`)
  }
  return hours
}

function minutesInInterval(interval: number) {
  const minutes = []
  for (let i = 0; i < 60; i += interval) {
    minutes.push(i < 10 ? `0${i}` : `${i}`)
  }
  return minutes
}

function TimePicker({
  value,
  onChange,
  placeholder,
  timeZoneId,
  ...props
}: TimePickerProps) {
  const [userCal] = useCalendars()
  const intl = useIntl()

  const [isOpen, setIsOpen] = useState(false)
  const is24h = userCal.uses24hourClock
  const [hours] = useState(hoursForLocale(is24h))
  const [minutes] = useState(minutesInInterval(5))

  const ref = useRef<RNTextInput>(null)
  const openDropdown = () => {
    ref.current?.blur()

    if (props.disabled) return

    setIsOpen(true)
  }

  const defaultTime = useMemo(() => {
    if (value) {
      return value
    }

    const time = new Date()
    const startHour = is24h ? 0 : 1
    time.setHours(startHour)
    time.setMinutes(0)

    return time
  }, [is24h])

  const [selectedHour, setSelectedHour] = useState(
    is24h
      ? defaultTime.getHours().toString()
      : (defaultTime.getHours() % 12).toString(),
  )
  const [selectedMinutes, setSelectedMinutes] = useState(
    defaultTime.getMinutes().toString(),
  )
  const [amOrPm, setAmOrPm] = useState(
    defaultTime.getHours() < 12 ? "AM" : "PM",
  )

  function handleSubmit() {
    const newTime = defaultTime

    let h = +selectedHour
    if (!is24h) {
      if (h === 12) {
        amOrPm === "AM" ? (h = 0) : h
      } else {
        h = amOrPm === "AM" ? h : h + 12
      }
    }

    const shiftedTime = DateTime.local(
      newTime.getFullYear(),
      newTime.getMonth() + 1,
      newTime.getDate(),
      h,
      +selectedMinutes,
    ).toJSDate()

    onChange(shiftedTime)
    setIsOpen(false)
  }

  const Picker = isWeb() ? WebPicker : StyledPicker
  return (
    <Dropdown
      open={isOpen}
      setOpen={(open) => setIsOpen(open)}
      title={placeholder}
      toggleComponent={
        <TextInput
          {...props}
          ref={ref}
          value={
            value
              ? intl.formatTime(value, {
                  timeStyle: "short",
                  timeZone: timeZoneId,
                })
              : placeholder
          }
          onFocus={openDropdown}
          caretHidden
          showSoftInputOnFocus={false}
          placeholder={placeholder}
          editable={!props.disabled}
        />
      }
    >
      <Box
        className={clsx("w-full flex-col pb-safe g-4", {
          "p-4": isWeb(),
        })}
      >
        <Box
          className={clsx("w-full flex-row", {
            "g-2": isWeb(),
          })}
        >
          <Picker
            selectedValue={selectedHour}
            onChange={(hour) => {
              setSelectedHour(hour)
            }}
            onValueChange={(hour) => {
              setSelectedHour(hour)
            }}
            className="flex-1"
            items={hours.map((hour) => ({
              label: hour,
              value: `${hour}`,
            }))}
          >
            {hours.map((item, index) => (
              <Item key={index} label={`${item}`} value={item} />
            ))}
          </Picker>
          <Picker
            selectedValue={selectedMinutes}
            onChange={(minutes) => {
              setSelectedMinutes(minutes)
            }}
            onValueChange={(minutes) => {
              setSelectedMinutes(minutes)
            }}
            className="flex-1"
            items={minutes.map((minute) => ({
              label: minute,
              value: `${minute}`,
            }))}
          >
            {minutes.map((item, index) => (
              <Item key={index} label={`${item}`} value={item} />
            ))}
          </Picker>
          {!is24h && (
            <Picker
              selectedValue={amOrPm}
              onChange={(ampm) => {
                setAmOrPm(ampm)
              }}
              onValueChange={(amOrPm) => {
                setAmOrPm(amOrPm)
              }}
              className="flex-1"
              items={[
                { label: "AM", value: "AM" },
                { label: "PM", value: "PM" },
              ]}
            >
              {["AM", "PM"].map((item, index) => (
                <Item key={index} label={`${item}`} value={item} />
              ))}
            </Picker>
          )}
        </Box>
        <Button as="button" handlePress={handleSubmit}>
          <FormattedMessage id="general.save" />
        </Button>
      </Box>
    </Dropdown>
  )
}

export default TimePicker
